import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { RouteName } from "../RouteName";
import Login from "./common/Login";
import Auth from "./common/Auth";
import NavBar from "./common/NavBar";
import VerifyRole from "./common/VerifyRole";
import { Role } from "../models/Role";
import ContentLayout from "./common/ContentLayout";
import SidePane from "./common/SidePane";
import Content from "./common/Content";
import { NotFound } from "./common/NotFound";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../translations/locales/en.json";
import fr from "../translations/locales/fr.json";
import LanguageDetector from "i18next-browser-languagedetector";
import { MenuContext } from "../context/MenuContext";
import TournamentContext from "../context/TournamentContext";
import Dashboard from "./Admin/Dashboard/Dashboard";
//import LiveScore from "./Admin/live-score/index";
import CreateTournament from "./Admin/CreateTournament";
import TournamentList from "./Admin/TournamentList";
// import Matches from "./Admin/MyMatches/Matches";

import MainDashboard from "./Admin/MainDashboard";
import AdminSideBar from "./Admin/AdminSideBar";
import Footer from "./common/Footer";
import UserManagement from "./Admin/UserManagement";
import UserManagement2 from "./Admin/UserManagement/index2";
import UserManagementTournament from "./Admin/UserManagement/indexTournaments";
import Dashboard2 from "./Admin/Dashboard/Dashboard2";
import UserProfile from "./Admin/UserManagement/Profile";
import DemoUser from "./Admin/demo-user";
import LiveScoreData from "./Admin/live-score/liveScoreData";
import LiveScoreQuickData from "./Admin/live-score/quickLiveScoreData";
import SignUp from "./common/SignUp";
import SubscriptionsManagement from "./Admin/Subscriptions";
import UpgradePlan from "./Admin/UserManagement/UpgradePlan";
import VerifyEmail from "./Admin/demo-user/verify-email";
import MainDashboard2 from "./Admin/MainDashboard_new";
import Tournaments from "./Admin/TournamentsTab";
import SingleTournament from "./Admin/SingleTournament";
import MyMatches from "./Admin/MyMatches/MyMatches";
import Matches from "./Admin/MyMatches/QuickMatchPointsTable";
import PointsTable from "./Admin/PointsTable";
import Teams from "./Admin/Teams";
// import CreateQuickMatch from "./Admin/MyMatches/CreateQuickMatch";
import CreateQuickTeam from "./Admin/MyMatches/CreateQuickTeams";
import QuickTeams from "./Admin/MyMatches/QuickTeams";
import TournamentMatches from "./Admin/TournamentMatches";
import QuickMatchPointsTable from "./Admin/MyMatches/QuickMatchPointsTable";
import CreateQuickMatch from "./Admin/MyMatches/CreateQuickMatch";
import PlanQuickMatch from "./Admin/MyMatches/PlanQuickMatch";
import QuickDashboard from "./Admin/Dashboard/QuickDashboard";
import QuickDashboard2 from "./Admin/Dashboard/QuickDashboard2";
const languages = ["en", "fr"];

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: { order: ["path", "navigator"] },
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
    whitelist: languages,
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

const App: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>();
  const [tournament, setTournament] = useState<any>();
  return (
    <Router>
      <Switch>
        <Route path={RouteName.LOGIN}>
          <Login />
        </Route>
        <Route path={RouteName.SIGNUP}>
          <SignUp />
        </Route>
        <Route path="/verify-email">
          <VerifyEmail />
        </Route>

        <Route path="/">
          <Auth>
            <TournamentContext.Provider value={[tournament, setTournament]}>
              <MenuContext.Provider value={[isMenuOpen, setIsMenuOpen]}>
                <div className="page-container">
                  <div className="content-wrap">
                    <Router>
                      <Switch>
                        <Route path="/admin">
                          <VerifyRole allow={[Role.SUPER_ADMIN]}>
                            <SuperAdminRouter />
                          </VerifyRole>

                          <VerifyRole allow={[Role.LEVEL01, Role.LEVEL02]}>
                            <UserRouter />
                          </VerifyRole>
                        </Route>
                      </Switch>
                    </Router>
                  </div>
                  <Footer />
                </div>
              </MenuContext.Provider>
            </TournamentContext.Provider>
          </Auth>
        </Route>
      </Switch>
    </Router>
  );
};

const SuperAdminRouter: React.FC = () => {
  return (
    <ContentLayout>
      <Router>
        <Route
          path={[
            RouteName.ADMIN_USER_MANAGEMENT,
            RouteName.ADMIN_DEMO_USERS,
            RouteName.ADMIN_SUBSCRIPTIONS,
            RouteName.ADMIN_USER_MANAGEMENT_TournamentList,
            RouteName.ADMIN_USER_MANAGEMENT_Tournament,
          ]}
          exact
          render={() => (
            <>
              <NavBar />
              <SidePane>
                <AdminSideBar />
              </SidePane>
            </>
          )}
        />
        <Content>
          <Switch>
            <Route path={RouteName.ADMIN_USER_MANAGEMENT}>
              <UserManagement />
            </Route>
            <Route path={RouteName.ADMIN_DEMO_USERS}>
              <DemoUser />
            </Route>
            <Route path={RouteName.ADMIN_SUBSCRIPTIONS}>
              <SubscriptionsManagement />
            </Route>
            <Route path={RouteName.ADMIN_USER_MANAGEMENT_TournamentList}>
              <UserManagement2 />
            </Route>
            <Route path={RouteName.ADMIN_USER_MANAGEMENT_Tournament}>
              <UserManagementTournament />
            </Route>
            <Route path={RouteName.ADMIN_LIVE_SCORE}>
              <LiveScoreData />
            </Route>
            <Route path={RouteName.ADMIN_QUICK_LIVE_SCORE}>
              <LiveScoreQuickData />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Content>
      </Router>
    </ContentLayout>
  );
};
const UserRouter: React.FC = () => {
  return (
    <ContentLayout>
      <Router>
        <Route
          path={[
            // RouteName.ADMIN_MATCHES,
            RouteName.ADMIN_MAIN_DASHBOARD,
            RouteName.ADMIN_POINTS_TABLE,
            RouteName.ADMIN_PROFILE,
            RouteName.ADMIN_UPGRADE_PACKAGE,
            RouteName.ADMIN_TOURNAMENT_DASHBOARD,
            RouteName.ADMIN_SINGLE_TOURNAMENT,
            RouteName.ADMIN_MY_MATCHES,
            RouteName.ADMIN_MY_MATCHES_POINTS_TABLE,
            // RouteName.ADMIN_MATCHES,
            RouteName.ADMIN_MY_MATCHES_QUICK_TEAMS,
            RouteName.ADMIN_CREATE_TOURNAMENT,
            RouteName.ADMIN_TOURNAMENT_TEAMS,
            RouteName.ADMIN_MY_MATCHES_CREATE_QUICK_MATCH,
            RouteName.ADMIN_MY_MATCHES_CREATE_QUICK_TEAMS,
            RouteName. ADMIN_MY_MATCHES_PLAN_QUICK_MATCH,

            RouteName.ADMIN_TOURNAMENT_MATCHES,
          ]}
          exact
          render={() => (
            <>
              <NavBar />
              <SidePane>
                <AdminSideBar />
              </SidePane>
            </>
          )}
        />
        <Content>
          <Switch>
            <Route path={RouteName.ADMIN_MY_MATCHES} exact>
              <MyMatches />
            </Route>
            {/* <Route path={RouteName.ADMIN_MATCHES} exact>
              <Matches />
            </Route> */}
            <Route path={RouteName.ADMIN_TOURNAMENT_MATCHES} exact>
              <TournamentMatches />
            </Route>
            <Route path={RouteName.ADMIN_TOURNAMENT_TEAMS} exact>
              <Teams />
            </Route>
            <Route path={RouteName.ADMIN_MY_MATCHES_QUICK_TEAMS} exact>
              <QuickTeams />
            </Route>
            <Route path={RouteName.ADMIN_MY_MATCHES_CREATE_QUICK_MATCH} exact>
              <CreateQuickMatch />
            </Route>
            <Route path={RouteName.ADMIN_MY_MATCHES_CREATE_QUICK_TEAMS} exact>
              <CreateQuickTeam/>
            </Route>
            <Route path={ RouteName. ADMIN_MY_MATCHES_PLAN_QUICK_MATCH} exact>
              <PlanQuickMatch/>
            </Route>

            <Route path={RouteName.ADMIN_POINTS_TABLE} exact>
              <PointsTable />
            </Route>
            <Route path={RouteName.ADMIN_MY_MATCHES_POINTS_TABLE} exact>
              <QuickMatchPointsTable />
            </Route>
            <Route path={RouteName.ADMIN_TOURNAMENT_DASHBOARD} exact>
              <Tournaments />
            </Route>
            <Route path={RouteName.ADMIN_SINGLE_TOURNAMENT} exact>
              <SingleTournament />
            </Route>
            <Route path={RouteName.ADMIN_MAIN_DASHBOARD} exact>
              <MainDashboard2 />
            </Route>
            <Route path={RouteName.ADMIN_DASHBOARD}>
              <Dashboard />
            </Route>
            <Route path={RouteName.ADMIN_QUICK_DASHBOARD}>
              <QuickDashboard />
            </Route>
            <Route path={RouteName.ADMIN_DASHBOARD2}>
              <Dashboard2 />
            </Route>
            <Route path={RouteName.ADMIN_QUICK_DASHBOARD2}>
              <QuickDashboard2 />
            </Route>
            <Route path={RouteName.ADMIN_LIVE_SCORE}>
              {/* <LiveScore /> */}
              <LiveScoreData />
            </Route>
            <Route path={RouteName.ADMIN_QUICK_LIVE_SCORE}>
              {/* <LiveScore /> */}
              <LiveScoreQuickData />
            </Route>
            <Route path={RouteName.ADMIN_CREATE_TOURNAMENT}>
              <CreateTournament />
            </Route>
            <Route path={RouteName.ADMIN_PROFILE}>
              <UserProfile />
            </Route>
            <Route path={RouteName.ADMIN_UPGRADE_PACKAGE}>
              <UpgradePlan />
            </Route>
            {/* <Route path={RouteName.ADMIN_TOURNAMENTS}>
              <Tournaments />
            </Route> */}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Content>
      </Router>
    </ContentLayout>
  );
};

export default App;
