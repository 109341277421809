import React, { useState } from "react";
import "../../vendors/styles/cricketAppStyles.css";
import TeamComponent from "../MainDashboard/index";
import MatchComponent from "../PointsTable";

const UserManagement: React.FC = () => {
  const [toggleMenu, setTogleMenu] = useState<number>(1);
  const handleClickToggle = (number: number) => {
    setTogleMenu(number);
  };
  return (
    <>

    
      <div className="main-container">
        <div className="pd-ltr-20">
          <div className={`${toggleMenu === 2 ? "mb-4" : "mb-2"} row`}>
            <div className="col-md-12 ">
              <div className="d-flex justify-content-center align-item-center  ">
                <ul className="nav col-md-12">
                  <li 
                    className={`${
                      toggleMenu === 1 ? "col-md-2 t-name btns" : "col-md-2 t-matches-count btns"
                    } nav-item cursor-p`}
                  >
                    <span
                      className="nav-link active"
                      onClick={() => handleClickToggle(1)}
                      aria-current="page"
                    >
                      Teams
                    </span>
                  </li>
                  <li
                    className={`${
                      toggleMenu === 2 ? "col-md-2 t-name btns " : "col-md-2 t-matches-count btns"
                    } nav-item cursor-p`}
                  >
                    <span
                      className="nav-link"
                      onClick={() => handleClickToggle(2)}
                    >
                      Matches
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {toggleMenu === 1 && (
            <>
              <TeamComponent />
            </>
          )}
          {toggleMenu === 2 && (
            <>
              <MatchComponent />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UserManagement;
