import React, { useEffect, useState, useContext } from "react";
import "../../vendors/styles/cricketAppStyles.css";
import RightArrow from "../../vendors/images/icon/right-arrow.png";
import Close from "../../vendors/images/icon/x.png";
import Crick1 from "../../vendors/images/crick1.png";
import { AdminService } from "../../../services/AdminService";
import swal from "sweetalert";
import UserContext from "../../../context/UserContext";
import { NavLink } from "react-router-dom";
import { environment } from "../../../environment/environment";
import { faInfinity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MainDashboard2: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [user] = useContext(UserContext);

  const [quickMatchCount, setQuickMatchCount] = useState<number>(0);
  const [tournamentCount, setTournamentCount] = useState<number>(0);
  const [tournamentsArray, setTournamentsArray] = useState<any>([]);
  const [isGoldPaymentLinkClicked, setGoldPaymentLinkClicked] =
    useState<boolean>(false);
  const [isSilverPaymentLinkClicked, setSilverPaymentLinkClicked] =
    useState<boolean>(false);
  const [toggleMsgShow, setToggleMsgShow] = useState<boolean>(true);

  const createQuickMatchClicked = () => {
    setPageNumber(4);
    console.log("createQuickMatchClicked");
  };
  const closePageClicked = () => {
    setPageNumber(1);
  };

  const matchList = [1, 2, 3];

  const handlePackageChange = () => {
    function getCookie(name: any) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          const index = cookie.indexOf("=");
          if (cookie.substring(0, index) === name) {
            cookieValue = cookie.substring(index + 1);
            break;
          }
        }
      }
      return cookieValue;
    }
    const prevSelection = getCookie("selectedPackage");

    console.log("userid index =>", user?._id);
    const userid = user?._id;

    console.log("prevSelection =>", prevSelection);
    if (
      prevSelection === "silver" &&
      user?.packageBought !== "GOLD" &&
      user?.packageBought !== "SILVER" &&
      user?.packageBought === "FREE"
    ) {
      console.log("inside silver");
      AdminService.upgradePlanRequesttoSilver(userid).then((userid) => {
        if (userid) {
          swal({
            title: "Silver Plan Requested!",
            text: "You will recieve payment link shortly",
            icon: "success",
            buttons: ["Ok"],
            dangerMode: false,
            className: "custom-swal",
          }).then((confirmed) => {
            document.cookie =
              "selectedPackage=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
          });
        }
      });
    } else if (prevSelection === "gold" && user?.packageBought !== "GOLD") {
      console.log("inside gold");
      AdminService.upgradePlanRequesttoGold(userid).then((userid) => {
        console.log("inside service====>");
        if (userid) {
          swal({
            title: "Gold  Plan Requested!",
            text: "You will recieve payment link shortly",
            icon: "success",
            buttons: ["Ok"],
            dangerMode: false,
            className: "custom-swal",
          }).then((confirmed) => {
            console.log("swal OK");
            document.cookie =
              "selectedPackage=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
          });
        }
      });
    }
  };

  handlePackageChange();

  useEffect(() => {
    AdminService.getTournamentList()
      .then((res) => {
        if (res.data) {
          console.log("tournaments count===>", res.data.length);
          const filteredTournaments = res.data?.filter(
            (tournament) => tournament.userId === user?._id
          );

          setTournamentCount(filteredTournaments.length);

          console.log("tournaments===>", res.data);
          setTournamentsArray(filteredTournaments);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    AdminService.getMatchesByUserId(user?._id)
      .then((res) => {
        console.log("matches", res.data);
        if (res.data) {
          setQuickMatchCount(res.data.length);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handlePackCloseBtnClick = () => {
    const userId = user?._id;
    AdminService.updateFirstLogin(userId).then((res: any) => {
      if (res.data) {
        // window.location.reload();
        console.log("res.data", res.data);
        setToggleMsgShow(false);
      }
    });
  };

  return (
    <>
      <div
        className={`${
          user?.userType === "SUPER_ADMIN" ? `` : `main-container`
        } ${pageNumber === 1 ? ` min-h-full dashboard bg-white` : null} `}
      >
        {pageNumber === 1 && (
          <div className="container">
            {user?.firstLogin &&
              user?.packageBought != "GOLD" &&
              toggleMsgShow && (
                <div className="w-100 h-auto py-4 d-flex justify-content-center align-items-center temp-pack-msg">
                  <div className="close-btn" onClick={handlePackCloseBtnClick}>
                    x
                  </div>
                  <p>
                    You are on free tier, upgrade to get more of CricView360!{" "}
                    <NavLink
                      to={`/admin/upgrade-package/${user?._id}}`}
                      className="a-tag-plan"
                    >
                      Click here to upgrade package
                    </NavLink>
                  </p>
                </div>
              )}
            <div className="row mb-10">
              <div className="col-md-7 h-auto">
                <div className="container-fluid mt-3 pl-pr-0 h-100">
                  <div className="">
                    <div className="row mb-10">
                      <div className="col-md-12 h-auto">
                        <div className="container-fluid mt-3 pl-pr-0 h-100">
                          <div className="d-flex w-100 h-100 justify-content-center align-items-center outer-box bg-color-changed-toGradient border-w border-r-10 rounded">
                            <div className="row w-100 d-flex align-items-center">
                              <div className="col-md-8 d-flex justify-content-left align-items-center">
                                <h2>Welcome to CricView360</h2>
                              </div>
                              <div>
                                {" "}
                                <img className=" ml-3" src={Crick1} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex mb-10 justify-content-between responsive-available">
                      <div className="col-md-12">
                        <div className=" mt-3 pl-pr-0 h-100">
                          <div className="d-flex w-100 h-100 justify-content-between align-items-center outer-box-changed bg-white border-w border-r-15 rounded pl-pr-0 pb-0 responsive-available">
                            <div className="col-md-3  h-100 d-flex justify-content-center align-items-center bg-color-changed border-w border-r-15 rounded responsive-mb quick-match">
                              {user?.userType === "SUPER_ADMIN" ? (
                                <span className="mt-3 f-18 fw-700 font-Poppins">
                                  Teams
                                </span>
                              ) : (
                                <NavLink
                                  // to={`/admin/my-matches/create-quick-match/${user?._id}`}
                                  to={"/admin/my-matches/create-quick-match"}
                                >
                                  <div className="arrow-btn ">
                                    <span className="f-17 f-color-white fw-500 font-Poppins ">
                                      Quick Match
                                    </span>
                                    <img
                                      className="arrow-icon-size ml-3"
                                      src={RightArrow}
                                      alt=""
                                    />
                                  </div>
                                </NavLink>
                              )}
                            </div>

                            <div
                              className="  d-flex flex-column col-md-4 ml-mr-2 
                             h-100 bg-color-changed  outer-box-changed border-w border-r-10 rounded pl-pr-6 justify-content-center align-items-center responsive-mb"
                            >
                              <h5 className="available">
                                Available Quick Matches
                              </h5>
                              <p className="mt-4 avail-mtchs">
                                {user?.packageBought === "GOLD" ? (
                                  <FontAwesomeIcon
                                    icon={faInfinity}
                                    style={{ color: "#000000" }}
                                  />
                                ) : user?.packageBought === "SILVER" ? (
                                  environment.ALLOWED_MATCH_COUNT_FOR_SILVER -
                                  quickMatchCount
                                ) : user?.packageBought === "FREE" ? (
                                  environment.ALLOWED_MATCH_COUNT_FOR_FREE -
                                  quickMatchCount
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>

                            <div className=" responsive-mb d-flex flex-column  col-md-4 ml-mr-2  h-100 bg-color-changed   outer-box-changed border-w border-r-10 rounded pl-pr-6 justify-content-center align-items-center">
                              <h5 className="available">
                                Available Tournaments
                              </h5>
                              <p className="mt-4 avail-mtchs">
                                {user?.packageBought === "GOLD" ? (
                                  <FontAwesomeIcon
                                    icon={faInfinity}
                                    style={{ color: "#000000" }}
                                  />
                                ) : user?.packageBought === "SILVER" ? (
                                  environment.ALLOWED_SILVER_TOURNAMENTS_COUNT -
                                  tournamentCount
                                ) : user?.packageBought === "FREE" ? (
                                  "0"
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 h-auto">
                <div className="container-fluid  pt-3 pb-2  mt-3  pl-pr-0 h-100">
                  <div className="d-flex w-100 h-100 justify-content-center align-item-start outer-box-changed bg-color-changed border-w border-r-10 rounded">
                    <div className="ml-3 h-auto w-100">
                      <div className="row topic">
                        <div className="col-md-12 d-flex justify-content-left align-items-center font-weight-bold">
                          News
                        </div>
                      </div>
                      <div className="row notif pad-top">
                        <div className="col-md-12 d-flex justify-content-left align-items-start ">
                          <div className="scrollable-list w-100">
                            <ul className=" w-100">
                              {user?.goldPaymentLink ? (
                                <li
                                  className="rounded news-li-items cursor-pointer"
                                  style={{
                                    backgroundColor: isGoldPaymentLinkClicked
                                      ? "transparent"
                                      : "#b0f2ffc5",
                                  }}
                                  onClick={() => {
                                    const absolutePayLink =
                                      user?.goldPaymentLink?.startsWith("http")
                                        ? user?.goldPaymentLink
                                        : "https://" + user?.goldPaymentLink;

                                    window.open(absolutePayLink, "_blank");
                                    setGoldPaymentLinkClicked(true);
                                  }}
                                >
                                  - Here is your payment link for Gold package
                                  upgrade:{" "}
                                  <span className="link-style">
                                    {user?.goldPaymentLink}
                                  </span>
                                </li>
                              ) : user?.silverPaymentLink ? (
                                <li
                                  className="rounded news-li-items"
                                  style={{
                                    backgroundColor: isSilverPaymentLinkClicked
                                      ? "transparent"
                                      : "#b0f2ffc5",
                                  }}
                                  onClick={() => {
                                    const absolutePayLink =
                                      user?.silverPaymentLink?.startsWith(
                                        "http"
                                      )
                                        ? user?.silverPaymentLink
                                        : "https://" + user?.silverPaymentLink;

                                    window.open(absolutePayLink, "_blank");
                                    setSilverPaymentLinkClicked(true);
                                  }}
                                >
                                  - Here is your payment link for Silver package
                                  upgrade:{" "}
                                  <span className="link-style">
                                    {user?.silverPaymentLink}
                                  </span>
                                </li>
                              ) : (
                                "- No news to show"
                              )}

                              {/* We can render other news items here */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pad-top">
              <div className="col-md-12">
                <div className="container-fluid mt-3 pl-pr-0">
                  <div className="d-flex w-100  justify-content-center align-items-center outer-box bg-white border-w border-r-10 rounded h-auto pl-pr-0">
                    <div className="row w-100 max-h-table">
                      <div className="col-md-12 mb-4 d-flex justify-content-left align-items-center">
                        <div className="table-responsive">
                          <p className="font-weight-bold mt-3">
                            Tournament Summary
                          </p>
                          <div className="over-flow-x">
                            <table className="table">
                              <thead className="table-head-matches">
                                <tr className="text-center">
                                  <th>Created date</th>
                                  <th>Name</th>
                                  <th>No. of matches</th>
                                  <th>No. of teams</th>
                                  <th>Overs per inning</th>
                                </tr>
                              </thead>
                              <tbody className="table-body-matches">
                                {tournamentsArray.map(
                                  (tournament: Tournament, index: number) => (
                                    <tr key={index} className="team-row">
                                      <td>
                                        {new Date(
                                          tournament.createdAt
                                        ).toLocaleDateString()}
                                      </td>
                                      <td>{tournament.name}</td>
                                      <td className="team-sec">
                                        {tournament.leagueTotalMatches}
                                      </td>
                                      <td>{tournament.numberOfTeams}</td>
                                      <td>{tournament.oversPerMatch}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {pageNumber === 4 && (
          <div className="container">
            <div className="row mb-10">
              <div className="col-md-12 h-auto">
                <div className="container-fluid mt-3 pl-pr-0 h-100">
                  <div className="d-flex w-100 h-100 justify-content-center align-items-center outer-box bg-white border-w border-r-10 rounded">
                    <div className="row w-100 d-flex align-items-center">
                      <div className="col-md-12 d-flex justify-content-between align-items-center">
                        <div>
                          <h4>Welcome to CricView360</h4>
                        </div>
                        <div>
                          <img
                            className="close-icon-size mr-1  db"
                            onClick={() => closePageClicked()}
                            src={Close}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 ">
                <div className="container-fluid mt-3 pl-pr-0">
                  <div className="d-flex w-100  justify-content-center align-items-center outer-box bg-white border-w border-r-10 rounded h-auto">
                    <div className="row w-100">
                      <div className="col-md-12 d-flex justify-content-left align-items-center">
                        <div className="col-md-12 mbb-20 mb-5">
                          <div className="container  border-top-10">
                            <div className="row pt-2 pl-3 pr-3 d-flex justify-content-center align-items-center">
                              <span className="f-color-black3 fw-600 f-25 mt-3 mb-3 font-Poppins">
                                Add a Quick Match
                              </span>
                            </div>
                            <div className="row ml-4 mr-4 border-r-9 pr-4 mb-1 min-h-35 mt-3">
                              <div className="table-responsive">
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <td className="justify-content-center align-item-center">
                                        <select className="select-dropdown2 fw-400 f-15 f-color-black3">
                                          <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                            Select Match Type
                                          </option>
                                          <option
                                            className="fw-400 f-15 f-color-black3 font-Poppins"
                                            value="REGULAR"
                                          >
                                            Regular
                                          </option>
                                          <option
                                            className="fw-400 f-15 f-color-black3 font-Poppins"
                                            value="QUALIFIER_ONE"
                                          >
                                            Qualifier 1
                                          </option>
                                          <option
                                            className="fw-400 f-15 f-color-black3 font-Poppins"
                                            value="QUALIFIER_TWO"
                                          >
                                            Qualifier 2
                                          </option>
                                          <option
                                            className="fw-400 f-15 f-color-black3 font-Poppins"
                                            value="ELIMINATER"
                                          >
                                            Eliminator
                                          </option>
                                          <option
                                            className="fw-400 f-15 f-color-black3 font-Poppins"
                                            value="SEMI_FINAL"
                                          >
                                            Semi-Final
                                          </option>
                                          <option
                                            className="fw-400 f-15 f-color-black3 font-Poppins"
                                            value="FINAL"
                                          >
                                            Final
                                          </option>
                                        </select>
                                      </td>
                                      <td className="justify-content-center align-item-center">
                                        <select className="select-dropdown fw-400 f-15 f-color-black3">
                                          <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                            Select Team
                                          </option>

                                          <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                            gfhg
                                          </option>
                                        </select>
                                      </td>
                                      <td>
                                        <span className="fw-400 f-15 f-color-black3 font-Poppins">
                                          VS
                                        </span>
                                      </td>
                                      <td>
                                        <div className="">
                                          <select className="select-dropdown fw-400 f-15 f-color-black3 font-Poppins">
                                            <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                              Select Team
                                            </option>
                                            <option value="">fgf</option>
                                          </select>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="row pt-2 pl-3 pr-3 d-flex justify-content-end align-items-end">
                              <div className="arrow-btn2">
                                <span className="f-17 f-color-white fw-500 font-Poppins">
                                  Save
                                </span>
                                <img
                                  className="arrow-icon-size ml-3"
                                  src={RightArrow}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MainDashboard2;
