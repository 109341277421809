import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "../vendors/styles/core.css";
import "../vendors/styles/style.css";
import "../vendors/styles/icon-font.css";
import { MenuContext } from "../../context/MenuContext";
import Logo from "../../components/vendors/images/icon/logo.png";
import matches from "../../components/vendors/images/matches.svg";
import pointstable from "../../components/vendors/images/pointstable.svg";
import dashboard from "../../components/vendors/images/dashboard.svg";
import userManagement from "../../components/vendors/images/fi_users.svg";
import profile from "../../components/vendors/images/profile_user.svg";
import logOut from "../../components/vendors/images/logOut.svg";
import UserContext from "../../context/UserContext";
import { Role } from "../../models/Role";
import { RouteName } from "../../RouteName";
import TournamentContext from "../../context/TournamentContext";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
const AdminSideBar: React.FC = () => {
  const [user] = useContext(UserContext);
  const [tournament] = useContext(TournamentContext);
  const [isMenuOpen, setIsMenuOpen] = useContext(MenuContext);
  const splittedURL = window.location.pathname.split("/");
  const tournamentId = splittedURL[splittedURL.length - 1];
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
  return (
    <div className={isMenuOpen ? "left-side-bar open" : "left-side-bar"}>
      <div className="brand-logo changed">
        <NavLink
          to={
            user?.userType === Role.SUPER_ADMIN
              ? `/admin/user-management`
              : `/admin/dashboard`
          }
        >
          <img src={Logo} className="light-logo" alt="cricView360_logo" />
        </NavLink>

        <div className="close-sidebar" onClick={toggleMenu}>
          <i className="ion-close-round"></i>
        </div>
      </div>
      <div className="menu-block customscroll">
        <div className="sidebar-menu  d-flex justify-content-center align-items-center">
          <ul id="accordion-menu " className="sidebar-menu-container ">
            {user?.userType === Role.LEVEL01 ||
            user?.userType === Role.LEVEL02 ? (
              <li>
                <NavLink
                  to={`/admin/dashboard`}
                  onClick={toggleMenu}
                  className={
                    window.location.pathname === `/admin/dashboard`
                      ? "dropdown-toggle selected-side no-arrow"
                      : "dropdown-toggle no-arrow"
                  }
                >
                  <img src={dashboard} alt="" className="micon" />
                  <span className="mtext">Dashboard</span>
                </NavLink>
              </li>
            ) : null}

            {user?.userType === Role.LEVEL01 ||
            user?.userType === Role.LEVEL02 ? (
              <li>
                <NavLink
                  // to={`/admin/my-matches/${user?._id}`}
                  to={`/admin/my-matches`}
                  onClick={toggleMenu}
                  className={
                    window.location.pathname === `/admin/my-matches`
                      ? "dropdown-toggle selected-side no-arrow"
                      : "dropdown-toggle no-arrow"
                  }
                >
                  <img src={pointstable} alt="" className="micon" />
                  <span className="mtext">My Matches</span>
                </NavLink>
              </li>
            ) : null}
            {user?.userType === Role.LEVEL01 ||
            user?.userType === Role.LEVEL02 ? (
              user?.packageBought === "GOLD" ||
              user?.packageBought === "SILVER" ? (
                <li>
                  <NavLink
                    to={`/admin/tournament-dashboard`}
                    onClick={toggleMenu}
                    className={
                      window.location.pathname ===
                        `/admin/tournament-dashboard` ||
                      window.location.pathname ===
                        `/admin/tournament/${tournament?.tournamentName}/${tournament?.tournamentId}`
                        ? "dropdown-toggle selected-side no-arrow"
                        : "dropdown-toggle no-arrow"
                    }
                  >
                    <img src={pointstable} alt="" className="micon" />
                    <span className="mtext">Tournaments</span>
                  </NavLink>
                </li>
              ) : user?.packageBought === "FREE" ? (
                <li>
                  <div
                    className={`cursor-pointer ${
                      window.location.pathname ===
                        `/admin/tournament-dashboard` ||
                      window.location.pathname ===
                        `/admin/tournament/${tournament?.tournamentName}/${tournament?.tournamentId}`
                        ? "dropdown-toggle selected-side no-arrow"
                        : "dropdown-toggle no-arrow"
                    }`}
                    onClick={() => {
                      swal({
                        icon: "warning",
                        title:
                          "Please upgrade your package to create tournaments!",
                      });
                    }}
                  >
                    <img src={pointstable} alt="" className="micon" />
                    <span className="mtext">
                      Tournaments{" "}
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        style={{ color: "#ff0000" }}
                      />
                    </span>
                  </div>
                </li>
              ) : null
            ) : null}

            {/* 
            {user?.userType === Role.LEVEL01 ||
            user?.userType === Role.LEVEL02 ? (
              <li>
                <NavLink
                  to={`/admin/matches/${user?._id}`}
                  onClick={toggleMenu}
                  className={
                    window.location.pathname === `/admin/matches/${user?._id}`
                      ? "dropdown-toggle selected-side no-arrow"
                      : "dropdown-toggle no-arrow"
                  }
                >
                  
                  <img src={matches} alt="" className="micon" />
                  <span className="mtext">Matches</span>
                </NavLink>
              </li>
            ) : null} */}

            {user?.userType === Role.SUPER_ADMIN ? (
              <li>
                <NavLink
                  to={"/admin/user-management"}
                  onClick={toggleMenu}
                  className={
                    window.location.pathname === "/admin/user-management" ||
                    RouteName.ADMIN_USER_MANAGEMENT_Tournament ||
                    RouteName.ADMIN_USER_MANAGEMENT_TournamentList
                      ? "dropdown-toggle selected-side no-arrow"
                      : "dropdown-toggle no-arrow"
                  }
                >
                  <img src={userManagement} alt="" className="micon" />
                  <div className="d-flex flex-column">
                    <span className="mtext mt-1">User Management</span>
                  </div>
                </NavLink>
              </li>
            ) : null}

            {/* {user?.userType === Role.SUPER_ADMIN ? (
              <li>
                <NavLink
                  to={`/admin/demo-users`}
                  onClick={toggleMenu}
                  className={
                    window.location.pathname === `/admin/demo-users`
                      ? "dropdown-toggle selected-side no-arrow"
                      : "dropdown-toggle no-arrow"
                  }
                >
                  <img src={userManagement} alt="" className="micon" />
                  <div className="d-flex flex-column">
                    <span className="mtext mt-1">Demo Users</span>
                  </div>
                </NavLink>
              </li>
            ) : null} */}
            {user?.userType === Role.SUPER_ADMIN ? (
              <li>
                <NavLink
                  to={`/admin/subscriptions`}
                  onClick={toggleMenu}
                  className={
                    window.location.pathname === `/admin/subscriptions`
                      ? "dropdown-toggle selected-side no-arrow"
                      : "dropdown-toggle no-arrow"
                  }
                >
                  <img src={userManagement} alt="" className="micon" />
                  <div className="d-flex flex-column">
                    <span className="mtext mt-1">Subscriptions</span>
                  </div>
                </NavLink>
              </li>
            ) : null}

            {user?.userType === Role.LEVEL01 ||
            user?.userType === Role.LEVEL02 ? (
              <li>
                <NavLink
                  to={`/admin/profile/${tournamentId}`}
                  onClick={toggleMenu}
                  className={
                    window.location.pathname === `/admin/profile`
                      ? "dropdown-toggle selected-side no-arrow"
                      : "dropdown-toggle no-arrow"
                  }
                >
                  <img src={profile} alt="" className="micon" />
                  <div className="d-flex flex-column">
                    <span className="mtext mt-1">Profile</span>
                  </div>
                </NavLink>
              </li>
            ) : null}
            {user?.userType === Role.LEVEL01 ||
            user?.userType === Role.LEVEL02 ? (
              // && user?.packageBought === "FREE"
              <li>
                <NavLink
                  to={`/admin/upgrade-package/${user?._id}`}
                  onClick={toggleMenu}
                  className={
                    window.location.pathname === `/admin/upgrade-package`
                      ? "dropdown-toggle selected-side no-arrow"
                      : "dropdown-toggle no-arrow"
                  }
                >
                  <img src={profile} alt="" className="micon" />
                  <div className="d-flex flex-column">
                    <span className="mtext mt-1">My Plan</span>
                  </div>
                </NavLink>
              </li>
            ) : null}

            <li>
              <div
                onClick={logout}
                className="dropdown-toggle selected-side no-arrow cursor-p"
              >
                <img src={logOut} alt="" className="micon" />
                <span className="mtext mt-1">Logout</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
