import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../../context/UserContext";
import "../../vendors/styles/cricketAppStyles.css";
import { AdminService } from "../../../services/AdminService";
import { useParams, NavLink, useHistory } from "react-router-dom";
import PlayIcon from "../../vendors/images/icon/fi_play.png";
import CustomModal from "../../common/Modal";
import RightArrow from "../../vendors/images/icon/right-arrow.png";
import swal from "sweetalert";
import { MatchService } from "../../../services/MatchService";

const QuickMatchPointsTable: React.FC = () => {
  const [allMatches, setAllMatches] = useState<any[]>([]);
  const [teams, setTeams] = useState<any[]>([]);
  const { matchId }: any = useParams();

  const [user] = useContext(UserContext);
  const history = useHistory();
  useEffect(() => {
    if (matchId) {
      AdminService.getQuickMatchData(matchId).then((res) => {
        if (res.success) {
          setAllMatches([res.data]);
          console.log("res data===|| ", [res.data]); //MATCHES ARRAY (OKAY)
          allTeamData();
        } else {
          console.log("error", res.error);
        }
      });
    } else {
      console.log("No matchId found");
    }
  }, [matchId]);

  const allTeamData = () => {
    return new Promise((resolve, reject) => {
      AdminService.getAllTeamsByMatchId(matchId).then((res) => {
        if (res.success) {
          setTeams(res.data);
          console.log("res data teams===>", res.data);
          resolve(res.data);
        } else {
          console.log("error", res.error);
          reject(res.error);
        }
      });
    });
  };

  const getAllPlayers = () => {
    var allPlayers: any = [];
    console.log("teams-------------------------------------> ", teams); //uSE STATE NOT WORKING FOR SETTEAMS
    teams &&
      teams.filter((team: any) => {
        console.log("team in getAllPlayers", team); //Get Team By team (team is got using tournamentId)

        console.log("getAllPlayers -> allMatches", allMatches); //MATCHES ARRAY (OKAY)
        var teamMatches = allMatches?.filter((m: any) => {
          console.log("getAllPlayers -> allMatches -> one match", m); //Get Match By Match (match is got using tournamentId)
          console.log("getAllPlayers -> allMatches -> one match ->", m.teams); //Get Match By Match (match is got using tournamentId)
          var teamFound = m.teams.filter((t: any) => {
            return t._id === team._id;
          });

          console.log("teamFound ++++++++++++++++++++++", teamFound);

          if (teamFound.length > 0) {
            return true;
          }
        });
        console.log("teamMatches ", teamMatches);

        console.log("team filtering team PPPLLLAAAYYYEEERRRSSSS===> ", team); //PLAYERS OBJECT ARRAY (NO PLAYERS HAVE BEEN POPULATED)
        team.players.filter((player: any) => {
          var playerTotalRuns = 0;
          var playerTotalWicktes = 0;
          var playerTotalBoundaries = 0;
          var playerTotalFourses = 0;
          var playerTotalSixes = 0;
          teamMatches?.filter((m: any) => {
            if (m.batFirst === team._id) {
              m.batFirstScoreboard &&
                m.batFirstScoreboard.playerBowls.filter((pb: any) => {
                  if (pb.status === "RUNS" && pb.batsmanId === player._id) {
                    playerTotalRuns = playerTotalRuns + pb.runs;

                    if (pb.runs === 4) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                      playerTotalFourses = playerTotalFourses + 1;
                    }
                    if (pb.runs === 6) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                      playerTotalSixes = playerTotalSixes + 1;
                    }
                  }

                  if (pb.status === "WICKET" && pb.bowlerId === player._id) {
                    playerTotalWicktes = playerTotalWicktes + 1;
                  }
                });
            } else {
              m.batSecondScoreboard &&
                m.batSecondScoreboard.playerBowls.filter((pb: any) => {
                  if (pb.status === "RUNS" && pb.batsmanId === player._id) {
                    playerTotalRuns = playerTotalRuns + pb.runs;

                    if (pb.runs === 4) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                      playerTotalFourses = playerTotalFourses + 1;
                    }
                    if (pb.runs === 6) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                      playerTotalSixes = playerTotalSixes + 1;
                    }
                  }

                  if (pb.status === "WICKET" && pb.bowlerId === player._id) {
                    playerTotalWicktes = playerTotalWicktes + 1;
                  }
                });
            }
          });

          allPlayers?.push({
            name: formatPlayerName(player.name),
            playerTotalRuns: playerTotalRuns,
            playerTotalWicktes: playerTotalWicktes,
            playerTotalBoundaries: playerTotalBoundaries,
            playerTotalFourses: playerTotalFourses,
            playerTotalSixes: playerTotalSixes,
          });
        });
      });

    return allPlayers;
  };
  function formatPlayerName(name: any) {
    const parts = name.split(" ");
    if (parts.length >= 2) {
      const lastName = parts.pop();
      const formattedParts = parts.map(
        (part: any) => part.charAt(0).toUpperCase() + "."
      );
      const formattedLastName =
        lastName.charAt(0).toUpperCase() + lastName.slice(1);
      return formattedParts.join(" ") + " " + formattedLastName;
    }

    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  const getAllPlayersWickets = () => {
    var allPlayers: any = [];

    teams &&
      teams.filter((team) => {
        var teamMatches = allMatches?.filter((m: any) => {
          var teamFound = m.teams.filter((t: any) => {
            return t._id === team._id;
          });

          if (teamFound.length > 0) {
            return true;
          }
        });

        team.players.filter((player: any) => {
          var playerTotalRuns = 0;
          var playerTotalWicktes = 0;
          var playerTotalBoundaries = 0;

          teamMatches?.filter((m: any) => {
            if (m.batFirst === team._id) {
              m.batSecondScoreboard &&
                m.batSecondScoreboard.playerBowls.filter((pb: any) => {
                  if (pb.status === "RUNS" && pb.batsmanId === player._id) {
                    playerTotalRuns = playerTotalRuns + pb.runs;

                    if (pb.runs === 4 || pb.runs === 6) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                    }
                  }

                  if (pb.status === "WICKET" && pb.bowlerId === player._id) {
                    playerTotalWicktes = playerTotalWicktes + 1;
                  }
                });
            } else {
              m.batFirstScoreboard &&
                m.batFirstScoreboard.playerBowls.filter((pb: any) => {
                  if (pb.status === "RUNS" && pb.batsmanId === player._id) {
                    playerTotalRuns = playerTotalRuns + pb.runs;

                    if (pb.runs === 4 || pb.runs === 6) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                    }
                  }

                  if (pb.status === "WICKET" && pb.bowlerId === player._id) {
                    playerTotalWicktes = playerTotalWicktes + 1;
                  }
                });
            }
          });

          allPlayers.push({
            name: formatPlayerName(player.name),
            playerTotalRuns: playerTotalRuns,
            playerTotalWicktes: playerTotalWicktes,
            playerTotalBoundaries: playerTotalBoundaries,
          });
        });
      });

    return allPlayers;
  };
  const getMostRuns = () => {
    var allPlayers = getAllPlayers();
    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalRuns,
        nameB = b.playerTotalRuns;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };

  const getMostWickets = () => {
    var allPlayers: any = getAllPlayersWickets();

    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalWicktes,
        nameB = b.playerTotalWicktes;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };

  const getMostBoundaries = () => {
    var allPlayers: any = getAllPlayers();

    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalBoundaries,
        nameB = b.playerTotalBoundaries;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };
  const getMostFours = () => {
    var allPlayers: any = getAllPlayers();

    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalFourses,
        nameB = b.playerTotalFourses;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };
  const getMostSixes = () => {
    var allPlayers: any = getAllPlayers();

    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalSixes,
        nameB = b.playerTotalSixes;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };

  const previosPage = () => {
    history.goBack();
  };
  return (
    <>
      <div
        className={`${
          user?.userType === "SUPER_ADMIN" ? `` : `main-container`
        }`}
      >
        <div className="container-fluid bg-white pt-3 pb-3 border2-r-10 mt-5">
          <i
            className="fa fa-arrow-left f-color-gray-7 ml-4 mt-4 cursor-p"
            aria-hidden="true"
            onClick={() => previosPage()}
          ></i>{" "}
          <span className="ml-4 fw-600">Stats Table</span>
          <div className="container ">
            <div className="row">
              <div className="col-lg mt-4">
                <div className="table-responsive">
                  <table className="table points-table">
                    <thead className="table-head-matches ">
                      <tr className="text-center">
                        <th scope="col" className="f-17 fw-500">
                          Most Runs
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {getMostRuns()?.map((player: any, index: any) => (
                        <tr
                          className="d-flex justify-content-between flex-wrap pl-3 pr-3"
                          key={index}
                        >
                          <td className="f-17 fw-400 ptl-4 ml-1">
                            {player.name}
                          </td>
                          <td className="f-17 fw-400 ptl-4 mr-1">
                            {player.playerTotalRuns}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg mt-4">
                <div className="table-responsive">
                  <table className="table points-table">
                    <thead className="table-head-matches">
                      <tr className="text-center">
                        <th scope="col" className="f-17 fw-500">
                          Most Wickets
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {getMostWickets()?.map((player: any, index: any) => (
                        <tr
                          className="d-flex justify-content-between flex-wrap pl-3 pr-3"
                          key={index}
                        >
                          <td className="f-17 fw-400 ptl-4 ml-1">
                            {player.name}
                          </td>
                          <td className="f-17 fw-400 ptl-4 mr-1">
                            {player.playerTotalWicktes}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg mt-4">
                <div className="table-responsive">
                  <table className="table points-table">
                    <thead className="table-head-matches">
                      <tr className="text-center">
                        <th scope="col" className="f-17 fw-500">
                          Most Boundaries
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {getMostBoundaries()?.map((player: any, index: any) => (
                        <tr
                          className="d-flex justify-content-between flex-wrap pl-3 pr-3"
                          key={index}
                        >
                          <td className="f-17 fw-400 ptl-4 ml-1">
                            {player.name}
                          </td>
                          <td className="f-17 fw-400 ptl-4 mr-1">
                            {player.playerTotalBoundaries}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg mt-4">
                <div className="table-responsive">
                  <table className="table points-table">
                    <thead className="table-head-matches">
                      <tr className="text-center">
                        <th scope="col" className="f-17 fw-500">
                          Most Fours
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {getMostFours()?.map((player: any, index: any) => (
                        <tr
                          className="d-flex justify-content-between flex-wrap pl-3 pr-3"
                          key={index}
                        >
                          <td className="f-17 fw-400 ptl-4 ml-1">
                            {player.name}
                          </td>
                          <td className="f-17 fw-400 ptl-4 mr-1">
                            {player.playerTotalFourses}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg mt-4">
                <div className="table-responsive">
                  <table className="table points-table">
                    <thead className="table-head-matches">
                      <tr className="text-center">
                        <th scope="col" className="f-17 fw-500">
                          Most Sixes
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {getMostSixes()?.map((player: any, index: any) => (
                        <tr
                          className="d-flex justify-content-between flex-wrap pl-3 pr-3"
                          key={index}
                        >
                          <td className="f-17 fw-400 ptl-4 ml-1">
                            {player.name}
                          </td>
                          <td className="f-17 fw-400 ptl-4 mr-1">
                            {player.playerTotalSixes}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickMatchPointsTable;
