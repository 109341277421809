import React, { useEffect, useState, useContext } from "react";
import "../../vendors/styles/cricketAppStyles.css";
import { useHistory } from "react-router-dom";
import { FormFeedback, Form, Input, Label, Modal, Row, Col } from "reactstrap";
import { useFormik } from "formik";
import { AdminService } from "../../../services/AdminService";
import * as Yup from "yup";
import swal from "sweetalert";
import UserContext from "../../../context/UserContext";

const UserManagement: React.FC = () => {
  const [modalCenter, setModalCenter] = useState(false);
  const [userData, setUserData] = useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(1);
  const [customerAdmin, setCustomerAdmin] = useState([] as any[]);
  const LIMIT = 10;
  const [user] = useContext(UserContext);
  const history = useHistory();
  useEffect(() => {
    viewAllUsers(LIMIT, offset);
  }, []);
  const viewAllUsers = (limit: number, offSet: number) => {
    AdminService.getAllUserListByAdmin(limit, offSet).then((res) => {
      setCustomerAdmin(res.data);
      setIsLoading(false);
      setHasMore(res.data?.length > 0 && res.data?.length == limit);
    });
  };

  const seeMore = () => {
    const newOffset = offset + 1;

    setOffset(newOffset);

    AdminService.getAllUserListByAdmin(LIMIT, newOffset).then((res) => {
      if (res.success) {
        setCustomerAdmin((previousSessions) => {
          return [...new Set([...previousSessions, ...res.data])];
        });
        setHasMore(res.data?.length > 0 && res.data?.length == LIMIT);
        setIsLoading(false);
      }
    });
  };
  function togCenter() {
    //  console.log("togcenter clicked")
    setModalCenter(!modalCenter);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function togCenterClose() {
    setModalCenter(false);
  }
  const validationStep = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
      userType: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Email Invalid").required("Please Enter Email"),
      name: Yup.string().required("Please Enter Name"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .max(40)
        .required("Please Enter  Password"),
      confirmPassword: Yup.string()
        .min(6, "Confirm password must be at least 6 characters")
        .max(40)
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Please Re-enter Your Password"),
      userType: Yup.string().required("Please Enter usetType"),
    }),
    onSubmit: (values, { resetForm }) => {
      const userData = {
        name: values.name,
        email: values.email,
        userType: values.userType,
        password: values.password,
      };

      registerUser(userData);
      resetForm();
    },
  });
  const registerUser = async (data: any) => {
    await AdminService.creatUserByAdmin(data).then((res) => {
      if (res.success) {
        swal({ icon: "success", title: "New user is created!" }).then(
          function () {
            setUserData({
              ...userData,
              name: "",
              email: "",
              password: "",
              confirmPassword: "",
              dateOfBirth: "",
              userType: "",
            });
            setModalCenter(false);
            setOffset(1);
            viewAllUsers(LIMIT, 1);
          }
        );
      } else {
        swal({
          icon: "error",
          title: res.error ? res.error : "Unable to create user!",
        });
      }
    });
  };
  const updateCustomerAdmin = (data: any) => {
    if (data) {
      const userDataId = {
        userId: data?._id,
      };
      const newStatus = data.userStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE";

      const updatedCustomerAdmin = customerAdmin.map((cus) => {
        if (cus._id === data._id) {
          return {
            ...cus,
            userStatus: newStatus,
          };
        }
        return cus;
      });
      if (data?.userStatus === "ACTIVE") {
        swal({
          title: "Are you sure?",
          text: "Are you sure that you want to inactivate this user?",
          icon: "warning",
          buttons: ["Cancel", "Confirm"],
          dangerMode: true,
          className: "custom-swal",
        }).then((willDelete) => {
          if (willDelete) {
            AdminService.activeUsersByAdmin(userDataId).then((res) => {
              if (res.success) {
                swal("Inactivated!", "User has been inactivated!", "success");
                setCustomerAdmin(updatedCustomerAdmin);
              } else {
                swal("Error!", "Something went wrong!", "error");
              }
            });
          }
        });
      } else {
        swal({
          title: "Are you sure?",
          text: "Are you sure that you want to activate this user?",
          icon: "warning",
          buttons: ["Cancel", "Confirm"],
          dangerMode: true,
          className: "custom-swal",
        }).then((willDelete) => {
          if (willDelete) {
            AdminService.activeUsersByAdmin(userDataId).then((res) => {
              if (res.success) {
                swal("Activated!", "User has been activated!", "success");
                setCustomerAdmin(updatedCustomerAdmin);
              } else {
                swal("Error!", "Something went wrong!", "error");
              }
            });
          }
        });
      }
    }
  };
  const formatNumber = (number: number) => {
    if (number === 10) {
      return "10+";
    } else if (number > 10 && number % 10 === 0) {
      return `${number}+`;
    } else {
      return number;
    }
  };
  const clickViewTournament = (userId: any) => {
    history.push(`/admin/user-tournament/${userId}`);
  };
  return (
    <>
      <div className="main-container">
        <div className="pd-ltr-20">
          {/* <div className="card-box pd-20 height-100-p mb-20 mt-20">
            <div className="row">
              <div className="col-md-4">
                <div className="d-xl-flex justify-content-start mb-2">
                  <div className="d-flex align-items-center mr-2">
                    <div className="avatar-md rounded-circle bg-soft card-avatar relative">
                      <span className="avatar-title text-size-avatr text-center-div">
                        A
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap flex-column ml-2 mt-2">
                    <span className="card-text-name">Admin</span>
                    <span className="card-text-email">{user?.email}</span>
                    <p className=" mt-2 bg-blue6 text-center w-50 rounded">
                      Admin Profile
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 mt-4">
                <div className="borderDashboard flex boxHeight">
                  <div className=" textCenter">
                    <span className="card-text-count">
                      {formatNumber(customerAdmin.length)}
                    </span>
                  </div>
                  <div className="  textCenter">
                    <span className="card-text-total">User Accounts</span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 user-add-btn-container">
                <button
                  type="button"
                  className="btn bg-blue7 text-white"
                  onClick={() => {
                    togCenter();
                  }}
                >
                  Add new user
                </button>
              </div>
            </div>
          </div> */}

          <div className="card-box pd-20 height-100-p mb-30 mt-20">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="d-flex  title mb-15  col-md-9 col-sm-12 mb-20 ">
                <h5 className="cardHearder font-Poppins mr-20  ">All Users </h5>
                <span className="card-text-count  bg-blue7 pr-2 pl-2  f-color-white rounded">
                  {formatNumber(customerAdmin.length)}
                </span>
              </div>
              <div className="col-md-3"></div>

              <div className="table-responsive">
                {!isLoading && customerAdmin.length > 0 && (
                  <table className="table">
                    <thead className="table-head-matches ">
                      <tr className="text-center">
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Subscription</th>
                        <th scope="col">UserStatus</th>
                        <th scope="col"></th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-body-matches ">
                      {!isLoading &&
                        customerAdmin.length > 0 &&
                        customerAdmin &&
                        customerAdmin.map((cus, index) => (
                          <tr key={index} className="text-center">
                            <td>{cus?.fname}</td>
                            <td>{cus?.email}</td>
                            <td>{cus?.packageBought}</td>
                            {/* <td>{cus?.userType}</td> */}
                            <td>{cus?.userStatus}</td>
                            <td>
                              <button
                                className="btn btn-primary bg-blue7 p-2 f-color-white font-Poppins"
                                onClick={() => clickViewTournament(cus._id)}
                              >
                                View tournament
                              </button>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <button
                                  className={`${
                                    cus.userStatus === "ACTIVE"
                                      ? "btn btn-danger btn-sm mr-2"
                                      : "btn btn-dark btn-sm mr-2"
                                  }`}
                                  title={`${
                                    cus.userStatus === "ACTIVE"
                                      ? "Deactivate user"
                                      : "Activate user"
                                  }`}
                                  onClick={() => updateCustomerAdmin(cus)}
                                >
                                  <i className="micon dw dw-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>

              {!isLoading && (
                <Row className=" d-flex justify-content-center align-items-center">
                  <Col xl="12 mt30 text-center">
                    {hasMore && (
                      <button
                        className="btn btn-warning mt-3"
                        onClick={() => seeMore()}
                      >
                        See More
                      </button>
                    )}
                  </Col>
                </Row>
              )}
            </div>
            <Modal
              isOpen={modalCenter}
              toggle={() => {
                togCenter();
              }}
              centered
            >
              <div>
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Add New User</h5>
                  <button
                    type="button"
                    onClick={() => {
                      togCenterClose();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="mb-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validationStep.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <div className="d-flex">
                          <Label className="form-label">User Name</Label>
                          <i
                            className="fa fa-star fa-xs mt-1 ms-1"
                            style={{ fontSize: "5px" }}
                          />
                        </div>

                        <Input
                          name="name"
                          type="text"
                          placeholder="Enter customer name"
                          value={validationStep.values.name}
                          onChange={validationStep.handleChange}
                          onBlur={validationStep.handleBlur}
                          invalid={
                            validationStep.touched.name &&
                            validationStep.errors.name
                              ? true
                              : false
                          }
                        />
                        {validationStep.touched.name &&
                        validationStep.errors.name ? (
                          <FormFeedback type="invalid">
                            {validationStep.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <div className="d-flex">
                          <Label className="form-label">Email</Label>
                          <i
                            className="fa fa-star fa-xs mt-1 ms-1"
                            style={{ fontSize: "5px" }}
                          />
                        </div>

                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter user email"
                          type="email"
                          value={validationStep.values.email}
                          onChange={validationStep.handleChange}
                          onBlur={validationStep.handleBlur}
                          invalid={
                            validationStep.touched.email &&
                            validationStep.errors.email
                              ? true
                              : false
                          }
                        />
                        {validationStep.touched.email &&
                        validationStep.errors.email ? (
                          <FormFeedback type="invalid">
                            {validationStep.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <div className="d-flex">
                          <Label className="form-label">Password</Label>
                          <i
                            className="fa fa-star fa-xs mt-1 ms-1"
                            style={{ fontSize: "5px" }}
                          />
                        </div>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter user password"
                          value={validationStep.values.password}
                          onChange={validationStep.handleChange}
                          onBlur={validationStep.handleBlur}
                          invalid={
                            validationStep.touched.password &&
                            validationStep.errors.password
                              ? true
                              : false
                          }
                        />
                        {validationStep.touched.password &&
                        validationStep.errors.password ? (
                          <FormFeedback type="invalid">
                            {validationStep.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <div className="d-flex">
                          <Label className="form-label mr-1">
                            Confirm Password
                          </Label>
                          <i
                            className="fa fa-star fa-xs mt-1 ms-1"
                            style={{ fontSize: "5px" }}
                          />
                        </div>
                        <Input
                          name="confirmPassword"
                          type="password"
                          placeholder="Re-enter user password"
                          onChange={validationStep.handleChange}
                          onBlur={validationStep.handleBlur}
                          value={validationStep.values.confirmPassword}
                          invalid={
                            validationStep.touched.confirmPassword &&
                            validationStep.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        {validationStep.touched.confirmPassword &&
                        validationStep.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validationStep.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <div className="d-flex">
                          <Label className="form-label">User Type</Label>
                          <i
                            className="fa fa-star fa-xs mt-1 ms-1"
                            style={{ fontSize: "5px" }}
                          />
                        </div>

                        <div className="col-md-12 " style={{ padding: "0" }}>
                          <Input
                            type="select"
                            className="form-control"
                            name="userType"
                            placeholder="Select User Type"
                            onChange={validationStep.handleChange}
                            onBlur={validationStep.handleBlur}
                            value={validationStep.values.userType}
                            invalid={
                              validationStep.touched.userType &&
                              validationStep.errors.userType
                                ? true
                                : false
                            }
                          >
                            <option value="">Select</option>
                            <option value="LEVEL01">Level1</option>
                            <option value="LEVEL02">Level2</option>
                          </Input>
                          {validationStep.touched.userType &&
                          validationStep.errors.userType ? (
                            <FormFeedback type="invalid">
                              {validationStep.errors.userType}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="row float-right newCustomerRes">
                        <button
                          type="submit"
                          className="btn-save mr-3 mt-2 btn-sm font-Poppins mb-4"
                        >
                          {" "}
                          Add User
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
