import React, { useEffect, useState } from "react";
import "../../vendors/styles/cricketAppStyles.css";
import { FormFeedback, Form, Input, Label, Modal } from "reactstrap";
import { useFormik } from "formik";
import { AdminService } from "../../../services/AdminService";
import * as Yup from "yup";
import swal from "sweetalert";
import TableData from "./table-data";
const userDefaultData = {
  name: "",
  email: "",
  demoStatus: "",
};

const DemoUserManagement: React.FC = () => {
  const [demoUserList, setDemoUserList] = useState<any[]>([]);
  const [modalCenter, setModalCenter] = useState(false);
  const [userData, setUserData] = useState<any>(userDefaultData);

  useEffect(() => {
    getDemoUsers();
  }, []);

  function togCenter() {
    setModalCenter(!modalCenter);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function togCenterClose() {
    setModalCenter(false);
  }

  const getDemoUsers = () => {
    AdminService.getDemoUsersList().then((res) => {
      if (res.success) {
        setDemoUserList(res.data);
      } else {
        console.log("error", res.error);
      }
    });
  };
  const rejectUser = (id: string, index: number) => {
    const data = {
      demoId: id,
    };
    AdminService.rejectDemoRequestUser(data).then((res) => {
      if (res.success) {
        const updateduser = res.data;
        swal({ icon: "success", title: "Rejected!" }).then(function () {
          const newArray = [...demoUserList];
          newArray[index] = updateduser;

          setDemoUserList(newArray);
        });
      } else {
        swal({
          icon: "error",
          title: res?.error ? res.error : "Something went wrong",
        });
      }
    });
  };
  const validationStep = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userData?.email,
      name: userData?.name,
      password: "",
      confirmPassword: "",

      userType: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Email Invalid").required("Please Enter Email"),
      name: Yup.string().required("Please Enter Name"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .max(40)
        .required("Please Enter  Password"),
      confirmPassword: Yup.string()
        .min(6, "Confirm password must be at least 6 characters")
        .max(40)
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Please Re-enter Your Password"),

      userType: Yup.string().required("Please Enter usetType"),
    }),
    onSubmit: (values, { resetForm }) => {
      const userData = {
        name: values.name,
        email: values.email,
        userType: values.userType,
        password: values.password,
      };

      registerUser(userData);
      resetForm();
    },
  });
  const registerUser = async (data: any) => {
    await AdminService.creatUserByAdmin(data).then((res) => {
      if (res.success) {
        swal({ icon: "success", title: "New user is created!" }).then(
          function () {
            setUserData({
              ...userData,
              name: "",
              email: "",
              password: "",
              confirmPassword: "",
              userType: "",
            });
            setModalCenter(false);
            handleFilterAndUpdate(res?.data.email, "APPROVED");
            setUserData("");
          }
        );
      } else {
        swal({
          icon: "error",
          title: res.error ? res.error : "Unable to create user!",
        });
      }
    });
  };
  const handleFilterAndUpdate = (emailToFilter: any, status: any) => {
    const filteredAndUpdatedArray = demoUserList.map((obj) => {
      if (obj.email === emailToFilter) {
        return { ...obj, demoStatus: status };
      }
      return obj;
    });
    setDemoUserList(filteredAndUpdatedArray);
  };
  const handleClickAdd = (user: any) => {
    setUserData({
      ...userData,
      name: user.name,
      email: user.email,
      password: "",
      confirmPassword: "",
      userType: "",
    });
    togCenter();
  };

  return (
    <>
      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box pd-20 height-100-p mb-30 mt-20">
            <div className="row">
              <div className="title mb-15  col-md-9 col-sm-12 mb-20 ">
                <h5 className="cardHearder font-Poppins">Demo Request Users</h5>
              </div>
              <div className="col-md-3"></div>
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-head-matches ">
                    <tr className="text-center">
                      <th scope="col" className="font-Poppins">
                        Name
                      </th>
                      <th scope="col" className="font-Poppins">
                        Email
                      </th>
                      <th scope="col" className="font-Poppins">
                        Message
                      </th>
                      <th scope="col" className="font-Poppins">
                        Status
                      </th>
                      <th scope="col" className="font-Poppins">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body-matches ">
                    {demoUserList?.map((user: any, index: number) => (
                      <TableData
                        user={user}
                        index={index}
                        handleClickAdd={handleClickAdd}
                        rejectUser={rejectUser}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalCenter}
        toggle={() => {
          togCenter();
        }}
        centered
      >
        <div>
          <div className="modal-header">
            <h5 className="modal-title mt-0  font-Poppins">Add Demo User</h5>
            <button
              type="button"
              onClick={() => {
                togCenterClose();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-2">
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validationStep.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <div className="d-flex">
                    <Label className="form-label">User Name</Label>
                    <i
                      className="fa fa-star fa-xs mt-1 ms-1"
                      style={{ fontSize: "5px" }}
                    />
                  </div>

                  <Input
                    name="name"
                    type="text"
                    placeholder="Enter customer name"
                    value={validationStep.values.name}
                    onChange={validationStep.handleChange}
                    onBlur={validationStep.handleBlur}
                    invalid={
                      validationStep.touched.name && validationStep.errors.name
                        ? true
                        : false
                    }
                    disabled={true}
                  />
                  {validationStep.touched.name && validationStep.errors.name ? (
                    <FormFeedback type="invalid">
                      {validationStep.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <div className="d-flex">
                    <Label className="form-label">Email</Label>
                    <i
                      className="fa fa-star fa-xs mt-1 ms-1"
                      style={{ fontSize: "5px" }}
                    />
                  </div>

                  <Input
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter user email"
                    type="email"
                    value={validationStep.values.email}
                    onChange={validationStep.handleChange}
                    onBlur={validationStep.handleBlur}
                    invalid={
                      validationStep.touched.email &&
                      validationStep.errors.email
                        ? true
                        : false
                    }
                    disabled={true}
                  />
                  {validationStep.touched.email &&
                  validationStep.errors.email ? (
                    <FormFeedback type="invalid">
                      {validationStep.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <div className="d-flex">
                    <Label className="form-label">Password</Label>
                    <i
                      className="fa fa-star fa-xs mt-1 ms-1"
                      style={{ fontSize: "5px" }}
                    />
                  </div>
                  <Input
                    name="password"
                    type="password"
                    placeholder="Enter user password"
                    value={validationStep.values.password}
                    onChange={validationStep.handleChange}
                    onBlur={validationStep.handleBlur}
                    invalid={
                      validationStep.touched.password &&
                      validationStep.errors.password
                        ? true
                        : false
                    }
                  />
                  {validationStep.touched.password &&
                  validationStep.errors.password ? (
                    <FormFeedback type="invalid">
                      {validationStep.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <div className="d-flex">
                    <Label className="form-label mr-1">Confirm Password</Label>
                    <i
                      className="fa fa-star fa-xs mt-1 ms-1"
                      style={{ fontSize: "5px" }}
                    />
                  </div>
                  <Input
                    name="confirmPassword"
                    type="password"
                    placeholder="Re-enter user password"
                    onChange={validationStep.handleChange}
                    onBlur={validationStep.handleBlur}
                    value={validationStep.values.confirmPassword}
                    invalid={
                      validationStep.touched.confirmPassword &&
                      validationStep.errors.confirmPassword
                        ? true
                        : false
                    }
                  />
                  {validationStep.touched.confirmPassword &&
                  validationStep.errors.confirmPassword ? (
                    <FormFeedback type="invalid">
                      {validationStep.errors.confirmPassword}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <div className="d-flex">
                    <Label className="form-label">User Type</Label>
                    <i
                      className="fa fa-star fa-xs mt-1 ms-1"
                      style={{ fontSize: "5px" }}
                    />
                  </div>

                  <div className="col-md-12" style={{ padding: "0" }}>
                    <Input
                      type="select"
                      className="form-control"
                      name="userType"
                      placeholder="Select User Type"
                      onChange={validationStep.handleChange}
                      onBlur={validationStep.handleBlur}
                      value={validationStep.values.userType}
                      invalid={
                        validationStep.touched.userType &&
                        validationStep.errors.userType
                          ? true
                          : false
                      }
                    >
                      <option value="">Select</option>
                      <option value="LEVEL01">Level1</option>
                      <option value="LEVEL02">Level2</option>
                    </Input>
                    {validationStep.touched.userType &&
                    validationStep.errors.userType ? (
                      <FormFeedback type="invalid">
                        {validationStep.errors.userType}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="row float-right newCustomerRes">
                  <button
                    type="submit"
                    className="btn-save mr-3 mt-2 btn-sm font-Poppins bg-blue7 text-white mb-4"
                  >
                    {" "}
                    Add
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DemoUserManagement;
