import React, { useContext, useEffect, useState } from "react";
import "../../vendors/styles/cricketAppStyles.css";
import correct from "../../vendors/images/icon/correct1.png";
import checkWhite from "../../vendors/images/icon/check-white.png";
import { AdminService } from "../../../services/AdminService";
import swal from "sweetalert";
import { FormFeedback, Form, Input, Label, Modal, Row, Col } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PaymentDetails, User } from "../../../models/User";
import { Role } from "../../../models/Role";
import { Upload } from "../../../models/Upload";
import UserContext from "../../../context/UserContext";
const splittedURL = window.location.pathname.split("/");
const user_id = splittedURL[splittedURL.length - 1];

type Props = {
  cus: {
    _id?: string;
    email: string;
    password: string;
    fname: string;
    lname: string;
    phone: string;
    role: Role;
    userType: string;
    socketId?: string;
    facebookId?: string;
    signedUpAs?: string;
    firebaseTokens?: string[];
    lastLoggedIn?: Date;
    photo?: Upload | string;
    packageBought: string;
    silverPaymentState: PaymentState;
    goldPaymentState: PaymentState;
    paymentLink?: string;
    userStatus: string;
    paymentDetails?: PaymentDetails;
  };
  reload: boolean;
  id: string;
};
enum PaymentState {
  NONE = "NONE",
  PAY_LINK_REQUESTED_SILVER = "PAY_LINK_REQUESTED_SILVER",
  PAY_LINK_REQUESTED_GOLD = "PAY_LINK_REQUESTED_GOLD",
  PAY_LINK_SENT = "PAY_LINK_SENT",
  PAID = "PAID",
}

const RowComponent: React.FC<Props> = (props) => {
  const [modalCenter, setModalCenter] = useState(false);
  const [customerAdmin, setCustomerAdmin] = useState([] as any[]);
  const [linkData, setLinkData] = useState<any>("");
  const [reload, setReload] = useState<boolean>(false);
  const [user] = useContext(UserContext);
  const [cus, setCus] = useState<any>();
  const [shallShowSendLink, setShallShowSendLink] = useState<boolean>(false);
  const [shallShowLinkSent, setShallShowLinkSent] = useState<boolean>(false);
  const [shallShowConfirm, setShallShowConfirm] = useState<boolean>(false);
  const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(false);
  const [packageBought, setPackageBought] = useState<string>("");

  const updateAction = () => {
    togCenterLink();
  };
  function togCenter() {
    setModalCenter(!modalCenter);
    removeBodyCss();
  }
  function togCenterLink() {
    setModalCenter(!modalCenter);
    removeBodyCss();
  }
  console.log("cus in row component =>", props.cus);
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const handlePlanConfirmClick = (cus: any) => {
    console.log("cus", cus);

    if (cus?._id) {
      swal({
        title: "Are you sure?",
        text: "Do you want to confirm this package upgrade?",
        icon: "info",
        buttons: ["Cancel", "Confirm"],
        dangerMode: false,
        className: "custom-swal",
      }).then((confirmed) => {
        if (confirmed) {
          AdminService.confirmPlan(cus._id).then((res) => {
            console.log("cus._id in service=>", cus._id);
            console.log("res::=>", res);
            const errorMsg = res?.error;
            if (res.success) {
              swal(
                "Plan Confirmed",
                "Plan has been confirmed successfully",
                "success"
              );
            } else {
              errorMsg && swal("Error!", errorMsg, "error");
            }
          });
        }
      });
    }
  };
  const updateCustomerAdmin = (data: any) => {
    if (data) {
      const userDataId = {
        userId: data?._id,
      };
      const newStatus = data.userStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE";

      const updatedCustomerAdmin = customerAdmin.map((cus) => {
        if (cus._id === data._id) {
          return {
            ...cus,
            userStatus: newStatus,
          };
        }
        return cus;
      });
      if (data?.userStatus === "ACTIVE") {
        swal({
          title: "Are you sure?",
          text: "Are you sure that you want to inactivate this user?",
          icon: "warning",
          buttons: ["Cancel", "Confirm"],
          dangerMode: true,
          className: "custom-swal",
        }).then((willDelete) => {
          if (willDelete) {
            AdminService.activeUsersByAdmin(userDataId).then((res) => {
              if (res.success) {
                swal("Inactivated!", "User has been inactivated!", "success");
                setCustomerAdmin(updatedCustomerAdmin);
              } else {
                swal("Error!", "Something went wrong!", "error");
              }
            });
          }
        });
      } else {
        swal({
          title: "Are you sure?",
          text: "Are you sure that you want to activate this user?",
          icon: "warning",
          buttons: ["Cancel", "Confirm"],
          dangerMode: true,
          className: "custom-swal",
        }).then((willDelete) => {
          if (willDelete) {
            AdminService.activeUsersByAdmin(userDataId).then((res) => {
              if (res.success) {
                swal("Activated!", "User has been activated!", "success");
                setCustomerAdmin(updatedCustomerAdmin);
              } else {
                swal("Error!", "Something went wrong!", "error");
              }
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    if (
      props?.cus?.silverPaymentState === "PAY_LINK_REQUESTED_SILVER" ||
      props?.cus?.goldPaymentState === "PAY_LINK_REQUESTED_GOLD"
    ) {
      setShallShowSendLink(true);
    } else if (
      props?.cus?.silverPaymentState === "PAY_LINK_SENT" ||
      props?.cus?.goldPaymentState === "PAY_LINK_SENT"
    ) {
      setShallShowLinkSent(true);
    } else if (
      props?.cus?.silverPaymentState === "PAID" ||
      props?.cus?.goldPaymentState === "PAID"
    ) {
      setShallShowConfirm(true);
    }
  }, [reload]);

  const validationStepLink = useFormik({
    enableReinitialize: true,
    initialValues: {
      link: "",
    },
    validationSchema: Yup.object({
      link: Yup.string().required("Please Enter payment link"),
    }),
    onSubmit: (values, { resetForm }) => {
      const linkData = {
        userId: customerAdmin,
        link: values.link,
      };
      sendLinkNow(linkData?.link, cus._id);
      setLinkData(linkData);
      resetForm();
    },
  });

  const sendLinkNow = async (link: any, userId: any) => {
    const linkdata = {
      userId: userId,
      link: link,
      requestedFor:
        props.cus?.silverPaymentState === "PAY_LINK_REQUESTED_SILVER"
          ? props?.cus?.silverPaymentState
          : props.cus?.goldPaymentState === "PAY_LINK_REQUESTED_GOLD"
          ? props?.cus?.goldPaymentState
          : PaymentState.NONE,
    };

    console.log("link data ===>", linkdata);

    AdminService.sendPayLink(linkdata).then((res) => {
      if (res.success) {
        swal(
          "Link sent",
          "Payment link has bees sent successfully",
          "success"
        ).then(() => setReload(!reload));
      } else {
        swal("Error!", "Something went wrong!", "error");
      }
    });
  };

  useEffect(() => {
    AdminService.getPackageBought(props.cus?._id)
      .then((res) => {
        console.log("res in getPackageBought =>", res.data.packageBought);
        const packageBought = res.data.packageBought;
        setPackageBought(packageBought);
        // if (packageBought === "SILVER") {
        //   setPackageBought("SILVER");
        //   setDisableConfirmBtn(true);
        // } else if (packageBought === "GOLD") {
        //   setDisableConfirmBtn(true);
        //   setPackageBought("GOLD");
        // }
        if (
          packageBought === "SILVER" &&
          props.cus?.silverPaymentState === "PAID"
        ) {
          setDisableConfirmBtn(true);
        } else if (
          packageBought === "GOLD" &&
          props.cus?.goldPaymentState === "PAID"
        ) {
          setDisableConfirmBtn(true);
        }
      })
      .catch((err) => {
        console.log("error in getPackageBought =>", err);
      });
  });
  return (
    <>
      <tr className="text-center" key={props.cus._id}>
        <td>{props.cus?.fname}</td>
        <td>{props.cus?.email}</td>
        <td className="package-bought">{props.cus?.packageBought}</td>
        <td>{props.cus?.userStatus}</td>
        <td>
          <span className="text-primary fw-bolder font-Poppins">
            {props.cus?.silverPaymentState ===
            PaymentState.PAY_LINK_REQUESTED_SILVER
              ? "Silver Plan Requested"
              : props.cus?.goldPaymentState ===
                PaymentState.PAY_LINK_REQUESTED_GOLD
              ? "Gold Plan Requested"
              : props.cus?.silverPaymentState === "PAY_LINK_SENT"
              ? "Link Sent"
              : props.cus?.goldPaymentState === "PAY_LINK_SENT"
              ? "Link Sent"
              : packageBought === "SILVER" &&
                props.cus?.goldPaymentState === "NONE" &&
                props.cus?.silverPaymentState === "PAID"
              ? "Confirmed"
              : packageBought === "GOLD" &&
                props.cus?.silverPaymentState === "NONE" &&
                props.cus?.goldPaymentState === "PAID"
              ? "Confirmed"
              : packageBought === "FREE" &&
                props.cus?.silverPaymentState === "NONE" &&
                props.cus?.goldPaymentState === "PAID"
              ? "Paid"
              : packageBought === "FREE" &&
                props.cus?.silverPaymentState === "PAID" &&
                props.cus?.goldPaymentState === "NONE"
              ? "Paid"
              : "No Plan Requested"}
          </span>
        </td>
        {props?.cus?.paymentDetails?.paymentReference ? (
          <td scope="col">{props?.cus?.paymentDetails?.paymentReference}</td>
        ) : (
          <td scope="col">N/A</td>
        )}
        <td>
          <div className="d-flex justify-content-end align-items-center">
            <button
              className={`btn btn-primary p-2 f-color-white font-Poppins mr-2  ${
                shallShowSendLink ? "bg-blue7" : "bg-transparent hidden"
              }`}
              onClick={() => {
                updateAction();
                setCus(props.cus);
              }}
            >
              Send Link
            </button>
            <button
              className={`btn btn-primary bg-blue7 p-2 f-color-white font-Poppins mr-2  ${
                shallShowLinkSent ? "bg-blue7" : "bg-transparent hidden"
              }`}
              onClick={() => {
                setCus(props.cus);
              }}
            >
              Link Sent
            </button>
            <button
              disabled={disableConfirmBtn}
              className={`btn btn-primary bg-blue7 p-2 f-color-white font-Poppins mr-2  ${
                shallShowConfirm ? "bg-blue7" : "bg-transparent hidden"
              }`}
              onClick={() => {
                setCus(props.cus);
                handlePlanConfirmClick(props.cus);
              }}
            >
              Confirm
            </button>

            <button
              className={`${
                props.cus.userStatus === "ACTIVE"
                  ? "btn btn-danger btn-sm mr-2"
                  : "btn btn-dark btn-sm mr-2"
              }`}
              title={`${
                props.cus.userStatus === "ACTIVE"
                  ? "Deactivate user"
                  : "Activate user"
              }`}
              onClick={() => updateCustomerAdmin(props.cus)}
            >
              <i className="micon dw dw-trash"></i>
            </button>
          </div>
        </td>
      </tr>
      <Modal
        isOpen={modalCenter}
        toggle={() => {
          togCenter();
        }}
        centered
      >
        <div>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Send/Update Payment Link</h5>
            <button
              type="button"
              onClick={() => {
                togCenterLink();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-2">
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validationStepLink.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <div className="d-flex">
                    <Label className="form-label">Payment Link</Label>
                    <i
                      className="fa fa-star fa-xs mt-1 ms-1"
                      style={{ fontSize: "5px" }}
                    />
                  </div>

                  <Input
                    name="link"
                    type="text"
                    placeholder="Enter payment link"
                    value={validationStepLink.values.link}
                    onChange={validationStepLink.handleChange}
                    onBlur={validationStepLink.handleBlur}
                    invalid={
                      validationStepLink.touched.link &&
                      validationStepLink.errors.link
                        ? true
                        : false
                    }
                  />
                  {validationStepLink.touched.link &&
                  validationStepLink.errors.link ? (
                    <FormFeedback type="invalid">
                      {validationStepLink.errors.link}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="row float-right newCustomerRes">
                  <button
                    type="submit"
                    className="btn-save mr-3 mt-2 btn-sm font-Poppins mb-4"
                    onClick={() => {
                      setModalCenter(false);
                      togCenterLink();
                    }}
                  >
                    {" "}
                    Send Link
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RowComponent;
