import React, { useEffect, useState } from "react";
import "../../vendors/styles/cricketAppStyles.css";

interface Props {
  toggleNextScoreboard?: () => boolean | undefined;
  fetchTournament?: () => Promise<void>;
  fetchUser?: () => Promise<void>;
  
  battingFirstTeam: string;
  ballingFirstTeam: string;
  match?: any;
  battingFirst?: boolean
}
interface BallingObject {
  ballNumber: number;
  batsmanId: string;
  boundary: boolean;
  bowlerId: string;
  createdAt: string;
  over: number;
  runs: number;
  status: string;
  _id: string;
}
const BallScore: React.FC<Props> = ({
  toggleNextScoreboard,
  fetchTournament,
  fetchUser,
  battingFirstTeam,
  ballingFirstTeam,
  match,
  battingFirst
}) => {
  const [boallingArr, setBoallingArr] = useState<BallingObject[]>([]);
  const [overByObject, setOverByObject] = useState<{ [over: number]: BallingObject[] }>();
  useEffect(() => {

    if (battingFirst) {
      const bollingArr1: BallingObject[] = match?.batFirstScoreboard?.playerBowls
      if (bollingArr1?.length > 0) {
        const sortedData = [...bollingArr1].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        const sortedDataReverse = sortedData.reverse()
        setBoallingArr(bollingArr1);
        const groupedByOver: { [over: number]: BallingObject[] } = {};
        sortedDataReverse.forEach((obj: BallingObject) => {
          const { over } = obj;
          if (!groupedByOver[over]) {
            groupedByOver[over] = [obj];
          } else {
            groupedByOver[over].push(obj);
          }
        });
        setOverByObject(groupedByOver);
      }
    } else {
      const bollingArr1: BallingObject[] = match?.batSecondScoreboard?.playerBowls
      if (bollingArr1?.length > 0) {
        const sortedData = [...bollingArr1].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        const sortedDataReverse = sortedData.reverse()
        setBoallingArr(bollingArr1);

        const groupedByOver: { [over: number]: BallingObject[] } = {};
        sortedDataReverse.forEach((obj: BallingObject) => {
          const { over } = obj;
          if (!groupedByOver[over]) {
            groupedByOver[over] = [obj];
          } else {
            groupedByOver[over].push(obj);
          }
        });

      //  console.log("groupedByOver==>", groupedByOver)
        setOverByObject(groupedByOver);
      }
    }
  }, [match]);

  const getOverCount = () => {
    var totalBalls = 0;

    boallingArr?.filter(function (b: any) {
      if (b.status == "RUNS" || b.status == "LEG_BYE" || b.status == "WICKET") {
        totalBalls = totalBalls + 1;
      }
    });
    var remindBalls = totalBalls % 6
    var overs = (totalBalls - remindBalls) / 6

    return `${overs}.${remindBalls}`;
  };
  const getBatFirstTotalRuns = () => {
    var totalRuns = 0;
    var totalWickets = 0;

    boallingArr.filter(function (b: any) {
      if (b.status != "WICKET") {
        totalRuns = totalRuns + b.runs;
      }

      if (b.status == "WICKET") {
        totalWickets = totalWickets + 1;
      }
    });

    return totalRuns + "/" + totalWickets;
  };

  return (
    <>
      <div className="container-fluid mt-3 ">
        <div className="d-flex h-auto justify-content-center align-items-center outer-box border-r-10 bg-white border-w border-r-10">
          <div className="container">
            <div className="row border-top-10 bg-gray2 d-flex  align-items-center">
              <div className="bg-rose-gradient  w-p-33 polygon1 border-top-l-10 d-flex justify-content-center align-items-center">
                <p className="pl-5 text-white line-h-24 f-20 mt-12 mb-12 font-inter">
                  {battingFirstTeam}
                </p>
                <p className="pl-5 text-white line-h-24 f-20 mt-12 mb-12 font-inter">
                  <span className="pl-4 pr-4">VS</span>
                </p>
                <p className="pl-5 text-white line-h-24 f-20 mt-12 mb-12 font-inter mr-5">
                  {ballingFirstTeam}
                </p>
              </div>
              <div className="d-flex justify-content-between pl-4 pr-4 align-items-center w-p2-33">
                <span className="">
                </span>
                <span className="fw-500 f-20 font-inter">
                  Overs: {getOverCount()}
                </span>
                <span className="fw-500 f-20 font-inter">
                  Score: {getBatFirstTotalRuns()}
                </span>
              </div>
            </div>
            <div className="container mt-4 border-g-10 border border-secondary ">
              <div className="ml-5 mr-5 row flex-nowrap overflow-auto " >

                {overByObject ? (
                  Object.keys(overByObject).reverse().map((overNumber) => {
                    const overObjects = overByObject[Number(overNumber)];

                    return (
                      <div key={overNumber} className="d-flex  ">
                        {overObjects.map((obj) => (
                          <div key={obj._id} className={`${obj?.status == "WICKET" ? "number-box-rose f-color-white" : obj?.status == "WIDE" ? "number-box-yellow f-color-black" : obj?.status == "NO_BALL" ? "number-box-blue f-color-white" : obj?.status == "LEG_BYE" ? "number-box-green f-color-white" : "f-color-black number-box"} mr-1 mt-3 mb-3 f-18 fw-700  font-Poppins`}>
                            {
                              obj?.status == "RUNS" ?
                                obj.runs
                                :
                                obj?.status == "WIDE" && obj.runs == 1 ?
                                  "WD"
                                  :
                                  obj?.status == "WIDE" && obj.runs != 1 ?
                                    <span>{`${obj.runs}`}<span className="f-12">WD</span></span>
                                    :
                                    obj?.status == "NO_BALL" && obj.runs == 1 ?
                                      "NB"
                                      :
                                      obj?.status == "NO_BALL" && obj.runs != 1 ?
                                        <span>{`${obj.runs}`}<span className="f-12">NB</span></span>
                                        :
                                        obj?.status == "WICKET" ?
                                          "W"
                                          :
                                          obj.runs
                            }
                          </div>
                        ))}
                        <div className="number-box-white mr-1 mt-3 mb-3 f-16 fw-400 font-Poppins">
                          {overNumber}{overNumber == "1" ? "st" : overNumber == "2" ? "nd" : overNumber == "3" ? "rd" : "th"}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>Yet to bat...</div>
                )}
              </div>
            </div>
            <div className="row mt-4 d-flex justify-content-left align-items-center flex-wrap">
              <div className="d-flex align-items-center mr-3">
                <div className="dot bg-rose"></div>
                <span className="ml-2 f-12 fw-400 f-color-gray font-inter">
                  Wicket
                </span>
              </div>
              <div className="d-flex align-items-center mr-3">
                <div className="dot bg-yellow"></div>
                <span className="ml-2 f-12 fw-400 f-color-gray font-inter">
                  Wide
                </span>
              </div>
              <div className="d-flex align-items-center mr-3">
                <div className="dot bg-blue"></div>
                <span className="ml-2 f-12 fw-400 f-color-gray font-inter">
                  No Ball
                </span>
              </div>
              <div className="d-flex align-items-center mr-3">
                <div className="dot bg-green"></div>
                <span className="ml-2 f-12 fw-400 f-color-gray font-inter">
                  Leg Bye
                </span>
              </div>
              <div className="d-flex align-items-center mr-3">
                <div className="dot bg-gray"></div>
                <span className="ml-2 f-12 fw-400 f-color-gray font-inter">
                  Normal
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BallScore;
