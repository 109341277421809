import React, { useContext, useEffect, useState } from "react";
import "../../vendors/styles/cricketAppStyles.css";
import correct from "../../vendors/images/icon/correct1.png";
import checkWhite from "../../vendors/images/icon/check-white.png";
import { AdminService } from "../../../services/AdminService";
import swal from "sweetalert";
import { FormFeedback, Form, Input, Label, Modal, Row, Col } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserContext from "../../../context/UserContext";
import jwt from "jsonwebtoken";

interface DecodedData {
  payment: string;
  secretKey: string;
  customFields: string;
  phone: string;
  localId: string;
}

const splittedURL = window.location.pathname.split("/");
const user_id = splittedURL[splittedURL.length - 1];
interface Paymentstate {
  silverPaymentState: string;
  goldPaymentState: string;
}
const UpgradePlan: React.FC = () => {
  const [reload, setReload] = useState(false);
  const [modalCenter, setModalCenter] = useState(false);
  const [user] = useContext(UserContext);
  const [webXPayData, setWebXPayData] = useState<any>();
  const [userPhone, setUserPhone] = useState(user?.phone || "");

  console.log("user from upgrade plan page =>", user);
  function togCenter() {
    setModalCenter(!modalCenter);
    removeBodyCss();
  }
  function togCenterLink() {
    setModalCenter(!modalCenter);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function togCenterClose() {
    setModalCenter(false);
  }
  const updateAction = () => {
    sessionStorage.clear();
    togCenterLink();
  };

  const handlePhoneChange = (event: any) => {
    setUserPhone(event.target.value);
  };

  const handleUpgradeToSilver = () => {
    const splittedURL = window.location.pathname.split("/");
    const userid = splittedURL[splittedURL.length - 1];
    if (userid) {
      swal({
        title: "Are you sure?",
        text: " You want to Upgrade the package to silver?",
        icon: "info",
        buttons: ["Cancel", "Confirm"],
        dangerMode: false,
        className: "custom-swal",
      }).then((confirmed) => {
        if (confirmed) {
          AdminService.getPackagePriceDetails(userid, "SILVER")
            .then((res) => {
              const token = res.data;
              console.log("Token received from server:", token);

              if (!token) {
                console.error(
                  "Empty or undefined token received from the server"
                );
                return;
              }
              // const secretKey = "HiranLive";

              const decodedData = jwt.decode(token);
              console.log("Decoded Token Content:", decodedData);

              // const decodedData: any = jwt.verify(token, secretKey);
              // console.log("Decoded Data:", decodedData);

              if (
                decodedData &&
                typeof decodedData === "object" &&
                "payment" in decodedData &&
                "secretKey" in decodedData &&
                "customFields" in decodedData &&
                "phone" in decodedData &&
                "localId" in decodedData
              ) {
                // Now you can safely cast to DecodedData
                const decoded: DecodedData = decodedData as DecodedData;

                // Now you can use the decoded data
                console.log("Decoded Data:", decoded);

                // Access specific fields from the decoded data
                const payment = decoded.payment;
                const secretKey = decoded.secretKey;
                const customFields = decoded.customFields;
                const phone = decoded.phone;
                const localId = decoded.localId;

                const webxPayData = {
                  payment,
                  secretKey,
                  customFields,
                  phone,
                  localId,
                };
                console.log("webxPayData", webxPayData);
                setWebXPayData(webxPayData);

                togCenter();
              } else {
                console.error("Invalid decoded data format");
              }
            })
            .catch((err) => {
              console.log("error in getPackagePriceDetails =>", err);
            });
        }
      });
    }
  };

  const handleUpgradeToGold = () => {
    const splittedURL = window.location.pathname.split("/");
    const userid = splittedURL[splittedURL.length - 1];
    if (userid) {
      swal({
        title: "Are you sure?",
        text: " You want to Upgrade the package to gold?",
        icon: "info",
        buttons: ["Cancel", "Confirm"],
        dangerMode: false,
        className: "custom-swal",
      }).then((confirmed) => {
        if (confirmed) {
          sessionStorage.setItem("selectedPackage", "no");

          AdminService.upgradePlanRequesttoGold(userid).then((userid) => {
            if (userid) {
              swal({
                title: "Success",
                text: "You will recieve payment link shortly",
                icon: "success",
                buttons: ["Ok"],
                dangerMode: false,
                className: "custom-swal",
              }).then((confirmed) => {
                setReload(!reload);
                document.cookie =
                  "selectedPackage=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
              });
            }
          });
        }
      });
    }
  };

  return (
    <>
      <div className="main-container">
        <div className="login-body d-lg-flex text-center w-100 bg-color-changed-toGradient">
          <div className="w-100 h-auto">
            <div className="w-100 package-container">
              <div className="row pad-20 jus-ar">
                <div className={`col-md-4 ${1 != 1 ? "column-hidden" : ""} `}>
                  <div className="free rounded d-flex">
                    <div className="plan-top">
                      <div className="plan-sum free-plan">
                        <h4> Free Plan</h4>
                      </div>
                    </div>
                    <div className="plan-bot">
                      <div className="features">
                        <div className="feature">
                          <div className="mr-10 ic mt-1 ">
                            <img src={correct} alt="" />
                          </div>
                          <div>Score $ Live Stream</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Ball by ball update</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Visualize Score</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Analyse Score</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Summary of match result</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>One match allowed</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Summary of match result</div>
                        </div>
                      </div>
                      <div className="up-btn hidden">
                        <button className="disable">Upgrade</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-md-4 ${1 != 1 ? "column-hidden" : ""} `}>
                  <div className="free silver rounded d-flex">
                    <div className="plan-top">
                      <div className="plan-sum free-plan">
                        {" "}
                        <h4>
                          {" "}
                          Silver Plan <br /> $15
                        </h4>
                      </div>
                    </div>
                    <div className="plan-bot">
                      <div className="features">
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Score $ Live Stream</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Ball by ball update</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Visualize Score</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Analyse Score</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Summary of match result</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>1 Tournament + 15 matches</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>One Tournament is allowed</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Summary of Series Result</div>
                        </div>
                      </div>
                      <div
                        className={`mt-4 up-btn-clicked ${
                          1 == 1 ? "" : "hidden"
                        } `}
                      >
                        <button onClick={handleUpgradeToSilver}>
                          Buy Silver
                        </button>
                      </div>

                      <div
                        className={`mt-4 up-btn-paid ${
                          1 == 1 ? "" : "hidden"
                        } ${1 == 1 ? "hidden" : ""}`}
                      >
                        {/* <button
                          onClick={
                            1 == 1
                              ? () => {
                                  const silverPayLink = "";
                                  const absolutePayLink =
                                    silverPayLink.startsWith("http")
                                      ? silverPayLink
                                      : "https://" + silverPayLink;

                                  window.open(absolutePayLink, "_blank");
                                }
                              : () => {
                                  console.log("Link requested for Gold");
                                }
                          }
                        >
                          Link Available
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-md-4`}>
                  <div className="free gold rounded d-flex">
                    <div className="plan-top">
                      <div className="plan-sum free-plan">
                        {" "}
                        <h4>
                          {" "}
                          Gold Plan <br /> $25
                        </h4>
                      </div>
                    </div>
                    <div className="plan-bot">
                      <div className="features">
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Score $ Live Stream</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Ball by ball update</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Visualize Score</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Analyse Score</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Summary of match result</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Unlimited tournements</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Unlimited matches</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Summary of Series Result</div>
                        </div>
                        <div className="feature">
                          <div className="mr-10 ic mt-1">
                            <img src={correct} alt="" />
                          </div>
                          <div>Decision-making statistics</div>
                        </div>
                      </div>
                      <div
                        className={`mt-4 up-btn-clicked ${
                          1 == 1 ? "" : "hidden"
                        } `}
                      >
                        <button onClick={handleUpgradeToGold}>Buy Gold</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isOpen={modalCenter}
              toggle={() => {
                togCenter();
              }}
              centered
            >
              <div>
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Send/Update Payment Link</h5>
                  <button
                    type="button"
                    onClick={() => {
                      togCenterLink();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="mb-2">
                    <form
                      className=""
                      action="https://stagingxpay.info/index.php?route=checkout/billing"
                      name="vote"
                      id="vote"
                      method="post"
                    >
                      <input
                        type="text"
                        name="first_name"
                        defaultValue={user?.fname}
                      />
                      <input
                        type="text"
                        name="last_name"
                        defaultValue={user?.lname}
                      />
                      <input
                        type="text"
                        name="email"
                        defaultValue={user?.email}
                      />
                      <input
                        type="text"
                        name="contact_number"
                        defaultValue={user?.phone}
                      />
                      <input
                        type="text"
                        name="address_line_one"
                        defaultValue="270/A"
                      />
                      <input
                        type="text"
                        name="process_currency"
                        defaultValue="LKR"
                      />
                      <input
                        type="text"
                        name="secret_key"
                        defaultValue={webXPayData?.secretKey}
                      />
                      <input type="text" name="custom_fields" defaultValue="" />
                      <input
                        type="text"
                        name="payment"
                        defaultValue={webXPayData?.payment}
                      />
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpgradePlan;
