import React, { useEffect, useState } from "react";
import "../../vendors/styles/cricketAppStyles.css";
import {  useHistory } from 'react-router-dom';
import createTournamentProfile from "../../../components/vendors/images/createTournamentProfile.svg";
import search from "../../../components/vendors/images/search.svg";
import { AdminService } from "../../../services/AdminService";

const UserManagement: React.FC = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tournamentList, setTournamentList] = useState<any[]>();
  const [filteredTournaments, setFilteredTournaments] = useState<any[]>();
  const splittedURL = window.location.pathname.split("/");
  const userLiveId = splittedURL[splittedURL.length - 1];

  useEffect(() => {
    getTeams();
  }, []);
  useEffect(() => {
    filterTournaments();
  }, [tournamentList, searchTerm]);
  const getTeams = () => {
    AdminService.getTournamentList().then((res) => {
      if (res.success) {
        const response = res.data
        const filteredTournaments = response?.filter(tournament => tournament.userId === userLiveId);
        setTournamentList(filteredTournaments);
      } else {
        console.log("error", res.error);
      }
    });
  };

 
  const clickViewTournament = (tournamentId:any) => {
  //  console.log("tournamentId==>",tournamentId)
    history.push(`/admin/user-management-tournament/${tournamentId}`)
  }
  const filterTournaments = () => {
    const filtered = tournamentList?.filter((tournament) =>
      tournament.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTournaments(filtered);
  };
 // console.log("filteredTournaments==>",filteredTournaments)
  return (
    <>
      <div className="main-container">
       
        <div>
        <div className="tournamentlist-body2 d-flex justify-content-center align-items-center  ">
          <div className="row  tournamelist-main-contianer" style={{ width: "80%", height: "90%" }}>
            <div className="col-lg-6  d-flex justify-content-center align-items-center ">
              <div className="tournament-list-container2 border-r-10 ">
                <div className="d-flex justify-content-center align-items-center mb-4">
                  <span className="mb-1 h-1 text-center tournament-creation-header mb-4">
                    Tournaments
                  </span>

                </div>
                
                <div className=" d-flex justify-content-end tournament-list-img-container" >

                  <img
                    src={createTournamentProfile}
                    alt="Right Background"
                    className="tournament-list-img"
                  />

                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <div className="tournament-list-container2 border-r-10 ">
                <div className="d-lg-flex justify-content-between mb-4">
                  <span className=" h-1  tournament-list-creation-header mb-4 mt-1">
                    Tournament List
                  </span>
                  <div className="d-flex  align-items-center tournament-list-search m-1 mt-2">
                    <img
                      src={search}
                      alt="Search"
                      className="mr-1"
                    />
                    <input
                      type="text"
                      className="search-input"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                </div>

                <div className="table-responsive mt-5 tournament-tabel">
                  <table className="table ">
                    <tbody className="tournament-list-table">
                      {filteredTournaments?.map((tournament: any, index: any) => (
                        <tr
                          className="d-flex justify-content-between flex-wrap"
                          key={index}
                          onClick={() => clickViewTournament(tournament._id)}
                        >
                          <td>{tournament.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
