import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import "../../vendors/styles/cricketAppStyles.css";
import createTournamentProfile from "../../../components/vendors/images/createTournamentProfile.svg";
import player2Profile from "../../../components/vendors/images/player2.png";
import CustomModal from "../../common/Modal";
import RightArrow from "../../vendors/images/icon/right-arrow.png";
import { AdminService } from "../../../services/AdminService";
import { User } from "../../../models/User";
import swal from "sweetalert";
import UserContext from "../../../../src/context/UserContext";
import { environment } from "../../../environment/environment";



const splittedURL = window.location.pathname.split("/");
const tournament_id = splittedURL[splittedURL.length - 1];
const UserProfile: React.FC = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [userdata, setUserdata] = useState<User>();
  const [userProfileImageLink, setUserProfileImageLink] = useState<string>("");
  const [userImage, setUserImage] = useState<File | null>(null);
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [user] = useContext(UserContext);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);


  const handleUpload = () => {
    // Create an instance of FormData
    const formData = new FormData();

    // Append the user ID to the form data
    if (user?._id) {
      formData.append("userId", user._id);
    }

    // Check if userImage is not null before appending
    if (userImage) {
      formData.append("userImage", userImage);
    } else {
      console.error("No image file selected");
      return; // Exit the function if no image is selected
    }

    console.log("formData", formData);

   


    // Send the form data
    AdminService.uploadProfilePicture(formData)
      .then((response) => {
        console.log("Upload successful", response);
        swal({ icon: "success", title: "New Profile picture is uploaded!" }).then()
         setIsImageModalOpen(false);
         fetchUser();
       
      })
      .catch((error) => {
        console.error("Upload failed", error);
      });
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleOpenImageModal = () => {
    setIsImageModalOpen(true);
  };

  useEffect(() => {
    // console.log('user ',user);
    fetchUser();
  }, []);

  const fetchUser = async () => {
    AdminService.getUserDetails()
      .then((res: any) => {
        console.log("res.data ", res.data);
        setUserdata(res.data);
        const userData: User = res.data;
        if (userData?.profileImage) {
          const baseUrl = environment.api_url;
          const absoluteUrl = `${baseUrl}/uploads/${userData.profileImage}`;
          console.log("Absolute URL ", absoluteUrl);
          userData.profileImageUrl = absoluteUrl;
          setUserProfileImageLink(absoluteUrl);
        }
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  const resetPassword = async () => {
    try {
      const { currentPassword, newPassword, confirmPassword } = formData;

      // console.log("currentPassword", currentPassword);
      // console.log("newPassword", newPassword);

      // console.log("Submitting password update...");

      if (newPassword === confirmPassword) {
        const response = await AdminService.resetPassword({
          currentPassword,
          newPassword,
        });
        if (response.success) {
          // console.log("Password changed successfully");
          swal("Password changed successfully", "", "success").then(() => {
            logout();
          });
        } else {
          // console.log("Failed to update password:", response.error);
          const errorMessage = response.error || "An error occurred";
          swal("Failed to update password:", errorMessage, "error");
        }
      } else {
        //  console.log("Passwords do not match");
        swal("Passwords do not match", "", "error");
      }
    } catch (error) {
      //   console.log("Error updating password:", error);
      const errorMessage = error || "An error occurred";
      swal("Passwords do not match", errorMessage, "error");
    }
  };

  const upLoadProfilePicture = () => {};

  return (
    <>
      <div className="main-container">
        <div className="login-body profile d-lg-flex text-center col-431">
          <div className="box-1-create-tournament profile-431">
            <div className="create-tournament-container border-r-10 width-450 profile-2-431">
              <div className="mb-4">
                <span className="h3 h-1 text-center tournament-creation-header mb-5 py-4">
                  Profile Details
                </span>
              </div>

              <div className="textbox-create-tournament-title text-left d-flex align-items-center mar-btm-0 justify-content-between">
                <p className="textbox-create-tournament-title text-left">
                  Name :
                </p>
                <p className="font-weight-bold">
                  {userdata?.fname} {userdata?.lname}
                </p>
              </div>
              <div className="textbox-create-tournament-title text-left d-flex align-items-center mar-btm-0 justify-content-between">
                <p className="textbox-create-tournament-title text-left">
                  Email :
                </p>
                <p className="font-weight-bold">{userdata?.email}</p>
              </div>
              <div className="textbox-create-tournament-title text-left d-flex align-items-center mar-btm-0 justify-content-between">
                <p className="textbox-create-tournament-title text-left">
                  Current Plan :
                </p>
                <p className="font-weight-bold">{userdata?.packageBought}</p>
              </div>
              <div className="textbox-create-tournament-title text-left d-flex align-items-center mar-btm-0 justify-content-between">
                <p className="textbox-create-tournament-title text-left ">
                  Update Password :
                </p>{" "}
                <div
                  className="arrow-btn2 update-btn"
                  onClick={handleOpenModal}
                >
                  <span className="f-color-white fw-500 font-Poppins update-fs">
                    Update
                  </span>
                  <img
                    className="arrow-icon-size ml-3"
                    src={RightArrow}
                    alt=""
                  />
                </div>
              </div>

              <div className="text-left d-flex align-items-center justify-content-between">
                <p className="textbox-create-tournament-title text-left">
                  Profile Picture :
                </p>
             
                <div
                  className="arrow-btn2 update-btn"
                  onClick={handleOpenImageModal}
                >
                  <span className="f-color-white fw-500 font-Poppins update-fs">
                    Upload
                  </span>
                  <img
                    className="arrow-icon-size ml-3"
                    src={RightArrow}
                    alt=""
                  />
                </div>
              </div>
       
            </div>
          </div>
          <div className = { userProfileImageLink ? "userProfileImage d-flex flex-column h-100 mt-3 mr-3" : "playerBackground2 hidden-431 playerImage d-flex flex-column h-100 mt-3 mr-3"}>
  
           
            <div
              className={
                userProfileImageLink ? "userProfileImage" : ""
              }
            >
 <img
               className={user?.profileImageUrl ? '' : 'playerImage'}
              src={userProfileImageLink?userProfileImageLink:player2Profile}
              alt="Right Background"
            />
            </div>
          </div>
        </div>
      </div>
      <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="py-3 px-3 update-modal">
          <div className="d-flex justify-content-center align-item-center mb-2">
            <p className="h3 fw-400 f-color-black3 font-Poppins text-left">
              Update your password
            </p>
          </div>
          <div className="mb-2 justify-content-between align-item-center">
            <label htmlFor="currentPassword">Current Password:</label>
            <div className="textbox-create-tournament mb-3">
              <input
                type="password"
                id="currentPassword"
                name="currentPassword"
                placeholder=""
                value={formData.currentPassword}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    currentPassword: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="mb-2 justify-content-between align-item-center">
            <label htmlFor="newPassword">New Password:</label>
            <div className="textbox-create-tournament mb-3">
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                placeholder=""
                value={formData.newPassword}
                onChange={(e) => {
                  setFormData({ ...formData, newPassword: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="mb-2 justify-content-between align-item-center">
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <div className="textbox-create-tournament mb-3">
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder=""
                value={formData.confirmPassword}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    confirmPassword: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="row pt-2 pl-3 pr-3 justify-content-center align-items-center">
            <button
              type="submit"
              className="arrow-btn2"
              onClick={resetPassword}
            >
              <span className="f-17 f-color-white fw-500 font-Poppins">
                Update
              </span>
              <img
                className="arrow-icon-size ml-3"
                src={RightArrow}
                alt="Arrow"
              />
            </button>
          </div>
        </div>
      </CustomModal>
      <CustomModal isOpen={isImageModalOpen} onClose={handleCloseImageModal}   >
      <div style={{ width: '500px', height: '400px' ,display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
        <div className="py-3 px-3 update-modal">
          <div className="d-flex justify-content-center align-item-center mb-2">
            <p className="h3 fw-400 f-color-black3 font-Poppins text-left">
              Update Your Profile Picture
            </p>
          </div>

          <input
            type="file"
            accept="image/*"
            className="text-right"
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                setUserImage(e.target.files[0]);
                const fileUrl = URL.createObjectURL(e.target.files[0]);
                setImagePreviewUrl(fileUrl);
              } else {
                setUserImage(null); // Reset to null if no file is selected
                setImagePreviewUrl(null);
              }
            }}
          />



<div className="image-preview mt-2 mx-auto" style={{ width: '200px', height: '200px', border: '3px solid #ccc', borderRadius:'50%',display: 'flex', justifyContent: 'center', alignItems: 'center',overflow:'hidden' }}>
  {imagePreviewUrl ? (
    <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
  ) : (
    <span style={{ color: '#ccc' }}>No image selected</span>
  )}
</div>

<div className="d-flex align-items-center justify-content-between">
<div className="row pt-2 pl-2 pr-2 justify-content-center align-items-center ml-1 ">
            <button
              type="submit"
              className="arrow-btn-save"
              onClick={handleUpload}
            >
              <span className="f-17 f-color-white fw-500 font-Poppins">
              Save
              </span>
              <img
                className="arrow-icon-size ml-3"
                src={RightArrow}
                alt="Arrow"
              />
            </button>
          </div>
          <div className="row pt-2 pl-2 pr-2 justify-content-center align-items-center mr-2">
            <button
              type="submit"
              className="arrow-btn-save"
              onClick={handleCloseImageModal}
            >
              <span className="f-17 f-color-white fw-500 font-Poppins">
              Cancel
              </span>
              <img
                className="arrow-icon-size ml-3"
                src={RightArrow}
                alt="Arrow"
              />
            </button>
          </div>
</div>

      


        </div>
        </div>
      </CustomModal>
    </>
  );
};

export default UserProfile;
