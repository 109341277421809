import axios, { AxiosResponse } from "axios";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";

// import { ApiResponse, MatchDetails } from "../models/Match";

export class MatchService {
  static subscribeToNewBall: any;

  public static async getMatchDetails(matchId: any): Promise<any> {
    const url = Util.apiAuthUrl(`match-details/${matchId}`);
    return await axios.get<any>(url);
  }
  public static async getQuickMatchDetails(matchId: any): Promise<any> {
    const url = Util.apiAuthUrl(`quick-match-details/${matchId}`);
    return await axios.get<any>(url);
  }


  public static async updateMatchScore(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update-score");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async updateTeamCrr(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update-score");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }




  public static async updateQuickMatchScore(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update-quick-score");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async updateExtraRuns(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update-extra-run");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async updateQuickExtraRuns(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update-quick-extra-run");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async deleteLastBall(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("delete-last-ball");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async deleteQuickLastBall(data: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("delete-quick-last-ball");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async updateCurrentPlayers(
    data: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update-current-players");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async updateQuickCurrentPlayers(
    data: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update-quick-current-players");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
}
