import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import "../../vendors/styles/cricketAppStyles.css";
import createTournamentProfile from "../../../components/vendors/images/createTournamentProfile.svg";
import playerProfile from "../../../components/vendors/images/player.png";
import { AdminService } from "../../../services/AdminService";
import { TournamentData } from "../../../models/Tournament";
import { QuickMatchData } from "../../../models/QuickMatch";
import TournamentContext from "../../../context/TournamentContext";

import UserContext from "../../../context/UserContext";
import { NavLink, useParams } from "react-router-dom";

const CreateQuickMatch: React.FC = () => {
  // const [user] = useContext(UserContext);

  // interface RouteParams {
  // tournamentName: string;
  // tournamentId: string;
  // userName: string;
  //   userId: string;

  //   state: string;
  // }

  // const { tournamentName, tournamentId } = useParams<RouteParams>();
  // const { userId } = useParams<RouteParams>();
  const [tournament, setTournament] = useContext(TournamentContext);
  const [user] = useContext(UserContext);

  const history = useHistory();
  // const initialState: TournamentData = {
  //   name: "",
  //   numberOfTeams: 0,
  //   leagueTotalMatches: 0,
  //   oversPerMatch: 0,
  //   semiFinalMatches: "0",
  //   numberOfTeamMembers: 11,
  // };
  const initialState1: QuickMatchData = {
    oversPerMatch: 0,
    numberOfTeamMembers: 0,
  };
  // const [userData, setUserData] = useState(initialState);
  const [quickMatchData, setQuickMatchData] = useState(initialState1);

  const addTournament = () => {
    console.log("add tournament called");
    if (!quickMatchData.oversPerMatch) {
      swal({ icon: "error", title: "Please enter number of overs for match!" });
      return;
    }
    if (!quickMatchData.numberOfTeamMembers) {
      swal({ icon: "error", title: "Please enter number of team members!" });
      return;
    }

    const currPack = user?.packageBought;

    AdminService.createQuickMatch(quickMatchData).then((res) => {
      console.log("Admin service called");
      console.log("res.data:::: piumantha ", res);

      if (res.success) {
        const data: any = res.data;
        const matchId: any = data._id;

        swal({ icon: "success", title: "New quick match is created!" }).then(
          () => {
            history.push(`/admin/my-matches/create-quick-teams/${matchId}`);
          }
        );
      } else {
        console.log("inside else");
        swal({
          icon: "error",
          title: `${
            currPack === "FREE"
              ? "To create more matches, please upgrade your package"
              : currPack === "SILVER"
              ? "Matches limit reached for the silver package. Please upgrade to GOLD package to create unlimited matches"
              : ""
          }`,
        }).then(() => {
          history.push(`/admin/my-matches`);
        });
      }
    });
  };
  const previosPage = () => {
    history.goBack();
  };
  return (
    <>
      <div className="main-container">
        <div className="login-body rmv-border d-lg-flex text-center w-100">
          <div className="w-100 h-auto">
            <div className="w-100 package-container">
              <div className="row pad-20 jus-ar w-100">
                <div className="login-body tournament-creation match-creation profile d-lg-flex text-center ">
                  <i
                    className="fa fa-arrow-left f-color-gray-7 ml-4 mt-4 cursor-p"
                    aria-hidden="true"
                    onClick={() => previosPage()}
                  ></i>
                  <div className="box-1-create-tournament  py-5">
                    <div className="create-tournament-container border-r-10">
                      <span className="mb-1 h-1 text-center tournament-creation-header mb-4">
                        Quick Match Creation
                      </span>

                      <p className="textbox-create-tournament-title text-left">
                        Overs Count
                      </p>
                      <div className="textbox-create-tournament">
                        <input
                          className="inputBackground"
                          type="number"
                          placeholder=""
                          value={
                            quickMatchData.oversPerMatch != 0
                              ? quickMatchData.oversPerMatch.toString()
                              : ""
                          }
                          onChange={(e) =>
                            setQuickMatchData({
                              ...quickMatchData,
                              oversPerMatch: parseInt(e.target.value),
                            })
                          }
                        />
                      </div>

                      <p className="textbox-create-tournament-title text-left">
                        No: of players per team
                      </p>
                      <div className="textbox-create-tournament">
                        <input
                          className="inputBackground"
                          type="number"
                          placeholder=""
                          value={
                            quickMatchData.numberOfTeamMembers != 0
                              ? quickMatchData.numberOfTeamMembers.toString()
                              : ""
                          }
                          onChange={(e) =>
                            setQuickMatchData({
                              ...quickMatchData,
                              numberOfTeamMembers: parseInt(e.target.value),
                            })
                          }
                        />
                      </div>

                      <div className="d-lg-flex justify-content-center mt-5 mb-5">
                        {/* <NavLink
                          className=""
                          to={`/admin/my-matches/create-quick-teams/${userId}`}
                        > */}
                        <button
                          className="create-tournament-btn"
                          onClick={addTournament}
                        >
                          Create Quick Match
                          <span className="fas fa-chevron-right ml-1"></span>
                        </button>
                        {/* </NavLink> */}
                      </div>
                    </div>
                  </div>
                  <div className=" box-2-create-tournament d-flex flex-column h-100 hidden-431">
                    <img
                      className="playerImage"
                      src={playerProfile}
                      alt="Right Background"
                    />
                    <div className="playerBackground hidden-431"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQuickMatch;
