import React, { useContext, useState, useEffect } from "react";
import UserContext from "../../context/UserContext";
import "../vendors/styles/core.css";
import "../vendors/styles/style.css";
import { MenuContext } from "../../context/MenuContext";
import "../vendors/styles/cricketAppStyles.css";
import { NavLink, useParams } from "react-router-dom";
import { AdminService } from "../../services/AdminService";
import { Role } from "../../models/Role";
import UserImg from "../vendors/images/icon/User.png";
import UserImg1 from "../vendors/images/icon/userIcon.png";
// import Certificate from "../vendors/images/icon/Certificate.png";
// import Certificate1 from "../vendors/images/icon/packageIcon.png";
import { User } from "../../models/User";
import SilverBadge from "../vendors/images/Silver.svg";
import GoldBadge from "../vendors/images/Gold.svg";
import FreeBadge from "../vendors/images/Free.svg";
import { environment } from "../../environment/environment";

const NavBar: React.FC = () => {
  const [user, setUser] = useContext(UserContext);

  const [isMenuOpen, setIsMenuOpen] = useContext(MenuContext);
  const [reload, setReload] = useState(false);
  const [userProfileImageLink, setUserProfileImageLink] = useState<string>("");

  // const [tournamentData, setTournamentData] = useState<any>(null);
  // const { tournamentId }: any = useParams();
  // const [isUpgraded, setIsUpgraded] = useState<boolean>(false);
  // const [nextPackage, setNextPackage] = useState<string>("");

  // useEffect(() => {
  //   const pack = "silver";
  //   const pack2 = "gold";
  //   setNextPackage(pack);
  // });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    AdminService.getUserById(user?._id).then((res) => {
      console.log("res inside navbar ", res.data);
      const user: User = res.data;
      setUser(user);
      setReload(false);

      const userData: User = res.data;
      if (userData?.profileImage) {
        const baseUrl = environment.api_url;
        const absoluteUrl = `${baseUrl}/uploads/${userData.profileImage}`;
        console.log("Absolute URL inside navbar ", absoluteUrl);
        userData.profileImageUrl = absoluteUrl;
        setUserProfileImageLink(absoluteUrl);
        // window.location.reload()
      }
    });
  }, [reload,userProfileImageLink]);

  // useEffect(() => {
  //   if (tournamentId) {
  //     AdminService.getTournamentDataByTournamentId(tournamentId).then((res) => {
  //       if (res.success) {
  //         setTournamentData(res.data);
  //       } else {
  //         console.log("error", res.error);
  //       }
  //     });
  //   }
  // }, [tournamentId]);

  const handleUpgradeClick = (user: any) => {
    // setTournament(tournament);
  };
  return (
    <div className="header">
      <div className="header-left">
        <div
          className="menu-icon ti ti-menu-alt mr-2"
          onClick={toggleMenu}
        ></div>
      </div>
      {user?.userType === Role.SUPER_ADMIN ? null : (
        <div className="header-right d-flex justify-content-between align-items-center flex-wrap">
          <div className="d-flex justify-content-start align-items-center header-container-left">
            {/* <span className="header-name">{tournamentData?.name}</span> */}
            {/* <span className="head-overs">
              Overs: {tournamentData?.oversPerMatch}
            </span> */}
          </div>
          <div className="d-flex justify-content-start align-items-center">
            {/* <NavLink to="/admin/tournament-list">
              <span className="head-tournament-list">Tournament List</span>
            </NavLink>
            <NavLink to="/admin/tournament-list">
              <span className="head-tournament-list-none">Tournaments</span>
            </NavLink> */}
            <span className="head-tournament-list user-name">
              {user?.packageBought}{" "}
              <span>
                {user?.packageBought === "SILVER" ? (
                  <img src={SilverBadge} alt="" />
                ) : user?.packageBought === "GOLD" ? (
                  <img src={GoldBadge} alt="" />
                ) : (
                  <img src={FreeBadge} alt="" />
                )}
              </span>{" "}
            </span>
            <span className="head-tournament-list user-name">
              {user?.fname}{" "}
              <span>
                {/* <img src={UserImg1} alt="" /> */}
                <img
                  src={userProfileImageLink ? userProfileImageLink : UserImg1}
                  alt=""
                  className= {userProfileImageLink ? "navBarProfileImage": ''}
                />
              </span>{" "}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
