import React, { useEffect, useState, useContext } from "react";
import "../../vendors/styles/cricketAppStyles.css";
import RightArrow from "../../vendors/images/icon/right-arrow.png";
import Edit from "../../vendors/images/icon/edit.png";
import Close from "../../vendors/images/icon/x.png";
import Remove from "../../vendors/images/icon/remove.png";
import PlusIcon from "../../vendors/images/icon/plus.png";
import { AdminService } from "../../../services/AdminService";
import { TeamData, TeamMemberData } from "../../../models/Team";
import CustomModal from "../../common/Modal";
import swal from "sweetalert";
import UserContext from "../../../context/UserContext";

const MainDashboard: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [teamList, setTeamList] = useState<any>([]);
  const [teamListMain, setTeamListMain] = useState<any>([]);
  const [teamListExtra, setTeamListExtra] = useState<any>([]);
  const [matchList, setMatchList] = useState<any>([]);
  const [newTeamName, setNewTeamName] = useState<string>();
  const [newTeamMemberName, setNewTeamMemberName] = useState<string>();
  const [newTeamMemberList, setNewTeamMemberList] = useState<any[]>([]);
  const [updateTeamMember, setUpdateTeamMember] = useState<any>();
  const [tournamentData, setTournamentData] = useState<any>();
  const [totalMatchCount, setTotalMatchCount] = useState<any>();
  const [selectTeam1ArrList, setSelectTeam1ArrList] = useState<any[]>([]);
  const [selectTeam2ArrList, setSelectTeam2ArrList] = useState<any[]>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateMatchIndex, setUpdateMatchIndex] = useState<number>(1);
  const [updateMatchId, setUpdateMatchId] = useState<string>();
  const [extraMatchTeam1, setExtraMatchTeam1] = useState<string>();
  const [extraMatchTeam2, setExtraMatchTeam2] = useState<string>();
  const [extraMatchType, setExtraMatchType] = useState<string>();
  const [updateMatchTeam1, setUpdateMatchTeam1] = useState<string>();
  const [updateMatchTeam2, setUpdateMatchTeam2] = useState<string>();
  const [updateTeamId, setUpdateTeamId] = useState<string>("");
  const [isUpdateTeam, setIsUpdateTeam] = useState<boolean>(false);
  const splittedURL = window.location.pathname.split("/");
  const tournamentId = splittedURL[splittedURL.length - 1];
  const [user] = useContext(UserContext);

  useEffect(() => {
    getTournamentData();
    getTeamsMain();
    getMatches();
  }, [update]);

  const createTeamsPageClicked = () => {
    if (tournamentData?.numberOfTeams === teamList?.length) {
      setPageNumber(2);
    } else if (tournamentData?.numberOfTeams > teamList?.length) {
      swal({
        icon: "error",
        title: "Please Add required amount of players",
      });
    } else {
      swal({
        icon: "error",
        title: "Cannot add more teams to this tournament",
      });
    }
  };
  const upgradePlanBtnClicked = () => {
    setPageNumber(5);
  };

  const planTournamentPageClicked = () => {
    if (matchList?.length >= totalMatchCount) {
      swal({ icon: "error", title: "Already added all matches" });
    } else {
      setPageNumber(3);
    }
  };
  const planTournamentExtraPageClicked = () => {
    if (matchList?.length >= totalMatchCount) {
      setPageNumber(4);
    } else {
      swal({ icon: "error", title: "First plan tournament matches" });
    }
  };
  const closePageClicked = () => {
    setPageNumber(1);
    setNewTeamMemberName("");
    setNewTeamName("");
    setNewTeamMemberList([]);
    setExtraMatchTeam1("");
    setExtraMatchTeam2("");
    setUpdateTeamId("");
    setIsUpdateTeam(false);
  };
  const getTeamsMain = () => {
    AdminService.getTeamsByTournamentId(tournamentId).then((res) => {
      if (res.success) {
        setTeamList(res.data);
      } else {
        console.log("error", res.error);
      }
    });
  };
  const getMatches = () => {
    AdminService.getMatchesByTournamentId(tournamentId).then((res) => {
      if (res.success) {
        setMatchList(res.data);
        const allMatches = res?.data;
        let arr1 = [];
        let arr2 = [];

        let numElements = Math.min(allMatches.length, totalMatchCount);

        arr1 = allMatches.slice(0, numElements);
        arr2 = allMatches.slice(numElements);
        setTeamListMain(arr1);
        setTeamListExtra(arr2);
      } else {
        console.log("error", res.error);
      }
    });
  };
  const getTournamentData = () => {
    AdminService.getTournamentDataByTournamentId(tournamentId).then((res) => {
      if (res.success) {
        setTournamentData(res.data);
        setTotalMatchCount(
          res?.data?.leagueTotalMatches + res?.data?.semiFinalMatches
        );
      } else {
        console.log("error", res.error);
      }
    });
  };
  const addTeamMemberToTeam = () => {
    if (updateTeamMember > -1) {
      const updatedTeamMemberList = [...newTeamMemberList];
      updatedTeamMemberList[updateTeamMember] = newTeamMemberName;
      setNewTeamMemberList(updatedTeamMemberList);
      setNewTeamMemberName("");
      setUpdateTeamMember(-1);
    } else {
      setNewTeamMemberName("");
      if (!newTeamMemberName) {
        swal({ icon: "error", title: "Enter Valid Name" });
      } else {
        if (tournamentData?.numberOfTeamMembers > newTeamMemberList?.length) {
          setNewTeamMemberList([...newTeamMemberList, newTeamMemberName]);
        } else {
          swal({ icon: "error", title: "Added Maximum count of members" });
        }
      }
    }
  };
  const removeMemberFromList = (index: number) => {
    const updatedTeamMemberList = [...newTeamMemberList];
    updatedTeamMemberList.splice(index, 1);
    setNewTeamMemberList(updatedTeamMemberList);
  };
  const updateMemberFromList = (index: number, memberName: any) => {
    setNewTeamMemberName(memberName);
    setUpdateTeamMember(index);
  };
  const createTeam = () => {
    if (!newTeamName) {
      swal({ icon: "error", title: "Please Enter Team name" });
    } else if (newTeamMemberList?.length < 1) {
      swal({ icon: "error", title: "Please Add Team Members" });
    }
    if (newTeamName && newTeamMemberList?.length > 0) {
      const data = {
        tournamentId: tournamentId,
        teams: [
          {
            name: newTeamName,
            players: newTeamMemberList,
          },
        ],
      };
      AdminService.createTeam(data).then((res) => {
        if (res.success) {
          const memberList = newTeamMemberList.map((member: any) => ({
            name: member,
          }));
          const data = {
            tournamentId: tournamentId,
            name: newTeamName,
            players: memberList,
          };
          setTeamList([...teamList, data]);
          setUpdate(!update);
          setNewTeamName("");
          setNewTeamMemberList([]);
          swal({ icon: "success", title: "New team created!" }).then(
            function () {
              setPageNumber(1);
            }
          );
        } else {
          swal({
            icon: "error",
            title: res?.error ? res.error : "Something went wrong",
          });
        }
      });
    }
  };
  const handleChangeTeam1 = (event: any, index: number) => {
    const newString = event.target.value;
    if (index >= 0 && index < selectTeam1ArrList.length) {
      const updatedArray = [...selectTeam1ArrList];
      updatedArray[index] = newString;
      setSelectTeam1ArrList(updatedArray);
    } else {
      const updatedArray = [...selectTeam1ArrList];
      while (updatedArray.length < index) {
        updatedArray.push("");
      }
      updatedArray[index] = newString;
      setSelectTeam1ArrList(updatedArray);
    }
  };
  const handleChangeTeam2 = (event: any, index: number) => {
    const newString = event.target.value;
    if (index >= 0 && index < selectTeam2ArrList.length) {
      const updatedArray = [...selectTeam2ArrList];
      updatedArray[index] = newString;
      setSelectTeam2ArrList(updatedArray);
    } else {
      const updatedArray = [...selectTeam2ArrList];
      while (updatedArray.length < index) {
        updatedArray.push("");
      }
      updatedArray[index] = newString;
      setSelectTeam2ArrList(updatedArray);
    }
  };
  const areValuesAtSameIndexEqual = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] === arr2[i]) {
        //  console.log("run==>", arr1[i], arr2[i]);
        return false;
      }
    }

    return true;
  };
  const addMatchForTournament = () => {
    if (selectTeam1ArrList?.length !== selectTeam2ArrList?.length) {
      swal({ icon: "error", title: "Please select team for all matches" });
    } else {
      const checkArray = selectTeam1ArrList.filter((team1) => team1.length < 1);
      const checkArray2 = selectTeam2ArrList.filter(
        (team2) => team2.length < 1
      );
      if (checkArray?.length !== 0) {
        swal({ icon: "error", title: "Please select team for all matches" });
      } else if (checkArray2?.length !== 0) {
        swal({ icon: "error", title: "Please select team for all matches" });
      } else {
        const notSameIndex = areValuesAtSameIndexEqual(
          selectTeam1ArrList,
          selectTeam2ArrList
        );
        if (notSameIndex) {
          const maxLength = Math.max(
            selectTeam1ArrList.length,
            selectTeam2ArrList.length
          );
          const newTeamArr = [];
          for (let i = 0; i < maxLength; i++) {
            newTeamArr.push([selectTeam1ArrList[i], selectTeam2ArrList[i]]);
          }
          for (let i = 0; i < maxLength; i++) {
            newTeamArr.push([selectTeam1ArrList[i], selectTeam2ArrList[i]]);
            const data = {
              tournamentId: tournamentId,
              type: "REGULAR",
              teams: newTeamArr[i],
            };
            AdminService.createMatch(data).then((res) => {
              if (res.success) {
                if (i === maxLength - 1) {
                  swal({
                    icon: "success",
                    title: "New tournament matches created!",
                  }).then(function () {
                    setUpdate(!update);
                    setPageNumber(1);
                  });
                }
              } else {
                swal({
                  icon: "error",
                  title: res?.error ? res?.error : "Something went wrong",
                });
              }
            });
          }
        } else {
          swal({
            icon: "error",
            title: "Please select different team for match",
          });
        }
      }
    }
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const updateMatchTeam = (index: number, id: string) => {
    setUpdateMatchIndex(index);
    setUpdateMatchId(id);
    const indexToFilter = index;
    const selectedMatch = matchList[indexToFilter];
    setUpdateMatchTeam1(selectedMatch?.teams[0]?._id);
    setUpdateMatchTeam2(selectedMatch?.teams[1]?._id);
    handleOpenModal();
  };
  const ExtraSelectTeam1 = (event: any) => {
    setExtraMatchTeam1(event.target.value);
  };
  const ExtraMatchType = (event: any) => {
    setExtraMatchType(event.target.value);
  };
  const ExtraSelectTeam2 = (event: any) => {
    setExtraMatchTeam2(event.target.value);
  };
  const addExtraMatchForTournament = () => {
    if (extraMatchTeam1 && extraMatchTeam2 && extraMatchType) {
      if (extraMatchTeam1 !== extraMatchTeam2) {
        const data = {
          tournamentId: tournamentId,
          type: extraMatchType,
          teams: [extraMatchTeam1, extraMatchTeam2],
        };
        AdminService.createMatch(data).then((res) => {
          if (res.success) {
            setExtraMatchTeam1("");
            setExtraMatchTeam2("");
            swal({
              icon: "success",
              title: "New extra match is created!",
            }).then(function () {
              setUpdate(!update);
              setPageNumber(1);
            });
          } else {
            swal({
              icon: "error",
              title: res?.error ? res?.error : "Something went wrong",
            });
          }
        });
      } else {
        swal({ icon: "error", title: "Please Select different Teams" });
      }
    } else {
      swal({ icon: "error", title: "Please Select All Fields" });
    }
  };
  const handleUpdateMatchTeam1 = (event: any) => {
    setUpdateMatchTeam1(event.target.value);
  };
  const handleUpdateMatchTeam2 = (event: any) => {
    setUpdateMatchTeam2(event.target.value);
  };
  const updateMatchTeams = () => {
    if (updateMatchTeam1 === updateMatchTeam2) {
      swal({ icon: "error", title: "Please Select different Teams" });
    } else {
      const data = {
        matchId: updateMatchId,
        team01: updateMatchTeam1,
        team02: updateMatchTeam2,
      };
      AdminService.updateMatchTeams(data).then((res) => {
        if (res.success) {
          setUpdate(!update);
          handleCloseModal();
          setUpdateMatchTeam1("");
          setUpdateMatchTeam2("");
          swal({
            icon: "success",
            title: "New tournament matches updated!",
          }).then(function () {});
        } else {
          swal({
            icon: "error",
            title: res?.error ? res.error : "Something went wrong",
          });
        }
      });
    }
  };
  const deleteTeam = (teamId: any) => {
    swal({
      icon: "success",
      title: "Do you want to delete team ?",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "my-custom-cancel-button",
          closeModal: true,
        },
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          // className: "my-custom-ok-button",
          closeModal: true,
        },
      },
    }).then(function (value) {
      if (value === null) {
        //  console.log("Cancel button clicked");
      } else {
        AdminService.deleteTeam(data).then((res) => {
          if (res.success) {
            setUpdate(!update);
            swal({
              icon: "success",
              title: "Successfully delete team!",
            }).then(function () {});
          } else {
            swal({
              icon: "error",
              title: res?.error ? res.error : "Something went wrong",
            });
          }
        });
      }
    });
    const data = {
      teamId: teamId,
    };
  };
  const updateTeam = (team: any) => {
    setIsUpdateTeam(true);
    setUpdateTeamId(team?._id);
    setNewTeamName(team?.name);
    const teamPlayersArr = team.players?.map((player: any) => player.name);
    setNewTeamMemberList(teamPlayersArr);
    setPageNumber(2);
    // console.log("team==>", team)
  };
  const handleClickUpdateTeam = () => {
    const data = {
      teamId: updateTeamId,
      teamName: newTeamName,
    };
    const data2 = {
      teamId: updateTeamId,
      newTeamData: newTeamMemberList,
    };
    if (!updateTeamId) {
      swal({
        icon: "error",
        title: "Something went wrong",
      });
    } else if (!newTeamName) {
      swal({
        icon: "error",
        title: "Please enter team name",
      });
    } else if (newTeamMemberList?.length == 0) {
      swal({
        icon: "error",
        title: "Please add team members",
      });
    } else {
      AdminService.updateTeamName(data).then((res) => {
        if (res.success) {
          AdminService.updateTeamMembers(data2).then((res) => {
            if (res.success) {
              setIsUpdateTeam(false);
              setNewTeamName("");
              setNewTeamMemberList([]);
              setPageNumber(1);
              setUpdate(!update);
              swal({
                icon: "success",
                title: "Team updated!",
              }).then(function () {});
            } else {
              swal({
                icon: "error",
                title: res?.error ? res.error : "Something went wrong",
              });
            }
          });
        } else {
          swal({
            icon: "error",
            title: res?.error ? res.error : "Something went wrong",
          });
        }
      });
    }
  };
  // console.log("newTeamMemberList==>", newTeamMemberList)
  return (
    <>
      <div
        className={`${
          user?.userType === "SUPER_ADMIN" ? `` : `main-container`
        } ${pageNumber === 1 ? ` min-h-full` : null} `}
      >
        {pageNumber === 1 && (
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="container-fluid mt-3 pl-pr-0">
                  <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                    <div className="row w-100">
                      <div className="col-md-12 mb-4 d-flex justify-content-left align-items-center">
                        {user?.userType === "SUPER_ADMIN" ? (
                          <span className="mt-3 f-18 fw-700 font-Poppins">
                            Teams
                          </span>
                        ) : (
                          <div
                            className="arrow-btn mt-3"
                            onClick={() => createTeamsPageClicked()}
                          >
                            <span className="f-17 f-color-white fw-500 font-Poppins ">
                              Create Teams
                            </span>
                            <img
                              className="arrow-icon-size ml-3"
                              src={RightArrow}
                              alt=""
                            />
                          </div>
                        )}
                      </div>

                      {/* Map this table card */}
                      {teamList?.map((team: TeamData, index: number) => (
                        <div className="col-md-6 mb-4" key={index}>
                          <div className="table-responsive">
                            <table className="table mb-0" key={index}>
                              <thead className="tbody-2">
                                <tr className="bg-gray3">
                                  <th colSpan={2}>
                                    <div className="w-100 d-flex justify-content-between align-item-center">
                                      {/* <span></span> */}
                                      <span className="f-16">{team?.name}</span>
                                      <span>
                                        <img
                                          className="edit-icon-size cursor-p"
                                          onClick={() => updateTeam(team)}
                                          src={Edit}
                                          alt=""
                                        />
                                        <img
                                          className="close-icon-size mr-1 ml-1 cursor-p"
                                          onClick={() => deleteTeam(team._id)}
                                          src={Close}
                                          alt=""
                                        />
                                      </span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="tbody-2">
                                {team?.players?.map(
                                  (member: TeamMemberData, index: number) => (
                                    <tr key={index}>
                                      <td className="t-align-left">
                                        <span className="pl-3 f-15 fw-500">
                                          {member?.name}
                                        </span>
                                      </td>
                                      <td></td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {teamList?.length === tournamentData?.numberOfTeams && (
                <div className="col-md-6">
                  <div className="container-fluid mt-3 pl-pr-0">
                    <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                      <div className="row w-100">
                        <div className="col-md-12 mb-4 d-flex justify-content-left align-items-center">
                          {user?.userType === "SUPER_ADMIN" ? (
                            <span className="mt-3 f-18 fw-700 font-Poppins">
                              Tournament Matches
                            </span>
                          ) : (
                            <div
                              className="arrow-btn4 mt-3"
                              onClick={() => planTournamentPageClicked()}
                            >
                              <span className="f-17 f-color-white fw-500 font-Poppins">
                                Plan Tournament Matches
                              </span>
                              <img
                                className="arrow-icon-size ml-3"
                                src={RightArrow}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="container">
                            {matchList?.map(
                              (match: any, index: number) =>
                                index < totalMatchCount && (
                                  <div
                                    className="row bg-gray5 border-r-9 mb-1 min-h-48"
                                    key={index}
                                  >
                                    <div className="between-content pl-3 pr-3 ">
                                      <div>
                                        <span className="fw-600 f-17 f-color-black3 font-Poppins">
                                          Match: {index + 1}
                                        </span>
                                      </div>
                                      <div>
                                        <div className="tag-btn">
                                          <span className="fw-400 f-16 f-color-black3 ml-3 mr-3 font-Poppins">
                                            {match?.teams[0]?.name}
                                          </span>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="tag-btn">
                                          <span className="fw-400 f-16 f-color-black3 ml-3 mr-3 font-Poppins">
                                            {match?.teams[1]?.name}
                                          </span>
                                        </div>
                                      </div>

                                      <div>
                                        {user?.userType ===
                                        "SUPER_ADMIN" ? null : (
                                          <img
                                            className="edit-icon-size cursor-p"
                                            onClick={() =>
                                              updateMatchTeam(index, match?._id)
                                            }
                                            src={Edit}
                                            alt=""
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {matchList?.length >= tournamentData?.leagueTotalMatches && (
                    <div className="container-fluid mt-3 pl-pr-0">
                      <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                        <div className="row w-100">
                          <div className="col-md-12 mb-4 d-flex justify-content-left align-items-center">
                            {user?.userType === "SUPER_ADMIN" ? (
                              <span className="mt-3 f-18 fw-700 font-Poppins">
                                Extra Matches
                              </span>
                            ) : (
                              <div
                                className="arrow-btn4 mt-3"
                                onClick={() => planTournamentExtraPageClicked()}
                              >
                                <span className="f-17 f-color-white fw-500 font-Poppins">
                                  Add Extra Matches
                                </span>
                                <img
                                  className="arrow-icon-size ml-3"
                                  src={RightArrow}
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-md-12 mb-4">
                            <div className="container">
                              {matchList?.map(
                                (match: any, index: number) =>
                                  index >= totalMatchCount && (
                                    <div
                                      className="row bg-gray5 border-r-9 mb-1 min-h-48"
                                      key={index}
                                    >
                                      <div className="between-content pl-1 pr-1 ">
                                        <div>
                                          <span className="fw-600 f-17 f-color-black3 font-Poppins">
                                            Match: {index + 1}
                                          </span>
                                        </div>
                                        <div>
                                          <div className="tag-btn">
                                            <span className="fw-400 f-16 f-color-black3 ml-3 mr-3 font-Poppins">
                                              {match?.teams[0]?.name}
                                            </span>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="tag-btn">
                                            <span className="fw-400 f-16 f-color-black3 ml-3 mr-3 font-Poppins">
                                              {match?.teams[1]?.name}
                                            </span>
                                          </div>
                                        </div>
                                        <div>
                                          {user?.userType ===
                                          "SUPER_ADMIN" ? null : (
                                            <img
                                              className="edit-icon-size cursor-p"
                                              onClick={() =>
                                                updateMatchTeam(
                                                  index,
                                                  match?._id
                                                )
                                              }
                                              alt=""
                                              src={Edit}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {pageNumber === 2 && (
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="container-fluid mt-3 pl-pr-0">
                  <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                    <div className="row w-100">
                      <div className="col-md-12  d-flex justify-content-end align-items-end">
                        <img
                          className="close-icon-size mr-1 mt-2"
                          onClick={() => closePageClicked()}
                          src={Close}
                          alt=""
                        />
                      </div>

                      {/* Map this table card */}
                      <div className="col-md-6 mb-4 mbb-20">
                        <div className="container">
                          <div className="row  d-flex justify-content-center align-items-center">
                            <span className="f-color-black3 fw-600 f-30 d-flex justify-content-center align-items-center mt-1 mb-1 font-Poppins">
                              {isUpdateTeam ? "Update" : "Create"} Team Members
                            </span>
                          </div>
                          <div className="row  ">
                            <div className="between-content pl-1 pr-1 ">
                              <div className="mb-1 w-100 text-input-container">
                                <label className="mb-pb-0 fw-500 f-17 f-color-gray font-Poppins">
                                  Team name
                                </label>
                                <input
                                  onChange={(e) =>
                                    setNewTeamName(e.target.value)
                                  }
                                  type="text"
                                  value={newTeamName}
                                  className="form-control text-input2"
                                  id="exampleInput"
                                />
                              </div>
                            </div>
                            <div className="between-content pl-1 pr-1 ">
                              <div className="mb-3 w-100 text-input-container">
                                <label className="mb-pb-0 fw-500 f-17 f-color-gray font-Poppins">
                                  Team member name
                                </label>
                                <input
                                  onChange={(e) =>
                                    setNewTeamMemberName(e.target.value)
                                  }
                                  type="text"
                                  value={newTeamMemberName}
                                  className="form-control text-input2"
                                  id="exampleInput"
                                />
                                imgupdate te
                                <div className="input-group-append d-flex justify-content-end align-items-end">
                                  <span
                                    className="input-group-text icon-container cursor-p"
                                    onClick={() => addTeamMemberToTeam()}
                                  >
                                    <img src={PlusIcon} alt="Icon" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row h-full pt-5 pl-3 pr-3 d-flex justify-content-end align-items-bottom">
                            {isUpdateTeam ? (
                              <div
                                className="arrow-btn2"
                                onClick={() => handleClickUpdateTeam()}
                              >
                                <span className="f-17 f-color-white fw-500 font-Poppins">
                                  Update Team
                                </span>
                                <img
                                  className="arrow-icon-size ml-3"
                                  src={RightArrow}
                                  alt=""
                                />
                              </div>
                            ) : (
                              <div
                                className="arrow-btn2"
                                onClick={() => createTeam()}
                              >
                                <span className="f-17 f-color-white fw-500 font-Poppins">
                                  Create Team
                                </span>
                                <img
                                  className="arrow-icon-size ml-3"
                                  src={RightArrow}
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {(newTeamName || newTeamMemberList?.length > 0) && (
                        <div className="col-md-6 mb-4 mbb-20">
                          <div className="container bg-gray6 border-top-10">
                            <div className="row pt-2 pl-3 pr-3 d-flex justify-content-left align-items-left">
                              <span className="text-black fw-400 f-18 mt-1 mb-1 font-Poppins">
                                {newTeamName}
                              </span>
                            </div>
                            {newTeamMemberList?.map(
                              (member: TeamMemberData, index: number) => (
                                <div
                                  className="row ml-4 mr-4 border-r-9 pr-4 mb-1 min-h-35 bg-gray2"
                                  key={index}
                                >
                                  <div className="between-content pl-1 pr-1 ">
                                    <div>
                                      <span className="fw-500 f-15 f-color-black3 font-poppins">
                                        {member}
                                      </span>
                                    </div>
                                    <div>
                                      <img
                                        className="edit-icon-size mr-md-3 cursor-p"
                                        src={Remove}
                                        onClick={() =>
                                          removeMemberFromList(index)
                                        }
                                        alt=""
                                      />
                                      <img
                                        className="edit-icon-size cursor-p"
                                        src={Edit}
                                        onClick={() =>
                                          updateMemberFromList(index, member)
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {pageNumber === 3 && (
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="container-fluid mt-3 pl-pr-0">
                  <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                    <div className="row w-100">
                      <div className="col-md-12  d-flex justify-content-end align-items-end">
                        <img
                          className="close-icon-size mr-1 mt-3"
                          onClick={() => closePageClicked()}
                          src={Close}
                          alt=""
                        />
                      </div>

                      <div className="col-md-12 mb-4 mbb-20">
                        <div className="container  border-top-10">
                          <div className="row pt-2 pl-3 pr-3 d-flex justify-content-center align-items-center">
                            <span className="f-color-black3 fw-600 f-30 mt-1 mb-1 font-Poppins">
                              Plan tournament matches
                            </span>
                          </div>
                          <div className="row ml-4 mr-4 border-r-9 pr-4 mb-1 min-h-35 ">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  {Array.from(
                                    {
                                      length: tournamentData?.semiFinalMatches
                                        ? tournamentData?.semiFinalMatches +
                                          tournamentData?.leagueTotalMatches
                                        : tournamentData?.leagueTotalMatches
                                        ? tournamentData?.semiFinalMatches +
                                          tournamentData?.leagueTotalMatches
                                        : 0,
                                    },
                                    (_, index) => (
                                      <tr key={index}>
                                        <th className="justify-content-center align-item-center">
                                          <span className="fw-600 f-17 f-color-black3 font-Poppins">
                                            Match: {index + 1}
                                          </span>
                                        </th>
                                        <td className="justify-content-center align-item-center">
                                          <select
                                            className="select-dropdown fw-400 f-15 f-color-black3"
                                            onChange={(e) =>
                                              handleChangeTeam1(e, index)
                                            }
                                          >
                                            <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                              Select Team
                                            </option>
                                            {teamList?.map(
                                              (
                                                team: TeamData,
                                                index: number
                                              ) => (
                                                <option
                                                  key={index}
                                                  className="fw-400 f-15 f-color-black3 font-Poppins"
                                                  value={team?._id}
                                                >
                                                  {team?.name}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </td>
                                        <td>
                                          <span className="fw-400 f-15 f-color-black3 font-Poppins">
                                            VS
                                          </span>
                                        </td>
                                        <td>
                                          <div className="">
                                            <select
                                              className="select-dropdown fw-400 f-15 f-color-black3 font-Poppins"
                                              onChange={(e) =>
                                                handleChangeTeam2(e, index)
                                              }
                                            >
                                              <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                                Select Team
                                              </option>
                                              {teamList?.map(
                                                (
                                                  team: TeamData,
                                                  index: number
                                                ) => (
                                                  <option
                                                    key={index}
                                                    className="fw-400 f-15 f-color-black3 font-Poppins"
                                                    value={team?._id}
                                                  >
                                                    {team?.name}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row pt-2 pl-3 pr-3 d-flex justify-content-end align-items-end">
                            <div
                              className="arrow-btn2"
                              onClick={() => addMatchForTournament()}
                            >
                              <span className="f-17 f-color-white fw-500 font-Poppins">
                                Save
                              </span>
                              <img
                                className="arrow-icon-size ml-3"
                                src={RightArrow}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {pageNumber === 4 && (
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="container-fluid mt-3 pl-pr-0">
                  <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                    <div className="row w-100">
                      <div className="col-md-12  d-flex justify-content-end align-items-end">
                        <img
                          className="close-icon-size mr-1 mt-3"
                          onClick={() => closePageClicked()}
                          src={Close}
                          alt=""
                        />
                      </div>

                      <div className="col-md-12 mb-4 mbb-20">
                        <div className="container  border-top-10">
                          <div className="row pt-2 pl-3 pr-3 d-flex justify-content-center align-items-center">
                            <span className="f-color-black3 fw-600 f-30 mt-1 mb-1 font-Poppins">
                              Add Extra Match
                            </span>
                          </div>
                          <div className="row ml-4 mr-4 border-r-9 pr-4 mb-1 min-h-35 ">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <th className="justify-content-center align-item-center">
                                      <span className="fw-600 f-17 f-color-black3 font-Poppins">
                                        Match: {matchList?.length + 1}
                                      </span>
                                    </th>
                                    <td className="justify-content-center align-item-center">
                                      <select
                                        className="select-dropdown2 fw-400 f-15 f-color-black3"
                                        onChange={ExtraMatchType}
                                      >
                                        <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                          Select Match Type
                                        </option>
                                        <option
                                          className="fw-400 f-15 f-color-black3 font-Poppins"
                                          value="REGULAR"
                                        >
                                          Regular
                                        </option>
                                        <option
                                          className="fw-400 f-15 f-color-black3 font-Poppins"
                                          value="QUALIFIER_ONE"
                                        >
                                          Qualifier 1
                                        </option>
                                        <option
                                          className="fw-400 f-15 f-color-black3 font-Poppins"
                                          value="QUALIFIER_TWO"
                                        >
                                          Qualifier 2
                                        </option>
                                        <option
                                          className="fw-400 f-15 f-color-black3 font-Poppins"
                                          value="ELIMINATER"
                                        >
                                          Eliminator
                                        </option>
                                        <option
                                          className="fw-400 f-15 f-color-black3 font-Poppins"
                                          value="SEMI_FINAL"
                                        >
                                          Semi-Final
                                        </option>
                                        <option
                                          className="fw-400 f-15 f-color-black3 font-Poppins"
                                          value="FINAL"
                                        >
                                          Final
                                        </option>
                                      </select>
                                    </td>
                                    <td className="justify-content-center align-item-center">
                                      <select
                                        className="select-dropdown fw-400 f-15 f-color-black3"
                                        onChange={ExtraSelectTeam1}
                                      >
                                        <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                          Select Team
                                        </option>
                                        {teamList?.map(
                                          (team: TeamData, index: number) =>
                                            team?._id !== extraMatchTeam2 ? (
                                              <option
                                                key={index}
                                                className="fw-400 f-15 f-color-black3 font-Poppins"
                                                value={team?._id}
                                              >
                                                {team?.name}
                                              </option>
                                            ) : null
                                        )}
                                      </select>
                                    </td>
                                    <td>
                                      <span className="fw-400 f-15 f-color-black3 font-Poppins">
                                        VS
                                      </span>
                                    </td>
                                    <td>
                                      <div className="">
                                        <select
                                          className="select-dropdown fw-400 f-15 f-color-black3 font-Poppins"
                                          onChange={ExtraSelectTeam2}
                                        >
                                          <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                            Select Team
                                          </option>
                                          {teamList?.map(
                                            (team: TeamData, index: number) =>
                                              team?._id !== extraMatchTeam1 ? (
                                                <option
                                                  key={index}
                                                  className="fw-400 f-15 f-color-black3 font-Poppins"
                                                  value={team?._id}
                                                >
                                                  {team?.name}
                                                </option>
                                              ) : null
                                          )}
                                        </select>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row pt-2 pl-3 pr-3 d-flex justify-content-end align-items-end">
                            <div
                              className="arrow-btn2"
                              onClick={() => addExtraMatchForTournament()}
                            >
                              <span className="f-17 f-color-white fw-500 font-Poppins">
                                Save
                              </span>
                              <img
                                className="arrow-icon-size ml-3"
                                src={RightArrow}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <h2 className="fw-600 f-17 f-color-black3 font-Poppins mb-4">
          Match: {updateMatchIndex + 1}
        </h2>
        <div>
          <div className="mb-2 d-flex justify-content-center align-item-center">
            <select
              className="select-dropdown3 fw-400 f-15 f-color-black3 font-Poppins"
              value={updateMatchTeam1}
              onChange={handleUpdateMatchTeam1}
            >
              <option className="fw-400 f-15 f-color-black3 font-Poppins">
                Select Team
              </option>
              {teamList?.map((team: TeamData, index: number) => (
                <option
                  key={index}
                  className="fw-400 f-15 f-color-black3 font-Poppins"
                  value={team?._id}
                >
                  {team?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex justify-content-center align-item-center mb-2">
            <span className="fw-400 f-15 f-color-black3 font-Poppins text-center">
              VS
            </span>
          </div>
          <div className="mb-2 d-flex justify-content-center align-item-center">
            <select
              className="select-dropdown3 fw-400 f-15 f-color-black3 font-Poppins"
              value={updateMatchTeam2}
              onChange={handleUpdateMatchTeam2}
            >
              <option className="fw-400 f-15 f-color-black3 font-Poppins">
                Select Team
              </option>
              {teamList?.map((team: TeamData, index: number) => (
                <option
                  key={index}
                  className="fw-400 f-15 f-color-black3 font-Poppins"
                  value={team?._id}
                >
                  {team?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="row pt-2 pl-3 pr-3 d-flex justify-content-end align-items-end">
            <div className="arrow-btn2" onClick={() => updateMatchTeams()}>
              <span className="f-17 f-color-white fw-500 font-Poppins">
                Update
              </span>
              <img className="arrow-icon-size ml-3" src={RightArrow} alt="" />
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default MainDashboard;
