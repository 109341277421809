import React, { useEffect, useState } from "react";
import "../../vendors/styles/cricketAppStyles.css";
import { MatchService } from "../../../services/MatchService";

interface ChildProps {
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}
const QuickLiveScore: React.FC<ChildProps> = (props) => {

  const [currentlyBowlingteamObject, setCurrentlyBowlingteamObject] =
    useState<any>();
  const [currentlyBattingPlayersObject, setCurrentlyBattingPlayersObject] =
    useState<any>();
  const [extrasObject, setExtrasCountsObject] = useState({
    noBalls: 0,
    wickets: 0,
    wides: 0,
    legByes: 0,
  });
  const [currentlyBattingTeamDetails, setCurrentlyBattingTeamDetails] =
    useState<any>();
  const [firstInningsStats, setFirstInningsStats] = useState<any>();
  const [secondInningsStats, setSecondInningsStats] = useState<any>();
  const [oversDataObject, setOversDataObject] = useState<any>();
  const [isMounted, setIsMounted] = useState(true);
  const [crr, setCRR] = useState<any>();
  const splittedURL = window.location.pathname.split("/");
  const match_id = splittedURL[splittedURL.length - 1];
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isBatFirstScoreBoard, setIsBatFirstScoreBoard] = useState<boolean>(false);
  const [isBatSecondScoreBoard, setIsBatSecondScoreBoard] = useState<boolean>(false);
  const [isMatchEnd, setIsMatchEnd] = useState<boolean>(false);
  const [winnerTeamData, setWinnerTeamData] = useState<any>();
  const [isCurrentlyBattingFirstInning, setCurrentlyBattingFirstInning] =
    useState<boolean>(false);

  useEffect(() => {
//  fetchTournament();
    fetchUser();

    return () => {
      setIsMounted(false);
    };
  }, [pageNumber]);
  const [isBattingFirstDoneBatting, setIsBattingFirstDoneBatting] =
    useState<boolean>(false);
    const [isBattingDoneBatting, setIsBattingDoneBatting] =
    useState<boolean>(true);


//   const fetchTournament = async () => {
//     MatchService.getMatchDetails(match_id)
//       .then((res) => {
//         if (res?.data?.batFirstScoreboard?.currentlyBatting.length === 0) {
//           setIsBatFirstScoreBoard(true);
//         }
//         if (res?.data?.batSecondScoreboard?.currentlyBatting.length === 0) {
//           setIsBatSecondScoreBoard(true);
//         }
//         if (
//           res?.data?.status === "BAT_FIRST_WIN" ||
//           res?.data?.status === "BALL_FIRST_WIN"
//         ) {
//           setIsMatchEnd(true);
//         }
//         const response = res?.data;

//         let scoreboardWithCurrentlyBowling;
//         let isBattingFirstDoneBatting = false;

//         let currentlyBattingFirstInning = true;

//         const isCurrentlyBowlingExistsInSecondScoreBoard = response?.batSecondScoreboard?.hasOwnProperty('currentlyBowling');
//         const isCurrentlyBowlingExistsInFirstScoreBoard = response?.batFirstScoreboard?.hasOwnProperty('currentlyBowling');

     
//         if (
//           isCurrentlyBowlingExistsInFirstScoreBoard &&
//           !isCurrentlyBowlingExistsInSecondScoreBoard 
   
//         ) {
//         //  console.log("First batting team is currently BATTING");
//           scoreboardWithCurrentlyBowling = response?.batFirstScoreboard;
//           isBattingFirstDoneBatting = false;
//           currentlyBattingFirstInning = true;
 

//         } else if (
//           isCurrentlyBowlingExistsInFirstScoreBoard &&
//           isCurrentlyBowlingExistsInSecondScoreBoard
//         ) {
//         //  console.log("Second batting team is currently BATTING");
//           scoreboardWithCurrentlyBowling = response?.batSecondScoreboard;
//           isBattingFirstDoneBatting = true;
//           currentlyBattingFirstInning = false;
          
//         } else {
//         //  console.log( "No team is currently bowling, either the match is not started or innings changed");
         
//         }

//         setIsBattingFirstDoneBatting(isBattingFirstDoneBatting);
//         setCurrentlyBattingFirstInning(currentlyBattingFirstInning);

//         if (response?.status === "BAT_FIRST_WIN" || response?.status === "BALL_FIRST_WIN") {
//       const battingFirstTeamId = response?.batFirst;
//       let winnerTeamId: any;

//       if (response?.status === "BAT_FIRST_WIN") {
//         winnerTeamId = battingFirstTeamId;
//       } else if (response?.status === "BALL_FIRST_WIN") {
//         winnerTeamId = response?.teams.find(
//           (team: { _id: any }) => team._id !== battingFirstTeamId
//         )._id;
//       }

//       const winnerTeam = response?.teams.find(
//         (team: { _id: any }) => team._id === winnerTeamId
//       );

//       const winnerTeamName = winnerTeam ? winnerTeam.name : "Winner team not found";

//       setWinnerTeamData({
//         winnerTeamId: winnerTeamId,
//         winnerTeamName: winnerTeamName,
//       });
//     }

//         // -------------------------------------------------------------------------------------------get Currently Bowling Team and Player-----------------------------------------------------

//         const currentlyBowlingPlayerId =
//           scoreboardWithCurrentlyBowling.currentlyBowling;
       

//         const findBowlerById = (playerId: any) => {
//           const allPlayers = response?.teams.flatMap(
//             (team: { players: any }) => team.players
//           );
//           return allPlayers.find(
//             (player: { _id: any }) => player._id === playerId
//           );
//         };

//         const findBowlingTeamById = (teamId: any) => {
//           return response?.teams.find(
//             (team: { _id: any }) => team._id === teamId
//           );
//         };

//         const currentBowlingTeamDetails = findBowlingTeamById(
//           findBowlerById(currentlyBowlingPlayerId).teamId
//         );
        
//         setCurrentlyBowlingteamObject(currentBowlingTeamDetails);

//         // -------------------------------------------------------------------------------------------get Currently Batting Team details-----------------------------------------------------
//         const currentlyBattingIds =
//           scoreboardWithCurrentlyBowling.currentlyBatting;


//         const findPlayerById = (playerId: any) => {
//           const allPlayers = response?.teams.flatMap(
//             (team: { players: any }) => team.players
//           );
//           return allPlayers.find(
//             (player: { _id: any }) => player._id === playerId
//           );
//         };

//         const findTeamById = (teamId: any) => {
//           return response?.teams.find(
//             (team: { _id: any }) => team._id === teamId
//           );
//         };

//         const currentBattingTeamDetails = findTeamById(
//           findPlayerById(currentlyBattingIds[0]).teamId
//         );

//         setCurrentlyBattingTeamDetails(currentBattingTeamDetails);

//         const currentlyBattingPlayersObj = currentlyBattingIds.map(
//           (id: any) => {
//             const player = findPlayerById(id);
//             const team = findTeamById(player.teamId);
//             return {
//               id: player._id,
//               name: player.name,
//             };
//           }
//         );
//         setCurrentlyBattingPlayersObject(currentlyBattingPlayersObj);


//         // -------------------------------------------------------------------------------------------GET SCOREBOARD ITEMS-----------------------------------------------------
//         // -------------------------------------------------get total runs-----------------------------------------------------
//         const playerBowls = scoreboardWithCurrentlyBowling.playerBowls;

//         const firstInningsTotalRuns = response?.batFirstScoreboard.playerBowls
//           .filter(
//             (bowl: any) =>
//               bowl.status === "RUNS" ||
//               bowl.status === "WIDE" ||
//               bowl.status === "NO_BALL" ||
//               bowl.status === "LEG_BYE" ||
//               bowl.status === "WICKET"
//           )
//           .reduce((total: number, bowl: any) => total + bowl.runs, 0);

//         const firstInningsWicketsFallen =
//           response?.batFirstScoreboard.playerBowls.filter(
//             (bowl: any) => bowl.status === "WICKET"
//           ).length;

//         const firstInningsData = {
//           runs: firstInningsTotalRuns,
//           wickets: firstInningsWicketsFallen,
//         };
//         setFirstInningsStats(firstInningsData);

//         const secondInningsTotalRuns = response?.batSecondScoreboard.playerBowls
//           .filter(
//             (bowl: any) =>
//               bowl.status === "RUNS" ||
//               bowl.status === "WIDE" ||
//               bowl.status === "NO_BALL" ||
//               bowl.status === "LEG_BYE" ||
//               bowl.status === "WICKET"
//           )
//           .reduce((total: number, bowl: any) => total + bowl.runs, 0);

//         const secondInningsWicketsFallen =
//           response?.batSecondScoreboard.playerBowls.filter(
//             (bowl: any) => bowl.status === "WICKET"
//           ).length;

//         const secondInningsdata = {
//           runs: secondInningsTotalRuns,
//           wickets: secondInningsWicketsFallen,
//         };
//         setSecondInningsStats(secondInningsdata);

//         // if (isBattingFirstDoneBatting) {
//         //   const needToWin = firstInningsTotalRuns - secondInningsTotalRuns;
//         // }

//         // -------------------------------------------------get count of (wide, no ball, leg-bye, wicket)-----------------------------------------------------

//         const countNoBalls = playerBowls.filter(
//           (bowl: { status: string }) => bowl.status === "NO_BALL"
//         ).length;
//         const countWickets = playerBowls.filter(
//           (bowl: { status: string }) => bowl.status === "WICKET"
//         ).length;
//         const countWides = playerBowls.filter(
//           (bowl: { status: string }) => bowl.status === "WIDE"
//         ).length;
//         const countLegByes = playerBowls.filter(
//           (bowl: { status: string }) => bowl.status === "LEG_BYE"
//         ).length;

//         const newExtrasObject = {
//           noBalls: countNoBalls,
//           wickets: countWickets,
//           wides: countWides,
//           legByes: countLegByes,
//         };
//         setExtrasCountsObject(newExtrasObject);

     

//         // --------------------------------------------------------------------------OVERS-----------------------------------------------------------

//         const calculateBatterStats = (
//           playerBowls: Ball[]
//         ): Record<string, { ballsFaced: number; oversFaced: number }> => {
//           const batterStats: Record<
//             string,
//             { ballsFaced: number; oversFaced: number }
//           > = {};

//           playerBowls.forEach((bowl) => {
//             if (!batterStats[bowl.batsmanId]) {
//               batterStats[bowl.batsmanId] = {
//                 ballsFaced: 0,
//                 oversFaced: 0,
//               };
//             }

//             if (
//               bowl.status === "RUNS" ||
//               bowl.batsmanId === "LEG_BYE" ||
//               bowl.batsmanId === "WICKET"
//             ) {
//               batterStats[bowl.batsmanId].ballsFaced++;
//             }

//             if (bowl.batsmanId === "RUNS") {
//               batterStats[bowl.batsmanId].oversFaced += Math.floor(
//                 batterStats[bowl.batsmanId].ballsFaced / 6
//               );
//               batterStats[bowl.batsmanId].ballsFaced %= 6;
//             }
//           });

//           return batterStats;
//         };

//         const calculateBowlerStats = (
//           playerBowls: Ball[]
//         ): Record<string, { ballsBowled: number; oversBowled: number }> => {
//           const bowlerStats: Record<
//             string,
//             { ballsBowled: number; oversBowled: number }
//           > = {};

//           playerBowls.forEach((bowl) => {
//             if (!bowlerStats[bowl?.bowlerId]) {
//               bowlerStats[bowl?.bowlerId] = {
//                 ballsBowled: 0,
//                 oversBowled: 0,
//               };
//             }

//             if (
//               bowl?.status === "RUNS" ||
//               bowl?.status === "LEG_BYE" ||
//               bowl?.status === "WICKET"
//             ) {
//               bowlerStats[bowl?.bowlerId].ballsBowled++;
//             }

//             if (bowl.status === "RUNS") {
//               bowlerStats[bowl?.bowlerId].oversBowled += Math.floor(
//                 bowlerStats[bowl?.bowlerId].ballsBowled / 6
//               );
//               bowlerStats[bowl?.bowlerId].ballsBowled %= 6;
//             }
//           });

//           return bowlerStats;
//         };

//         const batterOversObjectFirst = calculateBatterStats(
//           response?.batFirstScoreboard?.playerBowls
//         );
//         const batterOversObjectSecond = calculateBatterStats(
//           response?.batSecondScoreboard?.playerBowls
//         );

//         const bowlerOversObjectFirst = calculateBowlerStats(
//           response?.batFirstScoreboard?.playerBowls
//         );
//         const bowlerOversObjectSecond = calculateBowlerStats(
//           response?.batSecondScoreboard?.playerBowls
//         );

//         const calculateOversFacedByTeam = (playerBowls: Ball[]): number => {
//           const ballsFaced = playerBowls.reduce(
//             (totalBalls: number, bowl: Ball) => {
//               if (
//                 bowl?.status === "RUNS" ||
//                 bowl?.status === "LEG_BYE" ||
//                 bowl?.status === "WICKET"
//               ) {
//                 return totalBalls + 1;
//               }
//               return totalBalls;
//             },
//             0
//           );

//           const oversFaced = Math.floor(ballsFaced / 6) + (ballsFaced % 6) / 10;
//           return oversFaced;
//         };
//         const oversFacedByFirstTeam = calculateOversFacedByTeam(
//           response?.batFirstScoreboard?.playerBowls
//         );
//         const oversFacedBySecondTeam = calculateOversFacedByTeam(
//           response?.batSecondScoreboard?.playerBowls
//         );



//         const oversData = {
//           firstInningBattersFaced: batterOversObjectFirst,
//           secondInningBattersFaced: batterOversObjectSecond,
//           firstInningBowlersBowled: bowlerOversObjectFirst,
//           secondInningBowlersBowled: bowlerOversObjectSecond,
//           totalOversBowledFirst: oversFacedBySecondTeam,
//           totalOversBowledSecond: oversFacedByFirstTeam,
//         };


//         if (isMounted) {
//           setOversDataObject(oversData);
//         }

//         const calculateCurrentRunRateOfFirstInnings = (
//           totalRuns: number,
//           oversBowled: number
//         ): string => {
//           if (oversBowled === 0) {
//             return "0.0";
//           } else {
//             const oversInt = Math.floor(oversBowled); 
//             const oversDec = (oversBowled - oversInt) * 10; 
//             const totalBalls = oversInt * 6 + oversDec;
//             const runrateFact = totalBalls
//             return ((totalRuns / runrateFact)*6).toFixed(2);
//           }
//         };

//         const calculateCurrentRunRateOfSecondInnings = (
//           totalRuns: number,
//           oversBowled: number
//         ) => {
//           if (oversBowled === 0) {
//             return "0.0";
//           } else {
//             return (totalRuns / oversBowled).toFixed(2);
//           }
//         };

//         const firstInningsRR = calculateCurrentRunRateOfFirstInnings(
//           firstInningsTotalRuns,
//           oversFacedByFirstTeam
//         );
//         const secondInningsRR = calculateCurrentRunRateOfSecondInnings(
//           secondInningsTotalRuns,
//           oversFacedBySecondTeam
//         );


//         const runrates = {
//           firstInningsRR: firstInningsRR,
//           secondInningsRR: secondInningsRR,
//         };

//         setCRR(runrates);
//       })

//       .catch((err) => {
//         if (isMounted) {
//           console.log("err", err);
//         }
//       });
//   };
 

const fetchUser = async () => {
    MatchService.getQuickMatchDetails(match_id)
      .then((res) => {
        if (res?.data?.batFirstScoreboard?.currentlyBatting.length === 0) {
          setIsBatFirstScoreBoard(true);
        }
        if (res?.data?.batSecondScoreboard?.currentlyBatting.length === 0) {
          setIsBatSecondScoreBoard(true);
        }
        if (
          res?.data?.status === "BAT_FIRST_WIN" ||
          res?.data?.status === "BALL_FIRST_WIN"
        ) {
          setIsMatchEnd(true);
        }
        const response = res?.data;

        let scoreboardWithCurrentlyBowling;
        let isBattingFirstDoneBatting = false;

        let currentlyBattingFirstInning = true;

        const isCurrentlyBowlingExistsInSecondScoreBoard = response?.batSecondScoreboard?.hasOwnProperty('currentlyBowling');
        const isCurrentlyBowlingExistsInFirstScoreBoard = response?.batFirstScoreboard?.hasOwnProperty('currentlyBowling');

     
        if (
          isCurrentlyBowlingExistsInFirstScoreBoard &&
          !isCurrentlyBowlingExistsInSecondScoreBoard 
   
        ) {
        //  console.log("First batting team is currently BATTING");
          scoreboardWithCurrentlyBowling = response?.batFirstScoreboard;
          isBattingFirstDoneBatting = false;
          currentlyBattingFirstInning = true;
 

        } else if (
          isCurrentlyBowlingExistsInFirstScoreBoard &&
          isCurrentlyBowlingExistsInSecondScoreBoard
        ) {
        //  console.log("Second batting team is currently BATTING");
          scoreboardWithCurrentlyBowling = response?.batSecondScoreboard;
          isBattingFirstDoneBatting = true;
          currentlyBattingFirstInning = false;
          
        } else {
        //  console.log( "No team is currently bowling, either the match is not started or innings changed");
         
        }

        setIsBattingFirstDoneBatting(isBattingFirstDoneBatting);
        setCurrentlyBattingFirstInning(currentlyBattingFirstInning);

        if (response?.status === "BAT_FIRST_WIN" || response?.status === "BALL_FIRST_WIN") {
      const battingFirstTeamId = response?.batFirst;
      let winnerTeamId: any;

      if (response?.status === "BAT_FIRST_WIN") {
        winnerTeamId = battingFirstTeamId;
      } else if (response?.status === "BALL_FIRST_WIN") {
        winnerTeamId = response?.teams.find(
          (team: { _id: any }) => team._id !== battingFirstTeamId
        )._id;
      }

      const winnerTeam = response?.teams.find(
        (team: { _id: any }) => team._id === winnerTeamId
      );

      const winnerTeamName = winnerTeam ? winnerTeam.name : "Winner team not found";

      setWinnerTeamData({
        winnerTeamId: winnerTeamId,
        winnerTeamName: winnerTeamName,
      });
    }

        // -------------------------------------------------------------------------------------------get Currently Bowling Team and Player-----------------------------------------------------

        const currentlyBowlingPlayerId =
          scoreboardWithCurrentlyBowling.currentlyBowling;
       

        const findBowlerById = (playerId: any) => {
          const allPlayers = response?.teams.flatMap(
            (team: { players: any }) => team.players
          );
          return allPlayers.find(
            (player: { _id: any }) => player._id === playerId
          );
        };

        const findBowlingTeamById = (teamId: any) => {
          return response?.teams.find(
            (team: { _id: any }) => team._id === teamId
          );
        };

        const currentBowlingTeamDetails = findBowlingTeamById(
          findBowlerById(currentlyBowlingPlayerId).teamId
        );
        
        setCurrentlyBowlingteamObject(currentBowlingTeamDetails);

        // -------------------------------------------------------------------------------------------get Currently Batting Team details-----------------------------------------------------
        const currentlyBattingIds =
          scoreboardWithCurrentlyBowling.currentlyBatting;


        const findPlayerById = (playerId: any) => {
          const allPlayers = response?.teams.flatMap(
            (team: { players: any }) => team.players
          );
          return allPlayers.find(
            (player: { _id: any }) => player._id === playerId
          );
        };

        const findTeamById = (teamId: any) => {
          return response?.teams.find(
            (team: { _id: any }) => team._id === teamId
          );
        };

        const currentBattingTeamDetails = findTeamById(
          findPlayerById(currentlyBattingIds[0]).teamId
        );

        setCurrentlyBattingTeamDetails(currentBattingTeamDetails);

        const currentlyBattingPlayersObj = currentlyBattingIds.map(
          (id: any) => {
            const player = findPlayerById(id);
            const team = findTeamById(player.teamId);
            return {
              id: player._id,
              name: player.name,
            };
          }
        );
        setCurrentlyBattingPlayersObject(currentlyBattingPlayersObj);


        // -------------------------------------------------------------------------------------------GET SCOREBOARD ITEMS-----------------------------------------------------
        // -------------------------------------------------get total runs-----------------------------------------------------
        const playerBowls = scoreboardWithCurrentlyBowling.playerBowls;

        const firstInningsTotalRuns = response?.batFirstScoreboard.playerBowls
          .filter(
            (bowl: any) =>
              bowl.status === "RUNS" ||
              bowl.status === "WIDE" ||
              bowl.status === "NO_BALL" ||
              bowl.status === "LEG_BYE" ||
              bowl.status === "WICKET"
          )
          .reduce((total: number, bowl: any) => total + bowl.runs, 0);

        const firstInningsWicketsFallen =
          response?.batFirstScoreboard.playerBowls.filter(
            (bowl: any) => bowl.status === "WICKET"
          ).length;

        const firstInningsData = {
          runs: firstInningsTotalRuns,
          wickets: firstInningsWicketsFallen,
        };
        setFirstInningsStats(firstInningsData);

        const secondInningsTotalRuns = response?.batSecondScoreboard.playerBowls
          .filter(
            (bowl: any) =>
              bowl.status === "RUNS" ||
              bowl.status === "WIDE" ||
              bowl.status === "NO_BALL" ||
              bowl.status === "LEG_BYE" ||
              bowl.status === "WICKET"
          )
          .reduce((total: number, bowl: any) => total + bowl.runs, 0);

        const secondInningsWicketsFallen =
          response?.batSecondScoreboard.playerBowls.filter(
            (bowl: any) => bowl.status === "WICKET"
          ).length;

        const secondInningsdata = {
          runs: secondInningsTotalRuns,
          wickets: secondInningsWicketsFallen,
        };
        setSecondInningsStats(secondInningsdata);

        // if (isBattingFirstDoneBatting) {
        //   const needToWin = firstInningsTotalRuns - secondInningsTotalRuns;
        // }

        // -------------------------------------------------get count of (wide, no ball, leg-bye, wicket)-----------------------------------------------------

        const countNoBalls = playerBowls.filter(
          (bowl: { status: string }) => bowl.status === "NO_BALL"
        ).length;
        const countWickets = playerBowls.filter(
          (bowl: { status: string }) => bowl.status === "WICKET"
        ).length;
        const countWides = playerBowls.filter(
          (bowl: { status: string }) => bowl.status === "WIDE"
        ).length;
        const countLegByes = playerBowls.filter(
          (bowl: { status: string }) => bowl.status === "LEG_BYE"
        ).length;

        const newExtrasObject = {
          noBalls: countNoBalls,
          wickets: countWickets,
          wides: countWides,
          legByes: countLegByes,
        };
        setExtrasCountsObject(newExtrasObject);

     

        // --------------------------------------------------------------------------OVERS-----------------------------------------------------------

        const calculateBatterStats = (
          playerBowls: Ball[]
        ): Record<string, { ballsFaced: number; oversFaced: number }> => {
          const batterStats: Record<
            string,
            { ballsFaced: number; oversFaced: number }
          > = {};

          playerBowls.forEach((bowl) => {
            if (!batterStats[bowl.batsmanId]) {
              batterStats[bowl.batsmanId] = {
                ballsFaced: 0,
                oversFaced: 0,
              };
            }

            if (
              bowl.status === "RUNS" ||
              bowl.batsmanId === "LEG_BYE" ||
              bowl.batsmanId === "WICKET"
            ) {
              batterStats[bowl.batsmanId].ballsFaced++;
            }

            if (bowl.batsmanId === "RUNS") {
              batterStats[bowl.batsmanId].oversFaced += Math.floor(
                batterStats[bowl.batsmanId].ballsFaced / 6
              );
              batterStats[bowl.batsmanId].ballsFaced %= 6;
            }
          });

          return batterStats;
        };

        const calculateBowlerStats = (
          playerBowls: Ball[]
        ): Record<string, { ballsBowled: number; oversBowled: number }> => {
          const bowlerStats: Record<
            string,
            { ballsBowled: number; oversBowled: number }
          > = {};

          playerBowls.forEach((bowl) => {
            if (!bowlerStats[bowl?.bowlerId]) {
              bowlerStats[bowl?.bowlerId] = {
                ballsBowled: 0,
                oversBowled: 0,
              };
            }

            if (
              bowl?.status === "RUNS" ||
              bowl?.status === "LEG_BYE" ||
              bowl?.status === "WICKET"
            ) {
              bowlerStats[bowl?.bowlerId].ballsBowled++;
            }

            if (bowl.status === "RUNS") {
              bowlerStats[bowl?.bowlerId].oversBowled += Math.floor(
                bowlerStats[bowl?.bowlerId].ballsBowled / 6
              );
              bowlerStats[bowl?.bowlerId].ballsBowled %= 6;
            }
          });

          return bowlerStats;
        };

        const batterOversObjectFirst = calculateBatterStats(
          response?.batFirstScoreboard?.playerBowls
        );
        const batterOversObjectSecond = calculateBatterStats(
          response?.batSecondScoreboard?.playerBowls
        );

        const bowlerOversObjectFirst = calculateBowlerStats(
          response?.batFirstScoreboard?.playerBowls
        );
        const bowlerOversObjectSecond = calculateBowlerStats(
          response?.batSecondScoreboard?.playerBowls
        );

        const calculateOversFacedByTeam = (playerBowls: Ball[]): number => {
          const ballsFaced = playerBowls.reduce(
            (totalBalls: number, bowl: Ball) => {
              if (
                bowl?.status === "RUNS" ||
                bowl?.status === "LEG_BYE" ||
                bowl?.status === "WICKET"
              ) {
                return totalBalls + 1;
              }
              return totalBalls;
            },
            0
          );

          const oversFaced = Math.floor(ballsFaced / 6) + (ballsFaced % 6) / 10;
          return oversFaced;
        };
        const oversFacedByFirstTeam = calculateOversFacedByTeam(
          response?.batFirstScoreboard?.playerBowls
        );
        const oversFacedBySecondTeam = calculateOversFacedByTeam(
          response?.batSecondScoreboard?.playerBowls
        );



        const oversData = {
          firstInningBattersFaced: batterOversObjectFirst,
          secondInningBattersFaced: batterOversObjectSecond,
          firstInningBowlersBowled: bowlerOversObjectFirst,
          secondInningBowlersBowled: bowlerOversObjectSecond,
          totalOversBowledFirst: oversFacedBySecondTeam,
          totalOversBowledSecond: oversFacedByFirstTeam,
        };


        if (isMounted) {
          setOversDataObject(oversData);
        }

        const calculateCurrentRunRateOfFirstInnings = (
          totalRuns: number,
          oversBowled: number
        ): string => {
          if (oversBowled === 0) {
            return "0.0";
          } else {
            const oversInt = Math.floor(oversBowled); 
            const oversDec = (oversBowled - oversInt) * 10; 
            const totalBalls = oversInt * 6 + oversDec;
            const runrateFact = totalBalls
            return ((totalRuns / runrateFact)*6).toFixed(2);
          }
        };

        const calculateCurrentRunRateOfSecondInnings = (
          totalRuns: number,
          oversBowled: number
        ) => {
          if (oversBowled === 0) {
            return "0.0";
          } else {
            return (totalRuns / oversBowled).toFixed(2);
          }
        };

        const firstInningsRR = calculateCurrentRunRateOfFirstInnings(
          firstInningsTotalRuns,
          oversFacedByFirstTeam
        );
        const secondInningsRR = calculateCurrentRunRateOfSecondInnings(
          secondInningsTotalRuns,
          oversFacedBySecondTeam
        );


        const runrates = {
          firstInningsRR: firstInningsRR,
          secondInningsRR: secondInningsRR,
        };

        setCRR(runrates);
      })

      .catch((err) => {
        if (isMounted) {
          console.log("err", err);
        }
      });
  };



  function battingFirstScoreBoard(isBattingFirstDoneBatting:any, runs:any, wickets:any) {
  
    if (isBattingFirstDoneBatting) {
      if (runs !== undefined && wickets !== undefined) {
        return `${runs}/${wickets}`;
      } else {
        return "";
      }
    } else {
      return "Yet to bat";
    }
  }
  return (
    <>
      <div className="container-fluid mt-3 ">
        <div className="d-flex h-auto justify-content-center align-items-center outer-box ">
          <div className="container">
            <div className="row ml-md-1 mr-md-1">
              <div
                className=" height-94 col-md-5 border border-white border-r-10 center-content score-card-btn"
                onClick={() => {
                  if (!isBatFirstScoreBoard && !isMatchEnd ) {
                    props?.setPageNumber(1);
                  }
               
                }}
              >
               
                <div className="between-content2 ">
                  <div className="">
                    <div className="">
                      <span className="text-white f-32 font-inter">
                        {currentlyBowlingteamObject?.name}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className=" d-flex justify-content-center align-items-center">
                      <span className="text-white line-h-36 f-30 ml-3 fw-300 font-inter">
                      {battingFirstScoreBoard(isBattingFirstDoneBatting,firstInningsStats?.runs,firstInningsStats?.wickets)}
                      </span>
                      <span className="text-white line-h-36 f-30 ml-3 fw-300 font-inter">
                        (
                        {!isBattingFirstDoneBatting
                          ? oversDataObject?.totalOversBowledFirst
                          : oversDataObject?.totalOversBowledSecond}
                        )
                      </span>
                    </div>
                  </div>
                </div>
              </div>

               <div className="height-94 col-md-2 center-content">
   {!winnerTeamData ? (
  isBattingFirstDoneBatting ? (
    <p className="text-white f-22 font-inter fw-400 text-center">
      {firstInningsStats && secondInningsStats ? (
        secondInningsStats?.runs + 1 > firstInningsStats?.runs ? (
          <span className="fw-700">Match is over</span>
        ) : (
          <span className="fw-700">
            {currentlyBattingTeamDetails?.name} Needs 
            <br />
            {firstInningsStats?.runs -
              secondInningsStats?.runs +
              1}{" "}
            Runs To Win
          </span>
        )
      ) : (
        "N/A"
      )}
    </p>
  ) : (
    <p className="text-white f-22 font-inter fw-400 text-center">
      CRR: <br />
      {crr?.firstInningsRR}{" "}
    </p>
  )
) : (
  ""
)}


      {winnerTeamData ? (
        <p className="text-white f-22 font-inter fw-400 text-center">
          <b>{winnerTeamData?.winnerTeamName}</b> has won the match
        </p>
      ) : null}
    </div>
            

              <div
                className=" height-94 col-md-5 border border-white border-r-10 center-content score-card-btn"
                onClick={() => {
                  if (!isBatSecondScoreBoard && !isMatchEnd ) {
                    props?.setPageNumber(2);
                  }
                 
                }}
              >
                <div className="between-content2 ">
                  <div className="">
                    <div className="">
                      <span className="text-white f-32 font-inter">
                        {currentlyBattingTeamDetails?.name}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className=" d-flex justify-content-center align-items-center">
                      <span className="text-white line-h-58 f-48 font-inter">
                        {/* {!isBattingFirstDoneBatting
                          ? firstInningsStats?.runs +
                            "/" +
                            firstInningsStats?.wickets
                          : secondInningsStats?.runs +
                            "/" +
                            secondInningsStats?.wickets} */}
                            {!isBattingFirstDoneBatting
  ? `${firstInningsStats?.runs ?? 0}/${firstInningsStats?.wickets ?? 0}`
  : `${secondInningsStats?.runs ?? 0}/${secondInningsStats?.wickets ?? 0}`}

                      </span>
                      <span className="text-white line-h-36 f-30 ml-3 fw-300 font-inter">
                        (
                        {/* {!isBattingFirstDoneBatting
                          ? oversDataObject?.totalOversBowledSecond
                          : oversDataObject?.totalOversBowledFirst} */}
                          {!isBattingFirstDoneBatting
  ? oversDataObject?.totalOversBowledSecond ?? 0
  : oversDataObject?.totalOversBowledFirst ?? 0}
                        )
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickLiveScore;
