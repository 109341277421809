import React from "react";
import "../vendors/styles/core.css";
import "../vendors/styles/style.css";
import "../vendors/styles/cricketAppStyles.css";

const Footer: React.FC = () => {
 
  return (
    <div className="d-flex justify-content-end align-items-center">
      <div className="footer-badge d-flex justify-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center ">
          <span>
            Powered by @{" "}
            <a className="text-white" href="https://efitosolutions.com/">
              Efito Solutions
            </a>
          </span>
        </div>
      </div>
     
    </div>
  );
};

export default Footer;
