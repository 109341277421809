import React, { useState } from "react";

import "../../vendors/styles/cricketAppStyles.css";
interface TableDataProps {
    user: any;
    index: number;
    handleClickAdd: (user: any) => void;
    rejectUser: (userId: any, indexNumb: any) => void;
}

const TableData: React.FC<TableDataProps> = ({ user, index, handleClickAdd, rejectUser }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleClick1 = (user: any) => {
        handleClickAdd(user);
    };
    const handleClick2 = (userId: any, indexNumb: any) => {
        rejectUser(userId, indexNumb);
    };
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <tr key={index} className="text-center">
            <td>{user?.name ? user?.name : null}</td>
            <td>{user?.email}</td>
            <td
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="td-w-250"
            >
                <span
                >
                    {isHovered ? user?.message : user?.message?.length > 25 ? user?.message?.slice(0, 25) + '...' : user?.message}
                </span>
            </td>
            <td>{user?.demoStatus}</td>
            <td>
                {user?.demoStatus !== "APPROVED" ? (
                    <button
                        className="btn bg-blue7 text-white font-Poppins mr-1 mt-1 mb-1"
                        onClick={() => handleClick1(user)}
                    >
                        Add
                    </button>
                ) : null}

                {user?.demoStatus !== "REJECTED" ? (
                    <button
                        className="btn btn-danger font-Poppins ml-1 mt-1 mb-1"
                        onClick={() => handleClick2(user?._id, index)}
                    >
                        Reject
                    </button>
                ) : null}
            </td>
        </tr>
    );
};

export default TableData;