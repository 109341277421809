import axios from "axios";
import { TeamData } from "../models/Team";
import { TournamentData } from "../models/Tournament";
import { QuickMatchData } from "../models/QuickMatch";
import { AppResponse } from "../models/Response";
import { Util } from "../Util";
import { User, UserData } from "../models/User";

export class AdminService {

  public static async getTeamsByTournamentId(tournament:any): Promise<AppResponse<TeamData[]>> {
    const url = Util.apiAuthUrl(`teams/${tournament}`);
    return await axios.get<Partial<TeamData[]>, AppResponse<TeamData[]>>(url);
  }
  public static async getAllTeamsByMatchId(matchid:any): Promise<AppResponse<TeamData[]>> {
    const url = Util.apiAuthUrl(`quick-teams-by-matchId/${matchid}`);
    return await axios.get<Partial<TeamData[]>, AppResponse<TeamData[]>>(url);
  }
  public static async getUserDetails(): Promise<AppResponse<User>> {
    const url = Util.apiAuthUrl(`get/user`);
    return await axios.get<any, AppResponse<User>>(url);
  }
public static async resetPassword({ currentPassword, newPassword }: any): Promise<AppResponse<User>> {
  const url = Util.apiAuthUrl(`reset/password`);
  const data = {
    currentPassword: currentPassword,
    newPassword: newPassword
  };
  return await axios.post<any, AppResponse<User>>(url, data);
}

  public static async createTournament(data: Partial<TournamentData>): Promise<AppResponse<TournamentData>> {
    const url = Util.apiAuthUrl("tournament");
    return await axios.post<Partial<TournamentData>, AppResponse<TournamentData>>(url, data);
  }

  public static async uploadProfilePicture(data: any): Promise<AppResponse<User>> {
    const url = Util.apiAuthUrl("upload/profile/image");
    return await axios.post<Partial<User>, AppResponse<User>>(url, data);
  }






  // public static async createQuickMatch(data: Partial<QuickMatchData>): Promise<AppResponse<QuickMatchData>> {
  //   const url = Util.apiAuthUrl(`match/create`);
  //   return await axios.post<Partial<QuickMatchData>, AppResponse<QuickMatchData>>(url, data);
  // }


  public static async getMatchesByTournamentId(tournament:any): Promise<AppResponse<any[]>> {
  
    const url = Util.apiAuthUrl("matches-by-tournament/"+tournament);
    console.log("url",url)
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
    
  }

  public static async updateTeamMatchCount(data: Partial<TeamData>): Promise<AppResponse<TeamData>> {
    const url = Util.apiAuthUrl("update/team-matchCount");
    return await axios.post<Partial<TeamData>, AppResponse<TeamData>>(url, data);
  }

  public static async getMatchesByUserId(user:any): Promise<AppResponse<any[]>> {
  
    const url = Util.apiAuthUrl("matches-by-user/"+user);
    console.log("url",url)
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
    
  }


   public static async getTeamsByUserId(userId:any): Promise<AppResponse<any[]>> {
  // public static async getTeamsByUserId(): Promise<AppResponse<any[]>> {
  
    // const data = {
    //   userId: userId,
    // };
  

     const url = Util.apiAuthUrl("quick-teams-by-userId/"+userId);
    // const url = Util.apiAuthUrl("quick-teams-by-userId");
    console.log("url",url)
    // return await axios.get<Partial<any[]>, AppResponse<any[]>>(url,data);
    // return await axios.get<any, AppResponse<any[]>>(url);
    // return await axios.get<Partial<TeamData[]>, AppResponse<TeamData[]>>(url);
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
    
  }





  public static async getTournamentDataByTournamentId(tournament:any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`tournament/${tournament}`);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

  ////////////////////////////
  public static async getLastQuickMatchDataByUserId(userId:any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`last-quick-match/${userId}`);
    return await axios.get<Partial<any>, AppResponse<any>>(url);
  }

////////////////////////////////

  public static async createTeam(data: Partial<TeamData>): Promise<AppResponse<TeamData>> {
    const url = Util.apiAuthUrl("teams/create");
    return await axios.post<Partial<TeamData>, AppResponse<TeamData>>(url, data);
  }
  public static async createMatch(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("match/create");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async updateMatchTeams(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update-teams");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async getTeamCrr(teamId:any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl("get/get-crr");
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url,teamId);
  }


  public static async updateTeamNrr(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update/team-nrr");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }


  public static async getTournamentList(): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl("tournaments");
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async getAllMatchesData(tournament:any): Promise<AppResponse<any[]>> {
  
    const url = Util.apiAuthUrl("matches-by-tournament/"+tournament);
    console.log("url",url)
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }

  public static async getQuickMatchData(matchId:any): Promise<AppResponse<any[]>> {
  
    const url = Util.apiAuthUrl(`quick-match-by/${matchId}`);
    console.log("url",url)
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  
  public static async getAllTeamsByTournamentId(tournament:any): Promise<AppResponse<any[]>> {
  
    const url = Util.apiAuthUrl("teams/"+tournament);
    console.log("url",url)
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }

  public static async updateMatchTossWinTeams(data: Partial<any>): Promise<AppResponse<any>> {
    console.log("data",data)
    const url = Util.apiAuthUrl("update-macth-status");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  
  public static async updateQuickMatchTossWinTeams(data: Partial<any>): Promise<AppResponse<any>> {
    console.log("data",data)
    const url = Util.apiAuthUrl("update-quick-match-status");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }


  public static async updateMatchCurrentPlayers(data: Partial<any>): Promise<AppResponse<any>> {
    console.log("data",data)
    const url = Util.apiAuthUrl("update-current-players");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async updateQuickMatchCurrentPlayers(data: Partial<any>): Promise<AppResponse<any>> {
    console.log("data in update quick match current players",data)
    const url = Util.apiAuthUrl("update-quick-current-players");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  
  
  public static async finalizedMatch(data: Partial<any>): Promise<AppResponse<any>> {
    console.log("data",data)
    const url = Util.apiAuthUrl("match/finalize");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }

  public static async finalizedQuickMatch(data: Partial<any>): Promise<AppResponse<any>> {
    console.log("data",data)
    const url = Util.apiAuthUrl("match/quick-finalize");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async creatUserByAdmin(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("create/user");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
 
  public static async getAllUserListByAdmin(limit: number, offset: number): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`get/user-list/${limit}/${offset}`);
    return await axios.get<void, AppResponse<any>>(url);
  }
  public static async getEveryUserByAdmin(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`get/every-user`);
    return await axios.get<void, AppResponse<any>>(url);
  }

  public static async getSubscribeRequestUserListByAdmin(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("get/subscribe-Requested-user-list");
    return await axios.get<void, AppResponse<any>>(url);
  }


  public static async activeUsersByAdmin(userId: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl("update/user-status")
    const res = await axios.post<any, AppResponse<any>>(ep, userId)
    return res
  }
  public static async getDemoUsersList(): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl("get/demo-mails/1000/1");
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async rejectDemoRequestUser(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("reject/demo");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async deleteTeam(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("delete/team");
    return await axios.delete<Partial<any>, AppResponse<any>>(url, { data });
  }
  public static async deleteTournament(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("delete/tournament");
    return await axios.delete<Partial<any>, AppResponse<any>>(url, { data });
  }
  public static async updateTeamName(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update/team-name");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);
  }
  public static async updateTeamMembers(data: Partial<any>): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl("update/team-members");
    return await axios.post<Partial<any>, AppResponse<any>>(url, data);


  }
  public static async upgradePlanRequesttoSilver(userId:any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`plan-upgrade/silver/request?id=${userId}`);
    return await axios.post<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async upgradePlanRequesttoGold(userId:any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`plan-upgrade/gold/request?id=${userId}`);
    return await axios.post<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async sendPayLink(data: Partial<any>): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`plan-upgrade/paylink?id=${data.userId}`);
    return await axios.post<Partial<any[]>, AppResponse<any[]>>(url,data);
  }
  

  public static async getFreeUsersList(): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl("get/free-users");
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async getSilverUsersList(): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl("get/silver-users");
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async getGoldUsersList(): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl("get/gold-users");
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async checkPaymentState(userId:any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`get/payment-status?id=${userId}`);
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async getPaymentState(userId:any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`get/current-status?id=${userId}`);
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async checkPayLinkState(userId:any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`get/paylink-status?id=${userId}`);
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async getSilverPayLink(userId:any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`get/silver/paylink?id=${userId}`);
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async getGoldPayLink(userId:any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`get/gold/paylink?id=${userId}`);
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }
  public static async previouslyRequestedPackage(userId:any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`get/last-package/request?id=${userId}`);
    return await axios.get<Partial<any[]>, AppResponse<any[]>>(url);
  }

    public static async confirmPayment(data: Partial<any>): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`confirm-payment?id=${data.userId}`);
    return await axios.post<Partial<any[]>, AppResponse<any[]>>(url,data);
  }

public static async getUserById(userId: any): Promise<AppResponse<User>> {
  const url = Util.apiAuthUrl(`get/admin/user?id=${userId}`);
  return await axios.get<User, AppResponse<User>>(url);
}
public static async getPackageBought(userId: any): Promise<AppResponse<User>> {
  const url = Util.apiAuthUrl(`get/admin/package?id=${userId}`);
  return await axios.get<User, AppResponse<User>>(url);
}

  public static async confirmPlan(userId: any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`confirm-plan?id=${userId}`);
    return await axios.post<Partial<any[]>, AppResponse<any[]>>(url);
  }

   public static async createQuickMatch(data: any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`match/create`);
    return await axios.post<Partial<any[]>, AppResponse<any[]>>(url, data);
  }
   public static async updateQuickMatchwithTeams(data: any): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`match/update-with-teams`);
    return await axios.post<Partial<any[]>, AppResponse<any[]>>(url, data);
  }

  public static async createQuickTeam(data: Partial<TeamData>): Promise<AppResponse<TeamData>> {
    const url = Util.apiAuthUrl("teams/create");
    return await axios.post<Partial<TeamData>, AppResponse<TeamData>>(url, data);
  }

public static async getPlayersByTeamId(teamId: any): Promise<AppResponse<Player>> {
  const url = Util.apiAuthUrl(`get/players/team?id=${teamId}`);
  return await axios.get<User, AppResponse<Player>>(url);
}
public static async getPackagePriceDetails(userId:string, pricePack:string): Promise<AppResponse<string>> {
  const url = Util.apiAuthUrl(`get-package-price-details/${userId}?pricePack=${pricePack}`);
  return await axios.get<User, AppResponse<string>>(url);
}

  
    public static async updateFirstLogin(userId:any): Promise<AppResponse<User>> {
    const url = Util.apiAuthUrl(`update/first-login/${userId}`);
    return await axios.post<Partial<User>, AppResponse<User>>(url);
  }


  
}
