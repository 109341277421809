import React, { useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { AdminService } from "../../../services/AdminService";
import UserContext from "../../../context/UserContext";
import swal from "sweetalert";
import RightArrow from "../../vendors/images/icon/right-arrow.png";
import Edit from "../../vendors/images/icon/edit.png";
import Close from "../../vendors/images/icon/x.png";
import Remove from "../../vendors/images/icon/remove.png";
import PlusIcon from "../../vendors/images/icon/plus.png";
import { TeamData, TeamMemberData } from "../../../models/Team";
import { useHistory, useLocation } from "react-router-dom";
import { MatchService } from "../../../services/MatchService";

const CreateQuickTeam: React.FC = () => {
  interface RouteParams {
    // tournamentName: string;
    // tournamentId: string;
    // userName: string;
    // userId: string;
    matchId: string;

    // state: string;
  }

  // const { tournamentName, tournamentId } = useParams<RouteParams>();
  // const { userName, userId } = useParams<RouteParams>();

  // const { state } = useParams<RouteParams>();

  const [numberOfTeams, setNumberOfTeams] = useState<number>(0);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [teamList, setTeamList] = useState<any>([]);
  const [teamListMain, setTeamListMain] = useState<any>([]);
  const [teamListExtra, setTeamListExtra] = useState<any>([]);
  const [matchList, setMatchList] = useState<any>([]);

  ///////////////////////////////////

  const [newTeamName, setNewTeamName] = useState<string>();
  const [newTeamMemberName, setNewTeamMemberName] = useState<string>();
  const [newTeamMemberList, setNewTeamMemberList] = useState<any[]>([]);
  const [updateTeamMember, setUpdateTeamMember] = useState<any>();
  const [numberOfTeamMembers,setNumberOfTeamMembers] =useState<number>();

  ///////////////////////////////////////////

  const [tournamentData, setTournamentData] = useState<any>();

  const [quickMatchData, setQuickMatchData] = useState<any>();

  const [quickMatchId, setQuickMatchId] = useState<any>();

  const [totalMatchCount, setTotalMatchCount] = useState<any>();
  const [selectTeam1ArrList, setSelectTeam1ArrList] = useState<any[]>([]);
  const [selectTeam2ArrList, setSelectTeam2ArrList] = useState<any[]>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateMatchIndex, setUpdateMatchIndex] = useState<number>(1);
  const [updateMatchId, setUpdateMatchId] = useState<string>();
  const [extraMatchTeam1, setExtraMatchTeam1] = useState<string>();
  const [extraMatchTeam2, setExtraMatchTeam2] = useState<string>();
  const [extraMatchType, setExtraMatchType] = useState<string>();
  const [updateMatchTeam1, setUpdateMatchTeam1] = useState<string>();
  const [updateMatchTeam2, setUpdateMatchTeam2] = useState<string>();

  // ///////////////////////////////////////////////
  const [updateTeamId, setUpdateTeamId] = useState<string>("");
  const [isUpdateTeam, setIsUpdateTeam] = useState<string>("");

  //////////////////////////////////////
  const splittedURL = window.location.pathname.split("/");
  const [user] = useContext(UserContext);
  const { matchId } = useParams<RouteParams>();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    console.log("user effect");

    // console.log('state is '+ state);

    // const match = new URLSearchParams(location.search).get('match');
    // setQuickMatchId(match)
    // console.log('match id  '+ match );
    //  console.log('match id  '+ quickMatchId);

    console.log("user id " + user?._id);
    console.log("match id " + matchId);



    

    // console.log({isUpdateTeam});
    // console.log("state is "+ {state});

    // else {
    //   getLastQuickMatchData();
    // }

    // setIsUpdateTeam(state)
    // console.log({isUpdateTeam});

    // getTournamentData();
    // getTeamsMain();
    // getMatches();
  }, []);

  // useEffect(()=>{

  //     getSelectedMatchDetail();

  // },[quickMatchId])

  const createTeamsPageClicked = () => {
    if (tournamentData?.numberOfTeams > teamList?.length) {
      setPageNumber(2);
    } else {
      swal({
        icon: "error",
        title: "Cannot add more teams to this tournament",
      });
    }
  };
  const upgradePlanBtnClicked = () => {
    setPageNumber(5);
  };

  const planTournamentPageClicked = () => {
    if (matchList?.length >= totalMatchCount) {
      swal({ icon: "error", title: "Already added all matches" });
    } else {
      setPageNumber(3);
    }
  };
  const planTournamentExtraPageClicked = () => {
    if (matchList?.length >= totalMatchCount) {
      setPageNumber(4);
    } else {
      swal({ icon: "error", title: "First plan tournament matches" });
    }
  };
  const closePageClicked = () => {
    setPageNumber(1);
    setNewTeamMemberName("");
    setNewTeamName("");
    setNewTeamMemberList([]);
    setExtraMatchTeam1("");
    setExtraMatchTeam2("");
    setUpdateTeamId("");
    setIsUpdateTeam("");
  };
  //   const getTeamsMain = () => {
  //     AdminService.getTeamsByTournamentId(tournamentId).then((res) => {
  //       if (res.success) {
  //         setTeamList(res.data);
  //       } else {
  //         console.log("error", res.error);
  //       }
  //     });
  //   };
  //   const getMatches = () => {
  //     AdminService.getMatchesByTournamentId(tournamentId).then((res) => {
  //       if (res.success) {
  //         setMatchList(res.data);
  //         const allMatches = res?.data;
  //         let arr1 = [];
  //         let arr2 = [];

  //         let numElements = Math.min(allMatches.length, totalMatchCount);

  //         arr1 = allMatches.slice(0, numElements);
  //         arr2 = allMatches.slice(numElements);
  //         setTeamListMain(arr1);
  //         setTeamListExtra(arr2);
  //       } else {
  //         console.log("error", res.error);
  //       }
  //     });
  //   };
  //   const getTournamentData = () => {
  //     AdminService.getTournamentDataByTournamentId(tournamentId).then((res) => {
  //       if (res.success) {
  //         setTournamentData(res.data);
  //         setTotalMatchCount(
  //           res?.data?.leagueTotalMatches + res?.data?.semiFinalMatches
  //         );
  //       } else {
  //         console.log("error", res.error);
  //       }
  //     });
  //   };

  // ////////////////////
  const getLastQuickMatchData = () => {
    console.log("inside last match");

    AdminService.getLastQuickMatchDataByUserId(user?._id).then((res) => {
      // console.log("data is " + res.data);
      if (res.success) {
        setQuickMatchData(res.data);
        // console.log("this is quick match data"+ quickMatchData);
        //  console.log( quickMatchData);
        //  console.log( quickMatchData._id);
        // console.log(res.data);
        //   const parsedData = JSON.parse(res.data);
        // console.log("this is last quickmatch", parsedData);
        // setTotalMatchCount(
        //   res?.data?.leagueTotalMatches + res?.data?.semiFinalMatches
        // );
      } else {
        console.log("error in getQuick Match Data", res.error);
      }
    });
  };

  const getSelectedMatchDetail = () => {
    console.log("inside selected match " + numberOfTeams);

    MatchService.getQuickMatchDetails(quickMatchId).then((res) => {
      if (res.success) {
        setQuickMatchData(res.data);
        console.log(res.data);
        // console.log(res.data.teams.length);
        setNumberOfTeams(res.data.teams.length + 1);
        console.log(
          "number of teams in getQuick match details:" + numberOfTeams
        );
        console.log(numberOfTeams);
        console.log(" hello wow");
      } else {
        console.log("error in getQuick Match Data", res.error);
      }


    });
  };

  ///////////////////////

  const addTeamMemberToTeam = () => {
    if (updateTeamMember > -1) {
      const updatedTeamMemberList = [...newTeamMemberList];
      updatedTeamMemberList[updateTeamMember] = newTeamMemberName;
      setNewTeamMemberList(updatedTeamMemberList);
      setNewTeamMemberName("");
      setUpdateTeamMember(-1);
    } else {
      setNewTeamMemberName("");
      if (!newTeamMemberName) {
        swal({ icon: "error", title: "Enter Valid Name" });
      } else {
        if (tournamentData?.numberOfTeamMembers > newTeamMemberList?.length) {
          setNewTeamMemberList([...newTeamMemberList, newTeamMemberName]);
        } else {
          swal({ icon: "error", title: "Added Maximum count of members" });
        }
      }
    }
  };

  const addTeamMemberToQuickTeam = () => {

    MatchService.getQuickMatchDetails(matchId).then((res) => {
      if (res.success) {
        // setQuickMatchData(res.data);
        console.log(res.data.teams);
        console.log(res.data.numberOfTeamMembers);
        // setNumberOfTeamMembers(res.data.numberOfTeamMembers)

        // console.log('number of team members  :'+numberOfTeamMembers);

        const memberCount  = res.data.numberOfTeamMembers
        console.log('member count 104 ',memberCount);
        console.log(res);
        console.log(res.status);
        // console.log(res.data.teams.length);
        // setNumberOfTeams(res.data.teams.length + 1);
      
        console.log(numberOfTeams);
        console.log(" hello wow");

        if (updateTeamMember > -1) {
          const updatedTeamMemberList = [...newTeamMemberList];
          updatedTeamMemberList[updateTeamMember] = newTeamMemberName;
          setNewTeamMemberList(updatedTeamMemberList);
          setNewTeamMemberName("");
          setUpdateTeamMember(-1);
        } else {
          setNewTeamMemberName("");
          if (!newTeamMemberName) {
            swal({ icon: "error", title: "Enter Quick Valid Name" });
          } else {
            // if (quickMatchData?.numberOfTeamMembers > newTeamMemberList?.length) {
            //   setNewTeamMemberList([...newTeamMemberList, newTeamMemberName]);
            // } else {
    
            console.log('member count 299 ',memberCount);
            console.log('new team member list length 300 ',newTeamMemberList.length);
            if (newTeamMemberName.length > 0 && memberCount > newTeamMemberList.length) {
              setNewTeamMemberList([...newTeamMemberList, newTeamMemberName]);
              // setNewTeamMemberList([...newTeamMemberList, newTeamMemberName]);
              console.log('member count 303 ',memberCount);
            } else {
              console.log(quickMatchData);
              console.log(quickMatchData?.numberOfTeamMembers);
              console.log(newTeamMemberList?.length);
              swal({
                icon: "error",
                title: "Added Maximum count of Quick Team members",
              });
            }
          }
        }











      } else {
        console.log("error in getQuick Match Data", res.error);
      }


      

    });

  };

  const removeMemberFromList = (index: number) => {
    const updatedTeamMemberList = [...newTeamMemberList];
    updatedTeamMemberList.splice(index, 1);
    setNewTeamMemberList(updatedTeamMemberList);
  };
  const updateMemberFromList = (index: number, memberName: any) => {
    setNewTeamMemberName(memberName);
    setUpdateTeamMember(index);
  };
  const createTeam = () => {
    if (!newTeamName) {
      swal({ icon: "error", title: "Please Enter Team name" });
    } else if (newTeamMemberList?.length < 1) {
      swal({ icon: "error", title: "Please Add Team Members" });
    }

 MatchService.getQuickMatchDetails(matchId).then((res) => {
      if (res.success) {
        // setQuickMatchData(res.data);
        console.log(res.data.teams);
        console.log(res.data.numberOfTeamMembers);
        // setNumberOfTeamMembers(res.data.numberOfTeamMembers)

        // console.log('number of team members  :'+numberOfTeamMembers);

        const memberCount  = res.data.numberOfTeamMembers
        console.log('member count 104 ',memberCount);
        console.log(res);
        console.log(res.status);
        // console.log(res.data.teams.length);
        // setNumberOfTeams(res.data.teams.length + 1);
      
        console.log(numberOfTeams);
        console.log(" hello wow");







    if (newTeamName && newTeamMemberList?.length   === memberCount) {




      const data = {
        // matchId: quickMatchData._id,
        // matchId: quickMatchId,
        matchId: matchId,
        userId: user?._id,
        teams: [
          {
            name: newTeamName,
            players: newTeamMemberList,
          },
        ],
      };
      AdminService.createQuickTeam(data).then((res) => {
        if (res.success) {
          console.log("res.team data" + res.data);
          const memberList = newTeamMemberList.map((member: any) => ({
            name: member,
          }));
          const data = {
            // matchId: quickMatchData._id,
            matchId: matchId,

            name: newTeamName,
            players: memberList,
          };
          // console.log(
          //   "inside create qiuick team admin services matchId" +
          //     quickMatchData._id
          // );
          console.log(
            "inside create quick team admin services matchId" + quickMatchId
          );

          const data1 = {
            // matchId: quickMatchData._id,
            // matchId: quickMatchId,
            matchId: matchId,
            teamData: res.data,
          };
          console.log("team data" + data1);

          AdminService.updateQuickMatchwithTeams(data1).then((res: any) => {
            if (res.success) {
              const teamCount = res.data?.teams?.length;
              console.log("sucess update team data " + res.data);
              console.log(res.data);
              console.log("365", teamCount);
              setTeamList([...teamList, data]);
              setUpdate(!update);
              setNewTeamName("");
              setNewTeamMemberList([]);
              swal({ icon: "success", title: "New team created!" }).then(() => {
                //  const lengthOfTeamsArray: any = teamCount
                console.log("testing :" + teamCount);
                if (teamCount === 2) {
                  console.log("hi inside route");
                  console.log("NUMBER OG  TEAMS " + teamCount);
                  // history.push(`/admin/my-matches/${user?._id}`);
                  history.push(`/admin/my-matches`);
                }

                if (teamCount !== 2) {
                  console.log("conditon is false" + teamCount);
                }
              });
            }
          });
        } else {
          swal({
            icon: "error",
            title: res?.error ? res.error : "Something went wrong",
          });
        }
      });
    }
    else{
      swal({ icon: "error", title: "Please Add  entered Team Members" })
       console.log("error in getQuick Match Data", res.error);
  
    }
  }

}
 )   }


  const handleChangeTeam1 = (event: any, index: number) => {
    const newString = event.target.value;
    if (index >= 0 && index < selectTeam1ArrList.length) {
      const updatedArray = [...selectTeam1ArrList];
      updatedArray[index] = newString;
      setSelectTeam1ArrList(updatedArray);
    } else {
      const updatedArray = [...selectTeam1ArrList];
      while (updatedArray.length < index) {
        updatedArray.push("");
      }
      updatedArray[index] = newString;
      setSelectTeam1ArrList(updatedArray);
    }
  };
  const handleChangeTeam2 = (event: any, index: number) => {
    const newString = event.target.value;
    if (index >= 0 && index < selectTeam2ArrList.length) {
      const updatedArray = [...selectTeam2ArrList];
      updatedArray[index] = newString;
      setSelectTeam2ArrList(updatedArray);
    } else {
      const updatedArray = [...selectTeam2ArrList];
      while (updatedArray.length < index) {
        updatedArray.push("");
      }
      updatedArray[index] = newString;
      setSelectTeam2ArrList(updatedArray);
    }
  };
  const areValuesAtSameIndexEqual = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] === arr2[i]) {
        //  console.log("run==>", arr1[i], arr2[i]);
        return false;
      }
    }

    return true;
  };
  //   const addMatchForTournament = () => {
  //     if (selectTeam1ArrList?.length !== selectTeam2ArrList?.length) {
  //       swal({ icon: "error", title: "Please select team for all matches" });
  //     } else {
  //       const checkArray = selectTeam1ArrList.filter((team1) => team1.length < 1);
  //       const checkArray2 = selectTeam2ArrList.filter(
  //         (team2) => team2.length < 1
  //       );
  //       if (checkArray?.length !== 0) {
  //         swal({ icon: "error", title: "Please select team for all matches" });
  //       } else if (checkArray2?.length !== 0) {
  //         swal({ icon: "error", title: "Please select team for all matches" });
  //       } else {
  //         const notSameIndex = areValuesAtSameIndexEqual(
  //           selectTeam1ArrList,
  //           selectTeam2ArrList
  //         );
  //         if (notSameIndex) {
  //           const maxLength = Math.max(
  //             selectTeam1ArrList.length,
  //             selectTeam2ArrList.length
  //           );
  //           const newTeamArr = [];
  //           for (let i = 0; i < maxLength; i++) {
  //             newTeamArr.push([selectTeam1ArrList[i], selectTeam2ArrList[i]]);
  //           }
  //           for (let i = 0; i < maxLength; i++) {
  //             newTeamArr.push([selectTeam1ArrList[i], selectTeam2ArrList[i]]);
  //             const data = {
  //               tournamentId: tournamentId,
  //               type: "REGULAR",
  //               teams: newTeamArr[i],
  //             };
  //             AdminService.createMatch(data).then((res) => {
  //               if (res.success) {
  //                 if (i === maxLength - 1) {
  //                   swal({
  //                     icon: "success",
  //                     title: "New tournament matches created!",
  //                   }).then(function () {
  //                     setUpdate(!update);
  //                     setPageNumber(1);
  //                   });
  //                 }
  //               } else {
  //                 swal({
  //                   icon: "error",
  //                   title: res?.error ? res?.error : "Something went wrong",
  //                 });
  //               }
  //             });
  //           }
  //         } else {
  //           swal({
  //             icon: "error",
  //             title: "Please select different team for match",
  //           });
  //         }
  //       }
  //     }
  //   };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const updateMatchTeam = (index: number, id: string) => {
    setUpdateMatchIndex(index);
    setUpdateMatchId(id);
    const indexToFilter = index;
    const selectedMatch = matchList[indexToFilter];
    setUpdateMatchTeam1(selectedMatch?.teams[0]?._id);
    setUpdateMatchTeam2(selectedMatch?.teams[1]?._id);
    handleOpenModal();
  };
  const ExtraSelectTeam1 = (event: any) => {
    setExtraMatchTeam1(event.target.value);
  };
  const ExtraMatchType = (event: any) => {
    setExtraMatchType(event.target.value);
  };
  const ExtraSelectTeam2 = (event: any) => {
    setExtraMatchTeam2(event.target.value);
  };
  //   const addExtraMatchForTournament = () => {
  //     if (extraMatchTeam1 && extraMatchTeam2 && extraMatchType) {
  //       if (extraMatchTeam1 !== extraMatchTeam2) {
  //         const data = {
  //           tournamentId: tournamentId,
  //           type: extraMatchType,
  //           teams: [extraMatchTeam1, extraMatchTeam2],
  //         };
  //         AdminService.createMatch(data).then((res) => {
  //           if (res.success) {
  //             setExtraMatchTeam1("");
  //             setExtraMatchTeam2("");
  //             swal({
  //               icon: "success",
  //               title: "New extra match is created!",
  //             }).then(function () {
  //               setUpdate(!update);
  //               setPageNumber(1);
  //             });
  //           } else {
  //             swal({
  //               icon: "error",
  //               title: res?.error ? res?.error : "Something went wrong",
  //             });
  //           }
  //         });
  //       } else {
  //         swal({ icon: "error", title: "Please Select different Teams" });
  //       }
  //     } else {
  //       swal({ icon: "error", title: "Please Select All Fields" });
  //     }
  //   };
  const handleUpdateMatchTeam1 = (event: any) => {
    setUpdateMatchTeam1(event.target.value);
  };
  const handleUpdateMatchTeam2 = (event: any) => {
    setUpdateMatchTeam2(event.target.value);
  };
  const updateMatchTeams = () => {
    if (updateMatchTeam1 === updateMatchTeam2) {
      swal({ icon: "error", title: "Please Select different Teams" });
    } else {
      const data = {
        matchId: updateMatchId,
        team01: updateMatchTeam1,
        team02: updateMatchTeam2,
      };
      AdminService.updateMatchTeams(data).then((res) => {
        if (res.success) {
          setUpdate(!update);
          handleCloseModal();
          setUpdateMatchTeam1("");
          setUpdateMatchTeam2("");
          swal({
            icon: "success",
            title: "New tournament matches updated!",
          }).then(function () {});
        } else {
          swal({
            icon: "error",
            title: res?.error ? res.error : "Something went wrong",
          });
        }
      });
    }
  };
  const deleteTeam = (teamId: any) => {
    swal({
      icon: "success",
      title: "Do you want to delete team ?",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "my-custom-cancel-button",
          closeModal: true,
        },
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          // className: "my-custom-ok-button",
          closeModal: true,
        },
      },
    }).then(function (value) {
      if (value === null) {
        //  console.log("Cancel button clicked");
      } else {
        AdminService.deleteTeam(data).then((res) => {
          if (res.success) {
            setUpdate(!update);
            swal({
              icon: "success",
              title: "Successfully delete team!",
            }).then(function () {});
          } else {
            swal({
              icon: "error",
              title: res?.error ? res.error : "Something went wrong",
            });
          }
        });
      }
    });
    const data = {
      teamId: teamId,
    };
  };
  const updateTeam = (team: any) => {
    // setIsUpdateTeam(state);
    setUpdateTeamId(team?._id);
    setNewTeamName(team?.name);
    const teamPlayersArr = team.players?.map((player: any) => player.name);
    setNewTeamMemberList(teamPlayersArr);
    setPageNumber(2);
    // console.log("team==>", team)
  };
  const handleClickUpdateTeam = () => {
    const data = {
      teamId: updateTeamId,
      teamName: newTeamName,
    };
    const data2 = {
      teamId: updateTeamId,
      newTeamData: newTeamMemberList,
    };
    if (!updateTeamId) {
      swal({
        icon: "error",
        title: "Something went wrong",
      });
    } else if (!newTeamName) {
      swal({
        icon: "error",
        title: "Please enter team name",
      });
    } else if (newTeamMemberList?.length == 0) {
      swal({
        icon: "error",
        title: "Please add team members",
      });
    } else {
      AdminService.updateTeamName(data).then((res) => {
        if (res.success) {
          AdminService.updateTeamMembers(data2).then((res) => {
            if (res.success) {
              setIsUpdateTeam("");
              setNewTeamName("");
              setNewTeamMemberList([]);
              setPageNumber(1);
              setUpdate(!update);
              swal({
                icon: "success",
                title: "Team updated!",
              }).then(function () {});
            } else {
              swal({
                icon: "error",
                title: res?.error ? res.error : "Something went wrong",
              });
            }
          });
        } else {
          swal({
            icon: "error",
            title: res?.error ? res.error : "Something went wrong",
          });
        }
      });
    }
  };

  const previosPage = () => {
    history.goBack();
  };

  return (
    <>
      <div className="container mt-20 ml-20">
        <div className="row">
          <div className="col-md-12">
            <div className="container-fluid mt-3 main-container">
              <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                <div className="row w-100">
                  <div>
                    <i
                      className="fa fa-arrow-left f-color-gray-7  cursor-p"
                      aria-hidden="true"
                      onClick={() => previosPage()}
                    ></i>
                  </div>

                  {/* <div className="col-md-12  d-flex justify-content-end align-items-end">
                  <img
                    className="close-icon-size mr-1 mt-2"
                    onClick={() => closePageClicked()}
                    src={Close}
                    alt=""
                  />
                </div> */}

                  {/* Map this table card */}
                  <div className="col-md-6 mb-4 mbb-20 mt-4">
                    <div className="container">
                      <div className="row  d-flex justify-content-center align-items-center">
                        <span className="f-color-black3 fw-600 f-30 d-flex justify-content-center align-items-center mt-1 mb-1 font-Poppins">
                          {isUpdateTeam === "Update" ? "Update" : "Create"} Team
                          Members
                        </span>
                      </div>
                      <div className="row  ">
                        <div className="between-content pl-1 pr-1 ">
                          <div className="mb-1 w-100 text-input-container">
                            <label className="mb-pb-0 fw-500 f-17 f-color-gray font-Poppins">
                              Team name
                            </label>
                            <input
                              onChange={(e) => setNewTeamName(e.target.value)}
                              type="text"
                              value={newTeamName}
                              className="form-control text-input2"
                              id="exampleInput"
                            />
                          </div>
                        </div>
                        <div className="between-content pl-1 pr-1 ">
                          <div className="mb-3 w-100 text-input-container">
                            <label className="mb-pb-0 fw-500 f-17 f-color-gray font-Poppins">
                              Team member name
                            </label>
                            <input
                              onChange={(e) =>
                                setNewTeamMemberName(e.target.value)
                              }
                              type="text"
                              value={newTeamMemberName}
                              className="form-control text-input2"
                              id="exampleInput"
                            />
                            <div className="input-group-append d-flex justify-content-end align-items-end">
                              <span
                                className="input-group-text icon-container cursor-p"
                                onClick={() =>
                                  // addTeamMemberToTeam()
                                  addTeamMemberToQuickTeam()
                                }
                              >
                                <img src={PlusIcon} alt="Icon" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row h-full pt-5 pl-3 pr-3 d-flex justify-content-end align-items-bottom">
                        {isUpdateTeam === "Update" ? (
                          <div
                            className="arrow-btn2"
                            onClick={() => handleClickUpdateTeam()}
                          >
                            <span className="f-17 f-color-white fw-500 font-Poppins">
                              Update Team
                            </span>
                            <img
                              className="arrow-icon-size ml-3"
                              src={RightArrow}
                              alt=""
                            />
                          </div>
                        ) : (
                          <div
                            className="arrow-btn2"
                            onClick={() => createTeam()}
                          >
                            <span className="f-17 f-color-white fw-500 font-Poppins">
                              Create Team
                            </span>
                            <img
                              className="arrow-icon-size ml-3"
                              src={RightArrow}
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {(newTeamName || newTeamMemberList?.length > 0) && (
                    <div className="col-md-6 mb-4 mbb-20">
                      <div className="container bg-gray6 border-top-10">
                        <div className="row pt-2 pl-3 pr-3 d-flex justify-content-left align-items-left">
                          <span className="text-black fw-400 f-18 mt-1 mb-1 font-Poppins">
                            {newTeamName}
                          </span>
                        </div>
                        {newTeamMemberList?.map(
                          (member: TeamMemberData, index: number) => (
                            <div
                              className="row ml-4 mr-4 border-r-9 pr-4 mb-1 min-h-35 bg-gray2"
                              key={index}
                            >
                              <div className="between-content pl-1 pr-1 ">
                                <div>
                                  <span className="fw-500 f-15 f-color-black3 font-poppins">
                                    {member}
                                  </span>
                                </div>
                                <div>
                                  <img
                                    className="edit-icon-size mr-md-3 cursor-p"
                                    src={Remove}
                                    onClick={() => removeMemberFromList(index)}
                                    alt=""
                                  />
                                  <img
                                    className="edit-icon-size cursor-p"
                                    src={Edit}
                                    onClick={() =>
                                      updateMemberFromList(index, member)
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateQuickTeam;
