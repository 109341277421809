import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../../context/UserContext";
import "../../vendors/styles/cricketAppStyles.css";
import { AdminService } from "../../../services/AdminService";
import { useParams, NavLink, useHistory } from "react-router-dom";
import PlayIcon from "../../vendors/images/icon/fi_play.png";
import CustomModal from "../../common/Modal";
import RightArrow from "../../vendors/images/icon/right-arrow.png";
import swal from "sweetalert";

import Close from "../../vendors/images/icon/x.png";
import QuickTeamsTable from "./QuickTeamsTable";

const QuickTeams: React.FC = () => {
  const history = useHistory();
  const [matchesData, setMatchesData] = useState<any[]>();
  const [user] = useContext(UserContext);
  const { tournamentId }: any = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectTeams, setSelectTeams] = useState<any>([]);
  const [tosWinTeam, setTosWinTeam] = useState();
  const [batFirstTeam, setBatFirstTeam] = useState();
  const [updateMatchId, setUpdateMatchId] = useState();
  const [quickTeamsData, setQuickTeamsData] = useState<any[]>();
  const [team, setTeam] = useState<any>();
  const [teamList, setTeamList] = useState<any[]>([]); // State to hold all teams

  const { userId }: any = useParams();

  useEffect(() => {
    let isMounted = true;
    if (userId) {
      console.log("user Id in Quick temas" + userId);
      AdminService.getTeamsByUserId(userId).then((res) => {
        if (isMounted) {
          if (res.success) {
            console.log(res);
            const teamsArray = res.data.map((teamData) => {
              const { _id, name, userId, matchId, createdAt } = teamData;

              AdminService.getPlayersByTeamId(_id)
                .then((res: any) => {
                  if (res.success) {
                    console.log("Players", res.data);
                    const player = res.data;
                    const mappedPlayers = player.map(
                      (player: {
                        _id: any;
                        name: any;
                        teamId: any;
                        createdAt: any;
                      }) => {
                        const createdDate = new Date(player.createdAt);

                        const formattedDate = createdDate.toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }
                        );

                        return {
                          _id: player._id,
                          name: player.name,
                          teamId: player.teamId,
                          createdAt: formattedDate,
                        };
                      }
                    );
                    const createdDate = new Date(createdAt);

                    const formattedDate = createdDate.toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    );
                    const team = {
                      _id: _id,
                      name: name,
                      userId: userId,
                      matchId: matchId,
                      players: mappedPlayers,
                      createdAt: formattedDate,
                    };

                    console.log("Team :::::", team);
                    setTeamList((prevTeams) => [...prevTeams, team]);
                  } else {
                    console.log("error", res.error);
                  }
                })
                .catch((err: any) => {
                  console.log("error", err);
                });
            });
          } else {
            console.log("error", res.error);
          }
        }
      });
    } else {
      console.log("No user id +not found");
    }

    return () => {
      isMounted = false;
    };
  }, [userId]);

  const getResultStatus = (
    batFirst: any,
    teams: any,
    status: any,
    matchId: any
  ) => {
    if (status === "NOT_STARTED") {
      return "Not Started Yet!";
    } else if (status === "ONGOING") {
      return <span className="matches-team-win">Ongoing</span>;
    } else if (status === "MATCH_TIED") {
      return "Match Tied!";
    } else if (status === "BAT_FIRST_WIN") {
      var batFirstTeam = teams?.filter(function (t: any) {
        return t?.id === batFirst;
      });

      return (
        <span className="matches-team-win">
          {batFirstTeam[0]?.name + " wins!"}
        </span>
      );
    } else if (status === "BALL_FIRST_WIN") {
      var ballFirstTeam = teams?.filter(function (t: any) {
        return t?.id !== batFirst;
      });

      return (
        <span className="matches-team-win">
          {ballFirstTeam[0].name + " wins!"}
        </span>
      );
    } else {
      return "Not Started Yet!";
    }
  };

  const getTeamTotals = (
    matchId: any,
    batFirst: any,
    teams: any,
    status: any,
    batFirstScoreboard: any,
    batSecondScoreboard: any
  ) => {
    var batFirstTeam = teams?.filter(function (t: any) {
      return t?.id === batFirst;
    });

    var ballFirstTeam = teams?.filter(function (t: any) {
      return t?.id !== batFirst;
    });

    if (status === "NOT_STARTED") {
      if (teams?.length > 0) {
        return <span>-</span>;
      }
    } else {
      if (!batFirstScoreboard || !batSecondScoreboard) {
        return <span>-</span>;
      }

      var batFirstTotal = 0;
      var batFirstWickets = 0;

      batFirstScoreboard?.playerBowls?.filter(function (b: any) {
        if (b?.status !== "WICKET") {
          batFirstTotal = batFirstTotal + b?.runs;
        }

        if (b?.status === "WICKET") {
          batFirstWickets = batFirstWickets + 1;
        }
      });

      var ballFirstTotal = 0;
      var ballFirstWickets = 0;

      batSecondScoreboard?.playerBowls?.filter(function (b: any) {
        if (b?.status !== "WICKET") {
          ballFirstTotal = ballFirstTotal + b?.runs;
        }

        if (b?.status === "WICKET") {
          ballFirstWickets = ballFirstWickets + 1;
        }
      });

      return (
        <span className="matches-team-total">
          {batFirstTeam[0]?.name?.split(" ")[0]?.charAt(0)?.toUpperCase() +
            "" +
            (batFirstTeam[0]?.name?.split(" ")[1]?.charAt(0) === undefined
              ? ""
              : "." +
                batFirstTeam[0]?.name?.split(" ")[1]?.charAt(0)?.toUpperCase() +
                "") +
            "" +
            (batFirstTeam[0]?.name?.split(" ")[2]?.charAt(0) === undefined
              ? ""
              : "." +
                batFirstTeam[0]?.name?.split(" ")[2]?.charAt(0)?.toUpperCase() +
                "") +
            ":" +
            batFirstTotal +
            "/" +
            batFirstWickets}
          &nbsp;&nbsp; | &nbsp;&nbsp;
          {ballFirstTeam[0]?.name?.split(" ")[0]?.charAt(0)?.toUpperCase() +
            "" +
            (ballFirstTeam[0]?.name.split(" ")[1]?.charAt(0) === undefined
              ? ""
              : "." +
                ballFirstTeam[0]?.name.split(" ")[1]?.charAt(0)?.toUpperCase() +
                "") +
            "" +
            (ballFirstTeam[0]?.name.split(" ")[2]?.charAt(0) === undefined
              ? ""
              : "." +
                ballFirstTeam[0]?.name.split(" ")[2]?.charAt(0)?.toUpperCase() +
                "") +
            ":" +
            ballFirstTotal +
            "/" +
            ballFirstWickets}
        </span>
      );
    }
  };
  const getTeamStatus = (
    matchId: any,
    batFirst: any,
    teams: any,
    status: any,
    batFirstScoreboard: any,
    batSecondScoreboard: any
  ) => {
    if (status === "NOT_STARTED") {
      if (teams.length > 0) {
        return (
          // <span
          //   className="matches-team-live"
          //   onClick={() => startMatch(matchId, teams)}
          // >
          //   Start Match
          // </span>
          <div
            className="border-r-20 bg-dark-light center-content p-2"
            onClick={() => startMatch(matchId, teams)}
          >
            <span className="f-16 f-color-white ml-2 pl-1">Start Match </span>
            <img
              className="play-icon-size ml-2 mr-2"
              src={PlayIcon}
              alt="live-play-btn"
            />
          </div>
        );
      }
    } else {
      if (!batFirstScoreboard || !batSecondScoreboard) {
        return (
          <div
            className="border-r-20 bg-dark-light center-content p-2"
            onClick={() => startMatch(matchId, teams)}
          >
            <span className="f-16 f-color-white ml-2 pl-1">Start Match </span>
            <img
              className="play-icon-size ml-2 mr-2"
              src={PlayIcon}
              alt="live-play-btn"
            />
          </div>
        );
      }
    }
    if (status === "ONGOING") {
      return (
        <div
          className="border-r-20 bg-dark-purple center-content p-2"
          onClick={() => navigateScore(matchId)}
        >
          <span className="f-16 f-color-white ml-2 pl-1">Ongoing </span>
          <img
            className="play-icon-size ml-2 mr-2"
            src={PlayIcon}
            alt="live-play-btn"
          />
        </div>
      );
    }
    if (status == "BAT_FIRST_WIN" || "BALL_FIRST_WIN" || "MATCH_TIED") {
      return (
        <div
          className="border-r-20 bg-yellow-dark center-content p-2"
          onClick={() => navigateScore(matchId)}
        >
          <span className="f-16 f-color-white ml-2 pl-1">Completed </span>
          <img
            className="play-icon-size ml-2 mr-2"
            src={PlayIcon}
            alt="live-play-btn"
          />
        </div>
      );
    }
  };

  const navigateScore = (id: any) => {
    history.push(`/admin/live-score/${id}`);
  };
  const startMatch = (id: any, teams: any) => {
    if (user?.userType === "SUPER_ADMIN") {
    } else {
      setUpdateMatchId(id);
      setSelectTeams(teams);
      handleOpenModal();
    }
  };
  const updateStartMatch = () => {
    if (!tosWinTeam || !batFirstTeam) {
      swal({ icon: "error", title: "Please select tos win and bat first" });
    } else {
      const data = {
        matchId: updateMatchId,
        status: "ONGOING",
        tossWin: tosWinTeam,
        batFirst: batFirstTeam,
      };
      AdminService.updateMatchTossWinTeams(data).then((res) => {
        if (res.success) {
          swal({ icon: "success", title: "Start match!" }).then(function () {
            history.push(`/admin/score-board/${updateMatchId}`);
          });
        } else {
          swal({
            icon: "error",
            title: res?.error ? res.error : "Something went wrong",
          });
        }
      });
    }
  };
  const handleUpdateMatchTeam1 = (event: any) => {
    setTosWinTeam(event.target.value);
  };
  const handleUpdateMatchTeam2 = (event: any) => {
    setBatFirstTeam(event.target.value);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className={`${
          user?.userType === "SUPER_ADMIN" ? `` : `main-container`
        }`}
      >
        <div className="my-teams">
          <div className="ml-mr-auto">
            <div className="row  mt-3 w-100  bg-white rounded d-flex align-items-center ml-auto mr-auto py-4">
              <div className="row w-100 h-25  d-flex justify-content-start ml-auto ">
                
                  <NavLink className="navlink-hcolor col-md-2 t-matches-count btns" to={`/admin/my-matches`}>
                  <div>
                    My Matches Dashboard
                    </div>
                  </NavLink>
                

               
                <div className="col-md-2      t-name btns">Teams</div>
                {/* <div className="row w-100 line ml-auto mr-auto pl-2 pr-2 "></div> */}
              </div>
            </div>
          </div>

          <div className="row pad-top">
            <div className="col-md-12">
              <div className="container-fluid mt-3 pl-pr-0">
                <div className="d-flex w-100  justify-content-center align-items-center outer-box bg-white border-w border-r-10 rounded h-auto add-mw-431 teams-431-reduce-margin-2">
                  <div className="row w-100 max-h-table teams-431-reduce-margin-1">
                    <div className="col-md-12 mb-4 d-flex justify-content-left align-items-center">
                      <div className="table-responsive">
                        <p className="font-weight-bold mt-2">Team Summary</p>
                        <div className="over-flow-x">
                          <table className="table">
                            <thead className="table-head-matches ">
                              <tr className="text-center">
                                <th>Created Date</th>
                                <th>Team Name</th>
                                <th>Number of Players</th>
                                <th>See Members</th>
                              </tr>
                            </thead>
                            <tbody className="table-body-matches">
                              {teamList.map((team, index) => (
                                <QuickTeamsTable
                                  key={team._id}
                                  userId={userId}
                                  team={team}
                                  forKey={index}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickTeams;
