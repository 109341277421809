import React, { useEffect, useState, useContext } from "react";

import "../../vendors/styles/cricketAppStyles.css";
import ScoreCard from "../../common/dashboardComponent/ScoreCardComponent";
import { MatchService } from "../../../services/MatchService";
import { useHistory } from "react-router-dom";
import cricketBall from "../../../components/vendors/images/cricketBall.svg";
import cricketBat from "../../../components/vendors/images/cricketBat.svg";
import Logo from "../../../components/vendors/images/icon/logo.png";
import BallScore from "../../common/dashboardComponent/BallScoreCardComponent";
import { Role } from "../../../models/Role";
import UserContext from "../../../context/UserContext";
const LiveScoreData: React.FC = (matchId: any) => {
  const [user, ] = useContext(UserContext);
  const [tournamentTitle, setTournamentTitle] = useState<any>(null);
  const [tournamentId, setTournamentId] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const splittedURL1 = window.location.pathname.split("/");
  const match_id = splittedURL1[splittedURL1.length - 1];
  const history = useHistory();
  const [match, setMatch] = useState<any>(null);
  const [battingFirstTeam, setbattingFirstTeam] = useState("");
  const [ballingFirstTeam, setballingFirstTeam] = useState("");
  const splittedURL = window.location.pathname.split("/");
  const matchLiveId = splittedURL[splittedURL.length - 1];
  const [currentBatsmanPair, setCurrentBatsmanPair] = useState<any[]>([]);
  const [currentBatsmanPairSecond, setCurrentBatsmanPairSecond] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchMatch();
    fetchTournament();
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(loadingTimeout);
  }, [pageNumber,matchId]);
  useEffect(() => {
   if(pageNumber !== 3){
    const intervalId = setInterval(() => {
      window.location.reload();
    }, 20000); 

    return () => {
      clearInterval(intervalId);
    };
   }
  }, [pageNumber]);
  const fetchTournament = async () => {
    try {

      const response = await MatchService.getMatchDetails(matchLiveId);

      if (response.success === true) {
        const matchData = response;
        setMatch(response.data);
        if (
          response.data?.batFirstScoreboard &&
          response.data?.batFirstScoreboard?.currentlyBatting?.length > 0
        ) {
        } else {
        }
        setbattingFirstTeam(
          batFirstTeam(matchData.data.batFirst, matchData.data.teams).name
        );

        setballingFirstTeam(
          ballFirstTeam(matchData.data.batFirst, matchData.data.teams).name
        );
        getCurrentBatsmanPair(matchData.data)
        getCurrentBatsmanPairSecond(matchData.data)
      } else {
        console.error("Failed to fetch match data");
      }
    } catch (error) {
      console.error("Error while fetching match data:", error);
    }
  };
  const fetchUser = async () => {
    try {

      const response = await MatchService.getMatchDetails(matchLiveId);

      if (response.success === true) {
        const matchData = response;
        setMatch(response.data);
        if (
          response.data?.batFirstScoreboard &&
          response.data?.batFirstScoreboard?.currentlyBatting?.length > 0
        ) {
        } else {
        }
        setbattingFirstTeam(
          batFirstTeam(matchData.data.batFirst, matchData.data.teams).name
        );

        setballingFirstTeam(
          ballFirstTeam(matchData.data.batFirst, matchData.data.teams).name
        );
        getCurrentBatsmanPair(matchData.data)
        getCurrentBatsmanPairSecond(matchData.data)
      } else {
        console.error("Failed to fetch match data");
      }
    } catch (error) {
      console.error("Error while fetching match data:", error);
    }
  };
  const getCurrentBatsmanPair = (matchData: any) => {
    const batsmanPairArr = matchData?.batFirstScoreboard?.currentlyBatting
    setCurrentBatsmanPair(batsmanPairArr)
  }
  const getCurrentBatsmanPairSecond = (matchData: any) => {
    const batsmanPairArr = matchData?.batSecondScoreboard?.currentlyBatting

    setCurrentBatsmanPairSecond(batsmanPairArr)
  }

  const toggleNextScoreboard = () => {
    var extraRunsBallsCount = 0;

    if (match.batFirstScoreboard == null) {
      return false;
    }

    match.batFirstScoreboard.playerBowls.filter(function (pb: any) {
      if (pb.status === "WIDE" || pb.status === "NO_BALL") {
        extraRunsBallsCount = extraRunsBallsCount + 1;
      }
    });
  };

  const batFirstTeam = (batFirst: any, teams: any) => {
    var batFirstTeam = teams.filter(function (t: any) {
      return t.id === batFirst;
    });

    return batFirstTeam[0];
  };

  const ballFirstTeam = (batFirst: any, teams: any) => {
    var ballFirstTeam = teams.filter(function (t: any) {
      return t.id !== batFirst;
    });

    return ballFirstTeam[0];
  };

  const batSecondTeam = (batFirst: any, teams: any) => {
    var batSecondTeam = teams.filter(function (t: any) {
      return t.id !== batFirst;
    });

    console.log(batSecondTeam[0]);

    return batSecondTeam[0];
  };

  const getBatterFacedBalls = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var playedBalls = 0;

    bowls.filter(function (b: any) {
      if ((b.status === "RUNS" || b.status === "WICKET") && b.batsmanId === playerId) {
        playedBalls = playedBalls + 1;
      }
    });

    return playedBalls;
  };

  const getBatter4Boundaries = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var player4Runs = 0;
      bowls.filter(function (b: any) {
        if (b.status === "RUNS" && b.batsmanId === playerId && b.runs == "4") {
          player4Runs = player4Runs + b.runs;
        }
      });
  
      return player4Runs / 4;
    };

  const getBatter6Boundaries = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var player6Runs = 0;

    bowls.filter(function (b: any) {
      if (b.status === "RUNS" && b.batsmanId === playerId && b.runs == "6") {
        player6Runs = player6Runs + b.runs;
      }
    });

    return player6Runs / 6;
  };
  const getBatterCurrentStatusBallFirstTeam = (playerId: any, bowls: any) => {
    var status = "yet to bat";
    currentBatsmanPair?.filter(function (b: any) {
      if (b === playerId) {
        status = "not out";
      }
    });
    console.log("bowls==>",bowls)
    bowls.filter(function (b: any) {
      if (b.status === "WICKET" && b.batsmanId === playerId) {
        ballFirstTeam(
          match?.batFirst,
          match?.teams
        ).players.filter(function (p:any) {
          if (p._id === b.bowlerId) {
            status = `wicket (by ${p.name})`
          }
        })       
      }
    });
    

    return status;
  };
  const getBatterCurrentStatusBallSecondTeam = (playerId: any, bowls: any) => {
    var status = "yet to bat";
    currentBatsmanPairSecond?.filter(function (b: any) {
      if (b === playerId) {
        status = "not out";
      }
    });
    console.log("bowls==>",bowls)
    bowls.filter(function (b: any) {
      if (b.status === "WICKET" && b.batsmanId === playerId) {
        ballSecondTeam(
          match?.batFirst,
          match?.teams
        ).players.filter(function (p:any) {
          if (p._id === b.bowlerId) {
            status = `wicket (by ${p.name})`
          }
        })       
      }
    });
    

    return status;
  };
  const getBatterRuns = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var playerRuns = 0;

    bowls.filter(function (b: any) {
      if (b.status === "RUNS" && b.batsmanId === playerId) {
        playerRuns = playerRuns + b.runs;
      }
    });

    return playerRuns;
  };
  const getBatFirstTotalRuns = (bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalRuns = 0;
    var totalWickets = 0;

    bowls.filter(function (b: any) {
      if (b.status !== "WICKET") {
        totalRuns = totalRuns + b.runs;
      }

      if (b.status === "WICKET") {
        totalWickets = totalWickets + 1;
      }
    });

    return totalRuns + "/" + totalWickets;
  };
  const getTotalWides = (bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalWides = 0;

    bowls.filter(function (b: any) {
      if (b.status === "WIDE") {
        totalWides = totalWides + b.runs;
      }
    });

    return totalWides;
  };
  const getTotalNoBalls = (bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalNoBalls = 0;

    bowls.filter(function (b: any) {
      if (b.status === "NO_BALL") {
        totalNoBalls = totalNoBalls + b.runs;
      }
    });

    return totalNoBalls;
  };
  const getTotalLegByes = (bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalLegByes = 0;

    bowls.filter(function (b: any) {
      if (b.status === "LEG_BYE") {
        totalLegByes = totalLegByes + b.runs;
      }
    });

    return totalLegByes;
  };
  const ballSecondTeam = (batFirst: any, teams: any) => {
    var ballSecondTeam = teams?.filter(function (t: any) {
      return t.id === batFirst;
    });

    return ballSecondTeam ? ballSecondTeam[0] : null;
  };
  const getBowlerRuns = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var bowlerRuns = 0;

    bowls.filter(function (b: any) {
      if (b.status !== "WICKET" && b.bowlerId === playerId) {
        bowlerRuns = bowlerRuns + b.runs;
      }
    });

    return bowlerRuns;
  };
  const getBowlerWickets = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var bowlerWickets = 0;

    bowls.filter(function (b: any) {
      if (b.status === "WICKET" && b.bowlerId === playerId) {
        bowlerWickets = bowlerWickets + 1;
      }
    });

    return bowlerWickets;
  };
  const getBowlerWides = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var bowlerWickets = 0;

    bowls.filter(function (b: any) {
      if (b.status === "WIDE" && b.bowlerId === playerId) {
        bowlerWickets = bowlerWickets + 1;
      }
    });

    return bowlerWickets;
  };
  const getBowlerNoBalls = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var bowlerWickets = 0;

    bowls.filter(function (b: any) {
      if (b.status === "NO_BALL" && b.bowlerId === playerId) {
        bowlerWickets = bowlerWickets + 1;
      }
    });

    return bowlerWickets;
  };
  const getBowlerDiliveredOvers = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var ballsCount = 0;

    bowls.filter(function (b: any) {
      if (b.status !== "WIDE" && b.status !== "NO_BALL" && b.bowlerId === playerId) {
        ballsCount = ballsCount + 1;
      }
    });
    var remainBalls = ballsCount % 6;
    var overs = (ballsCount-remainBalls)/6;
    return overs;
  };
  const getBowlerDiliveredRemainBalls = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var ballsCount = 0;

    bowls.filter(function (b: any) {
      if (b.status !== "WIDE" && b.status !== "NO_BALL" && b.bowlerId === playerId) {
        ballsCount = ballsCount + 1;
      }
    });
    var remainBalls = ballsCount % 6;
    return remainBalls;
  };
  const getPlayer = (playerId: any, teamPlayers: any) => {
    if (!playerId) {
      return "";
    }
    var player = teamPlayers.filter(function (p: any) {
      return p._id === playerId;
    });

    return player[0];
  };
  const previosPage = () => {
    if(user?.userType === Role.SUPER_ADMIN){
      if(tournamentId){
        history.push(`/admin/user-management-tournament/${tournamentId}`)
      }
      else{
        history.goBack();
      }
    }
    else{
      if (tournamentId) {
        history.push(`/admin/matches/${tournamentId}`)
      } else {
        history.goBack();
      }
    }
  }
  const fetchMatch =  () => {
    MatchService.getMatchDetails(match_id)
      .then((res) => {
        if (
          res.data.status === "BAT_FIRST_WIN" ||
          res.data.status === "BALL_FIRST_WIN"
        ) {
          setPageNumber(3);
        }
        
        const tournamentTitle = res.data.tournamentId?.name;
        const tournamentId= res.data.tournamentId?._id;
        setTournamentTitle(tournamentTitle);
        setTournamentId( tournamentId)

    })
    .catch((err: any) => {
        console.log("err", err);
      });
  };

  return (
    <>
     <div className="d-flex justify-content-between align-items-center dashboard-header">
        <span>
        <i className="fa fa-arrow-left f-color-gray-7 pl-3 cursor-p" aria-hidden="true" onClick={() => previosPage()}></i>
        <span className="dashboard-title-txt pl-3">{tournamentTitle}</span>
        </span>
        <span className="cursor-pointer" onClick={previosPage}><img src={Logo} alt="cricView360_logo"/></span>
        
        <span className="pr-3 f-20 fw-500 font-inter"></span>
      </div>
      <ScoreCard setPageNumber={setPageNumber} />

      {pageNumber === 1 && (
        <>
             {
              isLoading ? null :(
                <BallScore
                toggleNextScoreboard={toggleNextScoreboard}
                fetchTournament={fetchTournament}
                 fetchUser={fetchUser}
                battingFirstTeam={battingFirstTeam}
                ballingFirstTeam={ballingFirstTeam}
                match={match}
                battingFirst={true}
              />
              )
             }
          <div className="container">
            <div className="row">
              <div className="col-md-4">
              <div className="container-fluid bg-white pt-3 pb-3 border-r-10 mt-3">
              <div className="d-flex justify-content-center align-items-center dashboard-table-head ">
                <img src={cricketBall} alt="" className="mr-2" />{" "}
                <span className="dashboard-table-title-txt">
                  Balling Team : {ballingFirstTeam}
                </span>
              </div>
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead className="table-head">
                    <tr>
                      <th scope="col">Current Bowler</th>
                      <th scope="col">Runs/Wickets</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      
                      <td>
                        {match?.batFirstScoreboard &&
                          getPlayer(
                            match?.batFirstScoreboard.currentlyBowling,
                            ballFirstTeam(match?.batFirst, match?.teams).players
                          )?.name}
                      </td>
                      <td>
                        {" "}
                        {match?.batFirstScoreboard &&
                          getBowlerRuns(
                            match?.batFirstScoreboard.currentlyBowling,
                            match?.batFirstScoreboard.playerBowls
                          )}{" "}
                        /
                        {match?.batFirstScoreboard &&
                          getBowlerWickets(
                            match?.batFirstScoreboard.currentlyBowling,
                            match?.batFirstScoreboard.playerBowls
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center align-items-center dashboard-table-head ">
                <img src={cricketBat} alt="" className="mr-2" />{" "}
                <span className="dashboard-table-title-txt">
                  Batting Team : {battingFirstTeam}
                </span>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th scope="col">Currently Playing</th>
                      <th scope="col">Runs</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    
                      <td>
                        {match?.batFirstScoreboard &&
                          getPlayer(
                            match?.batFirstScoreboard.currentlyBatting[0],
                            batFirstTeam(match?.batFirst, match?.teams).players
                          )?.name}
                      </td>
                      <td>
                        {match?.batFirstScoreboard &&
                          getBatterRuns(
                            match?.batFirstScoreboard.currentlyBatting[0],
                            match?.batFirstScoreboard.playerBowls
                          )}
                      </td>
                    </tr>
                    <tr>
                     
                      <td>
                        {match?.batFirstScoreboard &&
                          getPlayer(
                            match?.batFirstScoreboard.currentlyBatting[1],
                            batFirstTeam(match?.batFirst, match?.teams).players
                          )?.name}
                      </td>
                      <td>
                        {match?.batFirstScoreboard &&
                          getBatterRuns(
                            match?.batFirstScoreboard.currentlyBatting[1],
                            match?.batFirstScoreboard.playerBowls
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
            </div>
              </div>
              <div className="col-md-4">
              <div className="bg-white mt-3">
                  <div className="table-responsive">
                    <table className="table ">
                      <thead className="tbody-2">
                        <tr className="bg-blue5">
                          <td colSpan={6}>
                            <span className=" text-black line-h-24 f-20 ">
                              Scoreboard
                            </span>
                          </td>
                        </tr>
                        <tr className="bg-gray7">
                          <td>Batting</td>
                          <td></td>
                          <td>R</td>
                          <td>B</td>
                          <td>4ss</td>
                          <td>6s</td>
                        </tr>
                      </thead>
                      <tbody className="tbody-2">
                        {match?.batFirstScoreboard &&
                          batFirstTeam(match.batFirst, match.teams).players.map(
                            (player: any, index: any) => (
                              <tr key={index}>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray">
                                    {player.name}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatterCurrentStatusBallFirstTeam(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1">

                                    {getBatterRuns(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td>
                                {getBatterFacedBalls(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatter4Boundaries(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatter6Boundaries(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        <tr className="bg-gray7">
                          <td >
                            <span className=" fw-500 f-15 f-color-black font-inter">
                              Total
                            </span>
                          </td>
                          <td colSpan={2}>
                            <span className=" fw-500 f-15 f-color-black font-inter">

                            </span>
                          </td>
                          <td colSpan={3}>
                            <span className="fw-500 f-15 f-color-black font-inter">
                              {match?.batFirstScoreboard &&
                                getBatFirstTotalRuns(
                                  match?.batFirstScoreboard?.playerBowls
                                )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6}>
                            <div className=" d-flex justify-content-center align-items-center  ">
                              <div>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  No Ball:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batFirstScoreboard &&
                                    getTotalNoBalls(
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </div>
                              <div>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  Wide:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batFirstScoreboard &&
                                    getTotalWides(
                                      match?.batFirstScoreboard?.playerBowls
                                    )}
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  Leg Bye:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batFirstScoreboard &&
                                    getTotalLegByes(
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
              <div className="bg-white mt-3">
                  <div className="table-responsive">
                    <table className="table ">
                      <thead className="tbody-2">
                        <tr className="bg-blue5">
                          <td colSpan={8}>
                            <span className=" text-black line-h-24 f-20 mt-12 mb-12">
                              Balling Stat
                            </span>
                          </td>
                        </tr>
                        <tr className="bg-gray7">
                          <td>Balling</td>
                          <td></td>
                          <td>B</td>
                          <td>O</td>
                          <td>R</td>
                          <td>W</td>
                          <td>WD</td>
                          <td>NB</td>
                        </tr>
                      </thead>
                      <tbody className="tbody-2">
                        {match?.batFirstScoreboard &&
                          ballFirstTeam(
                            match?.batFirst,
                            match?.teams
                          )?.players?.map((player: any, index: any) => (
                            <tr className="" key={index}>
                              <td>
                                {player.name}
                              </td>
                              <td className="min-w-30"></td>
                              <td><span className="fw-400 f-15 f-color-gray">
                                  {match?.batFirstScoreboard &&
                                    getBowlerDiliveredRemainBalls(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span></td>
                              <td>
                              <span className="fw-400 f-15 f-color-gray">
                                  {match?.batFirstScoreboard &&
                                    getBowlerDiliveredOvers(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                                <span className="fw-400 f-15 f-color-gray">
                                  {match?.batFirstScoreboard &&
                                    getBowlerRuns(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                                <span className="fw-400 f-15 f-color-gray">
                                  {match?.batFirstScoreboard &&
                                    getBowlerWickets(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                              {match?.batFirstScoreboard &&
                                    getBowlerWides(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                              </td>
                              <td>
                              {match?.batFirstScoreboard &&
                                    getBowlerNoBalls(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {pageNumber === 2 && (
        <>
           {isLoading ? null :(
             <BallScore
             toggleNextScoreboard={toggleNextScoreboard}
             fetchTournament={fetchTournament}
             fetchUser={fetchUser}
             battingFirstTeam={battingFirstTeam}
             ballingFirstTeam={ballingFirstTeam}
             match={match}
           />
           )}
          <div className="container">
            <div className="row">
              <div className="col-md-4">
              <div className="container-fluid bg-white pt-3 pb-3 border-r-10 mt-3">
              <div className="d-flex justify-content-center align-items-center dashboard-table-head ">
                <img src={cricketBall} alt="" className="mr-2" />{" "}
                <span className="dashboard-table-title-txt">
                  Balling Team : {battingFirstTeam}
                </span>
              </div>
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead className="table-head">
                    <tr>
                    
                      <th scope="col">Current Bowler</th>
                      <th scope="col">Runs/Wickets</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      
                      <td>
                      {match?.batSecondScoreboard &&
                          getPlayer(
                            match?.batSecondScoreboard.currentlyBowling,
                            ballSecondTeam(match?.batFirst, match?.teams)
                              .players
                          )?.name}
                      </td>
                      <td>
                      {match?.batSecondScoreboard &&
                          getBowlerRuns(
                            match?.batSecondScoreboard.currentlyBowling,
                            match?.batSecondScoreboard.playerBowls
                          )}{" "}
                        /
                        {match?.batSecondScoreboard &&
                          getBowlerWickets(
                            match?.batSecondScoreboard.currentlyBowling,
                            match?.batSecondScoreboard.playerBowls
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center align-items-center dashboard-table-head ">
                <img src={cricketBat} alt="" className="mr-2" />{" "}
                <span className="dashboard-table-title-txt">
                  Batting Team : {ballingFirstTeam}
                </span>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                  
                      <th scope="col">Currently Playing</th>
                      <th scope="col">Runs</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    
                      <td>
                      {match?.batSecondScoreboard &&
                          getPlayer(
                            match?.batSecondScoreboard.currentlyBatting[0],
                            batSecondTeam(match?.batFirst, match?.teams).players
                          )?.name}
                      </td>
                      <td>
                       {match?.batSecondScoreboard &&
                          getBatterRuns(
                            match?.batSecondScoreboard.currentlyBatting[0],
                            match?.batSecondScoreboard.playerBowls
                          )}
                      </td>
                    </tr>
                    <tr>
                     
                      <td>
                      {match?.batSecondScoreboard &&
                          getPlayer(
                            match?.batSecondScoreboard.currentlyBatting[1],
                            batSecondTeam(match?.batFirst, match?.teams).players
                          )?.name}
                      </td>
                      <td>
                      {match?.batSecondScoreboard &&
                          getBatterRuns(
                            match?.batSecondScoreboard.currentlyBatting[1],
                            match?.batSecondScoreboard.playerBowls
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
            </div>
              </div>
              <div className="col-md-4">
              <div className="bg-white mt-3">
                  <div className="table-responsive">
                    <table className="table ">
                      <thead className="tbody-2">
                        <tr className="bg-blue5">
                          <td colSpan={6}>
                            <span className=" text-black line-h-24 f-20 ">
                              Scoreboard
                            </span>
                          </td>
                        </tr>
                        <tr className="bg-gray7">
                          <td>Batting</td>
                          <td></td>
                          <td>R</td>
                          <td>B</td>
                          <td>4s</td>
                          <td>6s</td>
                        </tr>
                      </thead>
                      <tbody className="tbody-2">
                        {match?.batSecondScoreboard &&
                          batSecondTeam(match.batFirst, match.teams).players.map(
                            (player: any, index: any) => (
                              <tr key={index}>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray">
                                    {player.name}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatterCurrentStatusBallSecondTeam(
                                      player._id,
                                      match.batSecondScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1">

                                    {getBatterRuns(
                                      player._id,
                                      match.batSecondScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td>
                                {getBatterFacedBalls(
                                      player._id,
                                      match.batSecondScoreboard.playerBowls
                                    )}
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatter4Boundaries(
                                      player._id,
                                      match.batSecondScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatter6Boundaries(
                                      player._id,
                                      match.batSecondScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        <tr className="bg-gray7">
                          <td >
                            <span className=" fw-500 f-15 f-color-black font-inter">
                              Total
                            </span>
                          </td>
                          <td colSpan={2}>
                            <span className=" fw-500 f-15 f-color-black font-inter">

                            </span>
                          </td>
                          <td colSpan={3}>
                            <span className="fw-500 f-15 f-color-black font-inter">
                              {match?.batSecondScoreboard &&
                                getBatFirstTotalRuns(
                                  match?.batSecondScoreboard?.playerBowls
                                )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6}>
                            <div className=" d-flex justify-content-center align-items-center  ">
                              <div>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  No Ball:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batSecondScoreboard &&
                                    getTotalNoBalls(
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                                </span>
                              </div>
                              <div>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  Wide:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batSecondScoreboard &&
                                    getTotalWides(
                                      match?.batSecondScoreboard?.playerBowls
                                    )}
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  Leg Bye:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batSecondScoreboard &&
                                    getTotalLegByes(
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
              <div className="bg-white mt-3">
                  <div className="table-responsive">
                    <table className="table ">
                      <thead className="tbody-2">
                        <tr className="bg-blue5">
                          <td colSpan={8}>
                            <span className=" text-black line-h-24 f-20 mt-12 mb-12">
                              Balling Stat
                            </span>
                          </td>
                        </tr>
                        <tr className="bg-gray7">
                          <td>Balling</td>
                          <td></td>
                          <td>B</td>
                          <td>O</td>
                          <td>R</td>
                          <td>W</td>
                          <td>WD</td>
                          <td>NB</td>
                        </tr>
                      </thead>
                      <tbody className="tbody-2">
                        {match?.batSecondScoreboard &&
                          ballSecondTeam(
                            match?.batFirst,
                            match?.teams
                          )?.players?.map((player: any, index: any) => (
                            <tr className="" key={index}>
                              <td>
                                {player.name}
                              </td>
                              <td className="min-w-30"></td>
                              <td><span className="fw-400 f-15 f-color-gray">
                                  {match?.batSecondScoreboard &&
                                    getBowlerDiliveredRemainBalls(
                                      player._id,
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                                </span></td>
                              <td>
                              <span className="fw-400 f-15 f-color-gray">
                                  {match?.batSecondScoreboard &&
                                    getBowlerDiliveredOvers(
                                      player._id,
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                                <span className="fw-400 f-15 f-color-gray">
                                  {match?.batSecondScoreboard &&
                                    getBowlerRuns(
                                      player._id,
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                                <span className="fw-400 f-15 f-color-gray">
                                  {match?.batSecondScoreboard &&
                                    getBowlerWickets(
                                      player._id,
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                              {match?.batSecondScoreboard &&
                                    getBowlerWides(
                                      player._id,
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                              </td>
                              <td>
                              {match?.batSecondScoreboard &&
                                    getBowlerNoBalls(
                                      player._id,
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {pageNumber === 3 && (
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="container-fluid mt-3 pl-pr-0">
                <div className="d-flex flex-column w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                  <div className="container mt-3">
                    <div className="bg-white">
                  <div className="table-responsive">
                    <table className="table ">
                      <thead className="tbody-2">
                        <tr className="bg-blue5">
                          <td colSpan={6}>
                            <span className=" text-black line-h-24 f-20 font-inter">
                            Batting first team scoreboard : {battingFirstTeam}
                            </span>
                          </td>
                        </tr>
                        <tr className="bg-gray7">
                          <td  className="font-inter">Batting</td>
                          <td></td>
                          <td  className="font-inter">R</td>
                          <td  className="font-inter">B</td>
                          <td  className="font-inter">4s</td>
                          <td  className="font-inter">6s</td>
                        </tr>
                      </thead>
                      <tbody className="tbody-2">
                        {match?.batFirstScoreboard &&
                          batFirstTeam(match.batFirst, match.teams).players.map(
                            (player: any, index: any) => (
                              <tr key={index}>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray font-inter">
                                    {player.name}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1 font-inter">
                                    {getBatterCurrentStatusBallFirstTeam(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1 font-inter">

                                    {getBatterRuns(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td className="font-inter">
                                {getBatterFacedBalls(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                </td>
                                <td  className="font-inter">
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatter4Boundaries(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td className="font-inter">
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatter6Boundaries(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        <tr className="bg-gray7">
                          <td >
                            <span className=" fw-500 f-15 f-color-black font-inter">
                              Total
                            </span>
                          </td>
                          <td colSpan={2}>
                            <span className=" fw-500 f-15 f-color-black font-inter">

                            </span>
                          </td>
                          <td colSpan={3}>
                            <span className="fw-500 f-15 f-color-black font-inter">
                              {match?.batFirstScoreboard &&
                                getBatFirstTotalRuns(
                                  match?.batFirstScoreboard?.playerBowls
                                )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6}>
                            <div className=" d-flex justify-content-center align-items-center  ">
                              <div>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  No Ball:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batFirstScoreboard &&
                                    getTotalNoBalls(
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </div>
                              <div>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  Wide:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batFirstScoreboard &&
                                    getTotalWides(
                                      match?.batFirstScoreboard?.playerBowls
                                    )}
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  Leg Bye:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batFirstScoreboard &&
                                    getTotalLegByes(
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                  </div>
                  <div className="container mt-3">
                  <div className="bg-white">
                  <div className="table-responsive">
                    <table className="table ">
                      <thead className="tbody-2">
                        <tr className="bg-blue5">
                          <td colSpan={8}>
                            <span className=" text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                              Balling first team balling stat : {ballingFirstTeam}
                            </span>
                          </td>
                        </tr>
                        <tr className="bg-gray7">
                          <td className="font-inter">Balling</td>
                          <td></td>
                          <td className="font-inter">B</td>
                          <td className="font-inter">O</td>
                          <td className="font-inter">R</td>
                          <td className="font-inter">W</td>
                          <td className="font-inter">WD</td>
                          <td className="font-inter">NB</td>
                        </tr>
                      </thead>
                      
                      <tbody className="tbody-2 font-inter">
                        {match?.batFirstScoreboard &&
                          ballFirstTeam(
                            match?.batFirst,
                            match?.teams
                          )?.players?.map((player: any, index: any) => (
                            <tr className="" key={index}>
                              <td  className="font-inter">
                                {player.name}
                              </td>
                              <td className="min-w-30"></td>
                              <td><span className="fw-400 f-15 f-color-gray font-inter">
                                  {match?.batFirstScoreboard &&
                                    getBowlerDiliveredRemainBalls(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span></td>
                              <td>
                              <span className="fw-400 f-15 f-color-gray font-inter">
                                  {match?.batFirstScoreboard &&
                                    getBowlerDiliveredOvers(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                                <span className="fw-400 f-15 f-color-gray font-inter">
                                  {match?.batFirstScoreboard &&
                                    getBowlerRuns(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                                <span className="fw-400 f-15 f-color-gray font-inter">
                                  {match?.batFirstScoreboard &&
                                    getBowlerWickets(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td className="font-inter">
                              {match?.batFirstScoreboard &&
                                    getBowlerWides(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                              </td>
                              <td  className="font-inter">
                              {match?.batFirstScoreboard &&
                                    getBowlerNoBalls(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="container-fluid mt-3 pl-pr-0">
                <div className="d-flex flex-column w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                  <div className="container mt-3">
                  <div className="bg-white">
                  <div className="table-responsive">
                    <table className="table ">
                      <thead className="tbody-2">
                        <tr className="bg-blue5">
                          <td colSpan={6}>
                            <span className=" text-black line-h-24 f-20 font-inter">
                              Batting second team scoreboard : {ballingFirstTeam}
                            </span>
                          </td>
                        </tr>
                        <tr className="bg-gray7">
                          <td  className="font-inter">Batting</td>
                          <td  className="font-inter"></td>
                          <td  className="font-inter">R</td>
                          <td  className="font-inter">B</td>
                          <td  className="font-inter">4s</td>
                          <td  className="font-inter">6s</td>
                        </tr>
                      </thead>
                      <tbody className="tbody-2">
                        {match?.batSecondScoreboard &&
                          batSecondTeam(match.batFirst, match.teams).players.map(
                            (player: any, index: any) => (
                              <tr key={index}>
                                <td> 
                                  <span className="fw-400 f-15 f-color-gray font-inter">
                                    {player.name}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1 font-inter">
                                    {getBatterCurrentStatusBallSecondTeam(
                                      player._id,
                                      match.batSecondScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1 font-inter">

                                    {getBatterRuns(
                                      player._id,
                                      match.batSecondScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td className="font-inter">
                                {getBatterFacedBalls(
                                      player._id,
                                      match.batSecondScoreboard.playerBowls
                                    )}
                                </td>
                                {/* <td>M</td> */}
                                <td  className="font-inter">
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatter4Boundaries(
                                      player._id,
                                      match.batSecondScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td  className="font-inter">
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatter6Boundaries(
                                      player._id,
                                      match.batSecondScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        <tr className="bg-gray7">
                          <td >
                            <span className=" fw-500 f-15 f-color-black font-inter">
                              Total
                            </span>
                          </td>
                          <td colSpan={2}>
                            <span className=" fw-500 f-15 f-color-black font-inter">

                            </span>
                          </td>
                          <td colSpan={3}>
                            <span className="fw-500 f-15 f-color-black font-inter">
                              {match?.batSecondScoreboard &&
                                getBatFirstTotalRuns(
                                  match?.batSecondScoreboard?.playerBowls
                                )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6}>
                            <div className=" d-flex justify-content-center align-items-center  ">
                              <div>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  No Ball:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batSecondScoreboard &&
                                    getTotalNoBalls(
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                                </span>
                              </div>
                              <div>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  Wide:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batSecondScoreboard &&
                                    getTotalWides(
                                      match?.batSecondScoreboard?.playerBowls
                                    )}
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  Leg Bye:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batSecondScoreboard &&
                                    getTotalLegByes(
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                  </div>
                  <div className="container mt-3">
                  <div className="bg-white">
                  <div className="table-responsive">
                    <table className="table font-inter">
                      <thead className="tbody-2 font-inter">
                        <tr className="bg-blue5">
                          <td colSpan={8}>
                            <span className=" text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                              Balling second team balling stat : {battingFirstTeam}
                            </span>
                          </td>
                        </tr>
                        <tr className="bg-gray7 font-inter">
                          <td className="font-inter">Balling</td>
                          <td></td>
                          <td className="font-inter">B</td>
                          <td className="font-inter">O</td>
                          <td className="font-inter">R</td>
                          <td className="font-inter">W</td>
                          <td className="font-inter">WD</td>
                          <td className="font-inter">NB</td>
                        </tr>
                      </thead>
                      <tbody className="tbody-2">
                        {match?.batSecondScoreboard &&
                          ballSecondTeam(
                            match?.batFirst,
                            match?.teams
                          )?.players?.map((player: any, index: any) => (
                            <tr className="" key={index}>
                              <td className="font-inter">
                                {player.name}
                              </td>
                              <td className="min-w-30"></td>
                              <td><span className="fw-400 f-15 f-color-gray font-inter">
                                  {match?.batSecondScoreboard &&
                                    getBowlerDiliveredRemainBalls(
                                      player._id,
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                                </span></td>
                              <td>
                              <span className="fw-400 f-15 f-color-gray font-inter">
                                  {match?.batSecondScoreboard &&
                                    getBowlerDiliveredOvers(
                                      player._id,
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                                <span className="fw-400 f-15 f-color-gray font-inter">
                                  {match?.batSecondScoreboard &&
                                    getBowlerRuns(
                                      player._id,
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                                <span className="fw-400 f-15 f-color-gray font-inter">
                                  {match?.batSecondScoreboard &&
                                    getBowlerWickets(
                                      player._id,
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td className="font-inter">
                              {match?.batSecondScoreboard &&
                                    getBowlerWides(
                                      player._id,
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                              </td>
                              <td  className="font-inter">
                              {match?.batSecondScoreboard &&
                                    getBowlerNoBalls(
                                      player._id,
                                      match?.batSecondScoreboard.playerBowls
                                    )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LiveScoreData;