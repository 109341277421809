import React, { useEffect, useState, useContext } from "react";
import "../../vendors/styles/cricketAppStyles.css";
import RightArrow from "../../vendors/images/icon/right-arrow.png";
import Edit from "../../vendors/images/icon/edit.png";
import Close from "../../vendors/images/icon/x.png";
import Remove from "../../vendors/images/icon/remove.png";
import PlusIcon from "../../vendors/images/icon/plus.png";
import { AdminService } from "../../../services/AdminService";
import { TeamData, TeamMemberData } from "../../../models/Team";
import CustomModal from "../../common/Modal";
import swal from "sweetalert";
import UserContext from "../../../context/UserContext";
import { useHistory } from "react-router-dom";



const PlanQuickMatch: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const history = useHistory();
  const [user] = useContext(UserContext);



  const previosPage = () => {
    history.goBack();
  };



return (
    <>
    {/* <div className="container  ">
      <div className="row mb-10">
        <div className="col-md-12 h-auto">
          <div className="container-fluid mt-3 pl-pr-0 h-100  main-container">
            <div className="d-flex w-100 h-100 justify-content-center align-items-center outer-box bg-white border-w border-r-10 rounded">
              <div className="row w-100 d-flex align-items-center">
                <div className="col-md-12 d-flex justify-content-between align-items-center">
                  <div>
                    <h4>Welcome to CricView360</h4>
                  </div>
                  <div>
                    <img
                      className="close-icon-size mr-1  db"
                    //   onClick={() => closePageClicked()}
                      src={Close}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container mt-20 ml-20">
      <div className="row">
        <div className="col-md-12 ">
          <div className="container-fluid mt-3  main-container">
            <div className="d-flex w-100  justify-content-center align-items-center outer-box bg-white border-w border-r-10 rounded h-auto">
              <div className="row w-100">
              <div>
                <i
                    className="fa fa-arrow-left f-color-gray-7  cursor-p"
                    aria-hidden="true"
                     onClick={() => previosPage()}
                  ></i>
                </div>
                <div className="col-md-12 d-flex justify-content-left align-items-center">
                  <div className="col-md-12 mbb-20 mb-5">
                    <div className="container  border-top-10">

          


                      <div className="row pt-2 pl-3 pr-3 d-flex justify-content-center align-items-center">
                        <span className="f-color-black3 fw-600 f-25 mt-3 mb-3 font-Poppins">
                          Plan a Quick Match
                        </span>
                      </div>
                      <div className="row ml-4 mr-4 border-r-9 pr-4 mb-1 min-h-35 mt-3">
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td className="justify-content-center align-item-center">
                                  <select className="select-dropdown2 fw-400 f-15 f-color-black3">
                                    <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                      Select Match Type
                                    </option>
                                    <option
                                      className="fw-400 f-15 f-color-black3 font-Poppins"
                                      value="REGULAR"
                                    >
                                      Regular
                                    </option>
                                    <option
                                      className="fw-400 f-15 f-color-black3 font-Poppins"
                                      value="QUALIFIER_ONE"
                                    >
                                      Qualifier 1
                                    </option>
                                    <option
                                      className="fw-400 f-15 f-color-black3 font-Poppins"
                                      value="QUALIFIER_TWO"
                                    >
                                      Qualifier 2
                                    </option>
                                    <option
                                      className="fw-400 f-15 f-color-black3 font-Poppins"
                                      value="ELIMINATER"
                                    >
                                      Eliminator
                                    </option>
                                    <option
                                      className="fw-400 f-15 f-color-black3 font-Poppins"
                                      value="SEMI_FINAL"
                                    >
                                      Semi-Final
                                    </option>
                                    <option
                                      className="fw-400 f-15 f-color-black3 font-Poppins"
                                      value="FINAL"
                                    >
                                      Final
                                    </option>
                                  </select>
                                </td>
                                <td className="justify-content-center align-item-center">
                                  <select className="select-dropdown fw-400 f-15 f-color-black3">
                                    <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                      Select Team
                                    </option>

                                    <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                      gfhg
                                    </option>
                                  </select>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-black3 font-Poppins">
                                    VS
                                  </span>
                                </td>
                                <td>
                                  <div className="">
                                    <select className="select-dropdown fw-400 f-15 f-color-black3 font-Poppins">
                                      <option className="fw-400 f-15 f-color-black3 font-Poppins">
                                        Select Team
                                      </option>
                                      <option value="">fgf</option>
                                    </select>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row pt-2 pl-3 pr-3 d-flex justify-content-end align-items-end">
                        <div className="arrow-btn2">
                          <span className="f-17 f-color-white fw-500 font-Poppins">
                            Save
                          </span>
                          <img
                            className="arrow-icon-size ml-3"
                            src={RightArrow}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )}

  export default PlanQuickMatch;