import React, { useEffect, useState, useContext } from "react";
import "../../vendors/styles/cricketAppStyles.css";
import RightArrow from "../../vendors/images/icon/right-arrow.png";
import Edit from "../../vendors/images/icon/edit.png";
import Stats from "../../vendors/images/icon/stat2.png";
import Close from "../vendors/images/icon/x.png";
import Remove from "../vendors/images/icon/remove.png";
import PlusIcon from "../vendors/images/icon/plus.png";
import PlayIcon from "../../vendors/images/icon/fi_play.png";
import { AdminService } from "../../../services/AdminService";
import { TeamData, TeamMemberData } from "../../../models/Team";
import CustomModal from "../../common/Modal";
import swal from "sweetalert";

import { NavLink, useParams, useHistory } from "react-router-dom";
import UserContext from "../../../context/UserContext";
import TournamentContext from "../../../context/TournamentContext";

const MyMatches: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [teamList, setTeamList] = useState<any>([]);
  const [teamListMain, setTeamListMain] = useState<any>([]);
  const [teamListExtra, setTeamListExtra] = useState<any>([]);
  const [matchList, setMatchList] = useState<any>([]);
  const [newTeamName, setNewTeamName] = useState<string>();
  const [newTeamMemberName, setNewTeamMemberName] = useState<string>();
  const [newTeamMemberList, setNewTeamMemberList] = useState<any[]>([]);
  const [updateTeamMember, setUpdateTeamMember] = useState<any>();
  const [tournamentData, setTournamentData] = useState<any>();
  const [totalMatchCount, setTotalMatchCount] = useState<any>();
  const [selectTeam1ArrList, setSelectTeam1ArrList] = useState<any[]>([]);
  const [selectTeam2ArrList, setSelectTeam2ArrList] = useState<any[]>([]);
  const [update, setUpdate] = useState<boolean>(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateMatchIndex, setUpdateMatchIndex] = useState<number>(1);
  // const [updateMatchId, setUpdateMatchId] = useState<string>();
  const [extraMatchTeam1, setExtraMatchTeam1] = useState<string>();
  const [extraMatchTeam2, setExtraMatchTeam2] = useState<string>();
  const [extraMatchType, setExtraMatchType] = useState<string>();
  const [updateMatchTeam1, setUpdateMatchTeam1] = useState<string>();
  const [updateMatchTeam2, setUpdateMatchTeam2] = useState<string>();
  const [updateTeamId, setUpdateTeamId] = useState<string>("");
  const [isUpdateTeam, setIsUpdateTeam] = useState<boolean>(false);
  const splittedURL = window.location.pathname.split("/");
  const [reload, setReload] = useState<boolean>(false);
  const history = useHistory();
  const [matchesData, setMatchesData] = useState<any[]>();
  const [user] = useContext(UserContext);
  const { tournamentId }: any = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectTeams, setSelectTeams] = useState<any>([]);
  const [tosWinTeam, setTosWinTeam] = useState();
  const [batFirstTeam, setBatFirstTeam] = useState();
  const [updateMatchId, setUpdateMatchId] = useState();
  const urlOfThisPage = window.location.href;
  const splitFromSlash = urlOfThisPage.split("/");
  const getTMatchesFromSplittedUrl = splitFromSlash[splitFromSlash.length - 2];
  const [tournament] = useContext(TournamentContext);
  const tournamentname = tournament?.name;
  const { userId }: any = useParams();
  //  const [user] = useContext(UserContext);

  const createQuickMatchClicked = () => {
    console.log("createQuickMatchClicked");
  };

  useEffect(() => {
    if (user?._id) {
      AdminService.getMatchesByUserId(user?._id).then((res) => {
        if (res.success) {
          setMatchesData(res.data);
          console.log(res.data);
          console.log("inside get Matches by user id");
        } else {
          console.log("error", res.error);
        }
      });
    } else {
      console.log("No user id +not found");
    }

    //     const matchId=matchesData._Id;

    // AdminService.getAllTeamsByMatchId(matchesData.id).then((res) => {
    //   if (matchId) {
    //   if (res.success) {
    //     setMatchesData(res.data);
    //   } else {
    //     console.log("error", res.error);
    //   }
    // });
    // } else {
    // console.log("No user matchId not found");

    // }
  }, []);

  const getResultStatus = (
    batFirst: any,
    teams: any,
    status: any,
    matchId: any
  ) => {
    if (status === "NOT_STARTED") {
      return "Not Started Yet!";
    } else if (status === "ONGOING") {
      return <span className="matches-team-win">Ongoing</span>;
    } else if (status === "MATCH_TIED") {
      return "Match Tied!";
    } else if (status === "BAT_FIRST_WIN") {
      var batFirstTeam = teams?.filter(function (t: any) {
        return t?.id === batFirst;
      });

      return (
        <span className="matches-team-win">
          {batFirstTeam[0]?.name + " wins!"}
        </span>
      );
    } else if (status === "BALL_FIRST_WIN") {
      var ballFirstTeam = teams?.filter(function (t: any) {
        return t?.id !== batFirst;
      });

      return (
        <span className="matches-team-win">
          {ballFirstTeam[0]?.name + " wins!"}
        </span>
      );
    } else {
      return "Not Started Yet!";
    }
  };

  const getTeamTotals = (
    matchId: any,
    batFirst: any,
    teams: any,
    status: any,
    batFirstScoreboard: any,
    batSecondScoreboard: any
  ) => {
    var batFirstTeam = teams?.filter(function (t: any) {
      return t?.id === batFirst;
    });

    var ballFirstTeam = teams?.filter(function (t: any) {
      return t?.id !== batFirst;
    });

    if (status === "NOT_STARTED") {
      if (teams?.length > 0) {
        return <span>-</span>;
      }
    } else {
      if (!batFirstScoreboard || !batSecondScoreboard) {
        return <span>-</span>;
      }

      var batFirstTotal = 0;
      var batFirstWickets = 0;

      batFirstScoreboard?.playerBowls?.filter(function (b: any) {
        if (b?.status !== "WICKET") {
          batFirstTotal = batFirstTotal + b?.runs;
        }

        if (b?.status === "WICKET") {
          batFirstWickets = batFirstWickets + 1;
        }
      });

      var ballFirstTotal = 0;
      var ballFirstWickets = 0;

      batSecondScoreboard?.playerBowls?.filter(function (b: any) {
        if (b?.status !== "WICKET") {
          ballFirstTotal = ballFirstTotal + b?.runs;
        }

        if (b?.status === "WICKET") {
          ballFirstWickets = ballFirstWickets + 1;
        }
      });

      return (
        <span className="matches-team-total">
          {batFirstTeam[0]?.name?.split(" ")[0]?.charAt(0)?.toUpperCase() +
            "" +
            (batFirstTeam[0]?.name?.split(" ")[1]?.charAt(0) === undefined
              ? ""
              : "." +
                batFirstTeam[0]?.name?.split(" ")[1]?.charAt(0)?.toUpperCase() +
                "") +
            "" +
            (batFirstTeam[0]?.name?.split(" ")[2]?.charAt(0) === undefined
              ? ""
              : "." +
                batFirstTeam[0]?.name?.split(" ")[2]?.charAt(0)?.toUpperCase() +
                "") +
            ":" +
            batFirstTotal +
            "/" +
            batFirstWickets}
          &nbsp;&nbsp; | &nbsp;&nbsp;
          {ballFirstTeam[0]?.name?.split(" ")[0]?.charAt(0)?.toUpperCase() +
            "" +
            (ballFirstTeam[0]?.name.split(" ")[1]?.charAt(0) === undefined
              ? ""
              : "." +
                ballFirstTeam[0]?.name.split(" ")[1]?.charAt(0)?.toUpperCase() +
                "") +
            "" +
            (ballFirstTeam[0]?.name.split(" ")[2]?.charAt(0) === undefined
              ? ""
              : "." +
                ballFirstTeam[0]?.name.split(" ")[2]?.charAt(0)?.toUpperCase() +
                "") +
            ":" +
            ballFirstTotal +
            "/" +
            ballFirstWickets}
        </span>
      );
    }
  };
  const getTeamStatus = (
    matchId: any,
    batFirst: any,
    teams: any,
    status: any,
    batFirstScoreboard: any,
    batSecondScoreboard: any
  ) => {
    if (status === "NOT_STARTED") {
      if (teams.length >= 0) {
        return (
          // <span
          //   className="matches-team-live"
          //   onClick={() => startMatch(matchId, teams)}
          // >
          //   Start Match
          // </span>
          <div
            className="border-r-20 bg-dark-light center-content p-2"
            onClick={() => startMatch(matchId, teams)}
          >
            <span className="f-16 f-color-white ml-2 pl-1">Start Match </span>
            <img
              className="play-icon-size ml-2 mr-2"
              src={PlayIcon}
              alt="live-play-btn"
            />
          </div>
        );
      }
    } else {
      if (!batFirstScoreboard || !batSecondScoreboard) {
        return (
          <div
            className="border-r-20 bg-dark-light center-content p-2"
            onClick={() => startMatch(matchId, teams)}
          >
            <span className="f-16 f-color-white ml-2 pl-1">Start Match </span>
            <img
              className="play-icon-size ml-2 mr-2"
              src={PlayIcon}
              alt="live-play-btn"
            />
          </div>
        );
      }
    }
    if (status === "ONGOING") {
      return (
        <div
          className="border-r-20 bg-dark-purple center-content p-2"
          onClick={() => navigateScore(matchId)}
        >
          <span className="f-16 f-color-white ml-2 pl-1">Ongoing </span>
          <img
            className="play-icon-size ml-2 mr-2"
            src={PlayIcon}
            alt="live-play-btn"
          />
        </div>
      );
    }
    if (
      status === "BAT_FIRST_WIN" ||
      status === "BALL_FIRST_WIN" ||
      status === "MATCH_TIED"
    ) {
      return (
        <div
          className="border-r-20 bg-yellow-dark center-content p-2"
          onClick={() => navigateScore(matchId)}
        >
          <span className="f-16 f-color-white ml-2 pl-1">Completed </span>
          <img
            className="play-icon-size ml-2 mr-2"
            src={PlayIcon}
            alt="live-play-btn"
          />
        </div>
      );
    }
  };

  const navigateScore = (id: any) => {
    history.push(`/admin/quick-live-score/${id}`);
  };
  const startMatch = (id: any, teams: any) => {
    if (user?.userType === "SUPER_ADMIN") {
    } else {
      console.log("inside start match");
      setUpdateMatchId(id);
      console.log("match id " + id);
      setSelectTeams(teams);
      console.log("teams " + teams);
      // setTeamsLength(teams.length);
      // Check if teams are empty
      if (!teams || teams.length < 2) {
        // Redirect to the specified URL

        // history.push(
        //   `/admin/my-matches/create-quick-teams/${id}?source=YourComponent`
        // );
        history.push(`/admin/my-matches/create-quick-teams/${id}`);
      } else {
        console.log("teams " + teams);
        handleOpenModal();
      }
      handleOpenModal();
    }
  };
  const updateStartMatch = () => {
    if (!tosWinTeam || !batFirstTeam) {
      swal({ icon: "error", title: "Please select tos win and bat first" });
    } else {
      const data = {
        matchId: updateMatchId,
        status: "ONGOING",
        tossWin: tosWinTeam,
        batFirst: batFirstTeam,
      };
      console.log("data inside update start match" + data);
      AdminService.updateQuickMatchTossWinTeams(data).then((res) => {
        if (res.success) {
          swal({ icon: "success", title: "Start Quick match!" }).then(
            function () {
              history.push(`/admin/quick-score-board/${updateMatchId}`);
            }
          );
        } else {
          swal({
            icon: "error",
            title: res?.error ? res.error : "Something went wrong",
          });
        }
      });
    }
  };
  const handleUpdateMatchTeam1 = (event: any) => {
    setTosWinTeam(event.target.value);
  };
  const handleUpdateMatchTeam2 = (event: any) => {
    setBatFirstTeam(event.target.value);
  };
  const handleOpenModal = () => {
    console.log("open modal");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className={`${
          user?.userType === "SUPER_ADMIN" ? `` : `main-container`
        } ${pageNumber === 1 ? ` min-h-full dashboard bg-white` : null} `}
      >
        {/* page for create teams button when dashboard is selected */}
        {pageNumber === 1 && (
          <div className="myMatches ">
            <div className="container  rmv-pad-15">
              <div className="row d-flex align-items-center mt-3 w-100  bg-white rounded py-4  ml-auto mr-auto">
                <div className="row w-40 h-25  d-flex justify-content-start ml-2 pl-1 col-md-6">
                  <div className="col-md-4 t-name btns">
                    My Matches Dashboard
                  </div>

                  <NavLink
                    className="navlink-hcolor col-md-4 t-matches-count btns"
                    to={`/admin/my-matches/teams/${user?._id}`}
                  >
                    <div>Teams</div>
                  </NavLink>
                </div>

                <div className="d-flex justify-content-end col-md-6 justify-content-center-mw431">
                  {/* <div className="row pr-2 pl-1 ">
                  <div className="col-md-2  d-flex justify-content-left align-items-center">
                    <div
                      className="arrow-btn-mymatches "
                      onClick={() => createQuickMatchClicked()}
                      // onClick={() => createTeamsPageClicked()}
                    >
                      <span className="f-17 f-color-white fw-500 font-Poppins ">
                        <NavLink
                          to={`/admin/my-matches/create-quick-teams/${user?._id}`}
                        >
                          <button className="list-tournament-btn">
                            Create Quick Teams
                            <span className="fas fa-chevron-right ml-3"></span>
                          </button>
                        </NavLink>
                      </span>
                    </div>
                  </div>
                </div> */}
                  <div className="row pr-2">
                    <div className="col-md-2  d-flex justify-content-left align-items-center">
                      <div
                        className="arrow-btn-mymatches "
                        onClick={() => createQuickMatchClicked()}
                      >
                        <span className="f-17 f-color-white fw-500 font-Poppins ">
                          <NavLink
                            // to={`/admin/my-matches/create-quick-match/${user?._id}`}
                            to={"/admin/my-matches/create-quick-match"}
                          >
                            <button className="list-tournament-btn">
                              Start Quick Match
                              <span className="fas fa-chevron-right ml-3"></span>
                            </button>
                          </NavLink>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    {/* <div className="col-md-2  d-flex justify-content-left align-items-center">
                  <div
                    className="arrow-btn-mymatches "
                    onClick={() => createQuickMatchClicked()}
                    // onClick={() => createTeamsPageClicked()}
                  >
                    <span className="f-17 f-color-white fw-500 font-Poppins ">
                      <NavLink
                        to={`/admin/my-matches/plan-quick-match/${user?._id}`}
                      >
                        <button className="list-tournament-btn">
                          Plan Quick Matches
                          <span className="fas fa-chevron-right ml-3"></span>
                        </button>
                      </NavLink>
                    </span>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>

              <div className="container-fluid bg-white pt-3 pb-3 border2-r-10 mt-5 res-height max-h-table myTable margin-top-mw431">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="table-head-matches ">
                      <tr className="text-center">
                        <th>Match Id</th>
                        <th>Type</th>
                        <th>No. of overs</th>
                        <th>No. of team members</th>
                        <th>Teams</th>
                        <th>Total</th>
                        <th>Live Scoreboard</th>
                        <th></th>
                        <th>Result</th>
                        <th>Stats</th>
                      </tr>
                    </thead>
                    <tbody className="table-body-matches">
                      {matchesData?.map((t: any, index: any) => (
                        <tr className="text-center" key={index}>
                          <td>Match {matchesData.length - index}</td>
                          <td>{t.type}</td>
                          <td>{t.oversPerMatch}</td>
                          <td>{t.numberOfTeamMembers}</td>
                          <td className="team-sec">
                            {t.teams.length !== 0 ? (
                              <div className="d-flex flex-wrap justify-content-center align-items-center matches-team-label py-3">
                                {t.teams.map((team: any, index1: any) => (
                                  <span className="m-1" key={index1}>
                                    {team.name}
                                  </span>
                                ))}
                              </div>
                            ) : (
                              <div className="d-flex flex-wrap justify-content-center align-items-center py-3">
                                <span className="m-1">No teams added!</span>
                              </div>
                            )}
                          </td>
                          <td>
                            {getTeamTotals(
                              t._id,
                              t.batFirst,
                              t.teams,
                              t.status,
                              t.batFirstScoreboard,
                              t.batSecondScoreboard
                            )}
                          </td>
                          <td className="cursor-pointer">
                            {getTeamStatus(
                              t._id,
                              t.batFirst,
                              t.teams,
                              t.status,
                              t.batFirstScoreboard,
                              t.batSecondScoreboard
                            )}
                          </td>
                          {t.status === "ONGOING" &&
                          user?.userType !== "SUPER_ADMIN" ? (
                            <td>
                              <NavLink to={`/admin/quick-score-board/${t._id}`}>
                                <div className="border-r-20 bg-rose2 center-content p-2 live-btn res-wid-431 click-live">
                                  <span className="f-16 f-color-white ml-2 pl-1">
                                    Live{" "}
                                  </span>
                                  <img
                                    className="play-icon-size ml-2 mr-4"
                                    src={PlayIcon}
                                    alt="live-play-btn"
                                  />
                                </div>
                              </NavLink>
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td>
                            <div className="win-status">
                              {getResultStatus(
                                t.batFirst,
                                t.teams,
                                t.status,
                                t._id
                              )}
                            </div>
                          </td>
                          <td className="">
                            {getResultStatus(
                              t.batFirst,
                              t.teams,
                              t.status,
                              t._id
                            ) === "Not Started Yet!" ? (
                              <></>
                            ) : (
                              <>
                                <NavLink
                                  to={`/admin/my-matches/points-table/${t?._id}`}
                                >
                                  <img
                                    className="stat-icon-size cursor-p mr-3 mt-2"
                                    src={Stats}
                                    alt=""
                                    // onClick={() => updateTeam(true)}
                                  />
                                </NavLink>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div>
          <div className="d-flex justify-content-center align-item-center mb-2">
            <span className="fw-400 f-15 f-color-black3 font-Poppins text-left">
              Toss Win
            </span>
          </div>
          <div className="mb-2 d-flex justify-content-center align-item-center">
            <select
              className="select-dropdown3 fw-400 f-15 f-color-black3 font-Poppins"
              onChange={(e) => handleUpdateMatchTeam1(e)}
            >
              <option className="fw-400 f-15 f-color-black3 font-Poppins">
                Select Team
              </option>
              {selectTeams?.map((team: any, index: number) => (
                <option
                  key={index}
                  className="fw-400 f-15 f-color-black3 font-Poppins"
                  value={team?._id}
                >
                  {team?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex justify-content-center align-item-center mb-2">
            <span className="fw-400 f-15 f-color-black3 font-Poppins text-left">
              Bat first
            </span>
          </div>
          <div className="mb-2 d-flex justify-content-center align-item-center">
            <select
              className="select-dropdown3 fw-400 f-15 f-color-black3 font-Poppins"
              onChange={(e) => handleUpdateMatchTeam2(e)}
            >
              <option className="fw-400 f-15 f-color-black3 font-Poppins">
                Select Team
              </option>
              {selectTeams?.map((team: any, index: number) => (
                <option
                  key={index}
                  className="fw-400 f-15 f-color-black3 font-Poppins"
                  value={team?._id}
                >
                  {team?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="row pt-2 pl-3 pr-3 d-flex justify-content-center align-items-center">
            <div className="arrow-btn2" onClick={() => updateStartMatch()}>
              <span className="f-17 f-color-white fw-500 font-Poppins">
                Start match
              </span>
              <img
                className="arrow-icon-size ml-3"
                src={RightArrow}
                alt="right-arrow"
              />
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default MyMatches;
