import React, { useEffect, useState, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import "../vendors/styles/cricketAppStyles.css";
import createTournamentProfile from "../../components/vendors/images/createTournamentProfile.svg";
import RightArrow from "../vendors/images/icon/right-arrow.png";
import Edit from "../vendors/images/icon/edit.png";
import search from "../../components/vendors/images/search.svg";
import { AdminService } from "../../services/AdminService";
import TournamentContext from "../../context/TournamentContext";
import UserContext from "../../context/UserContext";
import Close from "../vendors/images/icon/x.png";
import batter from "../vendors/images/batter.png";
import { environment } from "../../environment/environment";
import swal from "sweetalert";
import { MenuContext } from "../../context/MenuContext";
import { TeamData, TeamMemberData } from "../../models/Team";
import { RouteName } from "../../RouteName";

const Tournaments: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [tournamentList, setTournamentList] = useState<any[]>();
  const [tournament, setTournament] = useContext(TournamentContext);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredTournaments, setFilteredTournaments] = useState<any[]>();
  const history = useHistory();
  const [user] = useContext(UserContext);
  const [teamList, setTeamList] = useState<any>([]);
  const [teamListMain, setTeamListMain] = useState<any>([]);
  const [teamListExtra, setTeamListExtra] = useState<any>([]);
  const [matchList, setMatchList] = useState<any>([]);
  const [newTeamName, setNewTeamName] = useState<string>();
  const [newTeamMemberName, setNewTeamMemberName] = useState<string>();
  const [newTeamMemberList, setNewTeamMemberList] = useState<any[]>([]);
  const [updateTeamMember, setUpdateTeamMember] = useState<any>();
  const [tournamentData, setTournamentData] = useState<any>();
  const [totalMatchCount, setTotalMatchCount] = useState<any>();
  const [selectTeam1ArrList, setSelectTeam1ArrList] = useState<any[]>([]);
  const [selectTeam2ArrList, setSelectTeam2ArrList] = useState<any[]>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateMatchIndex, setUpdateMatchIndex] = useState<number>(1);
  const [updateMatchId, setUpdateMatchId] = useState<string>();
  const [extraMatchTeam1, setExtraMatchTeam1] = useState<string>();
  const [extraMatchTeam2, setExtraMatchTeam2] = useState<string>();
  const [extraMatchType, setExtraMatchType] = useState<string>();
  const [updateMatchTeam1, setUpdateMatchTeam1] = useState<string>();
  const [updateMatchTeam2, setUpdateMatchTeam2] = useState<string>();
  const [updateTeamId, setUpdateTeamId] = useState<string>("");
  const [isUpdateTeam, setIsUpdateTeam] = useState<boolean>(false);
  const [tournamentCount, setTournamentCount] = useState<number>(0);
  // const splittedURL = window.location.pathname.split("/");
  // const tournamentId = splittedURL[splittedURL.length - 1];

  // useEffect(() => {
  //   getTournamentData();
  //   getTeamsMain();
  //   getMatches();
  // }, [update]);

  const checkAvailability = () => {
    AdminService.getTournamentList()
      .then((res) => {
        if (res.data) {
          console.log("tournaments count===>", res.data.length);
          const filteredTournaments = res.data?.filter(
            (tournament) => tournament.userId === user?._id
          );

          setTournamentCount(filteredTournaments.length);
          console.log("set touranment count  ", filteredTournaments.length);

          const currPack = user?.packageBought;
console.log('current pack ',currPack);
          if(filteredTournaments.length === environment.ALLOWED_SILVER_TOURNAMENTS_COUNT && currPack === "SILVER")
          {
            swal({
              icon: "error",
              title: "Unable to create tournament!",
              text: "Tournaments limit reached for the silver package. Please upgrade to GOLD package to create unlimited tournaments"
          });
            
          }
          else{
            history.push(`/admin/create-tournament`)        
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };




  const createTeamsPageClicked = () => {
    if (tournamentData?.numberOfTeams > teamList?.length) {
      setPageNumber(2);
    } else {
      swal({
        icon: "error",
        title: "Cannot add more teams to this tournament",
      });
    }
  };
  const upgradePlanBtnClicked = () => {
    setPageNumber(5);
  };

  const planTournamentPageClicked = () => {
    if (matchList?.length >= totalMatchCount) {
      swal({ icon: "error", title: "Already added all matches" });
    } else {
      setPageNumber(3);
    }
  };
  const planTournamentExtraPageClicked = () => {
    if (matchList?.length >= totalMatchCount) {
      setPageNumber(4);
    } else {
      swal({ icon: "error", title: "First plan tournament matches" });
    }
  };
  const closePageClicked = () => {
    setPageNumber(1);
    setNewTeamMemberName("");
    setNewTeamName("");
    setNewTeamMemberList([]);
    setExtraMatchTeam1("");
    setExtraMatchTeam2("");
    setUpdateTeamId("");
    setIsUpdateTeam(false);
  };
  // const getTeamsMain = () => {
  //   AdminService.getTeamsByTournamentId(tournamentId).then((res) => {
  //     if (res.success) {
  //       setTeamList(res.data);
  //     } else {
  //       console.log("error", res.error);
  //     }
  //   });
  // };
  // const getMatches = () => {
  //   AdminService.getMatchesByTournamentId(tournamentId).then((res) => {
  //     if (res.success) {
  //       setMatchList(res.data);
  //       const allMatches = res?.data;
  //       let arr1 = [];
  //       let arr2 = [];

  //       let numElements = Math.min(allMatches.length, totalMatchCount);

  //       arr1 = allMatches.slice(0, numElements);
  //       arr2 = allMatches.slice(numElements);
  //       setTeamListMain(arr1);
  //       setTeamListExtra(arr2);
  //     } else {
  //       console.log("error", res.error);
  //     }
  //   });
  // };
  // const getTournamentData = () => {
  //   AdminService.getTournamentDataByTournamentId(tournamentId).then((res) => {
  //     if (res.success) {
  //       setTournamentData(res.data);
  //       setTotalMatchCount(
  //         res?.data?.leagueTotalMatches + res?.data?.semiFinalMatches
  //       );
  //     } else {
  //       console.log("error", res.error);
  //     }
  //   });
  // };
  // const addTeamMemberToTeam = () => {
  //   if (updateTeamMember > -1) {
  //     const updatedTeamMemberList = [...newTeamMemberList];
  //     updatedTeamMemberList[updateTeamMember] = newTeamMemberName;
  //     setNewTeamMemberList(updatedTeamMemberList);
  //     setNewTeamMemberName("");
  //     setUpdateTeamMember(-1);
  //   } else {
  //     setNewTeamMemberName("");
  //     if (!newTeamMemberName) {
  //       swal({ icon: "error", title: "Enter Valid Name" });
  //     } else {
  //       if (tournamentData?.numberOfTeamMembers > newTeamMemberList?.length) {
  //         setNewTeamMemberList([...newTeamMemberList, newTeamMemberName]);
  //       } else {
  //         swal({ icon: "error", title: "Added Maximum count of members" });
  //       }
  //     }
  //   }
  // };
  // const removeMemberFromList = (index: number) => {
  //   const updatedTeamMemberList = [...newTeamMemberList];
  //   updatedTeamMemberList.splice(index, 1);
  //   setNewTeamMemberList(updatedTeamMemberList);
  // };
  // const updateMemberFromList = (index: number, memberName: any) => {
  //   setNewTeamMemberName(memberName);
  //   setUpdateTeamMember(index);
  // };
  // const createTeam = () => {
  //   if (!newTeamName) {
  //     swal({ icon: "error", title: "Please Enter Team name" });
  //   } else if (newTeamMemberList?.length < 1) {
  //     swal({ icon: "error", title: "Please Add Team Members" });
  //   }
  //   if (newTeamName && newTeamMemberList?.length > 0) {
  //     const data = {
  //       tournamentId: tournamentId,
  //       teams: [
  //         {
  //           name: newTeamName,
  //           players: newTeamMemberList,
  //         },
  //       ],
  //     };
  //     AdminService.createTeam(data).then((res) => {
  //       if (res.success) {
  //         const memberList = newTeamMemberList.map((member: any) => ({
  //           name: member,
  //         }));
  //         const data = {
  //           tournamentId: tournamentId,
  //           name: newTeamName,
  //           players: memberList,
  //         };
  //         setTeamList([...teamList, data]);
  //         setUpdate(!update);
  //         setNewTeamName("");
  //         setNewTeamMemberList([]);
  //         swal({ icon: "success", title: "New team created!" }).then(
  //           function () {
  //             setPageNumber(1);
  //           }
  //         );
  //       } else {
  //         swal({
  //           icon: "error",
  //           title: res?.error ? res.error : "Something went wrong",
  //         });
  //       }
  //     });
  //   }
  // };
  // const handleChangeTeam1 = (event: any, index: number) => {
  //   const newString = event.target.value;
  //   if (index >= 0 && index < selectTeam1ArrList.length) {
  //     const updatedArray = [...selectTeam1ArrList];
  //     updatedArray[index] = newString;
  //     setSelectTeam1ArrList(updatedArray);
  //   } else {
  //     const updatedArray = [...selectTeam1ArrList];
  //     while (updatedArray.length < index) {
  //       updatedArray.push("");
  //     }
  //     updatedArray[index] = newString;
  //     setSelectTeam1ArrList(updatedArray);
  //   }
  // };
  // const handleChangeTeam2 = (event: any, index: number) => {
  //   const newString = event.target.value;
  //   if (index >= 0 && index < selectTeam2ArrList.length) {
  //     const updatedArray = [...selectTeam2ArrList];
  //     updatedArray[index] = newString;
  //     setSelectTeam2ArrList(updatedArray);
  //   } else {
  //     const updatedArray = [...selectTeam2ArrList];
  //     while (updatedArray.length < index) {
  //       updatedArray.push("");
  //     }
  //     updatedArray[index] = newString;
  //     setSelectTeam2ArrList(updatedArray);
  //   }
  // };
  // const areValuesAtSameIndexEqual = (arr1: any, arr2: any) => {
  //   if (arr1.length !== arr2.length) {
  //     return false;
  //   }

  //   for (let i = 0; i < arr1.length; i++) {
  //     if (arr1[i] === arr2[i]) {
  //       //  console.log("run==>", arr1[i], arr2[i]);
  //       return false;
  //     }
  //   }

  //   return true;
  // };
  // const addMatchForTournament = () => {
  //   if (selectTeam1ArrList?.length !== selectTeam2ArrList?.length) {
  //     swal({ icon: "error", title: "Please select team for all matches" });
  //   } else {
  //     const checkArray = selectTeam1ArrList.filter((team1) => team1.length < 1);
  //     const checkArray2 = selectTeam2ArrList.filter(
  //       (team2) => team2.length < 1
  //     );
  //     if (checkArray?.length !== 0) {
  //       swal({ icon: "error", title: "Please select team for all matches" });
  //     } else if (checkArray2?.length !== 0) {
  //       swal({ icon: "error", title: "Please select team for all matches" });
  //     } else {
  //       const notSameIndex = areValuesAtSameIndexEqual(
  //         selectTeam1ArrList,
  //         selectTeam2ArrList
  //       );
  //       if (notSameIndex) {
  //         const maxLength = Math.max(
  //           selectTeam1ArrList.length,
  //           selectTeam2ArrList.length
  //         );
  //         const newTeamArr = [];
  //         for (let i = 0; i < maxLength; i++) {
  //           newTeamArr.push([selectTeam1ArrList[i], selectTeam2ArrList[i]]);
  //         }
  //         for (let i = 0; i < maxLength; i++) {
  //           newTeamArr.push([selectTeam1ArrList[i], selectTeam2ArrList[i]]);
  //           const data = {
  //             tournamentId: tournamentId,
  //             type: "REGULAR",
  //             teams: newTeamArr[i],
  //           };
  //           AdminService.createMatch(data).then((res) => {
  //             if (res.success) {
  //               if (i === maxLength - 1) {
  //                 swal({
  //                   icon: "success",
  //                   title: "New tournament matches created!",
  //                 }).then(function () {
  //                   setUpdate(!update);
  //                   setPageNumber(1);
  //                 });
  //               }
  //             } else {
  //               swal({
  //                 icon: "error",
  //                 title: res?.error ? res?.error : "Something went wrong",
  //               });
  //             }
  //           });
  //         }
  //       } else {
  //         swal({
  //           icon: "error",
  //           title: "Please select different team for match",
  //         });
  //       }
  //     }
  //   }
  // };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  // const updateMatchTeam = (index: number, id: string) => {
  //   setUpdateMatchIndex(index);
  //   setUpdateMatchId(id);
  //   const indexToFilter = index;
  //   const selectedMatch = matchList[indexToFilter];
  //   setUpdateMatchTeam1(selectedMatch?.teams[0]?._id);
  //   setUpdateMatchTeam2(selectedMatch?.teams[1]?._id);
  //   handleOpenModal();
  // };
  // const ExtraSelectTeam1 = (event: any) => {
  //   setExtraMatchTeam1(event.target.value);
  // };
  // const ExtraMatchType = (event: any) => {
  //   setExtraMatchType(event.target.value);
  // };
  // const ExtraSelectTeam2 = (event: any) => {
  //   setExtraMatchTeam2(event.target.value);
  // };
  // const addExtraMatchForTournament = () => {
  //   if (extraMatchTeam1 && extraMatchTeam2 && extraMatchType) {
  //     if (extraMatchTeam1 !== extraMatchTeam2) {
  //       const data = {
  //         tournamentId: tournamentId,
  //         type: extraMatchType,
  //         teams: [extraMatchTeam1, extraMatchTeam2],
  //       };
  //       AdminService.createMatch(data).then((res) => {
  //         if (res.success) {
  //           setExtraMatchTeam1("");
  //           setExtraMatchTeam2("");
  //           swal({
  //             icon: "success",
  //             title: "New extra match is created!",
  //           }).then(function () {
  //             setUpdate(!update);
  //             setPageNumber(1);
  //           });
  //         } else {
  //           swal({
  //             icon: "error",
  //             title: res?.error ? res?.error : "Something went wrong",
  //           });
  //         }
  //       });
  //     } else {
  //       swal({ icon: "error", title: "Please Select different Teams" });
  //     }
  //   } else {
  //     swal({ icon: "error", title: "Please Select All Fields" });
  //   }
  // };
  // const handleUpdateMatchTeam1 = (event: any) => {
  //   setUpdateMatchTeam1(event.target.value);
  // };
  // const handleUpdateMatchTeam2 = (event: any) => {
  //   setUpdateMatchTeam2(event.target.value);
  // };
  // const updateMatchTeams = () => {
  //   if (updateMatchTeam1 === updateMatchTeam2) {
  //     swal({ icon: "error", title: "Please Select different Teams" });
  //   } else {
  //     const data = {
  //       matchId: updateMatchId,
  //       team01: updateMatchTeam1,
  //       team02: updateMatchTeam2,
  //     };
  //     AdminService.updateMatchTeams(data).then((res) => {
  //       if (res.success) {
  //         setUpdate(!update);
  //         handleCloseModal();
  //         setUpdateMatchTeam1("");
  //         setUpdateMatchTeam2("");
  //         swal({
  //           icon: "success",
  //           title: "New tournament matches updated!",
  //         }).then(function () {});
  //       } else {
  //         swal({
  //           icon: "error",
  //           title: res?.error ? res.error : "Something went wrong",
  //         });
  //       }
  //     });
  //   }
  // };
  // const deleteTeam = (teamId: any) => {
  //   swal({
  //     icon: "success",
  //     title: "Do you want to delete team ?",
  //     buttons: {
  //       cancel: {
  //         text: "Cancel",
  //         value: null,
  //         visible: true,
  //         className: "my-custom-cancel-button",
  //         closeModal: true,
  //       },
  //       confirm: {
  //         text: "OK",
  //         value: true,
  //         visible: true,
  //         // className: "my-custom-ok-button",
  //         closeModal: true,
  //       },
  //     },
  //   }).then(function (value) {
  //     if (value === null) {
  //       //  console.log("Cancel button clicked");
  //     } else {
  //       AdminService.deleteTeam(data).then((res) => {
  //         if (res.success) {
  //           setUpdate(!update);
  //           swal({
  //             icon: "success",
  //             title: "Successfully delete team!",
  //           }).then(function () {});
  //         } else {
  //           swal({
  //             icon: "error",
  //             title: res?.error ? res.error : "Something went wrong",
  //           });
  //         }
  //       });
  //     }
  //   });
  //   const data = {
  //     teamId: teamId,
  //   };
  // };
  // const updateTeam = (team: any) => {
  //   setIsUpdateTeam(true);
  //   setUpdateTeamId(team?._id);
  //   setNewTeamName(team?.name);
  //   const teamPlayersArr = team.players?.map((player: any) => player.name);
  //   setNewTeamMemberList(teamPlayersArr);
  //   setPageNumber(2);
  //   // console.log("team==>", team)
  // };
  // const handleClickUpdateTeam = () => {
  //   const data = {
  //     teamId: updateTeamId,
  //     teamName: newTeamName,
  //   };
  //   const data2 = {
  //     teamId: updateTeamId,
  //     newTeamData: newTeamMemberList,
  //   };
  //   if (!updateTeamId) {
  //     swal({
  //       icon: "error",
  //       title: "Something went wrong",
  //     });
  //   } else if (!newTeamName) {
  //     swal({
  //       icon: "error",
  //       title: "Please enter team name",
  //     });
  //   } else if (newTeamMemberList?.length == 0) {
  //     swal({
  //       icon: "error",
  //       title: "Please add team members",
  //     });
  //   } else {
  //     AdminService.updateTeamName(data).then((res) => {
  //       if (res.success) {
  //         AdminService.updateTeamMembers(data2).then((res) => {
  //           if (res.success) {
  //             setIsUpdateTeam(false);
  //             setNewTeamName("");
  //             setNewTeamMemberList([]);
  //             setPageNumber(1);
  //             setUpdate(!update);
  //             swal({
  //               icon: "success",
  //               title: "Team updated!",
  //             }).then(function () {});
  //           } else {
  //             swal({
  //               icon: "error",
  //               title: res?.error ? res.error : "Something went wrong",
  //             });
  //           }
  //         });
  //       } else {
  //         swal({
  //           icon: "error",
  //           title: res?.error ? res.error : "Something went wrong",
  //         });
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    getTeams();
  }, []);
  useEffect(() => {
    filterTournaments();
  }, [tournamentList, searchTerm]);
  const getTeams = () => {
    AdminService.getTournamentList().then((res) => {
      if (res.success) {
        const response = res.data;
        console.log("res===>", res.data);
        const filteredTournaments = response?.filter(
          (tournament) => tournament.userId === user?._id
        );
        setTournamentList(filteredTournaments);
      } else {
        console.log("error", res.error);
      }
    });
  };

  const handleTournamentClick = (tournament: any) => {
    setTournament(tournament);
    history.push(`/admin/tournament/${tournament.name}/${tournament._id}`);
  };
  // const handleUpgradeClick = (user: any) => {
  //   history.push(`/admin/dashboard/upgrade-package/${user?._id}`);
  // };

  const filterTournaments = () => {
    const filtered = tournamentList?.filter((tournament) =>
      tournament.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTournaments(filtered);
  };
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
  const deleteTournament = (tournamentId: string) => {
    swal({
      icon: "info",
      title: "Do you want to delete tournament ?",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "my-custom-cancel-button",
          closeModal: true,
        },
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          // className: "my-custom-ok-button",
          closeModal: true,
        },
      },
    }).then(function (value) {
      if (value === null) {
        //  console.log("Cancel button clicked");
      } else {
        const data = {
          tournamentId: tournamentId,
        };
        AdminService.deleteTournament(data).then((res) => {
          if (res.success) {
            const filteredTournaments = tournamentList?.filter(
              (tournament) => tournament._id != tournamentId
            );
            setTournamentList(filteredTournaments);
            swal({
              icon: "success",
              title: "Successfully delete tournament!",
            }).then(function () {});
          } else {
            swal({
              icon: "error",
              title: res?.error ? res.error : "Something went wrong",
            });
          }
        });
      }
    });
  };

  return (
    <>
      {pageNumber === 1 && (
        <div className="main-container">
          <div className="login-body d-lg-flex text-center w-100 mar-btm-0">
            <div className="w-100 h-auto">
              <div className="w-100 package-container bg-color-changed-toGradient">
                <div className="row pad-20 jus-ar w-100">
                  <div className="tournamentlist-body d-flex justify-content-center align-items-center w-100">
                    <div className="row  tournamelist-main-contianer-new t">
                      <div className="col-lg-12  d-flex justify-content-center align-items-center ">
                        <div className="tournament-list-container-top  align-items-center">
                          <div className="d-flex w-100 justify-content-between px-5 align-items-center remove-left-padding">
                            <div className="d-flex justify-content-center align-items-center mb-4">
                              <span className="mb-1 h-1 text-center tournament-creation-header mb-4">
                                Tournaments
                              </span>
                            </div>
                            <div className="d-lg-flex justify-content-center mt-2 mb-5">

                            <button
                    className="create-tournament-btn"
                    onClick={checkAvailability}
                  >
                    Create new Tournament
                    <span className="fas fa-chevron-right ml-1"></span>
                  </button>



                              {/* <NavLink to="/admin/create-tournament">
                                <button className="list-tournament-btn">
                                  Create new Tournament
                                  <span className="fas fa-chevron-right ml-1"></span>
                                </button>
                              </NavLink> */}
                            </div>
                          </div>
                          <div className="tournament-list-container-bottom less-shadow">
                            <div className="d-lg-flex justify-content-between mb-4">
                              <span className=" h-1  tournament-list-creation-header mb-4 mt-1">
                                Tournament List
                              </span>
                              <div className="d-flex  align-items-center tournament-list-search m-1 mt-2">
                                <img
                                  src={search}
                                  alt="Search"
                                  className="mr-1"
                                />
                                <input
                                  type="text"
                                  className="search-input"
                                  placeholder="Search..."
                                  value={searchTerm}
                                  onChange={(e) =>
                                    setSearchTerm(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="table-responsive tournament-tabel  d-flex justify-content-between">
                              <table className="table ">
                                <tbody className="tournament-list-table">
                                  {filteredTournaments?.map(
                                    (tournament: any, index: any) => (
                                      <tr
                                        className="d-flex justify-content-between flex-wrap"
                                        key={index}
                                      >
                                        <td
                                          onClick={() =>
                                            handleTournamentClick(tournament)
                                          }
                                        >
                                          <span className="pl-2 pr-2 cursor-p">
                                            {tournament.name}
                                          </span>
                                        </td>
                                        <td>
                                          <img
                                            className="close-icon-size mr-1 cursor-p"
                                            onClick={() =>
                                              deleteTournament(tournament._id)
                                            }
                                            src={Close}
                                            alt=""
                                          />
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                              <img
                                src={batter}
                                alt="Right Background"
                                className="tournament-list-img"
                              />
                            </div>
                          </div>

                          {/* <div className=" d-flex justify-content-end tournament-list-img-container">
                            <img
                              src={createTournamentProfile}
                              alt="Right Background"
                              className="tournament-list-img"
                            />
                          </div> */}
                        </div>
                      </div>
                      {/* <div className="col-lg-6 d-flex justify-content-center align-items-center">
                        <div className="tournament-list-container-bottom">
                          <div className="d-lg-flex justify-content-between mb-4">
                            <span className=" h-1  tournament-list-creation-header mb-4 mt-1">
                              Tournament List
                            </span>
                            <div className="d-flex  align-items-center tournament-list-search m-1 mt-2">
                              <img src={search} alt="Search" className="mr-1" />
                              <input
                                type="text"
                                className="search-input"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="table-responsive mt-5 tournament-tabel">
                            <table className="table ">
                              <tbody className="tournament-list-table">
                                {filteredTournaments?.map(
                                  (tournament: any, index: any) => (
                                    <tr
                                      className="d-flex justify-content-between flex-wrap"
                                      key={index}
                                    >
                                      <td
                                        onClick={() =>
                                          handleTournamentClick(tournament)
                                        }
                                      >
                                        <span className="pl-2 pr-2 cursor-p">
                                          {tournament.name}
                                        </span>
                                      </td>
                                      <td>
                                        <img
                                          className="close-icon-size mr-1 cursor-p"
                                          onClick={() =>
                                            deleteTournament(tournament._id)
                                          }
                                          src={Close}
                                          alt=""
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {pageNumber === 2 && (
        <div className="main-container">
          <div className="login-body d-lg-flex text-center w-100">
            <div className="w-100 h-auto">
              <div className="w-100 package-container">
                <div className="row pad-20 jus-ar w-100"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tournaments;
