import React, { useEffect, useState, useContext } from "react";
import "../../vendors/styles/cricketAppStyles.css";
import { useHistory } from "react-router-dom";
import { FormFeedback, Form, Input, Label, Modal, Row, Col } from "reactstrap";
import { useFormik } from "formik";
import { AdminService } from "../../../services/AdminService";
import * as Yup from "yup";
import swal from "sweetalert";
import UserContext from "../../../context/UserContext";
import RowComponent from "./row-component";

const SubscriptionsManagement: React.FC = () => {
  const [modalCenter, setModalCenter] = useState(false);
  const [userData, setUserData] = useState<any>("");
  const [linkData, setLinkData] = useState<any>("");

  const [offset, setOffset] = useState<number>(1);
  const [customerAdmin, setCustomerAdmin] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const [reload, setReload] = useState<boolean>(false);

  const [cus, setCus] = useState<any>();
  const LIMIT = 10;
  const [user] = useContext(UserContext);
  const history = useHistory();
  useEffect(() => {
    viewAllUsers(LIMIT, offset);
  }, []);
  const viewAllUsers = (limit: number, offSet: number) => {
    AdminService.getSubscribeRequestUserListByAdmin().then((res) => {
      console.log("subscriptions customers", res.data);
      setCustomerAdmin(res.data);
      setIsLoading(false);
      setHasMore(res.data?.length > 0 && res.data?.length == limit);
    });
  };

  const seeMore = () => {
    const newOffset = offset + 1;

    setOffset(newOffset);

    AdminService.getSubscribeRequestUserListByAdmin().then((res) => {
      if (res.success) {
        setCustomerAdmin((previousSessions) => {
          return [...new Set([...previousSessions, ...res.data])];
        });
        setHasMore(res.data?.length > 0 && res.data?.length == LIMIT);
        setIsLoading(false);
      }
    });
  };

  function togCenter() {
    //  console.log("togcenter clicked")
    setModalCenter(!modalCenter);
    removeBodyCss();
  }
  function togCenterLink() {
    setModalCenter(!modalCenter);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function togCenterClose() {
    setModalCenter(false);
  }
  const validationStep = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
      userType: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Email Invalid").required("Please Enter Email"),
      name: Yup.string().required("Please Enter Name"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .max(40)
        .required("Please Enter  Password"),
      confirmPassword: Yup.string()
        .min(6, "Confirm password must be at least 6 characters")
        .max(40)
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Please Re-enter Your Password"),
      userType: Yup.string().required("Please Enter usetType"),
    }),
    onSubmit: (values, { resetForm }) => {
      const userData = {
        name: values.name,
        email: values.email,
        userType: values.userType,
        password: values.password,
      };

      registerUser(userData);
      resetForm();
    },
  });

  const registerUser = async (data: any) => {
    await AdminService.creatUserByAdmin(data).then((res) => {
      if (res.success) {
        swal({ icon: "success", title: "New user is created!" }).then(
          function () {
            setUserData({
              ...userData,
              name: "",
              email: "",
              password: "",
              confirmPassword: "",
              dateOfBirth: "",
              userType: "",
            });
            setModalCenter(false);
            setOffset(1);
            viewAllUsers(LIMIT, 1);
          }
        );
      } else {
        swal({
          icon: "error",
          title: res.error ? res.error : "Unable to create user!",
        });
      }
    });
  };
  const updateCustomerAdmin = (data: any) => {
    if (data) {
      const userDataId = {
        userId: data?._id,
      };
      const newStatus = data.userStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE";

      const updatedCustomerAdmin = customerAdmin.map((cus) => {
        if (cus._id === data._id) {
          return {
            ...cus,
            userStatus: newStatus,
          };
        }
        return cus;
      });
      if (data?.userStatus === "ACTIVE") {
        swal({
          title: "Are you sure?",
          text: "Are you sure that you want to inactivate this user?",
          icon: "warning",
          buttons: ["Cancel", "Confirm"],
          dangerMode: true,
          className: "custom-swal",
        }).then((willDelete) => {
          if (willDelete) {
            AdminService.activeUsersByAdmin(userDataId).then((res) => {
              if (res.success) {
                swal("Inactivated!", "User has been inactivated!", "success");
                setCustomerAdmin(updatedCustomerAdmin);
              } else {
                swal("Error!", "Something went wrong!", "error");
              }
            });
          }
        });
      } else {
        swal({
          title: "Are you sure?",
          text: "Are you sure that you want to activate this user?",
          icon: "warning",
          buttons: ["Cancel", "Confirm"],
          dangerMode: true,
          className: "custom-swal",
        }).then((willDelete) => {
          if (willDelete) {
            AdminService.activeUsersByAdmin(userDataId).then((res) => {
              if (res.success) {
                swal("Activated!", "User has been activated!", "success");
                setCustomerAdmin(updatedCustomerAdmin);
              } else {
                swal("Error!", "Something went wrong!", "error");
              }
            });
          }
        });
      }
    }
  };

  const formatNumber = (number: number) => {
    if (number === 10) {
      return "10+";
    } else if (number > 10 && number % 10 === 0) {
      return `${number}+`;
    } else {
      return number;
    }
  };
  const clickViewTournament = (userId: any) => {
    history.push(`/admin/user-tournament/${userId}`);
  };

  return (
    <>
      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box pd-20 height-100-p mb-30 mt-20">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="d-flex  title mb-15  col-md-9 col-sm-12 mb-20 ">
                <h5 className="cardHearder font-Poppins mr-20  ">
                  Subscription Updates
                </h5>
                <span className="card-text-count  bg-blue7 pr-2 pl-2  f-color-white rounded d-flex align-items-center">
                  {formatNumber(customerAdmin.length)}
                </span>
              </div>
              <div className="col-md-3"></div>

              <div className="table-responsive">
                {!isLoading && customerAdmin.length > 0 && (
                  <table className="table">
                    <thead className="table-head-matches ">
                      <tr className="text-center">
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Current Plan</th>
                        <th scope="col">Status</th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Reference</th>

                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-body-matches ">
                      {!isLoading &&
                        customerAdmin.length > 0 &&
                        customerAdmin &&
                        customerAdmin.map((cus, index) => (
                          <>
                            <RowComponent
                              id={cus?._id}
                              cus={cus}
                              key={cus?._id}
                              reload={reload}
                            />
                          </>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>

              {!isLoading && (
                <Row className=" d-flex justify-content-center align-items-center">
                  <Col xl="12 mt30 text-center">
                    {hasMore && (
                      <button
                        className="btn btn-warning mt-3"
                        onClick={() => seeMore()}
                      >
                        See More
                      </button>
                    )}
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionsManagement;
