import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import "../vendors/styles/cricketAppStyles.css";
import createTournamentProfile from "../../components/vendors/images/createTournamentProfile.svg";
import player1Profile from "../../components/vendors/images/player1.svg";
import { AdminService } from "../../services/AdminService";
import { TournamentData } from "../../models/Tournament";
import TournamentContext from "../../context/TournamentContext";
import UserContext from "../../context/UserContext";

const CreateTournament: React.FC = () => {
  const [tournament, setTournament] = useContext(TournamentContext);
  const [user] = useContext(UserContext);

  const history = useHistory();
  const initialState: TournamentData = {
    name: "",
    numberOfTeams: 0,
    leagueTotalMatches: 0,
    oversPerMatch: 0,
    semiFinalMatches: "0",
    numberOfTeamMembers: 0,
  };
  const [userData, setUserData] = useState(initialState);

  const addTournament = () => {
    if (!userData.name) {
      swal({ icon: "error", title: "Please enter tournament name!" });
      return;
    }
    if (!userData.numberOfTeams) {
      swal({
        icon: "error",
        title: "Please enter number of teams for tournament!",
      });
      return;
    }
    if (!userData.leagueTotalMatches) {
      swal({
        icon: "error",
        title: "Please enter total matches for tournament!",
      });
      return;
    }

    if (!userData.oversPerMatch) {
      swal({ icon: "error", title: "Please enter number of overs for match!" });
      return;
    }

    const currPack = user?.packageBought;

    AdminService.createTournament(userData).then((res) => {
      console.log(res);
      if (res.success) {
        swal({ icon: "success", title: "New tournament is created!" }).then(
          function () {
            setUserData({ ...userData, name: "", oversPerMatch: 0 });
            setTournament(res);
            history.push(`/admin/tournament-dashboard`);
          }
        );
      } else {
        swal({
          icon: "error",
          title: res.error
            ? currPack === "FREE"
              ? "To create more tournaments, please upgrade your package"
              : currPack === "SILVER"
              ? "Tournaments limit reached for the silver package. Please upgrade to GOLD package to create unlimited tournaments"
              : ""
            : "Unable to create tournament!",
        });
      }
    });
  };
  const previosPage = () => {
    history.goBack();
  };
  return (
    <>
      <div className="main-container">
        <div className="login-body  d-lg-flex text-center w-100">
          <div className="w-100 h-auto">
            <div className="w-100 package-container ">
              <div className="row pad-20 jus-ar w-100">
                <div className="login-body tournament-creation d-lg-flex d-flex text-center ">
                  <i
                    className="fa fa-arrow-left f-color-gray-7 ml-4 mt-4 cursor-p"
                    aria-hidden="true"
                    onClick={() => previosPage()}
                  ></i>
                  <div className="box-1-create-tournament ">
                    <div className="create-tournament-container border-r-10">
                      <span className="mb-1 h-1 text-center tournament-creation-header mb-4">
                        Tournament Creation
                      </span>

                      <p className="textbox-create-tournament-title text-left mt-3">
                        Tournament Name
                      </p>

                      <div className="textbox-create-tournament mb-3">
                        <input
                          className="inputBackground"
                          type="text"
                          placeholder=""
                          value={userData.name}
                          onChange={(e) =>
                            setUserData({ ...userData, name: e.target.value })
                          }
                        />
                      </div>

                      <p className="textbox-create-tournament-title text-left">
                        Tournament Team Count
                      </p>
                      <div className="textbox-create-tournament">
                        <input
                          className="inputBackground"
                          type="number"
                          placeholder=""
                          value={
                            userData.numberOfTeams != 0
                              ? userData.numberOfTeams.toString()
                              : ""
                          }
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              numberOfTeams: parseInt(e.target.value),
                            })
                          }
                        />
                      </div>
                      <p className="textbox-create-tournament-title text-left mt-3">
                        Tournament Match Count
                      </p>

                      <div className="textbox-create-tournament mb-3">
                        <input
                          className="inputBackground"
                          type="number"
                          placeholder=""
                          value={
                            userData.leagueTotalMatches != 0
                              ? userData.leagueTotalMatches.toString()
                              : ""
                          }
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              leagueTotalMatches: parseInt(e.target.value),
                            })
                          }
                        />
                      </div>

                      <p className="textbox-create-tournament-title text-left">
                        Overs Count
                      </p>
                      <div className="textbox-create-tournament">
                        <input
                          className="inputBackground"
                          type="number"
                          placeholder=""
                          value={
                            userData.oversPerMatch != 0
                              ? userData.oversPerMatch.toString()
                              : ""
                          }
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              oversPerMatch: parseInt(e.target.value),
                            })
                          }
                        />
                      </div>

                      <p className="textbox-create-tournament-title text-left">
                      No: of players per team
                      </p>
                      <div className="textbox-create-tournament">
                        <input
                          className="inputBackground"
                          type="number"
                          placeholder=""
                          value={
                            userData.numberOfTeamMembers != 0
                              ? userData.numberOfTeamMembers.toString()
                              : ""
                          }
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              numberOfTeamMembers: parseInt(e.target.value),
                            })
                          }
                        />
                      </div>

                      <div className="d-lg-flex d-flex justify-content-center mt-5 mb-5">
                        <button
                          className="create-tournament-btn"
                          onClick={addTournament}
                        >
                          Create Tournament
                          <span className="fas fa-chevron-right ml-1"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className=" box-2-create-tournament d-flex flex-column h-100">
                    <img
                      className="playerImage"
                      src={player1Profile}
                      alt="Right Background"
                    />
                    <div className="playerBackground1 hidden-431"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTournament;
