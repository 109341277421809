import React, { useContext, useEffect, useState } from "react";
import "../vendors/styles/cricketAppStyles.css";
import { AdminService } from "../../services/AdminService";
import { NavLink, useParams } from "react-router-dom";
import TournamentContext from "../../context/TournamentContext";
import UserContext from "../../context/UserContext";
const PointsTable: React.FC = () => {
  const [allMatches, setAllMatches] = useState<any[]>();
  const [teams, setTeams] = useState<any[]>();
  const { tournamentId }: any = useParams();
  const urlOfThisPage = window.location.href;
  const splitFromSlash = urlOfThisPage.split("/");
  const getPointsTableFromSplittedUrl =
    splitFromSlash[splitFromSlash.length - 2];
  const [tournament] = useContext(TournamentContext);
  const [user] = useContext(UserContext);
  const tournamentname = tournament?.name;
  const [tournamentData, setTournamentData] = useState<any>();
  const [totalMatchCount, setTotalMatchCount] = useState<any>();
  const baseClass = "container"; // Replace with your common class
  const userTypeClasses =
    user?.userType !== "SUPER_ADMIN" ? "conditional-class" : "";

  const combinedClasses = `${baseClass} ${userTypeClasses}`;

  useEffect(() => {
    if (tournamentId) {
      getTournamentData();
      AdminService.getAllMatchesData(tournamentId).then((res) => {
        if (res.success) {
          setAllMatches(res.data);
          console.log("res data ", res.data); //MATCHES ARRAY
          allTeamData();
        } else {
          console.log("error", res.error);
        }
      });
    } else {
      console.log("No tournamentId found");
    }
  }, [tournamentId]);

  const getTournamentData = () => {
    AdminService.getTournamentDataByTournamentId(tournamentId).then((res) => {
      if (res.success) {
        setTournamentData(res.data);
        setTotalMatchCount(
          res?.data?.leagueTotalMatches + res?.data?.semiFinalMatches
        );
      } else {
        console.log("error", res.error);
      }
    });
  };


  const allTeamData = () => {
    AdminService.getAllTeamsByTournamentId(tournamentId).then((res) => {
      if (res.success) {
        setTeams(res.data);
        console.log("res data teams:::::::::::", res.data); //TEAMS ARRAY by tournamentId
        getAllPlayers();
      } else {
        console.log("error", res.error);
      }
    });
  };
  const getTeamStatistics = () => {
    // WILL NOT BE NEEDED IN QUICK MATCHES
    var sortedTeams: any = [];

    if (teams) {
      teams &&
        teams.filter((team) => {
          var playedMatches = 0;
          var wonMatches = 0;
          var lostMatches = 0;
          var tiedMatches = 0;
          var points = 0;

          console.log("teams variable", teams); //Access okay
          console.log("allMatches variable", allMatches); //Access okay, All matches come by TournamentId

          allMatches?.filter((match) => {
            if (match.type === "REGULAR") {
              var teamFound = match.teams.filter((t: any) => {
                return t._id === team._id;
              });

              if (teamFound.length > 0) {
                if (match.status === "BAT_FIRST_WIN") {
                  playedMatches = playedMatches + 1;

                  if (match.batFirst === team._id) {
                    wonMatches = wonMatches + 1;
                    points = points + 2;
                  } else {
                    lostMatches = lostMatches + 1;
                  }
                } else if (match.status === "BALL_FIRST_WIN") {
                  playedMatches = playedMatches + 1;

                  if (match.batFirst === team._id) {
                    lostMatches = lostMatches + 1;
                  } else {
                    wonMatches = wonMatches + 1;
                    points = points + 2;
                  }
                } else if (match.status === "MATCH_TIED") {
                  playedMatches = playedMatches + 1;

                  tiedMatches = tiedMatches + 1;
                  points = points + 1;
                }
              }
            }
          });
          console.log("team nrr : ", team.nrr);
          sortedTeams.push({
            name: team.name,
            playedMatches: playedMatches,
            wonMatches: wonMatches,
            lostMatches: lostMatches,
            tiedMatches: tiedMatches,
            points: points,
            nrr: team.nrr,
          });
        });

      sortedTeams.sort(function (a: any, b: any) {
        var nameA = a.points,
          nameB = b.points;
        var nrrA = a.nrr;
        var nrrB = b.nrr;

        if (nameA === nameB) {
          // If points are equal, compare by net run rate (nrr)
          if (nrrA < nrrB) return 1;
          if (nrrA > nrrB) return -1;
          return 0;
        }

        if (nameA < nameB) return 1;
        if (nameA > nameB) return -1;
        return 0;
      });

      return sortedTeams;
    } else {
      return [];
    }
  };

  const getAllPlayers = () => {
    var allPlayers: any = [];
    console.log("teams-------------------------------------> ", teams);
    teams &&
      teams.filter((team) => {
        console.log("team in getAllPlayers", team); //Get Team By team (team is got using tournamentId)
        var teamMatches = allMatches?.filter((m: any) => {
          console.log("getAllPlayers -> allMatches", allMatches); //Access Okay
          console.log("getAllPlayers -> allMatches -> one match", m); //Get Match By Match (match is got using tournamentId)
          var teamFound = m.teams.filter((t: any) => {
            console.log(
              "getAllPlayers -> allMatches -> one match -> teams",
              m.teams
            );
            return t._id === team._id;
          });

          if (teamFound.length > 0) {
            return true;
          }
        });
        console.log("teamMatches ", teamMatches);

        console.log("team players ", team.players); //PLAYERS OBJECT ARRAY
        team.players.filter((player: any) => {
          var playerTotalRuns = 0;
          var playerTotalWicktes = 0;
          var playerTotalBoundaries = 0;
          var playerTotalFourses = 0;
          var playerTotalSixes = 0;
          teamMatches?.filter((m: any) => {
            if (m.batFirst === team._id) {
              m.batFirstScoreboard &&
                m.batFirstScoreboard.playerBowls.filter((pb: any) => {
                  if (pb.status === "RUNS" && pb.batsmanId === player._id) {
                    playerTotalRuns = playerTotalRuns + pb.runs;

                    if (pb.runs === 4) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                      playerTotalFourses = playerTotalFourses + 1;
                    }
                    if (pb.runs === 6) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                      playerTotalSixes = playerTotalSixes + 1;
                    }
                  }

                  if (pb.status === "WICKET" && pb.bowlerId === player._id) {
                    playerTotalWicktes = playerTotalWicktes + 1;
                  }
                });
            } else {
              m.batSecondScoreboard &&
                m.batSecondScoreboard.playerBowls.filter((pb: any) => {
                  if (pb.status === "RUNS" && pb.batsmanId === player._id) {
                    playerTotalRuns = playerTotalRuns + pb.runs;

                    if (pb.runs === 4) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                      playerTotalFourses = playerTotalFourses + 1;
                    }
                    if (pb.runs === 6) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                      playerTotalSixes = playerTotalSixes + 1;
                    }
                  }

                  if (pb.status === "WICKET" && pb.bowlerId === player._id) {
                    playerTotalWicktes = playerTotalWicktes + 1;
                  }
                });
            }
          });

          allPlayers?.push({
            name: formatPlayerName(player.name),
            playerTotalRuns: playerTotalRuns,
            playerTotalWicktes: playerTotalWicktes,
            playerTotalBoundaries: playerTotalBoundaries,
            playerTotalFourses: playerTotalFourses,
            playerTotalSixes: playerTotalSixes,
          });
        });
      });

    return allPlayers;
  };
  function formatPlayerName(name: any) {
    const parts = name.split(" ");
    if (parts.length >= 2) {
      const lastName = parts.pop();
      const formattedParts = parts.map(
        (part: any) => part.charAt(0).toUpperCase() + "."
      );
      const formattedLastName =
        lastName.charAt(0).toUpperCase() + lastName.slice(1);
      return formattedParts.join(" ") + " " + formattedLastName;
    }

    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  const getAllPlayersWickets = () => {
    var allPlayers: any = [];

    teams &&
      teams.filter((team) => {
        var teamMatches = allMatches?.filter((m: any) => {
          var teamFound = m.teams.filter((t: any) => {
            return t._id === team._id;
          });

          if (teamFound.length > 0) {
            return true;
          }
        });

        team.players.filter((player: any) => {
          var playerTotalRuns = 0;
          var playerTotalWicktes = 0;
          var playerTotalBoundaries = 0;

          teamMatches?.filter((m: any) => {
            if (m.batFirst === team._id) {
              m.batSecondScoreboard &&
                m.batSecondScoreboard.playerBowls.filter((pb: any) => {
                  if (pb.status === "RUNS" && pb.batsmanId === player._id) {
                    playerTotalRuns = playerTotalRuns + pb.runs;

                    if (pb.runs === 4 || pb.runs === 6) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                    }
                  }

                  if (pb.status === "WICKET" && pb.bowlerId === player._id) {
                    playerTotalWicktes = playerTotalWicktes + 1;
                  }
                });
            } else {
              m.batFirstScoreboard &&
                m.batFirstScoreboard.playerBowls.filter((pb: any) => {
                  if (pb.status === "RUNS" && pb.batsmanId === player._id) {
                    playerTotalRuns = playerTotalRuns + pb.runs;

                    if (pb.runs === 4 || pb.runs === 6) {
                      playerTotalBoundaries = playerTotalBoundaries + 1;
                    }
                  }

                  if (pb.status === "WICKET" && pb.bowlerId === player._id) {
                    playerTotalWicktes = playerTotalWicktes + 1;
                  }
                });
            }
          });

          allPlayers.push({
            name: formatPlayerName(player.name),
            playerTotalRuns: playerTotalRuns,
            playerTotalWicktes: playerTotalWicktes,
            playerTotalBoundaries: playerTotalBoundaries,
          });
        });
      });

    return allPlayers;
  };
  const getMostRuns = () => {
    var allPlayers = getAllPlayers();
    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalRuns,
        nameB = b.playerTotalRuns;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };

  const getMostWickets = () => {
    var allPlayers: any = getAllPlayersWickets();

    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalWicktes,
        nameB = b.playerTotalWicktes;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };

  const getMostBoundaries = () => {
    var allPlayers: any = getAllPlayers();

    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalBoundaries,
        nameB = b.playerTotalBoundaries;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };
  const getMostFours = () => {
    var allPlayers: any = getAllPlayers();

    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalFourses,
        nameB = b.playerTotalFourses;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };
  const getMostSixes = () => {
    var allPlayers: any = getAllPlayers();

    allPlayers.sort(function (a: any, b: any) {
      var nameA = a.playerTotalSixes,
        nameB = b.playerTotalSixes;

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });

    return allPlayers;
  };
  return (
    <>
      <div className="main-container">
        <div className="container-fluid bg-white pb-3 border2-r-10">
          <div className="container">
            <div className="row">
              <div className="row w-100 h-25 px-4 d-flex justify-content-start">
                <div className="col-md-3 t-name">
                  Tournament name :{" "}
                  <span className="font-weight-bold">
                    &nbsp;{tournamentData?.name}
                  </span>
                </div>

                <div className="col-md-2 t-teams-count">
                  No. of teams :{" "}
                  <span className="font-weight-bold">
                    &nbsp;{tournamentData?.numberOfTeams}
                  </span>
                </div>

                <div className="col-md-3 t-overs-count">
                  Overs per inning :{" "}
                  <span className="font-weight-bold">
                    &nbsp;{tournamentData?.oversPerMatch}
                  </span>
                </div>
                <div className="col-md-3 t-overs-count">
                  No. of Team members :{" "}
                  <span className="font-weight-bold">
                    &nbsp;{tournamentData?.numberOfTeamMembers}
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="container-fluid mt-3 pl-pr-0">
                  <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box  bg-white border-w border-r-10">
                    <div className="row w-100 h-25 d-flex justify-content-start center-align-431">
                      <NavLink
                        className="navlink-hcolor col-md-2  t-matches-count btns mb-431"
                        to={`/admin/tournament/${tournamentname}/${tournamentId}`}
                      >
                        <div>Tournament Dashboard</div>
                      </NavLink>

                      <NavLink
                        className="navlink-hcolor col-md-2 t-matches-count btns mb-431"
                        to={`/admin/matches/${tournamentId}`}
                      >
                        <div>Matches</div>
                      </NavLink>

                      <NavLink
                        className="navlink-hcolor col-md-2 t-matches-count btns mb-431"
                        to={`/admin/teams/${tournamentId}`}
                      >
                        <div>Teams</div>
                      </NavLink>

                      <NavLink
                        to={`/admin/points-table/${tournamentId}`}
                        className="col-md-2 t-name btns mb-431"
                      >
                        <div>Points Table</div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-white pt-3 pb-3 border2-r-10 mt-5">
          <div className="table-responsive">
            <table className="table">
              <thead className="table-head-matches ">
                <tr className="text-center">
                  <th scope="col">Team</th>
                  <th scope="col">Played</th>
                  <th scope="col">Won</th>
                  <th scope="col">Lost</th>
                  <th scope="col">Tied</th>
                  <th scope="col">Points</th>
                  <th scope="col">NRR</th>
                </tr>
              </thead>
              <tbody className="table-body-matches ">
                {getTeamStatistics()?.map((team: any, index: any) => (
                  <tr key={index} className="text-center">
                    <td>{team.name}</td>
                    <td>{team.playedMatches}</td>
                    <td>{team.wonMatches}</td>
                    <td>{team.lostMatches}</td>
                    <td>{team.tiedMatches}</td>
                    <td>{team.points}</td>
                    <td>{team.nrr}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="container ">
            <div className="row">
              <div className="col-lg mt-4">
                <div className="table-responsive">
                  <table className="table points-table">
                    <thead className="table-head-matches ">
                      <tr className="text-center">
                        <th scope="col" className="f-17 fw-500">
                          Most Runs
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {getMostRuns()?.map((player: any, index: any) => (
                        <tr
                          className="d-flex justify-content-between flex-wrap pl-3 pr-3"
                          key={index}
                        >
                          <td className="f-17 fw-400 ptl-4 ml-1">
                            {player.name}
                          </td>
                          <td className="f-17 fw-400 ptl-4 mr-1">
                            {player.playerTotalRuns}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg mt-4">
                <div className="table-responsive">
                  <table className="table points-table">
                    <thead className="table-head-matches">
                      <tr className="text-center">
                        <th scope="col" className="f-17 fw-500">
                          Most Wickets
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {getMostWickets()?.map((player: any, index: any) => (
                        <tr
                          className="d-flex justify-content-between flex-wrap pl-3 pr-3"
                          key={index}
                        >
                          <td className="f-17 fw-400 ptl-4 ml-1">
                            {player.name}
                          </td>
                          <td className="f-17 fw-400 ptl-4 mr-1">
                            {player.playerTotalWicktes}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg mt-4">
                <div className="table-responsive">
                  <table className="table points-table">
                    <thead className="table-head-matches">
                      <tr className="text-center">
                        <th scope="col" className="f-17 fw-500">
                          Most Boundaries
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {getMostBoundaries()?.map((player: any, index: any) => (
                        <tr
                          className="d-flex justify-content-between flex-wrap pl-3 pr-3"
                          key={index}
                        >
                          <td className="f-17 fw-400 ptl-4 ml-1">
                            {player.name}
                          </td>
                          <td className="f-17 fw-400 ptl-4 mr-1">
                            {player.playerTotalBoundaries}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg mt-4">
                <div className="table-responsive">
                  <table className="table points-table">
                    <thead className="table-head-matches">
                      <tr className="text-center">
                        <th scope="col" className="f-17 fw-500">
                          Most Fours
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {getMostFours()?.map((player: any, index: any) => (
                        <tr
                          className="d-flex justify-content-between flex-wrap pl-3 pr-3"
                          key={index}
                        >
                          <td className="f-17 fw-400 ptl-4 ml-1">
                            {player.name}
                          </td>
                          <td className="f-17 fw-400 ptl-4 mr-1">
                            {player.playerTotalFourses}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg mt-4">
                <div className="table-responsive">
                  <table className="table points-table">
                    <thead className="table-head-matches">
                      <tr className="text-center">
                        <th scope="col" className="f-17 fw-500">
                          Most Sixes
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      {getMostSixes()?.map((player: any, index: any) => (
                        <tr
                          className="d-flex justify-content-between flex-wrap pl-3 pr-3"
                          key={index}
                        >
                          <td className="f-17 fw-400 ptl-4 ml-1">
                            {player.name}
                          </td>
                          <td className="f-17 fw-400 ptl-4 mr-1">
                            {player.playerTotalSixes}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PointsTable;
