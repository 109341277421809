import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AdminService } from "../../../services/AdminService";
import Edit from "../../vendors/images/icon/edit.png";
import Eye from "../../vendors/images/icon/eye.png";
import Delete from "../../vendors/images/icon/bucket.png";
import swal from "sweetalert";

type QuickTeamsTableProps = {
  userId: string;
  team: {
    _id: string;
    name: string;
    userId: string;
    matchId: string;
    players: [
      {
        _id: string;
        name: string;
        type: string;
        createdAt: string;
      }
    ];
    createdAt: string;
  };
  forKey: number;
};

const QuickTeamsTable: React.FC<QuickTeamsTableProps> = (
  QuickTeamsTableProps
) => {
  const [quickTeamsData, setQuickTeamsData] = useState<any[]>();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isUpdateTeam, setIsUpdateTeam] = useState<boolean>(false);

  useEffect(() => {
    if (QuickTeamsTableProps?.userId) {
      console.log("user Id in Quick temas" + QuickTeamsTableProps?.userId);
      AdminService.getTeamsByUserId(QuickTeamsTableProps?.userId).then(
        (res) => {
          if (res.success) {
            console.log(res);
            setQuickTeamsData(res.data);
            console.log("quickTeamsData", res.data);
          } else {
            console.log("error", res.error);
          }
        }
      );
    } else {
      console.log("No user id +not found");
    }
  }, []);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  return (
    <tr className="team-row" key={QuickTeamsTableProps.forKey}>
      <td>{QuickTeamsTableProps.team.createdAt}</td>
      <td>{QuickTeamsTableProps?.team?.name}</td>
      <td>{QuickTeamsTableProps?.team?.players.length}</td>

      <td className="">
        <span className="mr-3 fw-600  cursor-p view-t" onClick={togglePopup}>
          <img className=" cursor-p mr-3 mt-3" src={Eye} alt="" />
        </span>
        {isPopupVisible && (
          <>
            <div className="popup-overlay bg-tr">
              <div className="popup-content">
                <p className="font-weight-bold f-16">
                  Team: {QuickTeamsTableProps?.team?.name}
                </p>
                <table className="table">
                  <thead className="table-head-matches ">
                    <tr className="text-center">
                      <th>Players</th>
                    </tr>
                  </thead>
                  <tbody className="table-body-matches">
                    {QuickTeamsTableProps?.team?.players?.map((player: any) => (
                      <tr
                        className="team-row"
                        key={QuickTeamsTableProps.forKey}
                      >
                        <td className="text-center">{player.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button className="close-popup" onClick={togglePopup}>
                  Close
                </button>
              </div>
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

export default QuickTeamsTable;
