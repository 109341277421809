import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../context/UserContext";
import "../vendors/styles/cricketAppStyles.css";
import { AdminService } from "../../services/AdminService";
import { useParams, NavLink, useHistory } from "react-router-dom";
import PlayIcon from "../vendors/images/icon/fi_play.png";
import CustomModal from "../common/Modal";
import RightArrow from "../vendors/images/icon/right-arrow.png";
import swal from "sweetalert";
import Close from "../vendors/images/icon/x.png";
import PlusIcon from "../vendors/images/icon/plus.png";
import { TeamMemberData } from "../../models/Team";
import Edit from "../vendors/images/icon/edit.png";
import Remove from "../vendors/images/icon/remove.png";
import TournamentContext from "../../context/TournamentContext";
import Eye from "../vendors/images/icon/eye.png";

interface RouteParams {
  tournamentId: string;
}
const Teams: React.FC = () => {
  const urlOfThisPage = window.location.href;
  const splitFromSlash = urlOfThisPage.split("/");
  const getTeamsFromSplittedUrl = splitFromSlash[splitFromSlash.length - 2];
  const [user] = useContext(UserContext);
  const { tournamentId } = useParams<RouteParams>();
  const [tournament] = useContext(TournamentContext);
  const [isUpdateTeam, setIsUpdateTeam] = useState<boolean>(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const tournamentname = tournament?.name;
  const [teamList, setTeamList] = useState<any[]>([]); // State to hold all teams
  const [playerList, setPlayerList] = useState<any[]>([]); // State to hold all teams
  const [currentTeam, setCurrentTeam] = useState<Team | null>(null);
  const [tournamentData, setTournamentData] = useState<any>();
  const [totalMatchCount, setTotalMatchCount] = useState<any>();
  const [teamsData, setTeamsData] = useState<any[]>();
  const [teamsOnly, setTeamsOnly] = useState([
    /* teams data fetched from API */
  ]);

  const [matchCountArray, setMatcountArray] = useState<any[]>([]);
  const [teamMatchCounts, setTeamMatchCounts] = useState({});
  useEffect(() => {
    let isMounted = true;
    if (tournamentId) {
      getTournamentData();
      getTournamentMatches();
      updateNumberOfMatchesperTeam();

      console.log("Tournament id in teams " + tournamentId);
      AdminService.getTeamsByTournamentId(tournamentId).then((res) => {
        if (isMounted) {
          if (res.success) {
            console.log(res);
            const teamsArray = res.data.map((teamData) => {
              const { _id, name, tournamentId, matchesCount, createdAt } =
                teamData;

              AdminService.getPlayersByTeamId(_id)
                .then((res: any) => {
                  if (res.success) {
                    console.log("Player ''''''", res.data);
                    setPlayerList(res.data);
                    setPlayerList((prevPlayers) => [...prevPlayers, res.data]);
                    const player = res.data;
                    const mappedPlayers = player.map(
                      (player: {
                        _id: any;
                        name: any;
                        teamId: any;
                        createdAt: any;
                      }) => {
                        const createdDate = new Date(player.createdAt);

                        const formattedDate = createdDate.toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }
                        );

                        return {
                          _id: player._id,
                          name: player.name,
                          teamId: player.teamId,
                          createdAt: formattedDate,
                        };
                      }
                    );
                    const createdDate = new Date(createdAt);

                    const formattedDate = createdDate.toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    );
                    const team = {
                      _id: _id,
                      name: name,
                      tournamentId: tournamentId,
                      // matchId: matchId,
                      players: mappedPlayers,
                      createdAt: formattedDate,
                      matchesCount: matchesCount,
                    };

                    console.log("Team :::::", team);
                    setTeamList((prevTeams) => [...prevTeams, team]);
                  } else {
                    console.log("error", res.error);
                  }
                })
                .catch((err: any) => {
                  console.log("error", err);
                });
            });
          } else {
            console.log("error", res.error);
          }
        }
      });
    } else {
      console.log("No user id + not found");
    }

    return () => {
      isMounted = false;
    };
  }, [tournamentId]);

  const togglePopup = (team: any) => {
    if (team) {
      setCurrentTeam(team);
    } else {
      setCurrentTeam(null);
    }
  };

  const getTournamentData = () => {
    AdminService.getTournamentDataByTournamentId(tournamentId).then((res) => {
      if (res.success) {
        setTournamentData(res.data);
        console.log("tournament data ", res.data);
        setTotalMatchCount(
          res?.data?.leagueTotalMatches + res?.data?.semiFinalMatches
        );
      } else {
        console.log("error", res.error);
      }
    });
  };
  const getTournamentMatches = () => {
    AdminService.getMatchesByTournamentId(tournamentId).then((res) => {
      if (res.success) {
        setTeamsData(res.data.map((match) => match.teams));
        console.log("matches data ;;;; ", res.data);
        let teamsOnly = res.data.map((match) => match.teams);

        console.log(teamsOnly);

        if (teamsOnly.length > 0) {
          // Flatten the teamsOnly array
          const flattenedTeams = teamsOnly.flat();

          // Initialize an object to store match counts
          let matchCounts: any = {};

          // Iterate over each team and count matches
          flattenedTeams.forEach((team) => {
            let teamId = team._id;
            matchCounts[teamId] = (matchCounts[teamId] || 0) + 1;
          });

          // Update state with the match counts
          setTeamMatchCounts(matchCounts);
          console.log("match count ", matchCounts);

          const objectArray = [];

          for (const key in matchCounts) {
            if (matchCounts.hasOwnProperty(key)) {
              const value = matchCounts[key];
              const newObj = { [key]: value };
              objectArray.push(newObj);
            }
          }
          console.log(
            "object arra ;;;;=========================== ",
            objectArray
          );

          setMatcountArray(objectArray);

          AdminService.updateTeamMatchCount(matchCounts).then((res) => {
            if (res.success) {
              console.log("res sucess");
            } else {
              console.log("error", res.error);
            }
          });
        }
      }
    });
  };

  const updateNumberOfMatchesperTeam = () => {
    // let matchCounts = {};
    // teamsData.forEach(match => {
    //     match.forEach(team => {
    //         let teamId = team._id;
    //         if (matchCounts[teamId]) {
    //             matchCounts[teamId]++;
    //         } else {
    //             matchCounts[teamId] = 1;
    //         }
    //     });
    // });
    // console.log(matchCounts);
  };

  // const togglePopup = () => {
  //   setPopupVisible(!isPopupVisible);
  // };

  const updateTeam = (team: any) => {
    setIsUpdateTeam(true);
  };

  const deleteTeam = () => {
    if (true) {
      swal({
        title: "Are you sure?",
        text: " You want to delete the team?",
        icon: "info",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
        className: "custom-swal",
      }).then((confirmed) => {
        if (confirmed) {
          // setSilverButtonText("Requested");
          // setSilverDisabled(true);
          // setGoldDisabled(false);
          // setGoldButtonText("Upgrade to Gold");
        }
      });
    }
  };

  return (
    <>
      <div className="main-container">
        <div className="container-fluid bg-white pb-3 border2-r-10">
          <div className="container">
            <div className="row">
              <div className="row w-100 h-25 px-4 d-flex justify-content-start">
                <div className="col-md-3 t-name">
                  Tournament name :{" "}
                  <span className="font-weight-bold">
                    &nbsp;{tournamentData?.name}
                  </span>
                </div>

                <div className="col-md-2 t-teams-count">
                  No. of teams :{" "}
                  <span className="font-weight-bold">
                    &nbsp;{tournamentData?.numberOfTeams}
                  </span>
                </div>

                <div className="col-md-3 t-overs-count">
                  Overs per inning :{" "}
                  <span className="font-weight-bold">
                    &nbsp;{tournamentData?.oversPerMatch}
                  </span>
                </div>
                <div className="col-md-3 t-overs-count">
                  No. of Team members :{" "}
                  <span className="font-weight-bold">
                    &nbsp;{tournamentData?.numberOfTeamMembers}
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <div className="container-fluid mt-3 pl-pr-0">
                  <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box  bg-white border-w border-r-10">
                    <div className="row w-100 h-25 d-flex justify-content-start center-align-431">
                      <NavLink
                        className="navlink-hcolor col-md-2  t-matches-count btns mb-431"
                        to={`/admin/tournament/${tournamentname}/${tournamentId}`}
                      >
                        <div>Tournament Dashboard</div>
                      </NavLink>

                      <NavLink
                        className="navlink-hcolor col-md-2  t-matches-count btns mb-431"
                        to={`/admin/matches/${tournamentId}`}
                      >
                        <div>Matches</div>
                      </NavLink>

                      <div className="col-md-2  t-name btns  mb-431">
                        <NavLink to={`/admi n/teams/${tournamentId}`}>
                          Teams
                        </NavLink>
                      </div>

                      <NavLink
                        className="navlink-hcolor col-md-2 t-matches-count btns mb-431"
                        to={`/admin/points-table/${tournamentId}`}
                      >
                        <div>Points Table</div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-white pt-3 pb-3 border2-r-10 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="container-fluid mt-3 pl-pr-0">
                  <div className="d-flex w-100 h-auto justify-content-center align-items-center outer-box bg-white border-w border-r-10">
                    <div className="table-responsive">
                      <p className="font-weight-bold">Team Summary</p>

                      <table className="table">
                        <thead className="table-head-matches ">
                          <tr className="text-center">
                            <th>Date</th>
                            <th>Team Name</th>
                            <th>Number of Players</th>
                            <th>Captain</th>

                            <th>Number Of Matches</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody className="table-body-matches">
                          {/* <tr className="team-row">
                       
                            <td>te</td>
                            <td>Efito</td>
                            <td className="team-sec">6</td>
                            <td>Hiran</td>

                            <td>2</td>
                            <td className="">1</td>
                            <td className="">3</td>

                            <td className="">
                              <span
                                className="mr-3 fw-600  cursor-p view-t"
                                onClick={togglePopup}
                              >
                                View Team
                              </span>
                              {isPopupVisible && (
                                <div className="popup-overlay bg-tr">
                                  <div className="popup-content">
                                    <p className="font-weight-bold f-16">
                                      Sri Lanka
                                    </p>
                               
                                    <table className="table">
                                      <thead className="table-head-matches ">
                                        <tr className="text-center">
                                          <th>Name</th>
                                          <th>Type</th>

                                          <div className="w-100 d-flex justify-content-between align-item-center">
                                         
                                            <span className="f-16">
                                           
                                            </span>
                                            <span>
                                     
                                            </span>
                                          </div>
                                        </tr>
                                      </thead>
                                      <tbody className="table-body-matches">
                                        <tr className="team-row">
                                          <td>Kavinda</td>
                                          <td>(C)</td>
                                        </tr>
                                        <tr className="team-row">
                                          <td>Chamal</td>
                                          <td>(WK)</td>
                                        </tr>
                                        <tr className="team-row">
                                          <td>Sadeepa</td>
                                          <td>Type</td>
                                        </tr>
                                        <tr className="team-row">
                                          <td>Ruwan</td>
                                          <td>Type</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <button
                                      className="close-popup"
                                      onClick={togglePopup}
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              )}
                   

                              <NavLink
                                to={`/admin/my-matches/create-quick-teams/${user?._id}/Update`}
                              >
                                <img
                                  className="edit-icon-size cursor-p mr-3 mt-2"
                                  src={Edit}
                                  alt=""
                                  onClick={() => updateTeam(true)}
                                />
                              </NavLink>
                              <button
                                className="btn btn-danger btn-sm m"
                                onClick={deleteTeam}
                              >
                                <i className="micon dw dw-trash"></i>
                              </button>
                            </td>
                          </tr>
                           */}

                          {teamList.map((team, index) => (
                            <tr key={index} className="team-row">
                              <td>
                                {new Date(team.createdAt).toLocaleDateString()}
                              </td>
                              <td>{team.name}</td>
                              <td className="team-sec">
                                {/* {tournament.leagueTotalMatches} */}
                              </td>
                              <td>
                                {/* {tournament.numberOfTeams} */}
                                </td>
                              <td>{team.matchesCount}</td>
                              <td>
                                <span
                                  className="mr-3 fw-600  cursor-p view-t"
                                  // onClick={togglePopup}
                                  onClick={() => togglePopup(team)}
                                >
                                  {/* View Team */}
                                  <img
                                    className=" cursor-p  ml-3 mt-2"
                                    src={Eye}
                                    alt=""
                                  />
                                </span>
                                {currentTeam && (
                                  <div className="popup-overlay bg-tr">
                                    <div className="popup-content">
                                      <p className="font-weight-bold f-16">
                                        {/* {teamList.map((team, index) => (team.name))} */}
                                        {/* {team.name} */}
                                        {currentTeam.name}
                                      </p>

                                      <table className="table">
                                        <thead className="table-head-matches ">
                                          <tr className="text-center">
                                            <th>Name</th>
                                            <th>Type</th>

                                            <div className="w-100 d-flex justify-content-between align-item-center">
                                              <span className="f-16"></span>
                                              <span></span>
                                            </div>
                                          </tr>
                                        </thead>
                                        <tbody className="table-body-matches">
                                          {/* {playerList.map(
                                  (player, index: number) => (
                                    <tr key={index} className="team-row">
                                     
                                      <td>{player.name}</td>
                                      <td className="team-sec">
                                        {tournament.leagueTotalMatches}
                                      </td>
                                  
                                    </tr>

                                        
                                  ))} */}

                                          {currentTeam.players.map(
                                            (player, index) => (
                                              <tr
                                                key={index}
                                                className="team-row"
                                              >
                                                <td>{player.name}</td>
                                                <td>
                                                  {/* Player type or other attribute here */}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                      <button
                                        className="close-popup"
                                        onClick={() => togglePopup(null)}
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                )}

                                {/* <NavLink
                                  to={`/admin/my-matches/create-quick-teams/${user?._id}/Update`}
                                >
                                  <img
                                    className="edit-icon-size cursor-p mr-3 mt-2"
                                    src={Edit}
                                    alt=""
                                    onClick={() => updateTeam(true)}
                                  />
                                </NavLink> */}
                                {/* <button
                                  className="btn btn-danger btn-sm m"
                                  onClick={deleteTeam}
                                >
                                  <i className="micon dw dw-trash"></i>
                                </button> */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {/* <table className="table">
                        <thead className="table-head-matches ">
                          <tr className="text-center">
                            <th>Tournament Name</th>
                            <th>Type</th>
                            <th>Teams</th>
                            <th>Total</th>
                            <th>Live Scoreboard</th>
                            <th></th>
                            <th>Result</th>
                          </tr>
                        </thead>
                        <tbody className="table-body-matches">
                          <tr className="team-row">
                            <td>Match</td>
                            <td>gdh</td>
                            <td className="team-sec">
                              {
                                <>
                                  <div className="d-flex flex-wrap justify-content-center align-items-center matches-team-label">
                                    <span className="m-1">ghjh</span>
                                  </div>
                                </>
                              }

                              <>-</>
                            </td>

                            <td>dfghfg</td>
                            <td className="cursor-pointer">dfgdf</td>

                            <td>sdfgdfg</td>

                            <td>
                              <div className="win-status">dfgdf</div>
                            </td>
                          </tr>
                        </tbody>
                      </table> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Teams;
