import React, { useEffect, useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { AuthService } from "../../services/AuthService";
import { RequestState } from "../../RequestState";
import swal from "sweetalert";
import { RouteName } from "../../RouteName";
import "../vendors/styles/cricketAppStyles.css";
import loginImageLeft from "../../components/vendors/images/loginImageLeft.svg";
import loginImageRight from "../../components/vendors/images/loginImageRight.svg";
import loginCardImage from "../../components/vendors/images/loginCardImage.svg";
import userIconLogin from "../../components/vendors/images/userIconLogin.svg";
import emailIconLogin from "../../components/vendors/images/emailIconLogin.svg";
import userLoginPasswordIcon from "../../components/vendors/images/userLoginPasswordIcon.svg";
import { LoginData } from "../../models/LoginModel";
import RightArrow from "../vendors/images/icon/right-arrow.png";
import CustomModal from "./Modal";
import { PublicService } from "../../services/PublicService";
import Logo from "../vendors/images/icon/logo2.png";
import { AdminService } from "../../services/AdminService";
const Login: React.FC = () => {
  const token = AuthService.getToken();
  const history = useHistory();
  let loginData: LoginData = {
    email: "",
    password: "",
    loginMethod: "",
    remember: "",
  };

  const [remember, setRemember] = useState(false);
  const [userData, setUserData] = useState(loginData);
  const [error, setError] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialState = {
    email: "",
    name: "",
    message: "",
  };
  const [demoEmail, setDemoEmail] = useState<any>(initialState);
  const [loginRequestState, setLoginRequestState] = useState<RequestState>(
    RequestState.INITIAL
  );

  useEffect(() => {
    function getCookie(name: any) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          const index = cookie.indexOf("=");
          if (cookie.substring(0, index) === name) {
            cookieValue = cookie.substring(index + 1);
            break;
          }
        }
      }
      return cookieValue;
    }
    const selectedPackage = getCookie("selectedPackage");
    console.log("selectedPackage inside login=>", selectedPackage);
  });

  useEffect(() => {
    if (loginRequestState === RequestState.LOADING) {
      AuthService.userLogin(userData)
        .then(async (res) => {
          if (res.success) {
            AuthService.setToken(res.data.token);
            setLoginRequestState(RequestState.SUCCESS);
          } else {
            setError(res.error);
            setLoginRequestState(RequestState.FAILED);
          }
        })
        .catch((e) => {
          setError(e);
          setLoginRequestState(RequestState.FAILED);
        });
    } else if (loginRequestState === RequestState.FAILED) {
      swal({ title: "User login fail!", icon: "error" });
    }
  }, [loginRequestState]);

  const handleChange = (event: any) => {
    // console.log(event.target.checked);
  };

  const submitLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setUserData({
      email: (
        event.currentTarget.elements.namedItem("email") as HTMLInputElement
      ).value,
      password: (
        event.currentTarget.elements.namedItem("password") as HTMLInputElement
      ).value,
      loginMethod: "EMAIL",
      remember: remember ? "TRUE" : "FALSE",
    });
    setLoginRequestState(RequestState.LOADING);
  };

  const verifyUser = async () => {
    try {
      const res = await AuthService.getMe();
      if (res.success) {
        const { userType, userStatus, isVerified, _id } = res.data;
        if (userStatus === "ACTIVE") {
          switch (userType) {
            case "SUPER_ADMIN":
              history.push(RouteName.ADMIN_USER_MANAGEMENT);
              break;
            case "LEVEL01":
            case "LEVEL02":
              history.push(RouteName.ADMIN_DASHBOARD);
              break;
            default:
              break;
          }
        } else {
          if (!isVerified) {
            for (const key in sessionStorage) {
              if (key !== "selectedPackage") {
                sessionStorage.removeItem(key);
              }
            }
            sessionStorage.clear();
            swal({ icon: "error", title: "User not verified yet!" });
          }
        }
      } else {
        for (const key in sessionStorage) {
          if (key !== "selectedPackage") {
            sessionStorage.removeItem(key);
          }
        }
        sessionStorage.clear();
        swal({ icon: "error", title: "User not verified yet!" });
      }
    } catch (error) {
      swal({ icon: "error", title: "User not verified yet!" });

      console.error("Error while verifying user:", error);
    }
  };

  if (token || loginRequestState === RequestState.SUCCESS) {
    verifyUser();
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const sendMail = () => {
    if (demoEmail?.email && demoEmail?.name) {
      const data = {
        email: demoEmail?.email,
        message: demoEmail?.message,
        name: demoEmail?.name,
      };
      //  console.log("data==>", data);
      PublicService.requestDemo(data).then((res) => {
        if (res.success) {
          // console.log("res==>", res);

          swal({ icon: "success", title: "Send Successfully!" }).then(
            function () {
              handleCloseModal();
            }
          );
        } else {
          swal({
            icon: "error",
            title: res?.error ? res.error : "Something went wrong",
          });
        }
      });
    } else {
      swal({ icon: "error", title: "Please fill all fields" });
    }
  };

  return (
    <>
      <div className="login-page">
        <div className="left-bg">
          <img src={loginImageLeft} alt="Left Background" />
        </div>
        <div className="right-bg">
          <img src={loginImageRight} alt="Right Background" />
        </div>

        <div className="container">
          <div className="login-body d-lg-flex text-center ">
            <div className="box-1 mt-md-0 ">
              <div className="mt-5 d-flex justify-content-center">
                <div className="login-form ">
                  <div className="mb-4">
                    <NavLink to={"/home"}>
                      <img
                        src={Logo}
                        className="main-logo"
                        alt="cricView360_logo"
                      />
                    </NavLink>
                  </div>
                  <form onSubmit={submitLogin}>
                    <p className="mb-1 h-1 text-center login-header">Login</p>

                    <div className="textbox mt-5">
                      <img
                        src={emailIconLogin}
                        alt="Input Icon"
                        className="input-icon"
                      />
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        required
                      />
                    </div>
                    <div className="textbox mt-3">
                      <img
                        src={userLoginPasswordIcon}
                        alt="Input Icon"
                        className="input-icon"
                      />
                      <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        required
                      />
                    </div>
                    <div className="d-lg-flex justify-content-between mt-4 mb-3">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          id="lightBlueCheckbox"
                          className="custom-checkbox"
                          onChange={handleChange}
                        />
                        {/* <span className="custom-checkbox-icon"></span>
                        Remember me */}
                      </label>
                      {/* <p className="checkbox-label-para cursor-p">
                        Forgot your password?
                      </p> */}
                    </div>
                    <div className="d-lg-flex justify-content-center mt-4 mb-5">
                      <button className="login-btn" type="submit">
                        Login
                        <span className="fas fa-chevron-right ml-1"></span>
                      </button>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                      <span className="login-end-text mt-1">
                        Do you want to try out ?
                      </span>
                      {/* <span
                        className="ml-3 signup-btn mt-2 cursor-p"
                        onClick={() => handleOpenModal()}
                      >
                        Contact us
                      </span> */}
                      <NavLink to={"/signup"}>
                        <span className="ml-3 signup-btn mt-2 cursor-p">
                          SignUp now
                        </span>
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className=" box-2 d-flex flex-column h-100">
              <img src={loginCardImage} alt="Right Background" />
            </div>
          </div>
        </div>
      </div>
      <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <h2 className="fw-600 f-17 f-color-black3 font-Poppins ">
          Contact us{" "}
        </h2>
        <div>
          <div className="login-form ">
            <div>
              {/* <Label className="form-label mb-0 mt-4 font-Poppins">Name</Label> */}
              <div className="textbox2 mt-3 mb-2">
                <img
                  src={userIconLogin}
                  alt="Input Icon"
                  className="input-icon"
                />
                <input
                  type="text"
                  placeholder="Name..."
                  name="name"
                  required
                  onChange={(e) =>
                    setDemoEmail({
                      ...demoEmail,
                      name: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div>
              {/* <Label className="form-label mb-0 mt-2 font-Poppins">Email</Label> */}
              <div className="textbox2 mt-3">
                <img
                  src={emailIconLogin}
                  alt="Input Icon"
                  className="input-icon"
                />
                <input
                  type="email"
                  placeholder="Email..."
                  name="email"
                  required
                  onChange={(e) =>
                    setDemoEmail({
                      ...demoEmail,
                      email: e.target.value,
                    })
                  }
                />
              </div>
              <div className="textbox3 mt-3">
                {/* <img
                  src={emailIconLogin}
                  alt="Input Icon"
                  className="input-icon"
                /> */}
                <textarea
                  placeholder="Message..."
                  name="message"
                  rows={3}
                  className="textarea-border-none"
                  required
                  onChange={(e) =>
                    setDemoEmail({
                      ...demoEmail,
                      message: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div></div>
          </div>
          <div className="row pt-2 pl-3 pr-3 d-flex justify-content-center align-items-center mt-3">
            <div className="arrow-btn2" onClick={() => sendMail()}>
              <span className="f-17 f-color-white fw-500 font-Poppins">
                Send
              </span>
              <img className="arrow-icon-size ml-3" src={RightArrow} alt="" />
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Login;
