import React, { useEffect, useState, useContext } from "react";
import { AuthService } from "../../../services/AuthService";
import { RequestState } from "../../../RequestState";
import swal from "sweetalert";
import "../../vendors/styles/cricketAppStyles.css";
import cricketBall from "../../../components/vendors/images/cricketBall.svg";
import cricketBat from "../../../components/vendors/images/cricketBat.svg";
import { useParams } from "react-router-dom";
import { MatchService } from "../../../services/MatchService";
import BallScore from "../../common/dashboardComponent/BallScoreCardComponent";
import { useHistory } from "react-router-dom";
import CustomModal from "../../common/Modal";
import RightArrow from "../../vendors/images/icon/right-arrow.png";
import { AdminService } from "../../../services/AdminService";
import TournamentContext from "../../../context/TournamentContext";
import Logo from "../../vendors/images/icon/logo.png";
import { Role } from "../../../models/Role";
import UserContext from "../../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const QuickDashboard: React.FC = () => {
  const [user] = useContext(UserContext);
  const initialState = { email: "", password: "" };
  const [loginRequestState, setLoginRequestState] = useState<RequestState>(
    RequestState.INITIAL
  );
  const history = useHistory();
  const { matchId } = useParams<{ matchId?: string }>();

  const [match, setMatch] = useState<any>(null);
  const [currentBatsman1, setCurrentBatsman1] = useState("");
  const [currentBatsman2, setCurrentBatsman2] = useState("");
  const [currentBowler, setCurrentBowler] = useState("");
  const [ballRuns, setBallRuns] = useState(0);
  const [currentBat, setCurrentBat] = useState("");
  const [runStatus, setRunStatus] = useState("RUNS");
  const [ballExtraRuns, setBallExtraRuns] = useState(0);
  const [extraRunType, setExtraRunType] = useState("");
  const [battingFirstTeam, setbattingFirstTeam] = useState("");
  const [ballingFirstTeam, setballingFirstTeam] = useState("");
  const splittedURL = window.location.pathname.split("/");
  const matchLiveId = splittedURL[splittedURL.length - 1];
  const [tournament, setTournament] = useContext(TournamentContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [battingFirstTeamMembers, setbattingFirstTeamMembers] = useState<any[]>(
    []
  );
  const [ballingFirstTeamMembers, setballingFirstTeamMembers] = useState<any[]>(
    []
  );
  const [currentBat1, setCurrentBat1] = useState("");
  const [currentBat2, setCurrentBat2] = useState("");
  const [currentBall, setCurrentBall] = useState("");
  const [currentBatsmanPair, setCurrentBatsmanPair] = useState<any[]>([]);
  const [tournamentId, setTournamentId] = useState<any>(null);
  const [userId, setUserId] = useState<any>(null);
  useEffect(() => {
    console.log("inside quick dashboard");
    console.log("match id" + matchId);
    // fetchTournament();
    fetchUser();
  }, [matchId]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // const fetchTournament = async () => {
  //   try {
  //     console.log("live match " + matchLiveId);
  //     const response = await MatchService.getMatchDetails(matchLiveId);

  //     if (response.success === true) {
  //       const matchData = response;
  //       setMatch(response.data);
  //       const tournamentId = response.data?.tournamentId?._id;
  //       setTournamentId(tournamentId);
  //       setUserId(userId);
  //       if (
  //         response.data?.batFirstScoreboard &&
  //         response.data?.batFirstScoreboard?.currentlyBatting?.length > 0
  //       ) {
  //         console.log("true");
  //       } else {
  //         // alert("false")
  //         const batFirstTeam = matchData.data.teams.filter(
  //           (t: any) => t.id === matchData.data.batFirst
  //         );
  //         setbattingFirstTeamMembers(batFirstTeam[0].players);
  //         const bollFirstTeam = matchData.data.teams.filter(
  //           (t: any) => t.id != matchData.data.batFirst
  //         );
  //         setballingFirstTeamMembers(bollFirstTeam[0].players);
  //         handleOpenModal();
  //       }

  //       if (
  //         matchData.data.batFirstScoreboard &&
  //         matchData.data.batFirstScoreboard.currentlyBatting.length > 0
  //       ) {
  //         setCurrentBatsman1(
  //           matchData.data.batFirstScoreboard.currentlyBatting[0]
  //         );
  //       }

  //       if (
  //         matchData.data.batFirstScoreboard &&
  //         matchData.data.batFirstScoreboard.currentlyBatting.length > 1
  //       ) {
  //         setCurrentBatsman2(
  //           matchData.data.batFirstScoreboard.currentlyBatting[1]
  //         );
  //       }

  //       if (
  //         matchData.data.batFirstScoreboard &&
  //         matchData.data.batFirstScoreboard.currentlyBowling
  //       ) {
  //         setCurrentBowler(matchData.data.batFirstScoreboard.currentlyBowling);
  //       }

  //       setbattingFirstTeam(
  //         batFirstTeam(matchData.data.batFirst, matchData.data.teams).name
  //       );

  //       setballingFirstTeam(
  //         ballFirstTeam(matchData.data.batFirst, matchData.data.teams).name
  //       );
  //       getCurrentBatsmanPair(matchData.data);
  //       getOverCountAlert();
  //     } else {
  //       console.error("Failed to fetch match data");
  //     }
  //   } catch (error) {
  //     console.error("Error while fetching match data:", error);
  //   }
  // };
  const fetchUser = async () => {
    try {
      console.log("live match (inside fetch user TRY)" + matchLiveId);
      const response = await MatchService.getQuickMatchDetails(matchLiveId);

      console.log("response from fetch user", response);

      if (response.success === true) {
        console.log("inside fetch user response success if");
        const matchData = response;
        setMatch(response.data);
        const userId = response.data?.userId?._id;
        setUserId(userId);
        if (
          response.data?.batFirstScoreboard &&
          response.data?.batFirstScoreboard?.currentlyBatting?.length > 0
        ) {
          console.log("true");
        } else {
          // alert("false")
          const batFirstTeam = matchData.data.teams.filter(
            (t: any) => t.id === matchData.data.batFirst
          );
          setbattingFirstTeamMembers(batFirstTeam[0].players);
          const bollFirstTeam = matchData.data.teams.filter(
            (t: any) => t.id != matchData.data.batFirst
          );
          setballingFirstTeamMembers(bollFirstTeam[0].players);
          console.log("should open modal");
          handleOpenModal();
        }

        if (
          matchData.data.batFirstScoreboard &&
          matchData.data.batFirstScoreboard.currentlyBatting.length > 0
        ) {
          setCurrentBatsman1(
            matchData.data.batFirstScoreboard.currentlyBatting[0]
          );
        }

        if (
          matchData.data.batFirstScoreboard &&
          matchData.data.batFirstScoreboard.currentlyBatting.length > 1
        ) {
          setCurrentBatsman2(
            matchData.data.batFirstScoreboard.currentlyBatting[1]
          );
        }

        if (
          matchData.data.batFirstScoreboard &&
          matchData.data.batFirstScoreboard.currentlyBowling
        ) {
          setCurrentBowler(matchData.data.batFirstScoreboard.currentlyBowling);
        }

        setbattingFirstTeam(
          batFirstTeam(matchData.data.batFirst, matchData.data.teams).name
        );

        setballingFirstTeam(
          ballFirstTeam(matchData.data.batFirst, matchData.data.teams).name
        );
        getCurrentBatsmanPair(matchData.data);
        getOverCountAlert();
      } else {
        console.error("Failed to fetch match data");
      }
    } catch (error) {
      console.error("Error while fetching match data:", error);
    }
  };

  const getCurrentBatsmanPair = (matchData: any) => {
    const batsmanPairArr = matchData?.batFirstScoreboard?.currentlyBatting;
    setCurrentBatsmanPair(batsmanPairArr);
  };

  const toggleNextScoreboard = () => {
    var extraRunsBallsCount = 0;

    if (match.batFirstScoreboard == null) {
      alert("Please score on first innings first!");
      return false;
    }

    match.batFirstScoreboard.playerBowls.filter(function (pb: any) {
      if (pb.status == "WIDE" || pb.status == "NO_BALL") {
        extraRunsBallsCount = extraRunsBallsCount + 1;
      }
    });

    window.open("/score-board2/" + match._id, "_blank");
  };

  const batFirstTeam = (batFirst: any, teams: any) => {
    var batFirstTeam = teams.filter(function (t: any) {
      return t.id == batFirst;
    });

    return batFirstTeam[0];
  };

  const ballFirstTeam = (batFirst: any, teams: any) => {
    var ballFirstTeam = teams.filter(function (t: any) {
      return t.id != batFirst;
    });

    return ballFirstTeam[0];
  };

  const batSecondTeam = (batFirst: any, teams: any) => {
    var batSecondTeam = teams.filter(function (t: any) {
      return t.id == batFirst;
    });

    return batSecondTeam[0];
  };

  const getBatterFacedBalls = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }
    var playedBalls = 0;

    bowls.filter(function (b: any) {
      if (
        (b.status == "RUNS" || b.status == "WICKET" || b.status == "LEG_BYE") &&
        b.batsmanId == playerId
      ) {
        playedBalls = playedBalls + 1;
      }
    });

    return playedBalls;
  };

  const getBatter4Boundaries = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var player4Runs = 0;

    bowls.filter(function (b: any) {
      if (b.status == "RUNS" && b.batsmanId == playerId && b.runs == "4") {
        player4Runs = player4Runs + b.runs;
      }
    });

    return player4Runs / 4;
  };

  const getBatter6Boundaries = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var player6Runs = 0;

    bowls.filter(function (b: any) {
      if (b.status == "RUNS" && b.batsmanId == playerId && b.runs == "6") {
        player6Runs = player6Runs + b.runs;
      }
    });

    return player6Runs / 6;
  };

  const getOverCount = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalBalls = 0;

    currentBatsmanPair.filter(function (b: any) {
      if (b == playerId) {
        totalBalls = totalBalls + 1;
      }
    });
    var remindBalls = totalBalls % 6;
    var overs = (totalBalls - remindBalls) / 6;

    return `${overs}.${remindBalls}`;
  };

  const getBatterCurrentStatus = (playerId: any, bowls: any) => {
    // if (bowls == null) {
    //   return "-";
    // }

    var status = "yet to bat";

    currentBatsmanPair?.filter(function (b: any) {
      if (b == playerId) {
        status = "not out";
      }
    });
    bowls.filter(function (b: any) {
      if (b.status == "WICKET" && b.batsmanId == playerId) {
        ballFirstTeam(match?.batFirst, match?.teams).players.filter(function (
          p: any
        ) {
          if (p._id == b.bowlerId) {
            status = `wicket (by ${p.name})`;
          }
        });
      }
    });

    return status;
  };

  const getBatterRuns = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var playerRuns = 0;

    bowls.filter(function (b: any) {
      if (b.status == "RUNS" && b.batsmanId == playerId) {
        playerRuns = playerRuns + b.runs;
      }
    });

    return playerRuns;
  };
  const getBatFirstTotalRuns = (bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalRuns = 0;
    var totalWickets = 0;

    bowls.filter(function (b: any) {
      if (b.status != "WICKET") {
        totalRuns = totalRuns + b.runs;
      }

      if (b.status == "WICKET") {
        totalWickets = totalWickets + 1;
      }
    });

    return totalRuns + "/" + totalWickets;
  };

  const getTotalWides = (bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalWides = 0;

    bowls.filter(function (b: any) {
      if (b.status == "WIDE") {
        totalWides = totalWides + b.runs;
      }
    });

    return totalWides;
  };

  const getTotalNoBalls = (bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalNoBalls = 0;

    bowls.filter(function (b: any) {
      if (b.status == "NO_BALL") {
        totalNoBalls = totalNoBalls + b.runs;
      }
    });

    return totalNoBalls;
  };

  const getTotalLegByes = (bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var totalLegByes = 0;

    bowls.filter(function (b: any) {
      if (b.status == "LEG_BYE") {
        totalLegByes = totalLegByes + b.runs;
      }
    });

    return totalLegByes;
  };

  const ballSecondTeam = (batFirst: any, teams: any) => {
    var ballSecondTeam = teams?.filter(function (t: any) {
      return t.id == batFirst;
    });

    return ballSecondTeam ? ballSecondTeam[0] : null;
  };

  const getBowlerRuns = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var bowlerRuns = 0;

    bowls.filter(function (b: any) {
      if (b.status != "WICKET" && b.bowlerId == playerId) {
        bowlerRuns = bowlerRuns + b.runs;
      }
    });

    return bowlerRuns;
  };

  const getBowlerWickets = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var bowlerWickets = 0;

    bowls.filter(function (b: any) {
      if (b.status == "WICKET" && b.bowlerId == playerId) {
        bowlerWickets = bowlerWickets + 1;
      }
    });

    return bowlerWickets;
  };
  const getBowlerWides = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var bowlerWickets = 0;

    bowls.filter(function (b: any) {
      if (b.status == "WIDE" && b.bowlerId == playerId) {
        bowlerWickets = bowlerWickets + 1;
      }
    });

    return bowlerWickets;
  };
  const getBowlerNoBalls = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var bowlerWickets = 0;

    bowls.filter(function (b: any) {
      if (b.status == "NO_BALL" && b.bowlerId == playerId) {
        bowlerWickets = bowlerWickets + 1;
      }
    });

    return bowlerWickets;
  };
  const getBowlerDiliveredOvers = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var ballsCount = 0;

    bowls.filter(function (b: any) {
      if (
        b.status != "WIDE" &&
        b.status != "NO_BALL" &&
        b.bowlerId == playerId
      ) {
        ballsCount = ballsCount + 1;
      }
    });
    var remainBalls = ballsCount % 6;
    var overs = (ballsCount - remainBalls) / 6;
    return overs;
  };
  const getBowlerDiliveredRemainBalls = (playerId: any, bowls: any) => {
    if (bowls == null) {
      return "-";
    }

    var ballsCount = 0;

    bowls.filter(function (b: any) {
      if (
        b.status != "WIDE" &&
        b.status != "NO_BALL" &&
        b.bowlerId == playerId
      ) {
        ballsCount = ballsCount + 1;
      }
    });
    var remainBalls = ballsCount % 6;
    return remainBalls;
  };
  // const getBatterRuns = (playerId: any, bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var playerRuns = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status == "RUNS" && b.batsmanId == playerId) {
  //       playerRuns = playerRuns + b.runs;
  //     }
  //   });

  //   return playerRuns;
  // };

  // const getBowlerRuns = (playerId: any, bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var bowlerRuns = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status != "WICKET" && b.bowlerId == playerId) {
  //       bowlerRuns = bowlerRuns + b.runs;
  //     }
  //   });

  //   return bowlerRuns;
  // };

  // const getBowlerWickets = (playerId: any, bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var bowlerWickets = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status == "WICKET" && b.bowlerId == playerId) {
  //       bowlerWickets = bowlerWickets + 1;
  //     }
  //   });

  //   return bowlerWickets;
  // };

  // const getBatFirstTotalRuns = (bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var totalRuns = 0;
  //   var totalWickets = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status != "WICKET") {
  //       totalRuns = totalRuns + b.runs;
  //     }

  //     if (b.status == "WICKET") {
  //       totalWickets = totalWickets + 1;
  //     }
  //   });

  //   return totalRuns + "/" + totalWickets;
  // };

  // const getTotalWides = (bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var totalWides = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status == "WIDE") {
  //       totalWides = totalWides + b.runs;
  //     }
  //   });

  //   return totalWides;
  // };

  // const getTotalNoBalls = (bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var totalNoBalls = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status == "NO_BALL") {
  //       totalNoBalls = totalNoBalls + b.runs;
  //     }
  //   });

  //   return totalNoBalls;
  // };

  // const getTotalLegByes = (bowls: any) => {
  //   if (bowls == null) {
  //     return "-";
  //   }

  //   var totalLegByes = 0;

  //   bowls.filter(function (b: any) {
  //     if (b.status == "LEG_BYE") {
  //       totalLegByes = totalLegByes + b.runs;
  //     }
  //   });

  //   return totalLegByes;
  // };

  const getPlayer = (playerId: any, teamPlayers: any) => {
    if (!playerId) {
      return "";
    }

    var player = teamPlayers.filter(function (p: any) {
      return p._id == playerId;
    });

    return player[0];
  };

  const updateCurrentPlayers = async () => {
    if (match.status == "NOT_STARTED") {
      alert("Please play toss first!");

      return false;
    }

    if (
      match.status == "BAT_FIRST_WIN" ||
      match.status == "BALL_FIRST_WIN" ||
      match.status == "MATCH_TIED"
    ) {
      alert("Maytch is already finalized!");

      return false;
    }

    if (!currentBatsman1) {
      alert("Please select first batsman!");

      return false;
    }

    if (!currentBowler) {
      alert("Please select current bowler!");

      return false;
    }

    if (currentBatsman1 == currentBatsman2) {
      alert("Same player selected as both batsman1 & batsman2");

      return false;
    }

    var currentPlayers = [];

    if (currentBatsman1) {
      currentPlayers.push(currentBatsman1);
    }

    if (currentBatsman2) {
      currentPlayers.push(currentBatsman2);
    }

    await MatchService.updateQuickCurrentPlayers({
      matchId: match._id,
      battingTeamId: match.batFirst,
      currentlyBattingPlayers: currentPlayers,
      currentlyBowlingPlayer: currentBowler,
    })

      .then((response) => response)
      .then((data) => {
        // fetchTournament();
        fetchUser();
        if (!data.success) {
          alert(data.message);
        } else {
          <ToastContainer
            position="top-right"
            autoClose={3000}
            limit={1}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />;
          toast.info("Current Players Updated!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          <ToastContainer />;
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const updateRuns = async () => {
    console.log("inside update scoere in qouick match ");

    if (match?.status == "NOT_STARTED") {
      alert("Please play toss first!");

      return false;
    }

    if (
      match?.status == "BAT_FIRST_WIN" ||
      match?.status == "BALL_FIRST_WIN" ||
      match?.status == "MATCH_TIED"
    ) {
      alert("Maytch is already finalized!");

      return false;
    }

    if (!currentBat) {
      alert("Please select current batsman!");

      return false;
    }

    if (currentBat) {
      const status = getBatterCurrentStatus(
        currentBat,
        match.batFirstScoreboard.playerBowls
      );
      if (status != "not out") {
        alert("Please select not out batsman!");

        return false;
      }
    }

    if (!currentBowler) {
      alert("Please select current bowler!");

      return false;
    }

    await MatchService.updateQuickMatchScore({
      matchId: match?._id,
      battingTeamId: match?.batFirst,
      currentBattingPlayer: currentBat,
      currentBowlingPlayer: currentBowler,
      runs: ballRuns,
      boundary: false,
      status: runStatus,
    })

      .then((response) => response)
      .then((data) => {
        // fetchTournament();
        fetchUser();
        const batsman1 = currentBatsmanPair[0];
        const batsman2 = currentBatsmanPair[1];
        if (runStatus == "RUNS") {
          if (currentBat == batsman1) {
            if (ballRuns % 2 != 0) {
              setCurrentBat(batsman2);
            }
          } else if (currentBat == batsman2) {
            if (ballRuns % 2 != 0) {
              setCurrentBat(batsman1);
            }
          }
        } else if (runStatus == "WICKET") {
          setCurrentBat("");
        } else {
        }
        setBallRuns(0);
        setBallExtraRuns(0);
        setRunStatus("RUNS");

        if (!data.success) {
          alert(data.message);
        } else {
          // console.log("res.data=======>",data.data)
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const updateExtraRuns = async () => {
    if (match.status == "NOT_STARTED") {
      alert("Please play toss first!");

      return false;
    }

    if (
      match.status == "BAT_FIRST_WIN" ||
      match.status == "BALL_FIRST_WIN" ||
      match.status == "MATCH_TIED"
    ) {
      alert("Maytch is already finalized!");

      return false;
    }

    if (!currentBowler) {
      alert("Please select current bowler!");

      return false;
    }

    if (!extraRunType) {
      alert("Please select extra run type!");

      return false;
    }

    if (ballExtraRuns == 0) {
      alert("Extra run count should not be 0!");

      return false;
    }
    if (!currentBat) {
      alert("Please select current batsman!");

      return false;
    }

    if (currentBat) {
      const status = getBatterCurrentStatus(
        currentBat,
        match.batFirstScoreboard.playerBowls
      );
      if (status != "not out") {
        alert("Please select not out batsman!");

        return false;
      }
    }

    await MatchService.updateQuickExtraRuns({
      matchId: match._id,
      battingTeamId: match.batFirst,
      batsmanId: currentBat,
      currentBowlingPlayer: currentBowler,
      runs: ballExtraRuns,
      boundary: false,
      status: extraRunType,
    })
      .then((response) => response)
      .then((data) => {
        // fetchTournament();
        fetchUser();
        setCurrentBat("");
        setBallRuns(0);
        setBallExtraRuns(0);
        setExtraRunType("");
        setRunStatus("RUNS");

        if (!data.success) {
          alert(data.message);
        } else {
          //     swal({ icon: "success", title: "Extra Run Updated!" });
          <ToastContainer
            position="top-right"
            autoClose={3000}
            limit={1}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />;
          toast.info("Extra Run Updated!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          <ToastContainer />;
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const deleteLastBall = async () => {
    if (match.status == "NOT_STARTED") {
      alert("Please play toss first!");

      return false;
    }

    if (
      match.status == "BAT_FIRST_WIN" ||
      match.status == "BALL_FIRST_WIN" ||
      match.status == "MATCH_TIED"
    ) {
      alert("Quick Match is already finalized!");

      return false;
    }

    if (window.confirm("Are you sure want to delete last ball?") == true) {
      await MatchService.deleteQuickLastBall({
        matchId: match._id,
        battingTeamId: match.batFirst,
      })
        .then((response) => response)
        .then((data) => {
          //   fetchTournament();
          fetchUser();
          // setCurrentBat("");
          setBallRuns(0);
          setBallExtraRuns(0);
          setExtraRunType("");
          setRunStatus("RUNS");

          if (!data.success) {
            alert(data.message);
          } else {
            swal({ icon: "success", title: "Last Ball Deleted Successfully!" });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };
  const changeScreen = () => {
    history.push(`/admin/quick-score-board2/${matchLiveId}`);
  };
  const currentbatsman1 = (event: any) => {
    setCurrentBat1(event.target.value);
  };
  const currentbatsman2 = (event: any) => {
    setCurrentBat2(event.target.value);
  };
  const currentballer1 = (event: any) => {
    setCurrentBall(event.target.value);
  };
  const startMatch = () => {
    console.log("inside start match");
    if (!currentBat1 || !currentBat2) {
      console.log("inside start match batter check");
      swal({ icon: "error", title: "please select two batsmans" });
    } else if (!currentBall) {
      swal({ icon: "error", title: "Please select a bowller" });
    } else {
      const data = {
        matchId: matchId,
        battingTeamId: match.batFirst,
        currentlyBattingPlayers: [currentBat1, currentBat2],
        currentlyBowlingPlayer: currentBall,
      };
      console.log("match data inside start match", data);
      AdminService.updateQuickMatchCurrentPlayers(data).then((res) => {
        console.log("inside admin service updateQuickMatchCurrentPlayers");
        if (res.success) {
          handleCloseModal();
          swal({ icon: "success", title: "Play Match!" }).then(function () {
            console.log("should fetch user");
            // fetchTournament();
            fetchUser();
            console.log("inside admin service");
          });
        } else {
          swal({
            icon: "error",
            title: res?.error ? res.error : "Something went wrong",
          });
        }
      });
    }
  };
  const previosPage = () => {
    history.push(`/admin/my-matches`);
  };
  const logoNavigation = () => {
    if (user?.userType === Role.SUPER_ADMIN) {
      if (tournamentId) {
        history.push(`/admin/user-management-tournament/${tournamentId}`);
      } else {
        history.goBack();
      }
    } else {
      if (tournamentId) {
        history.push(`/admin/matches/${tournamentId}`);
      } else {
        history.goBack();
      }
    }
  };
  const getOverCountAlert = () => {
    if (match?.batFirstScoreboard?.playerBowls == null) {
      return console.log("match not started");
    } else {
      var totalBalls = 0;

      match?.batFirstScoreboard?.playerBowls?.filter(function (b: any) {
        if (
          b.status == "RUNS" ||
          b.status == "LEG_BYE" ||
          b.status == "WICKET"
        ) {
          totalBalls = totalBalls + 1;
        }
      });
      var remindBalls = totalBalls % 6;
      var overs = (totalBalls - remindBalls) / 6;
      if (remindBalls == 5) {
        // alert("please change the bowler");
        <ToastContainer
          position="top-right"
          autoClose={3000}
          limit={1}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />;
        toast.info("Please change the bowler", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        <ToastContainer />;
      }
    }
  };
  return (
    <>
      <div className="d-md-flex justify-content-between align-items-center dashboard-header">
        <div className="mb-2 d-flex justify-content-center align-items-center">
          <span>
            <i
              className="fa fa-arrow-left f-color-gray-7 pl-3 cursor-p"
              aria-hidden="true"
              onClick={() => previosPage()}
            ></i>
            <span className="dashboard-title-txt pl-3">
              {battingFirstTeam} VS {ballingFirstTeam}
            </span>
          </span>
        </div>
        <div className="mb-2 d-flex justify-content-center align-items-center">
          <span className="cursor-pointer" onClick={logoNavigation}>
            <img src={Logo} alt="cricView360_logo" />
          </span>
        </div>
        <div className="mb-2 d-flex justify-content-center align-items-center">
          <span className="pr-3 f-20 fw-500 font-inter">
            Batting: {battingFirstTeam}
          </span>
        </div>
      </div>

      <div className="container-fluid mt-3">
        <div className="d-flex justify-content-center align-items-center border-r-10">
          <div className="row inner-box d-flex justify-content-center align-items-center">
            <div className="col-xl-5  d-md-flex justify-content-between align-items-center ">
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <span className="dropdown-text mb-2">Runs</span>
                <div className="dropdown">
                  <select
                    className="form-select shadow score-dropdown font-inter"
                    value={ballRuns}
                    onChange={(e) =>
                      setBallRuns((prev) => parseInt(e.target.value))
                    }
                  >
                    <option value="0" className="font-inter">
                      0 run
                    </option>
                    <option value="1" className="font-inter">
                      1 run
                    </option>
                    <option value="2" className="font-inter">
                      2 runs
                    </option>
                    <option value="3" className="font-inter">
                      3 runs
                    </option>
                    <option value="4" className="font-inter">
                      4 runs
                    </option>
                    <option value="5" className="font-inter">
                      5 runs
                    </option>
                    <option value="6" className="font-inter">
                      6 runs
                    </option>
                    <option value="7" className="font-inter">
                      7 runs
                    </option>
                    <option value="8" className="font-inter">
                      8 runs
                    </option>
                    <option value="9" className="font-inter">
                      9 runs
                    </option>
                    <option value="10" className="font-inter">
                      10 runs
                    </option>
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <span className="dropdown-text mb-2">Runs / Wicket</span>
                <div className="dropdown">
                  <select
                    className="form-select shadow score-dropdown font-inte"
                    value={runStatus}
                    onChange={(e) => setRunStatus((prev) => e.target.value)}
                  >
                    <option value="RUNS" className="font-inter">
                      RUNS
                    </option>
                    <option value="WICKET" className="font-inter">
                      WICKET
                    </option>
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <span className="dropdown-text mb-2">Current Batter</span>
                <div className="dropdown">
                  <select
                    className="form-select shadow score-dropdown font-inte"
                    value={currentBat}
                    onChange={(e) => setCurrentBat((prev) => e.target.value)}
                  >
                    <option value="">No One</option>
                    {match?.batFirstScoreboard &&
                      match?.batFirstScoreboard.currentlyBatting.map(
                        (player: any, index: any) => (
                          <option
                            value={player}
                            key={index}
                            className="font-inter"
                          >
                            {
                              getPlayer(
                                player,
                                batFirstTeam(match?.batFirst, match?.teams)
                                  .players
                              )?.name
                            }
                          </option>
                        )
                      )}
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center mb-3">
                <div
                  onClick={() => {
                    updateRuns();
                  }}
                  className="dashboard-update-btn d-flex justify-content-center align-items-center cursor-p"
                >
                  Update runs
                </div>
              </div>
            </div>
            <div className="col-xl-1 d-flex justify-content-center">
              <div className="horizontal-divider"></div>
            </div>
            <div className="col-xl-5  d-md-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <span className="dropdown-text mb-2">Extra Runs</span>
                <div className="dropdown">
                  <select
                    className="form-select shadow score-dropdown font-inter"
                    value={ballExtraRuns}
                    onChange={(e) =>
                      setBallExtraRuns((prev) => parseInt(e.target.value))
                    }
                  >
                    <option value="0" className="font-inter">
                      0 run
                    </option>
                    <option value="1" className="font-inter">
                      1 run
                    </option>
                    <option value="2" className="font-inter">
                      2 runs
                    </option>
                    <option value="3" className="font-inter">
                      3 runs
                    </option>
                    <option value="4" className="font-inter">
                      4 runs
                    </option>
                    <option value="5" className="font-inter">
                      5 runs
                    </option>
                    <option value="6" className="font-inter">
                      6 runs
                    </option>
                    <option value="7" className="font-inter">
                      7 runs
                    </option>
                    <option value="8" className="font-inter">
                      8 runs
                    </option>
                    <option value="9" className="font-inter">
                      9 runs
                    </option>
                    <option value="10" className="font-inter">
                      10 runs
                    </option>
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <span className="dropdown-text mb-2">Extra Run Types</span>
                <div className="dropdown">
                  <select
                    className="form-select shadow score-dropdown font-inter"
                    value={extraRunType}
                    onChange={(e) => setExtraRunType((prev) => e.target.value)}
                  >
                    <option value="" className="font-inter">
                      NONE
                    </option>
                    <option value="WIDE" className="font-inter">
                      WIDE
                    </option>
                    <option value="NO_BALL" className="font-inter">
                      NO BALL
                    </option>
                    <option value="LEG_BYE" className="font-inter">
                      LEG BYE
                    </option>
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <div
                  onClick={updateExtraRuns}
                  className="dashboard-update-btn d-flex justify-content-center align-items-center mb-2 cursor-p"
                >
                  Update Extra
                </div>
                <div
                  onClick={deleteLastBall}
                  className="dashboard-update-btn-extra d-flex justify-content-center align-items-center cursor-p"
                >
                  Delete last ball
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  className="dashboard-next-btn"
                  onClick={() => changeScreen()}
                >
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <div className="d-flex flex-column">
                      <span className="font-inter">Next Score</span>
                      <span className="font-inter">Board</span>
                    </div>
                    <div>
                      <span className="fas fa-chevron-right ml-1"></span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-3 ">
        <div className="row">
          <div className="col-xl-4 mt-3 ">
            <div className="container-fluid bg-white pt-3 pb-3 border-r-10">
              <div className="d-flex justify-content-center align-items-center dashboard-table-head ">
                <img src={cricketBall} alt="" className="mr-2" />{" "}
                <span className="dashboard-table-title-txt font-inter">
                  Balling Team : {ballingFirstTeam}
                </span>
              </div>
              <div className="table-responsive font-inter">
                <table className="table mb-0 font-inter f-18">
                  <thead className="table-head font-inter">
                    <tr>
                      <th scope="col">
                        <span className="font-inter f-17">Bowler</span>
                      </th>
                      <th scope="col">
                        <span className="font-inter f-17">Current Bowler</span>
                      </th>
                      <th scope="col">
                        <span className="font-inter f-17">Runs/Wickets</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-inter f-18">
                    <tr>
                      <th scope="row">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="dropdown">
                            <select
                              className="form-select shadow font-inter f-18"
                              value={currentBowler}
                              onChange={(e) => {
                                setCurrentBowler((prev) => e.target.value);
                              }}
                            >
                              <option value="" className="font-inter f-18">
                                No One
                              </option>
                              {match?.batFirstScoreboard &&
                                ballFirstTeam(
                                  match?.batFirst,
                                  match?.teams
                                ).players.map((player: any, index: number) => (
                                  <option
                                    value={player._id}
                                    key={index}
                                    className="font-inter f-18"
                                  >
                                    {player.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </th>
                      <td className="font-inter f-17">
                        {match?.batFirstScoreboard &&
                          getPlayer(
                            match?.batFirstScoreboard.currentlyBowling,
                            ballFirstTeam(match?.batFirst, match?.teams).players
                          )?.name}
                      </td>
                      <td className="font-inter f-17">
                        {" "}
                        {match?.batFirstScoreboard &&
                          getBowlerRuns(
                            match?.batFirstScoreboard.currentlyBowling,
                            match?.batFirstScoreboard.playerBowls
                          )}{" "}
                        /
                        {match?.batFirstScoreboard &&
                          getBowlerWickets(
                            match?.batFirstScoreboard.currentlyBowling,
                            match?.batFirstScoreboard.playerBowls
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center align-items-center dashboard-table-head ">
                <img src={cricketBat} alt="" className="mr-2" />{" "}
                <span className="dashboard-table-title-txt">
                  Batting Team : {battingFirstTeam}
                </span>
              </div>
              <div className="table-responsive font-inter">
                <table className="table font-inter f-18">
                  <thead className="table-head">
                    <tr>
                      <th scope="col">Batter</th>
                      <th scope="col">Currently Playing</th>
                      <th scope="col">Runs</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="dropdown">
                            <select
                              className="form-select shadow font-inter"
                              value={currentBatsman1}
                              onChange={(e) =>
                                setCurrentBatsman1((prev) => e.target.value)
                              }
                            >
                              <option value="">No One</option>
                              {match?.batFirstScoreboard &&
                                batFirstTeam(
                                  match?.batFirst,
                                  match?.teams
                                ).players.map((player: any, index: number) =>
                                  currentBatsman2 != player._id ? (
                                    <option
                                      value={player._id}
                                      key={index}
                                      className="font-inter"
                                    >
                                      {player.name}
                                    </option>
                                  ) : null
                                )}
                            </select>
                          </div>
                        </div>
                      </th>
                      <td>
                        {match?.batFirstScoreboard &&
                          getPlayer(
                            match?.batFirstScoreboard.currentlyBatting[0],
                            batFirstTeam(match?.batFirst, match?.teams).players
                          )?.name}
                      </td>
                      <td className="font-inter f-17">
                        {match?.batFirstScoreboard &&
                          getBatterRuns(
                            match?.batFirstScoreboard.currentlyBatting[0],
                            match?.batFirstScoreboard.playerBowls
                          )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div className="d-flex justify-content-start align-items-center">
                          <div className="dropdown">
                            <select
                              className="form-select shadow font-inter"
                              value={currentBatsman2}
                              onChange={(e) =>
                                setCurrentBatsman2((prev) => e.target.value)
                              }
                            >
                              <option value="" className="font-inter">
                                No One
                              </option>
                              {match?.batSecondScoreboard &&
                                batFirstTeam(
                                  match?.batFirst,
                                  match?.teams
                                ).players.map((player: any, index: number) =>
                                  currentBatsman1 != player._id ? (
                                    <option
                                      value={player._id}
                                      key={index}
                                      className="font-inter"
                                    >
                                      {player.name}
                                    </option>
                                  ) : null
                                )}
                            </select>
                          </div>
                        </div>
                      </th>
                      <td className="font-inter f-17">
                        {match?.batFirstScoreboard &&
                          getPlayer(
                            match?.batFirstScoreboard.currentlyBatting[1],
                            batFirstTeam(match?.batFirst, match?.teams).players
                          )?.name}
                      </td>
                      <td className="font-inter f-17">
                        {match?.batFirstScoreboard &&
                          getBatterRuns(
                            match?.batFirstScoreboard.currentlyBatting[1],
                            match?.batFirstScoreboard.playerBowls
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-start align-items-center mb-3 ">
                <div
                  onClick={updateCurrentPlayers}
                  className="dashboard-update-btn-table d-flex justify-content-center align-items-center font-inter cursor-p"
                >
                  Update
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 ">
            <BallScore
              toggleNextScoreboard={toggleNextScoreboard}
              // fetchTournament={fetchTournament}
              fetchUser={fetchUser}
              battingFirstTeam={battingFirstTeam}
              ballingFirstTeam={ballingFirstTeam}
              match={match}
              battingFirst={true}
            />
            <div className="row w-100  pl-15 pl-md-0">
              <div className="col-xl-6 w-100 mt-4 pl-md-30">
                <div className="bg-white">
                  <div className="table-responsive">
                    <table className="table font-inter">
                      <thead className="tbody-2">
                        <tr className="bg-blue5">
                          <td colSpan={6}>
                            <span className=" text-black line-h-24 f-20 font-inter">
                              Scoreboard
                            </span>
                          </td>
                        </tr>
                        <tr className="bg-gray7">
                          <td>Batting</td>
                          <td></td>
                          <td>R</td>
                          <td>B</td>
                          {/* <td>M</td> */}
                          <td>4s</td>
                          <td>6s</td>
                        </tr>
                      </thead>
                      <tbody className="tbody-2">
                        {match?.batFirstScoreboard &&
                          batFirstTeam(match.batFirst, match.teams).players.map(
                            (player: any, index: any) => (
                              <tr key={index}>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray">
                                    {player.name}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatterCurrentStatus(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatterRuns(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td>
                                  {getBatterFacedBalls(
                                    player._id,
                                    match.batFirstScoreboard.playerBowls
                                  )}
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatter4Boundaries(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="fw-400 f-15 f-color-gray mr-1">
                                    {getBatter6Boundaries(
                                      player._id,
                                      match.batFirstScoreboard.playerBowls
                                    )}
                                  </span>
                                </td>
                              </tr>
                            )
                          )}
                        <tr className="bg-gray7">
                          <td>
                            <span className=" fw-500 f-15 f-color-black font-inter">
                              Total
                            </span>
                          </td>
                          <td colSpan={2}>
                            <span className=" fw-500 f-15 f-color-black font-inter"></span>
                          </td>
                          <td colSpan={3}>
                            <span className="fw-500 f-15 f-color-black font-inter">
                              {match?.batFirstScoreboard &&
                                getBatFirstTotalRuns(
                                  match?.batFirstScoreboard?.playerBowls
                                )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6}>
                            <div className=" d-flex justify-content-center align-items-center  ">
                              <div>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  No Ball:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batFirstScoreboard &&
                                    getTotalNoBalls(
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </div>
                              <div>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  Wide:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batFirstScoreboard &&
                                    getTotalWides(
                                      match?.batFirstScoreboard?.playerBowls
                                    )}
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 ml-2 mt-1 mb-1 font-inter">
                                  Leg Bye:
                                </span>
                                <span className="text-center fw-400 f-13 f-color-black2 mr-2 ml-1 mt-1 mb-1 font-inter">
                                  {match?.batFirstScoreboard &&
                                    getTotalLegByes(
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 w-100 pr-pl-0 mt-4 div2">
                <div className="bg-white">
                  <div className="table-responsive font-inter">
                    <table className="table font-inter">
                      <thead className="tbody-2 font-inter">
                        <tr className="bg-blue5 font-inter">
                          <td colSpan={8}>
                            <span className=" text-black line-h-24 f-20 mt-12 mb-12 font-inter">
                              Balling Stat
                            </span>
                          </td>
                        </tr>
                        <tr className="bg-gray7 font-inter">
                          <td>Balling</td>
                          <td></td>
                          <td>B</td>
                          <td>O</td>
                          <td>R</td>
                          <td>W</td>
                          <td>WD</td>
                          <td>NB</td>
                        </tr>
                      </thead>
                      <tbody className="tbody-2 font-inter">
                        {match?.batFirstScoreboard &&
                          ballFirstTeam(
                            match?.batFirst,
                            match?.teams
                          )?.players?.map((player: any, index: any) => (
                            <tr className="" key={index}>
                              <td>{player.name}</td>
                              <td className="min-w-30"></td>
                              <td>
                                <span className="fw-400 f-15 f-color-gray">
                                  {match?.batFirstScoreboard &&
                                    getBowlerDiliveredRemainBalls(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                                <span className="fw-400 f-15 f-color-gray">
                                  {match?.batFirstScoreboard &&
                                    getBowlerDiliveredOvers(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                                <span className="fw-400 f-15 f-color-gray">
                                  {match?.batFirstScoreboard &&
                                    getBowlerRuns(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                                <span className="fw-400 f-15 f-color-gray">
                                  {match?.batFirstScoreboard &&
                                    getBowlerWickets(
                                      player._id,
                                      match?.batFirstScoreboard.playerBowls
                                    )}
                                </span>
                              </td>
                              <td>
                                {match?.batFirstScoreboard &&
                                  getBowlerWides(
                                    player._id,
                                    match?.batFirstScoreboard.playerBowls
                                  )}
                              </td>
                              <td>
                                {match?.batFirstScoreboard &&
                                  getBowlerNoBalls(
                                    player._id,
                                    match?.batFirstScoreboard.playerBowls
                                  )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal isOpen={isModalOpen}>
        <div>
          <div className="d-flex justify-content-center align-item-center mb-2">
            <span className="fw-400 f-15 f-color-black3 font-Poppins text-left">
              Current Batsmans
            </span>
          </div>
          <div className="mb-2 d-flex justify-content-center align-item-center">
            <select
              className="select-dropdown3 fw-400 f-15 f-color-black3 font-Poppins mr-1"
              onChange={(e) => currentbatsman1(e)}
            >
              <option className="fw-400 f-15 f-color-black3 font-Poppins">
                Batsman 1
              </option>
              {battingFirstTeamMembers?.map((team: any, index: number) => (
                <option
                  key={index}
                  className="fw-400 f-15 f-color-black3 font-Poppins"
                  value={team?._id}
                >
                  {team?.name}
                </option>
              ))}
            </select>
            <select
              className="select-dropdown3 fw-400 f-15 f-color-black3 font-Poppins mr-1"
              onChange={(e) => currentbatsman2(e)}
            >
              <option className="fw-400 f-15 f-color-black3 font-Poppins">
                Batsman 2
              </option>
              {battingFirstTeamMembers?.map((team: any, index: number) => (
                <option
                  key={index}
                  className="fw-400 f-15 f-color-black3 font-Poppins"
                  value={team?._id}
                >
                  {team?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex justify-content-center align-item-center mb-2">
            <span className="fw-400 f-15 f-color-black3 font-Poppins text-left">
              Current Bowler
            </span>
          </div>
          <div className="mb-2 d-flex justify-content-center align-item-center">
            <select
              className="select-dropdown fw-400 f-15 f-color-black3 font-Poppins"
              onChange={(e) => currentballer1(e)}
            >
              <option className="fw-400 f-15 f-color-black3 font-Poppins">
                Select Bowler
              </option>
              {ballingFirstTeamMembers?.map((team: any, index: number) => (
                <option
                  key={index}
                  className="fw-400 f-15 f-color-black3 font-Poppins"
                  value={team?._id}
                >
                  {team?.name}
                </option>
              ))}
            </select>
          </div>

          <div className="row pt-2 pl-3 pr-3 d-flex justify-content-center align-items-center">
            <div className="arrow-btn2" onClick={() => startMatch()}>
              <span className="f-17 f-color-white fw-500 font-Poppins">
                Play
              </span>
              <img className="arrow-icon-size ml-3" src={RightArrow} />
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default QuickDashboard;
